import React from "react";
import BaseComponent from '../baseComponent'
import {cookiesConstants} from "../../constants";
import {sessionManager} from '../../managers/sessionManager'
import {Column} from "simple-flexbox";

class MediaClientComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: ''
        }
    }

    componentDidMount() {
        let details = sessionManager.getDataFromCookies(cookiesConstants.USER_DETAIL);
        let id = JSON.parse(details).sub;
        this.setState({id: id});
    }


    render() {
        return (
            <Column style={{backgroundColor: '#110a4e'}} className="h-100-vh fc-white">
                <div className="margin-auto text-align-center">
                    <h1>AppsStore.ai</h1>
                    <span>{this.state.id ? this.state.id : ''}</span>
                    {/*<div className="m-t-sm display-flex fs-18">Install the AppsStore client. Run the following command*/}
                    {/*    on the terminal/Command Prompt.*/}
                    {/*</div>*/}

                    {/*<div className="m-t-30 display-flex fs-16">Ubuntu*/}
                    {/*</div>*/}
                    {/*<div className="m-t-sm display-flex fs-16">*/}
                    {/*    curl -q*/}
                    {/*    'https://www.appsstore.ai:3002/download?MEDIA_CLIENT_ID={this.state.id ? this.state.id : ''}'*/}
                    {/*    -o "temp.sh"*/}
                    {/*    && bash temp.sh*/}
                    {/*</div>*/}

                    {/*<div className="m-t-30 display-flex fs-16">Mac*/}
                    {/*</div>*/}
                    {/*<div className="m-t-sm display-flex fs-16">*/}
                    {/*    curl -q*/}
                    {/*    'https://www.appsstore.ai:3002/download?MEDIA_CLIENT_ID={this.state.id ? this.state.id : ''}'*/}
                    {/*    -o "temp.sh"*/}
                    {/*    && bash temp.sh*/}
                    {/*</div>*/}
                    {/*<div className="m-t-100 display-flex w-300  margin-auto fs-14">Check out the instructions <p><a*/}
                    {/*    href="/client-download" className="m-l-xs">  here. </a></p>*/}
                    {/*</div>*/}
                </div>

            </Column>
        );
    }
}


export default MediaClientComponent;
