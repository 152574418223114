import React from "react";
import BaseComponent from '../baseComponent'
import MyCamerasComponent from './myCamerasComponent'
import Utils, {dispatchAction} from "../../utility";
import {connect} from "react-redux";
import {actionsConst, apiFailureConstants, cookiesConstants, pathConstants} from "../../constants";
import HeaderComponent from "../common/headerComponent";
import {MADService, DeviceUserMapService, VideoStreamingService} from "../../services";
import {deleteCamera} from '../../services/deviceUserMapService'
import {sessionManager} from "../../managers/sessionManager";
import utility from "../../utility";
import FooterComponent2 from "../common/footerComponentSecond";

class MyCameras extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            deviceID: "",
            MADDetail: {},
            userMADDetails: [],
            videoStreamUrl: "",
            cameraList: [],
            deviceMAD: [],
            menuOpen: true,
            loaded: true,
            cameraName: "",
            executingServer: '',
            localIP: '',
            image: '',
            confirmation: false,
            mad: {},
            Port: '',
            ffmpegFailureCount: 0,
            mobileMenuOpen: false,
            selectedDevice: {},
            threshold: [30],
            loading:''

        };
    }

    handleChange = async (deviceID, mad, checked) => {
        // let connectedDevice = [];
        // for (let index = 0; index < mad.connectedDevice.length; index++) {
        //     let devices = {};
        //     if (mad.connectedDevice[index] && mad.connectedDevice[index] && mad.connectedDevice[index].deviceID !== deviceID && !mad.connectedDevice[index].isDeleted) {
        //         devices.deviceName = mad.connectedDevice[index].deviceName;
        //         devices.deviceID = mad.connectedDevice[index].deviceID;
        //         devices.rtsp = mad.connectedDevice[index].rtsp;
        //         devices.executingServer = mad.connectedDevice[index].executingServer ? mad.connectedDevice[index].executingServer : "cloud";
        //         connectedDevice.push(devices);
        //     }
        // }
        // await this.updateMAD(mad, connectedDevice);
        // await this.addMADSchedule(mad);
        // await this.removeDeviceSchedule(deviceID);
        let deviceDetails = this.state.cameraList.find((device) => {
            return device._id === this.state.deviceID;
        });
        if (!deviceDetails || Object.keys(deviceDetails).length <= 0){
            return;
        }
        if (!mad){
            return;
        }
        let connectedDevice = [];
        // if device does not exists in MAD then add device details in MAD otherwise change the status of device in MAD
        if (!checked) {
            let devices = {
                "deviceName": deviceDetails.entityData.deviceName,
                "deviceID": deviceDetails._id,
                "rtsp": deviceDetails.entityData.rtsp.url,
                "executingServer": deviceDetails.entityData.executingServer ? deviceDetails.entityData.executingServer : "cloud"
            };
            connectedDevice = [...mad.connectedDevice, devices];
        } else {
            let devices = {};
            for (let index = 0; index < mad.connectedDevice.length; index++) {
                devices = {};
                if (mad.connectedDevice[index] && Object.keys(mad.connectedDevice[index]).length > 0 && mad.connectedDevice[index].deviceID !== deviceDetails._id && !mad.connectedDevice[index].isDeleted) {
                    devices.deviceName = mad.connectedDevice[index].deviceName;
                    devices.deviceID = mad.connectedDevice[index].deviceID;
                    devices.rtsp = mad.connectedDevice[index].rtsp;
                    devices.executingServer = mad.connectedDevice[index].executingServer;
                    connectedDevice.push(devices);
                }
            }
            if (deviceDetails.entityData.executingServer === 'webCam') {
                await this.setState({isWebCam: false});
            }
            await this.removeDeviceSchedule(deviceDetails._id);
        }
        this.setState({loaded: true});
        await this.updateMAD(mad, connectedDevice);
        await this.addMADSchedule(mad);
        this.componentDidMount();
        this.setState({loaded: false});
    };

    removeDeviceSchedule = async (deviceID) => {
        let findObj = {
            "deviceID": deviceID
        };
        await Utils.parseResponse(MADService.removeDeviceSchedule(findObj));
    };

    addMADSchedule = async (madDetails) => {
        if (!madDetails || !madDetails._id || !madDetails.schedules || madDetails.schedules.length <= 0)
            return;
        let reqObj = {
            "madID": madDetails._id,
            "schedules": madDetails.schedules,
            "isRecurring": 1
        };
        await Utils.parseResponse(MADService.addMADSchedule(reqObj));
    };

    updateMAD = async (mad, connectedDevice) => {
        let findObj = {
            "userID": mad.userID,
            "_id": mad._id,
            "appMeta.appID": mad.appMeta.appID
        };
        let updateObj = {
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "connectedDevice": connectedDevice
        };
        let [error, updateMadResponse] = await Utils.parseResponse(MADService.updateMAD(findObj, updateObj));
        this.componentDidMount();
    };

    async componentDidMount() {
        if (!this.props.isLoggedIn) {
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        await this.getUserEntity();
        await this.userAllDevices();
        await this.userAllMads();
        setTimeout(() => {
            this.setState({loaded: false})
        }, 10000); 
    }

    editCameraDetails = async () => {
        let cameraDetails = this.state.cameraList.find((device) => {
            return device._id === this.state.deviceID;
        });
        if (!cameraDetails || Object.keys(cameraDetails).length <= 0)
            return;
        this.props.dispatchAction(actionsConst.CAMERA_EDIT, {cameraDetails: cameraDetails});
        if (cameraDetails.entityData.executingServer === "webCam") {
            return Utils.navigateToPath(pathConstants.DEVICE_CAMERA_SIGN_UP);
        }
        return Utils.navigateToPath(pathConstants.CAMERA_SIGN_UP);
    };

    deleteCamera = async () => {
        this.setState({
            loading: true
        })
        let cameraDetails = this.state.cameraList.find((device) => {
            return device._id === this.state.deviceID;
        });
        this.getDeviceMad(cameraDetails);
        try {
            await deleteCamera(cameraDetails);
            if (this.state.deviceMAD && this.state.deviceMAD.length > 0) {
                this.state.deviceMAD.forEach(mad => {
                    this.handleChange(cameraDetails._id, mad ,true);
                })
            }
            await this.componentDidMount();
            utility.apiSuccessToast('Camera Deleted Successfully');
        } catch (error) {
            // console.log(error);
        }
        this.setState({confirmation: false})
        this.setState({
            loading: false
        })
    };

    async getUserEntity() {
        // let deviceImage = sessionManager.getDataFromCookies(cookiesConstants.USER_LOCAL_IP_IMAGE_URL);
        let IP = '';
        let PORT = '';
        let findObj = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : ''
                // 'entityData.userDetails.userID': 'test'
            }),
            limit: 0, skip: 0
        };
        let [error, entityDetail] = await Utils.parseResponse(DeviceUserMapService.getEntityDetails(findObj));
        if (error || !entityDetail)
            return false;
        if (entityDetail && entityDetail.length > 0) {
            IP = entityDetail[0].entityData.IP;
            PORT = entityDetail[0].entityData.port;
            await this.setState({localIP: IP, Port: PORT});
        }
    }

    async componentWillUnmount() {
        this.state.cameraList.map((devices) => {
            this.createVideoStream(false, devices);
        })
    }

    userAllDevices = async () => {
        let userID = sessionManager.getDataFromCookies(cookiesConstants.USER_ID);
        // let userID = "google-oauth2|117602483847868762045";
        if (!userID || !userID.length) {
            // Utils.apiFailureToast(apiFailureConstants.GET_USER_DEVICES);
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        let query = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': userID
            }),
            limit: 0, skip: 0
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.getDevicesByQueryObj(query));
        if (error || !userDevicesResponse || userDevicesResponse.length <= 0) {
            // Utils.apiFailureToast(apiFailureConstants.GET_USER_DEVICES);
            Utils.navigateToPath(pathConstants.USER_DEVICES);
            return;
        }
        this.setState({cameraList: userDevicesResponse});
    };

    userAllMads = async () => {
        let userID = sessionManager.getDataFromCookies(cookiesConstants.USER_ID);
        // let userID = "google-oauth2|117602483847868762045";
        if (!userID || !userID.length) {
            return;
        }
        let [error, userMadResponse] = await Utils.parseResponse(MADService.getUserMAD(userID));
        if (error || !userMadResponse) {
            return;
        }
        this.setState({userMADDetails: userMadResponse});
        if (this.state.cameraList && this.state.cameraList[0]) {
            this.getDeviceMad(this.state.cameraList[0]);
        }
    };

    getDeviceMad = (deviceDetails) => {
        if (!deviceDetails || !deviceDetails.entityData){
            return false;
        }
        let image = '';
        this.setState({selectedDevice: deviceDetails});
        if (deviceDetails && deviceDetails.entityData && deviceDetails.entityData.executingServer) {
            if (!deviceDetails.entityData.ffmpegFailureCount)
                this.setState({ffmpegFailureCount: 0});
            else
                this.setState({ffmpegFailureCount: deviceDetails.entityData.ffmpegFailureCount});
            this.setState({deviceID: deviceDetails._id});
            this.setState({executingServer: deviceDetails.entityData.executingServer});
            if (deviceDetails.entityData.executingServer === 'local') {
                image = 'https://' + this.state.localIP + ':' + this.state.Port + '/public/cameraRecentImages/' + deviceDetails._id + '.jpg';
                this.setState({image: image});
            }
        } else
            this.setState({executingServer: ''})
        if (deviceDetails && deviceDetails.entityData && deviceDetails.entityData.deviceName) {
            this.setState({cameraName: deviceDetails.entityData.deviceName});
        }
        let deviceMad = [];
        for (let index = 0; index < this.state.userMADDetails.length; index++) {
            for (let connectedDevice = 0; connectedDevice < this.state.userMADDetails[index].connectedDevice.length; connectedDevice++) {
                if (deviceDetails._id === this.state.userMADDetails[index].connectedDevice[connectedDevice].deviceID)
                    deviceMad.push(this.state.userMADDetails[index]);

            }
        }
        this.setState({deviceMAD: deviceMad, mobileMenuOpen: false});
        this.videoStreamUrl(deviceDetails._id);

    };

    onDeviceClicked = (device) => {
        this.getDeviceMad(device);
    };

    addAnotherDevice = (path) => {
        Utils.navigateToPath(path);
    };

    videoStreamUrl = async (deviceID) => {
        let rtspUrl = this.state.cameraList.find((devices) => {
            return deviceID === devices._id
        });
        if (!rtspUrl || !rtspUrl.entityData)
            return;
        await this.createVideoStream(true, rtspUrl);
        let videoStreamUrl = "https://www.appsstore.ai:3003/";
        this.setState({videoStreamUrl: videoStreamUrl + this.state.deviceID + '?width=460&height=340'})

    };

    createVideoStream = async (isStream, rtspUrl) => {
        if (rtspUrl.entityData.executingServer === "local" || rtspUrl.entityData.executingServer === "webCam")
            return;
        let rtsp = {
            "rtsp": {
                "uri": rtspUrl.entityData.rtsp.url,
                "resolution": "1080*1200",
                "rate": 0.001,
                "quality": 10
            },
            "isStream": isStream,
            "id": rtspUrl._id
        };

        let [error, videoStreamResponse] = await Utils.parseResponse(VideoStreamingService.createVideoStream(rtsp));
        if (error || !videoStreamResponse) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            return false;
        }
        return videoStreamResponse;
    };

    toggleMenu = () => {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    navigateToApps = () => {
        Utils.navigateToPath(pathConstants.MY_APPS);
    }

    handleConfirmation = () => {
        this.setState({confirmation: !this.state.confirmation})
    }

    handleMobileMenu = () => {
        this.setState({mobileMenuOpen: !this.state.mobileMenuOpen})
    }


    onSliderChange = async (threshold) => {
        let requestBody = {
            "rtsp": this.state.selectedDevice.entityData.rtsp.url,
            "deviceID": this.state.selectedDevice._id,
            "deviceName": this.state.selectedDevice.entityData.deviceName,
            "executingServer": this.state.selectedDevice.entityData.executingServer,
            threshold: threshold[0]
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(MADService.updateMADConnectedDevice(requestBody));
    };


    render() {
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
              <HeaderComponent />
            </div>
            {/*<hr className="m-t-0 m-b-0"/>*/}
            <div className="base-container px-120 md-px-24 xxl-px-120 xl-px-70">
              <MyCamerasComponent
                state={this.state}
                toggleMenu={this.toggleMenu}
                userMADDetails={this.state.userMADDetails}
                MADDetail={this.state.MADDetail}
                videoStreamUrl={this.state.videoStreamUrl}
                cameraList={this.state.cameraList}
                deviceMAD={this.state.deviceMAD}
                deviceID={this.state.deviceID}
                ffmpegFailureCount={this.state.ffmpegFailureCount}
                onDeviceClicked={this.onDeviceClicked}
                handleChange={this.handleChange}
                addAnotherDevice={this.addAnotherDevice}
                navigateToApps={this.navigateToApps}
                editCameraDetails={this.editCameraDetails}
                cameraName={this.state.cameraName}
                deleteCamera={this.deleteCamera}
                handleConfirmation={this.handleConfirmation}
                handleMobileMenu={this.handleMobileMenu}
                onSliderChange={this.onSliderChange}
              />
            </div>
            {/* <FooterComponent /> */}
            <FooterComponent2 />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(MyCameras);
