import React from "react";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";

const TopBar = () => {
  return (
    <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70 m-t-32 display-flex justify-between align-items-center">
      <Link to="/">
        <img
          src="/images/appsStoreAiLogo.svg"
          alt="Appsstore.ai"
          height="23px"
          className="h-25px-m "
        />
      </Link>
      <div>
        <PopupButton
          className="blue-styled-btn br-25 w-216"
          url={process.env.REACT_APP_CALENDLY}
          rootElement={document.getElementById("root")}
          text="Get a free consultation"
        />
      </div>
    </div>
  );
};

export default TopBar;
