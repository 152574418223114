import React from "react";
import BaseComponent from '../baseComponent'
import AppDetailComponent from './appDetails'
import Utils, {dispatchAction} from "../../utility";
import {AppDirectoryService, MADService} from "../../services";
import {connect} from "react-redux";
import {actionsConst, apiFailureConstants, cookiesConstants, pathConstants} from "../../constants";
import {sessionManager} from "../../managers/sessionManager";
import HeaderComponent from "../common/headerComponent";
import AuthService from "../../services/auth0Service";
import DeviceUserMapService from "../../services/deviceUserMapService";
import moment from "moment";
import FooterComponent2 from "../common/footerComponentSecond";

class AppDetail extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            appDetail: {},
            isAppPurchased: false,
            isFreeTrialActive: false,
            isFreeTrialExpired: false,
            orderDetails: {},
            selectedPlan: {
                timeInMillis: 2592000000,
                credits: 1000
            },
            isPopOver: false,
            userAlldevices: [],
            schedules: [],
            appRoute: '',
            loader: false,
            appId:"",
            upcomingApps:[]
        }
    }

    async componentDidMount() {
        // console.log("AppDetail --> componentDidMount");
        window.scrollTo(0,0);

        let appDetail = await this.getAppDetail();
        let upcomingAppsResponse=await this.upComingApps();
        this.setState({
            appDetail: appDetail,
            appRoute: appDetail.entityData.appRoute ? appDetail.entityData.appRoute : appDetail.entityData.appName,
            appId:appDetail._id,
            upcomingApps:upcomingAppsResponse.responseData.entityList
        });

        try {
            this.authCallback();
        } catch (err) {
            // console.log("authCallback err", err);
        }
        await this.getOrder();
    }
    async componentDidUpdate() {
        window.scrollTo(0,0);
 
        if (this.state.appId !== this.props.match.params.appName) {
          let appDetail = await this.getAppDetail();
          let upcomingAppsResponse=await this.upComingApps();       
          this.setState({
            appDetail: appDetail,
            appRoute: appDetail.entityData.appRoute ? appDetail.entityData.appRoute : appDetail.entityData.appName,
            appId:appDetail._id,
            upcomingApps:upcomingAppsResponse.responseData.entityList
        });

        try {
            this.authCallback();
        } catch (err) {
            // console.log("authCallback err", err);
        }
        await this.getOrder();
        }
    }

    async upComingApps(){
        let [error, upcomingAppResponse] = await Utils.parseResponse(
            AppDirectoryService.getUpcomingApps()
          );
          if (error) return Utils.apiFailureToast(apiFailureConstants.GET_UPCOMING_APPS);
           return upcomingAppResponse;
    }


    getAppDetail = async () => {
        let params = this.props.match.params.appName;
       
        let appDetail = {};
        let [error, upcomingAppResponse] = await Utils.parseResponse(AppDirectoryService.getUpcomingApps());
        if (error)
            Utils.apiFailureToast(apiFailureConstants.GET_UPCOMING_APPS);
        else if (upcomingAppResponse) {
            appDetail = upcomingAppResponse.responseData.entityList.find(item => {
                // return item.entityData._id === params
                return item._id === params
            });
            return appDetail;
        }
        Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
    }

    authCallback = async () => {
        let authUrl = process.env.REACT_APP_WEB_APP + `app-details/${this.state.appId}`;
        this.authObject = new AuthService(authUrl);
        let [err, authResponse] = await this.authObject.handleAuthentication();
        if (err || !authResponse || !authResponse.accessToken || !authResponse.idTokenPayload || !authResponse.idTokenPayload.name || !authResponse.idTokenPayload.sub || !authResponse.idTokenPayload.email)
            return;
        sessionManager.setDataInCookies(authResponse.accessToken, cookiesConstants.AUTH0_ACCESS_TOKEN);
        sessionManager.setDataInCookies(authResponse.idToken, cookiesConstants.AUTH0_ID_TOKEN);
        sessionManager.setDataInCookies(JSON.stringify(authResponse.idTokenPayload), cookiesConstants.USER_DETAIL);
        sessionManager.setDataInCookies(authResponse.idTokenPayload.email, cookiesConstants.EMAIL_ID);
        sessionManager.setDataInCookies(authResponse.idTokenPayload.sub, cookiesConstants.USER_ID);
        let [error, jwtResponse] = await Utils.parseResponse(MADService.getJWTToken());
        if (jwtResponse && jwtResponse.length > 0)
            sessionManager.setDataInCookies(jwtResponse, cookiesConstants.AUTH0_ACCESS_TOKEN);
        this.props.dispatchAction(actionsConst.USER_AUTH_CALLBACK, {userDetails: authResponse.idTokenPayload});
    };

    tryButtonClick = async () => {
        this.setState({loader: true})
        if (!this.props.isLoggedIn) {
            await this.sendForSignIn();
            this.setState({loader: false})
            return;
        }
        if (this.state.isAppPurchased || this.state.isFreeTrialActive) {
            // console.log("purchased or in free trial");
            let madResponse = await this.registerMad();
            Utils.navigateToPath(pathConstants.MY_APPS);
            this.setState({loader: false})
        } else {
            if (this.state.isFreeTrialExpired) {
                // console.log("inside free trial Expired");
                let madResponse = await this.registerMad();
                this.setState({loader: false})
                Utils.navigateToPath(pathConstants.MY_APPS);                // this.navigateToCostCalculator();
                // this.openRazorPayPopup(this.state.orderDetails.orderDetails);
            } else {
                // this.setState({isPopOver: !this.state.isPopOver})
                await this.createOrder(true);
                this.setState({loader: false})
            }
        }
    };

    async sendForSignIn() {
        let authUrl = process.env.REACT_APP_WEB_APP + `app-details/${this.state.appId}`;
        this.authObject = new AuthService(authUrl);
        await this.authObject.signIn(authUrl);
    }

    navigateToCostCalculator = () => {
        let {appID} = this.props.match.params;
        // console.log("Inside navigate", appID)
        Utils.navigateToPath(pathConstants.COST_CALCULATOR + appID)
    }

    getOrder = async () => {
        if (!sessionManager.getDataFromCookies(cookiesConstants.USER_ID)) {
            // console.log("user details not found")
            return false;
        }
        let requestBody = {
            'purchasedBy.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID),
            'appDetails.appId': this.state.appDetail._id
        };

        let [error, getOrderResponse] = await Utils.parseResponse(MADService.getOrder(requestBody));
        if (getOrderResponse)
            this.setState({orderDetails: getOrderResponse});
        if (!error && getOrderResponse && getOrderResponse.isPurchased && getOrderResponse.expiryTimeMillis > Date.now())
            this.setState({isAppPurchased: true});
        if (!error && getOrderResponse && getOrderResponse.freeTrialInfo && getOrderResponse.freeTrialInfo.isActive && getOrderResponse.freeTrialInfo.freeTill > Date.now())
            this.setState({isFreeTrialActive: true, isFreeTrialExpired: false});
        if (!error && getOrderResponse && getOrderResponse.freeTrialInfo && getOrderResponse.freeTrialInfo.isActive && getOrderResponse.freeTrialInfo.freeTill <= Date.now())
            this.setState({isFreeTrialActive: false, isFreeTrialExpired: true});
    }

    createOrder = async (isFreeTrial) => {
        let requestBody = {
            amount: this.state.appDetail.entityData.amount,
            receipt: this.state.appDetail._id,
            purchasedBy: {
                userID: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : process.env.REACT_APP_USER_ID
            },
            appDetails: {
                appId: this.state.appDetail._id
            }
        };
        if (isFreeTrial)
            requestBody.freeTrialInfo = {
                isActive: true,
                freeTill: Utils.addDaysInTimeStamp(Date.now(), 15)
            };

        let [error, createOrderResponse] = await Utils.parseResponse(MADService.createOrder(requestBody));
        this.setState({orderDetails: createOrderResponse})
        if (!error && !isFreeTrial) {
            this.openRazorPayPopup(createOrderResponse)
            return;
        }
        if (!error && isFreeTrial) {
            await this.userAllDevice();
            if (!this.state.userAllDevices || !this.state.userAllDevices.length) {
                let addMADResponse = await this.registerMad(false);
                await this.props.dispatchAction(actionsConst.MAD_DETAILS, {madDetails: addMADResponse});
                await this.props.dispatchAction(actionsConst.CAMERA_ADD, {firstCamera: actionsConst.CAMERA_ADD});
                Utils.navigateToPath(pathConstants.USER_DEVICES);
                return;
            }
            let madResponse = await this.registerMad(true);
            // Utils.navigateToPath(pathConstants.APP_INPUT + madResponse._id);
            Utils.navigateToPath(pathConstants.MY_APPS);
        }
    };

    userAllDevice = async () => {
        let query = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID)
            }),
            limit: 0, skip: 0
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.getDevicesByQueryObj(query));
        // console.log("userDevicesResponse", userDevicesResponse);
        if (!error)
            this.setState({userAllDevices: userDevicesResponse});
    };

    openRazorPayPopup = (orderDetails) => {
        let _this = this;
        let options = {
            key: process.env.REACT_APP_RAZOR_PAY_ID,
            amount: this.state.appDetail.entityData.amount,
            name: 'Make Payment',
            image: '/images/logo.png',
            description: '',
            async handler(response) {
                // console.log("openRazorPayPopup -- > ", response);
                await _this.updateOrder(response, orderDetails)
            },
            notes: {
                order_id: orderDetails.id
            },
            theme: {
                color: '#4c84ff',
            }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    updateOrder = async (paymentRes, orderDetails) => {
        let requestBody = {
            orderId: orderDetails.id,
            paymentId: paymentRes.razorpay_payment_id,
            subscriptionPlan: 'MONTHLY'
        };
        let [error, updateOrderResponse] = await Utils.parseResponse(MADService.updateOrder(requestBody));
        if (!error) {
            let madResponse = await this.registerMad();
            // Utils.navigateToPath(pathConstants.APP_INPUT + madResponse._id);
            Utils.navigateToPath(pathConstants.MY_APPS);
        }
    };

    registerMad = async (deviceToConnect) => {
        await this.props.dispatchAction(actionsConst.MAD_DETAILS, {madDetails: {}});
        this.addSchedules();
        let defaultSchedules = this.parseSchedules();
        let requestData = {
            userID: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : process.env.REACT_APP_USER_ID,
            emailID: sessionManager.getDataFromCookies(cookiesConstants.EMAIL_ID) ? sessionManager.getDataFromCookies(cookiesConstants.EMAIL_ID) : "",
            appMeta: this.state.appDetail.entityData,
            activeInput: this.state.appDetail.entityData.activeInput,
            activeOutput: this.state.appDetail.entityData.activeOutput,
            schedules: defaultSchedules.length > 0 ? defaultSchedules : [],
        };
        if (deviceToConnect) {
            let connectedDevice = {
                "deviceName": this.state.userAllDevices[0].entityData.deviceName,
                "deviceID": this.state.userAllDevices[0]._id,
                "rtsp": this.state.userAllDevices[0].entityData.rtsp.url,
                "ffmpegFailureCount": 0,
                "executingServer": this.state.userAllDevices[0].entityData.executingServer ? this.state.userAllDevices[0].entityData.executingServer : "cloud"
            };
            requestData = {
                ...requestData,
                connectedDevice: Object.keys(connectedDevice).length > 0 ? [connectedDevice] : []
            };
        }
        if (!this.state.isAppPurchased && !this.state.isFreeTrialActive)
            requestData.internalUsage = this.getCredits();
        let [error, addMADResponse] = await Utils.parseResponse(MADService.addMAD(requestData));
        this.addMADSchedule(addMADResponse);
        // console.log("tryButtonClick -- > addMADResponse ", addMADResponse);
        if (error || !addMADResponse) {
            return Utils.apiFailureToast(apiFailureConstants.ADD_MAD);
        } else if (addMADResponse) {
            sessionManager.setDataInCookies(addMADResponse._id, cookiesConstants.MAD_ID);
            return addMADResponse;
        }
    };

    addMADSchedule = async (madDetails) => {
        if (!madDetails || !madDetails._id || !madDetails.schedules || madDetails.schedules.length <= 0)
            return;
        let reqObj = {
            "madID": madDetails._id,
            "schedules": madDetails.schedules,
            "isRecurring": 1
        };
        await Utils.parseResponse(MADService.addMADSchedule(reqObj));
    };

    addSchedules = () => {
        let schedulesAdded = [];
        // let start = moment((moment().startOf('isoweek')).startOf('D')).add(9, "hours").valueOf();
        // let end = moment((moment().startOf('isoweek')).startOf('D')).add(17, "hours").valueOf()
        let start = moment().startOf('day').valueOf();
        let end = moment().endOf('day').valueOf();
        for (let i = 1; i < 7; i++) {
            schedulesAdded.push({
                day: i,
                startTime: start,
                endTime: end
            });
            // start = moment((moment().startOf('isoweek')).add(i, "day")).startOf('D').add(9, "hours").valueOf();
            // end = moment((moment().startOf('isoweek')).add(i, "day")).startOf('D').add(17, "hours").valueOf();
            start = moment().startOf('day').valueOf();
            end = moment().endOf('day').valueOf();
        }
        this.setState({schedules: schedulesAdded})
    };

    parseSchedules = () => {
        return this.state.schedules.map(schedule => {
            let startTime = new Date(schedule.startTime);
            let endTime = new Date(schedule.endTime);
            startTime.setDate(startTime.getDate() + ((7 - startTime.getDay()) % 7 + Number(schedule.day)) % 7);
            endTime.setDate(endTime.getDate() + ((7 - endTime.getDay()) % 7 + Number(schedule.day)) % 7);
            let callbackIdentifier = String(new Date(startTime).getTime()) + "-" + String(new Date(endTime).getTime());
            return {
                startTime: new Date(startTime).getTime(),
                endTime: new Date(endTime).getTime(),
                type: 'WEEKLY',
                callbackIdentifier
            }
        });
    };

    getCredits = () => {
        let credits = {
            FD: {
                unit: 'face',
                endTime: Date.now() + this.state.selectedPlan.timeInMillis,
                allocatedCredits: this.state.selectedPlan.credits,
                remainingCredits: this.state.selectedPlan.credits
            }
        }
        return credits;
    }

    handleClick = (path) => (event) => {
        Utils.navigateToPath(path);
    };

    onContinueClick = async () => {

    }
    onUpcomingAppsClicked = (id, value, app) => {
        if (value) Utils.navigateToPath(pathConstants.MY_APPS);
        else {
          // Utils.navigateToPath(pathConstants.APP_DETAILS + app.entityData._id);
          Utils.navigateToPath(pathConstants.APP_DETAILS + app._id);
        }
      };

    render() {
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
              <HeaderComponent />
            </div>
            <div className="">
            {this.state.appId !== this.props.match.params.appName || !Object.keys(this.state.appDetail).length ? (
            <div className="text-center">
              <img src={"/images/loader.svg"} alt="" />
            </div>
          ):
            <AppDetailComponent
              state={this.state}
              handleClick={this.handleClick}
              appDetail={this.state.appDetail}
              tryButtonClick={this.tryButtonClick}
              onContinueClick={this.onContinueClick}
              getAppDetail={this.getAppDetail}
              upComingApps={this.state.upcomingApps}
              onUpcomingAppsClicked={this.onUpcomingAppsClicked}
            />}
            </div>
            {/* <FooterComponent/> */}
            <FooterComponent2 />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(AppDetail);
