import React from 'react';
import {Router, Route} from 'react-router-dom';
import {Redirect, Switch} from "react-router";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {connect} from "react-redux";
import {history} from "./managers/history";
import {
    BaseComponent,
    MarketPlace,
    Home,
    CameraSignUp,
    AppDetails,
    AppInput,
    MyApps,
    MyCameras,
    AboutUs,
    Developer,
    Terms,
    MachineDetailComponent,
    Privacy,
    DeviceCamera,
    UserDevice,
    DeviceCameraSignUp,
    Theft, 
    FaceRecognition
} from "./modules"
import {pathConstants} from './constants'
import MediaClientComponent from './modules/mediaClient/index'
import ConnectToLocalServer from './modules/mediaClient/connectToLocalServer'


class Routes extends BaseComponent {

    componentDidMount() {

    }

    render() {
        return (
            <MuiThemeProvider muiTheme={getMuiTheme()}>
                <Router history={history}>
                    <Switch>
                        <Route exact path={pathConstants.HOME} component={Home}/>
                        <Route exact path={pathConstants.CAMERA_SIGN_UP} component={CameraSignUp}/>
                        <Route exact path={pathConstants.MARKET_PLACE} component={MarketPlace}/>
                        <Route exact path={pathConstants.APP_DETAILS + ':appName'} component={AppDetails}/>
                        <Route exact path={pathConstants.APP_INPUT + ':madID'} component={AppInput}/>
                        <Route exact path={pathConstants.MY_APPS} component={MyApps}/>
                        <Route exact path={pathConstants.MY_CAMERAS} component={MyCameras}/>
                        <Route exact path={pathConstants.ABOUT_US} component={AboutUs}/>
                        <Route exact path={pathConstants.TERMS} component={Terms}/>
                        <Route exact path={pathConstants.PRIVACY} component={Privacy}/>
                        <Route exact path={pathConstants.DEVELOPER} component={Developer}/>
                        <Route exact path={pathConstants.MEDIA_CLIENT} component={MediaClientComponent}/>
                        <Route exact path={pathConstants.CONNECTED_MACHINE} component={MachineDetailComponent}/>
                        <Route exact path={pathConstants.CONNECT_TO_LOCAL_SERVER} component={ConnectToLocalServer}/>
                        <Route exact path={pathConstants.DEVICE_CAMERA} component={DeviceCamera}/>
                        <Route exact path={pathConstants.USER_DEVICES} component={UserDevice}/>
                        <Route exact path={pathConstants.DEVICE_CAMERA_SIGN_UP} component={DeviceCameraSignUp}/>
                        <Route exact path={pathConstants.THEFT_DETECTION} component={Theft}/>
                        <Route exact path={pathConstants.FACE_RECOGNITION} component={FaceRecognition}/>
                        <Redirect exact from='*' to={pathConstants.HOME}/>
                    </Switch>
                </Router>
            </MuiThemeProvider>);
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};
export default connect(mapStateToProps)(Routes);
