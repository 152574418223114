import React from "react";
import { Link } from "react-router-dom";

const TopBar = () => {
  return (
    <div className="px-120 m-t-32">
      <Link to="/">
        <img
          src="/images/appsStoreAiLogo.svg"
          alt="Appsstore.ai"
          height="23px"
          className="h-25px-m "
        />
      </Link>
    </div>
  );
};

export default TopBar;
