import React from 'react'
import {Column, Row} from "simple-flexbox";


function popularAppsCell(props, appObject, index) {
    let appIcon = appObject.entityData.appImage;
    return (
        <div key={'ai-apps-' + index} className="w-300 cursor-pointer mr-3 mb-5"
             onClick={() => props.onPopularAppsClicked(appObject._id)}>
            <img className='w-100' src={appIcon} alt='meeting'/>
            <Row className='mt-3'>
                <div>
                    <img className='w-75-px' src={appObject.entityData.logo} alt='meeting'/>
                </div>
                <div className="m-l-20">
                    <div className="fc-black fs-20">{appObject.entityData.appName}</div>
                    <div className="fc-warm-grey fs-14">{appObject.entityData.description} </div>
                </div>
            </Row>
        </div>
    )
}

function activeApps(props, appObject, index) {
    return (
        <div key={'ai-apps-' + index} className="w-300 cursor-pointer mb-5 m-r-20"
             onClick={() => props.onPopularAppsClicked(appObject)}>
            <img className='w-100 max-h-169 h-169' src={appObject.entityData.appImage}
                 alt='meeting'/>
            <Row className='mt-3'>
                <div>
                    <img className='w-75-px' src={appObject.entityData.logo}
                         alt='meeting'/>
                </div>
                <div className="m-l-20">
                    <div
                        className="fc-black fs-20">{appObject.entityData.appName}</div>
                    <div
                        className="fc-warm-grey fs-14">{appObject.entityData.description} </div>
                </div>
            </Row>
        </div>
    );
}


function marketPlaceComponent(props) {
    return (
        <div className='mb-4 p-l p-r'>
            <Row className="justify-content-center">
                <Column className="m-l-20 max-w-805">

                    {/* {props.state.loaded ?
                        <div className="position-abs m-t-100 left-45">
                            <img src={'/images/loader.svg'}/>
                        </div>
                        : ''} */}
                    {/* <Typography className='fc-dark-grey fs-17 mt-2'>Your Camera Feed</Typography>
                    <Iframe url={props.videoStreamURL} width="805px" height="486px" loading="" id="myId"
                            className="mt-3 iframe-b-1 w-100-per-m"
                    /> */}
                </Column>
            </Row>

            {/* <Row className='home-container'>
                {(props.popularApps && props.popularApps.length) ?
                    <Column className="mt-4 mb-4">
                        <Row className="fs-22 w-100">Popular Apps</Row>
                        <Row className='flex-wrap mt-3 justify-content-between'>
                            {props.popularApps.map((appObject, index) => {
                                return popularAppsCell(props, appObject, index);
                            })}
                        </Row>
                    </Column> : ""}
            </Row> */}
            <Row className="fs-30 justify-content-center m-t-20">Active Apps</Row>
            <Row className=" w-100 justify-content-start m-t-15 flex-wrap-m flex-wrap">

                {props.popularApps && props.popularApps.length ?
                    props.popularApps.map((appObject, index) => {
                        return appObject.isActive ? activeApps(props, appObject, index) : "";
                    }) : ''}
            </Row>
        </div>
    );
}

export default marketPlaceComponent;
