import React, { useState } from "react";
import { Column, Row } from "simple-flexbox";
import ClickOutside from "../common/clickOutside";

const DropDown = ({ value, setValue ,dropDownData }) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div className="position-relative">
      <Row
        onClick={() => setShowOptions((prev) => !prev)}
        className="form-input w-auto jc-space-between align-items-center p-15 cursor-pointer"
      >
        <div className="fs-18-22-500 font-color-light-grey-black">{value}</div>
        <img src="/images/drop-down.svg" className={`${showOptions && "-rotate-180"}`} alt="" />
      </Row>
      {showOptions && (
        <ClickOutside
          onClickOutside={() => setShowOptions(false)}
          className={`position-absolute"`}
        >
          <Column className="rounded mt-2 position-absolute bg-creme .fc-blue max-h-40 w-100-per overflow-auto dialog-scrollbar z-index-100 ">
            {dropDownData.map((value) => (
              <div
                key={value}
                onClick={() => {
                  setValue(value);
                  setShowOptions(false);
                }}
                className="p-15  fs-18-22-500 font-color-light-grey-black cursor-pointer"
              >
                {value}
              </div>
            ))}
          </Column>
        </ClickOutside>
      )}
    </div>
  );
};

export default DropDown;
