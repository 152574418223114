import React from "react";

const HowItsWork = ({ appData }) => {
  return (
    <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
      <section className="m-t-72">
        <div className="fs-36-43-600 md-fs-21-140per-700 fc-dark-slate-blue">
          How it work
        </div>
        <div className="full-width display-grid grid-auto-fit md-grid-cols-2 m-t-40 md-row-gap-35 work-container md-hide">
          {appData.workFlow?.map(({ img, value }, idx) => {
            const lastIndex = idx === appData.workFlow.length - 1;
            return (
              <div className="flex align-items-baseline" key={idx}>
                <div
                  className={`flex flex-column align-items-center w-100-per`}
                >
                  <div className="br-16 md-br-4 w-82 h-82 bg-theme grid grid-place-items-center">
                    <img src={img} loading="lazy" alt="" className=""></img>
                  </div>

                  <div className="fs-24-28-600 md-fs-16-150per-400 t-a-c m-t-20 md-grid md-place-items-center">
                    <div>{value}</div>
                  </div>
                </div>
                <img
                  src="/images/arrow.svg"
                  alt=""
                  loading="lazy"
                  className={` ${lastIndex && "display-none"}  md-w-53 lg-w-54`}
                />
              </div>
            );
          })}
        </div>
      </section>
      <MobWork workFlow={appData.workFlow} />
    </div>
  );
};

const MobWork = ({ workFlow }) => {
  [workFlow[2], workFlow[3]] = [workFlow[3], workFlow[2]];
  return (
    <div className="full-width display-grid grid-auto-fit md-grid-cols-2 m-t-40 md-row-gap-35 work-container display-none md-grid ">
      {workFlow?.map(({ img, value }, idx) => {
        const lastIndex = idx === workFlow.length - 1;
        return (
          <div className="flex align-items-baseline" key={idx}>
            <div className={`flex flex-column align-items-center w-100-per`}>
              <div className="br-16 md-br-4 w-82 h-82 bg-theme grid grid-place-items-center">
                <img src={img} alt="" className=""></img>
              </div>

              <div className="fs-24-28-600 md-fs-16-150per-400 t-a-c m-t-20 md-grid md-place-items-center">
                <div>{value}</div>
                <img
                  src="/images/arrow.svg"
                  loading="lazy"
                  alt=""
                  className={`  ${
                    idx === 1 || (workFlow.length === 5 && idx === 2)
                      ? "md-block display-none md-rotate-90 m-t-30 "
                      : "display-none"
                  } md-w-53`}
                />
              </div>
            </div>
            <img
              src="/images/arrow.svg"
              alt=""
              className={`${idx === 3 || idx === 1 ? "md-hide" : ""} ${
                lastIndex && "display-none"
              }  ${idx === 2 && "md-rotate-180"} md-w-53 lg-w-54`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default HowItsWork;