import React from "react";
import { Carousel } from "react-bootstrap";
import renderHTML from "react-render-html";
import { genericConstants } from "../../constants";
import Utils from "../../utility";
import { useScrollPosition } from "../../customHook/customHook";

export const Heading = (props) => {
  const scrollPosition = useScrollPosition();
  let appData =
    props.appDetail && props.appDetail.entityData
      ? props.appDetail.entityData
      : {};
  return scrollPosition < 140 ? (
    <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
      <div className="flex gap-17 h-153 md-h-112 sticky-css myElement">
        <div className="logo-container-home w-153 md-w-112 flex justify-center align-items-center">
          <img src={appData?.logo} alt="" className="margin-auto w-70" />
        </div>
        <div className="">
          <div className="fs-32-38-600 md-fs-24-30-600">
            {appData && appData?.appName}
          </div>

          <div className="fs-21-140-700 fc-4c84ff md-fs-10-12-600 flex-1 m-t-8">
            {appData && appData.description}
          </div>
          {/* <div className="fs-21-140per-700 fc-4c84ff">
          {appData && appData?.appType}
        </div> */}
          {props.appDetail &&
          props.appDetail.isActive &&
          props.appDetail.entityData.amount ? (
            <button
              onClick={() => props.tryButtonClick()}
              className="br-25 br-1-4ff bg-white fc-4c84ff h-42 w-130 m-t-11"
            >
              {!props.state.isAppPurchased &&
              !props.state.isFreeTrialActive &&
              !props.state.isFreeTrialExpired ? (
                !props.state.loader ? (
                  "Start free trial"
                ) : (
                  <img src={"/images/loader.svg"} alt="" />
                )
              ) : props.state.isAppPurchased ||
                props.state.isFreeTrialActive ? (
                <span>
                  {!props.state.loader ? (
                    "Open Dashboard"
                  ) : (
                    <img src={"/images/loader.svg"} alt="" />
                  )}
                </span>
              ) : !props.state.loader ? (
                "Purchase"
              ) : (
                <img src={"/images/loader.svg"} alt="" />
              )}
            </button>
          ) : (
            <button className="br-25 br-1-4ff bg-white fc-4c84ff h-42 md-h-34 w-130 md-w-118 m-t-11">
              Pre-register
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-between gap-17 h-96 sticky-css myElement px-120 md-px-24 base-container xxl-px-120 xl-px-70">
      <div className="flex gap-14 align-items-center">
        <div className="logo-container-home w-54 h-54 flex justify-center align-items-center">
          <img src={appData?.logo} alt="" className="margin-auto w-34" />
        </div>
        <div>
          <div className="fs-28-34-700 md-fs-24-30-600">
            {appData && appData?.appName}
          </div>
          <div className="fs-16-19-600 fc-4c84ff md-fs-10-12-600 flex-1 m-t-4">
            {appData && appData.description}
          </div>
        </div>
      </div>
      <div className="flex align-items-center">
        {props.appDetail &&
        props.appDetail.isActive &&
        props.appDetail.entityData.amount ? (
          <button
            onClick={() => props.tryButtonClick()}
            className="br-25 br-1-4ff bg-white fc-4c84ff h-42 w-130"
          >
            {!props.state.isAppPurchased &&
            !props.state.isFreeTrialActive &&
            !props.state.isFreeTrialExpired ? (
              !props.state.loader ? (
                "Start free trial"
              ) : (
                <img src={"/images/loader.svg"} alt="" />
              )
            ) : props.state.isAppPurchased || props.state.isFreeTrialActive ? (
              <span>
                {!props.state.loader ? (
                  "Open Dashboard"
                ) : (
                  <img src={"/images/loader.svg"} alt="" />
                )}
              </span>
            ) : !props.state.loader ? (
              "Purchase"
            ) : (
              <img src={"/images/loader.svg"} alt="" />
            )}
          </button>
        ) : (
          <button className="br-25 br-1-4ff bg-white fc-4c84ff h-42 md-h-34 w-130 md-w-118">
            Pre-register
          </button>
        )}
      </div>
    </div>
  );
};

export const AboutApp = ({ appData }) => {
  let mimeType;
  return (
    <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
      <section className="flex lg-flex-col flex-row  m-t-78 gap-26">
        <div className="w-419">
          <Carousel
            className="custom-carousel"
            pause={"hover"}
            slide={true}
            controls={false}
            fade={true}
            interval={3000}
          >
            {appData.media
              ? appData.media.map((mediaObj, index) => {
                  mimeType = Utils.getMimeType(mediaObj.mimeType);
                  return (
                    mimeType === genericConstants.MIME_TYPE.IMAGE && (
                      <Carousel.Item key={"carousel-item" + index}>
                        <div className="main-div">
                          <img
                            className="d-block w-100-per br-20 min-h-494 base-div"
                            key={"carousel-item" + index}
                            src={mediaObj.url}
                            alt={"Media-" + index}
                          />
                        </div>
                      </Carousel.Item>
                    )
                  );
                })
              : ""}
          </Carousel>
        </div>
        <div className="flex  flex-column  gap-14 ">
          <div className="full-width fs-36-43-600 md-fs-21-140per-700 fc-dark-slate-blue">
            ABOUT APP
          </div>
          <div className="full-width fs-18-150-400 md-fs-16-24-500">
            {renderHTML(appData.overview)}
          </div>
          {appData.features.length !== 0 && (
            <>
              <div className="full-width fs-36-43-600  md-fs-21-140per-700 fc-dark-slate-blue">
                Features
              </div>
              <div className="full-width display-grid gap-24 m-t-20">
                {appData.features?.map(({ value, img }, index) => {
                  return (
                    <div className="flex align-items-center gap-18" key={index}>
                      <img src={img} alt="" loading="lazy"/>
                      <div className="fs-21-140-400 md-fs-16-150per-400 fc-black">
                        {value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};
