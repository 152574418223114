import React from "react";
import BaseComponent from '../baseComponent'
import MachineDetailComponent from './component'
import Utils, {dispatchAction} from "../../utility";
import {connect} from "react-redux";
import {apiFailureConstants, cookiesConstants, pathConstants} from "../../constants";
import HeaderComponent from "../common/headerComponent";
import {sessionManager} from "../../managers/sessionManager";
import utility from "../../utility";
import FooterComponent2 from "../common/footerComponentSecond";
import {
    deleteConnectedMachine,
    publishActionQueueData,
    getUserEntityDetails,
    checkConnectedMachineStatus
} from '../../services/deviceUserMapService'
import {DeviceUserMapService} from "../../services";

class ConnectedMachine extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            connectedMachine: [],
            menuOpen: true,
            machineId: '',
            selectedMachine: [],
            confirmation: false,
            isDetails: true,
            mobileMenuOpen: false,
            IP: '',
            status: "Inactive"
        }
    }

    async componentDidMount() {
        let userID = sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : '';
        let findObj = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': userID
            }),
            limit: 0, skip: 0
        };
        let detail = [];
        let entityData = await getUserEntityDetails(findObj).catch(err => {
            // console.log(err);
        });
        let connectedMachines = [];
        if (entityData && entityData.length > 0) {
            detail.push(entityData[0]);
            await this.setState({
                connectedMachine: entityData,
                machineId: entityData[0]._id,
                selectedMachine: detail,
                IP: entityData[0].entityData.IP
            });
        } else {
            utility.apiFailureToast("No connected device available")
            return utility.navigateToPath(pathConstants.HOME);

        }
        await this.checkConnectedMachineStatus(userID);
    }

    checkConnectedMachineStatus = async (userID) => {
        let machine = await checkConnectedMachineStatus({"userID": userID}).catch(err => {
            // console.log(err)
        });
        if (!machine) {
            this.setState({status: "Inactive"});
            return;
        }
        this.setState({status: machine.status});
    }

    toggleMenu = () => {
        this.setState({menuOpen: !this.state.menuOpen})
    }


    onMachineClicked = (machine) => {
        let device = [];
        device.push(machine)
        this.setState({selectedMachine: device, machineId: machine._id, mobileMenuOpen: false})
    };

    handleConfirmation = () => {
        this.setState({confirmation: !this.state.confirmation})
    }

    deleteEntity = async () => {
        try {
            await deleteConnectedMachine(this.state.selectedMachine[0]);
        } catch (error) {
            // console.log(error);
        }
    }

    deleteConnectedMachine = async () => {
        let response = await publishActionQueueData({
            userID: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : '',
            exchangeName: 'action_exchange',
            exchangeType: 'fanout',
            queueName: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : '',
            queueType: 'publisher_subscriber_queue',
            queueData: [{action: "killDocker"}],
            _id: this.state.selectedMachine[0]._id
        }).catch(/*error => console.log(error)*/);
        await this.deleteEntity();
        this.setState({confirmation: false});
        await this.componentDidMount();
        utility.apiSuccessToast("Machine deleted successfully");
    }

    toggleMachineDetail = () => {
        this.setState({isDetails: !this.state.isDetails})
    }

    handleMobileMenu = () => {
        this.setState({mobileMenuOpen: !this.state.mobileMenuOpen});
    }

    updateMachine = async () => {
        let requestBody = {
            queryObj: {
                _id: this.state.selectedMachine[0]._id,
            },
            updationObj: {
                "entityData.IP": this.state.IP,
            }
        };
        let [error, connectedDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.updateConnectedMachine(requestBody));
        if (error || !connectedDevicesResponse)
            return false;
        this.setState({edited: false})
        await this.componentDidMount();
        return Utils.apiSuccessToast(apiFailureConstants.MACHINE_DETAILS_UPDATED);
    }

    onInputChange = (e) => {
        this.setState({IP: e.target.value, edited: true})
    }

    render() {
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70 ">
              <HeaderComponent />
            </div>
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
            <MachineDetailComponent
              state={this.state}
              connectedMachine={this.state.connectedMachine}
              toggleMenu={this.toggleMenu}
              machineId={this.state.machineId}
              selectedMachine={this.state.selectedMachine}
              onMachineClicked={this.onMachineClicked}
              handleConfirmation={this.handleConfirmation}
              deleteEntity={this.deleteEntity}
              deleteConnectedMachine={this.deleteConnectedMachine}
              toggleMachineDetail={this.toggleMachineDetail}
              handleMobileMenu={this.handleMobileMenu}
              updateMachine={this.updateMachine}
              onInputChange={this.onInputChange}
            />
            </div>
            {/* <FooterComponent/> */}
            <FooterComponent2 />
          </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        userDetails: state.user.userDetails,
        isLoggedIn: state.user.isLoggedIn,
    }
};

export default connect(mapStateToProps, {dispatchAction})(ConnectedMachine);
