import React from "react";
import { PopupButton } from "react-calendly";
import { useForm } from "react-hook-form";
import { AppDirectoryService } from "../../services";
import utility from "../../utility";
import { errorConstant } from "../../constants";

function ContactUs() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleFormSubmit = async (event) => {
    const obj = getValues();
    try {
      await AppDirectoryService.addContact(obj);
      utility.apiSuccessToast("We will get back to you soon.");
    } catch (err) {
      utility.apiFailureToast("server error");
    }
  };
  return (
    <div className="m-t-94">
      <div className="fc-dark-slate-blue text-ft32-38 font-weight-bold display-flex justify-center m-b-56">
        Contact Us
      </div>
      <div className="h-1626-md position-rel h-951 m-b-113">
        <div className="w-100-per-md flex-wrap-md w-1040 br-1-b4d br-32 p-t-34 p-b-25 m-auto bg-white position-rel  z-index-100 display-flex">
          <div className="w-100-per-md w-506 p-x-44 vertical-line-right">
            <div className="fc-dark-slate-blue text-ft28-140 font-weight-bold display-flex justify-center p-t-30">
              Write To Us
            </div>
            <div className="fc-lightBlue30 text-ft18-150 text-align-center m-t-17">
              Whether you are a start-up or an enterprise, we’d be glad to tell
              you how our AI Apps can help businesses automate.
            </div>
            <div className="display-flex justify-center">
              <img
                src="/images/contact-us-new.svg"
                className="width-290 m-t-26"
                alt="contact us"
              />
            </div>
          </div>
          <div className="w-100-per-md w-534">
            <div className="">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="m-t-20 w-100-per p-l-56 p-r-32 display-grid gap-19">
                  <div>
                    {errors.name && (
                      <div className="font-color-red mb-2">
                        {errors.name.message}
                      </div>
                    )}
                    <div className="fs-18-150-400 fc-dark-grey m-l-10">
                      Name
                    </div>
                    <input
                      type={"text"}
                      placeholder="Rick John"
                      className="p-l-24 form-input width-100-per"
                      {...register("name", {
                        required: errorConstant.NAME,
                      })}
                    />
                  </div>
                  <div>
                    {errors.email && (
                      <div className="font-color-red mb-2">
                        {errors.email.message}
                      </div>
                    )}
                    <div className="fs-18-150-400 fc-dark-grey m-l-10">
                      Email Id
                    </div>
                    <input
                      type={"text"}
                      placeholder="example@domail.com"
                      className="p-l-24 form-input width-100-per"
                      {...register("email", {
                        required: errorConstant.EMAIL,
                        pattern: {
                          value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: errorConstant.EMAIL_VALID,
                        },
                      })}
                    />
                  </div>
                  <div>
                    {errors.message && (
                      <div className="font-color-red mb-2">
                        {errors.message.message}
                      </div>
                    )}
                    <div className="fs-18-150-400 fc-dark-grey m-l-10">
                      description
                    </div>
                    <textarea
                      type={"text"}
                      placeholder="Type your msg here"
                      className="p-l-24 form-input width-100-per h-153 resize-none p-t-16"
                      {...register("message", {
                        required: errorConstant.DESCRIPTION,
                      })}
                    />
                  </div>
                </div>
                <div className="display-grid grid-place-items-center w-100-per m-t-32 m-b-20">
                  <button className="bg-theme fc-white w-207 h-53 border-0 br-30 ">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bg-lighter-voilet position-abs bottom-1 width-100-per">
          <div className="w-unset flex-col-md w-1040 m-auto p-t-247 p-b-52 display-flex gap-56">
            <div className="flex-center-md">
              <img
                src="/images/customer.svg"
                className="w-474"
                alt="customer"
              />
            </div>
            <div>
              <div className="flex-center-md fs-24-150-600 font-color-264780 m-b-8 m-t-40">
                Already a customer?
              </div>
              <div className="text-align-md p-x-md flex-center-md fs-18-150-400 font-color-264780">
                If you're encountering a technical or payment issue, the
                customer support team will be happy to assist you.{" "}
              </div>
              <div className="flex-center-md">
                <PopupButton
                  className="fs-20-24-600 fc-white br-90 bg-theme br-none p-y-14 p-x-72 m-t-24 outline-none"
                  url={process.env.REACT_APP_CALENDLY}
                  rootElement={document.getElementById("root")}
                  text="Contact Support"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
