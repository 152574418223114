import React from "react";
import { Column, Row } from "simple-flexbox";


const TestimonialSection = () => {
  return (
    <Row className="display-flex  m-t-85 flex-direction-column px-120 md-px-24 base-container xxl-px-120 xl-px-70">
      <p className="fs-32-75-700-lato text-align-center ">Testimonial</p>
      <Row className="scroll-div p-b-10">
        <div className="display-flex jc-space-evenly lg-flex-col md-gap-16 gap-24 w-2260 h-d-600 lg-place-items-center  no-scroll-div">
          <Column className=" bg-lighter-blue w-600 md-w-full h-300 br-30 p-d-20">
            <Row>
              <div className="m-l-30 m-t-20">
                <img src="/images/downwardQuotesImage.png" alt="" />
              </div>
            </Row>
            <Row className="m-t-sm">
              <p className="m-l-30 fs-16-24-400 fc-white m-r-30">
                Our retail chain was facing losses due to the nuance of theft. I
                Had tried automated and manual alarms but this innovative AI
                solution from Appsstore.ai has helped us to eliminate the
                problem of theft completely. Kudos to the team.
              </p>
            </Row>
            <Row>
              <div className="m-l-90-per">
                <img src="/images/upwardQuotes.png" alt="" />
              </div>
            </Row>
            <Row className="m-t-10 m-b-10">
              <div className="p-b-sm m-l-30">
                <img src="/images/profilePicture.png" alt="" />
              </div>
              <div className="display-flex flex-direction-column m-l-sm">
                <div>
                  <h3>Rick john</h3>
                </div>
                <div className="fc-white">
                  <p>Retail chain Owner</p>
                </div>
              </div>
            </Row>
          </Column>
          <Column className=" bg-lighter-blue w-600 md-w-full h-300 br-30 p-d-20">
            <Row>
              <div className="m-l-30 m-t-20">
                <img src="/images/downwardQuotesImage.png" alt="" />
              </div>
            </Row>
            <Row className="m-t-sm">
              <p className="m-l-30 fs-16-24-400 fc-white m-r-30">
                The Appsstore’s AI theft detection skill on our CCTV cameras has
                greatly reduced theft incidents and saved us time and resources.
                Highly recommended for enhancing security measures in retail
                showrooms
              </p>
            </Row>
            <Row>
              <div className="m-l-90-per">
                <img src="/images/upwardQuotes.png" alt="" />
              </div>
            </Row>
            <Row className="m-t-30 m-b-10">
              <div className="p-b-sm m-l-30">
                <img src="/images/profilePictureSecond.png" alt="" />
              </div>
              <div className="display-flex flex-direction-column m-l-sm">
                <div>
                  <h3>Amelia Max</h3>
                </div>
                <div className="fc-white">
                  <p> Luxury retail Showroom</p>
                </div>
              </div>
            </Row>
          </Column>
          <Column className=" bg-lighter-blue w-600 md-w-full h-300 br-30 p-d-20">
            <Row>
              <div className="m-l-30 m-t-20">
                <img src="/images/downwardQuotesImage.png" alt="" />
              </div>
            </Row>
            <Row className="m-t-sm">
              <p className="m-l-30 fs-16-24-400 fc-white m-r-20">
                As the manager of a busy warehouse, I was always worried about
                the potential for theft. That's why I decided to implement
                Appsstore’s AI theft detection skills on our CCTV cameras. The
                results have been astounding - since implementing the
                technology, instances of theft have dropped by 90%.
              </p>
            </Row>
            <Row>
              <div className="m-l-90-per">
                <img src="/images/upwardQuotes.png" alt="" />
              </div>
            </Row>
            <Row className="m-t-10 ">
              <div className="p-b-sm m-l-30">
                <img src="/images/profilePicThird.png" alt="" />
              </div>
              <div className="display-flex flex-direction-column m-l-sm">
                <div>
                  <h3>maximux</h3>
                </div>
                <div className="fc-white">
                  <p> Luxury retail Showroom</p>
                </div>
              </div>
            </Row>
          </Column>
        </div>
      </Row>
    </Row>
  );
};

export default TestimonialSection;

  
