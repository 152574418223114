import React from "react";
import { useState ,useEffect} from "react";
import Developer from "./developer";

const Index = () => {
  useEffect(()=>window.scrollTo(0,0,),[])
  const [dialogType, setDialogType] = useState({
    type: "developer",
    data: {}
  });

  const changeState=(key,value)=>{
    setDialogType((prev) => ({ ...prev, [key]: value }));
  }


  return (
    <div>
      <Developer state={dialogType} changeState={changeState} />
    </div>
  );
};

export default Index;
