import React from "react";

const useOfTheftDetection = [
  "Identify all kinds of thefts Using AI",
  "Instant alerts and notifications",
  "Work on your existing camera system",
];

const WhyAppsstore = () => {
  return (
    <div className="m-t-64">
      <p className="font-lato font-bold text-ft32-40 m-b-sm text-align-center md-text-ft24-30 m-b-40">
        Why Use Appsstore Theft Detection
      </p>
      <div className="flex-direction-column display-flex m-t-40 md-mt-28 home-container">
        <div className="horizontal-line w-100-per rounded "></div>
        <div className="display-flex jc-space-evenly">
          {useOfTheftDetection.map((item, idx) => (
            <div
              key={idx}
              className="width-180 md-w-140 display-flex flex-direction-column align-item-center"
            >
              <div
                className={`h-50 vertical-line-lightBlue ${
                  idx === 1 && "md-h-176"
                }`}
              ></div>
              <div>
                <img className="" src="/images/cctvImage.png" alt="" />
              </div>
              <div className="fs-18-20-400-lato m-t-20 text-align-center md-text-ft14-22">
                {item}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyAppsstore;
