import React from "react";
import BaseComponent from '../baseComponent';
import UserDeviceComponent from "./userDeviceComponent";
import Utils, {dispatchAction} from "../../utility";
import {pathConstants} from "../../constants";
import {connect} from "react-redux";



class UserDevice extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    redirect = (props) => {
        if (props === "IP_CAMERA") {
            return Utils.navigateToPath(pathConstants.CAMERA_SIGN_UP);
        } else if (props === "PHONE_CAMERA") {
            localStorage.setItem("deviceType", "phone");
            return Utils.navigateToPath(pathConstants.DEVICE_CAMERA_SIGN_UP);
        } else {
            localStorage.setItem("deviceType", "laptop");
            return Utils.navigateToPath(pathConstants.DEVICE_CAMERA_SIGN_UP);
        }
    };

    async componentDidMount() {
        if (!this.props.isLoggedIn)
            return Utils.navigateToPath(pathConstants.HOME);
    }

    render() {

        return (
        
            <UserDeviceComponent redirect={this.redirect} />
           
          
        );
         
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(UserDevice);

