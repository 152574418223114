import React, { lazy } from "react";
import WorldMapSection from "./worldMapSection.js";
import Commitment from "./commitment.js";
import { Link } from "react-router-dom";
import ContactUs from "./contactUs.js";
import { pathConstants } from "../../constants/index.js";
import { Suspense } from "react";
const RealWorldProblum = lazy(() => import("./realWorldProblum"));

function NewAboutUsComponent() {
  const Loader = () => {
    return (
      <div className="text-center">
        <img src={"/images/loader.svg"} alt="" />
      </div>
    );
  };

  return (
    <div>
      <div className="base-container px-120 md-px-24 xxl-px-120 xl-px-70">
        <div className="mt-3 flex-wrap-reverse-m  margin-auto display-flex justify-between">
          <div className="pl-2 pr-3 m-t-20-m">
            <div className="w-100-per-l h-none font-weight-bold fs-48 mt-lg-5 w-597 h-159 l-h-52">
              <span className="fc-4c84ff">AI</span> for automated monitoring and
              analytics of live video streams
            </div>
            <div className="w-100-per-l w-597 mt-4 fs-18 l-h-150 fc-dark-grey">
              We at AppsStore are on a mission to enable every live stream from,
              CCTV, Dashcam, and DroneCam with AI to automate and analyze
              various business functions like Quality inspection, PPE detection,
              Theft detection, X-ray analysis, Crop analysis, Retail store
              analytics, Employee attendance, etc.
            </div>
            <Link to={`${pathConstants.CAMERA_SIGN_UP}`}>
              <button className="fs-18 bg-theme border-0 text-white cursor-pointer m-t-74 br-25 p-t-16 p-b-16 p-x-32">
                Start Free Trial Now
              </button>
            </Link>
          </div>
          <div className="mt-lg-5 display-flex-m jc-center-m margin-auto-m">
            <video autoPlay loop muted className="w-100-per-m">
              <source src={"/videos/app-store-about.mp4"} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <Suspense fallback={<Loader />}>
        <RealWorldProblum />
      </Suspense>
      <Commitment />
      <WorldMapSection />
      <ContactUs />
    </div>
  );
}

export default NewAboutUsComponent;
