import React, { createRef } from "react";
import BaseComponent from '../baseComponent'
import MyAppComponent from './myAppsComponent'
import Utils, {dispatchAction} from "../../utility";
import utility from '../../utility';
import {DeviceUserMapService, MADService, UploadVideoService} from "../../services";
import {connect} from "react-redux";
import {actionsConst, cookiesConstants, pathConstants} from "../../constants";
import {sessionManager} from "../../managers/sessionManager";
import HeaderComponent from "../common/headerComponent";
import {getMediaClient} from "appstore-sdk";
import EXIF from "exif-js";
import moment from "moment";
import FooterComponent2 from "../common/footerComponentSecond";


class MyApps extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            madID: "",
            MADDetail: {},
            userMADDetails: [],
            userAllDevices: [],
            rtsp: "",
            deviceID: "",
            loaded: false,
            target: null,
            isPopoverOpen: false,
            menuOpen: true,
            active: false,
            accessToken: '',
            isWebCam: false,
            entityDetail: [],
            webCamDevice: {},
            deviceFrames: [],
            webcam: '',
            mobileMenuOpen: false,
            webCamImage: '',
            interval: 0,
            recordedChunks: []
        }
        this.mediaRecorderRef = createRef(null);
    }

    handleChange = async (checked, deviceDetails, mad) => {
        if (!mad)
            return;
        let connectedDevice = [];
        // if device does not exists in MAD then add device details in MAD otherwise change the status of device in MAD
        if (!checked) {
            let devices = {
                "deviceName": deviceDetails.entityData.deviceName,
                "deviceID": deviceDetails._id,
                "rtsp": deviceDetails.entityData.rtsp.url,
                "executingServer": deviceDetails.entityData.executingServer ? deviceDetails.entityData.executingServer : "cloud"
            };
            connectedDevice = [...mad.connectedDevice, devices];
            if (deviceDetails.entityData.executingServer === 'webCam') {
                await this.setState({isWebCam: true});
            }
        } else {
            let devices = {};
            for (let index = 0; index < mad.connectedDevice.length; index++) {
                devices = {};
                if (mad.connectedDevice[index] && Object.keys(mad.connectedDevice[index]).length > 0 && mad.connectedDevice[index].deviceID !== deviceDetails._id && !mad.connectedDevice[index].isDeleted) {
                    devices.deviceName = mad.connectedDevice[index].deviceName;
                    devices.deviceID = mad.connectedDevice[index].deviceID;
                    devices.rtsp = mad.connectedDevice[index].rtsp;
                    devices.executingServer = mad.connectedDevice[index].executingServer;
                    connectedDevice.push(devices);
                }
            }
            if (deviceDetails.entityData.executingServer === 'webCam') {
                await this.setState({isWebCam: false});
            }
            await this.removeDeviceSchedule(deviceDetails._id);
        }
        this.setState({loaded: true});
        await this.updateMAD(mad, connectedDevice);
        await this.addMADSchedule(mad);
        mad["connectedDevice"] = connectedDevice;
        await this.checkScheduleAvailability(mad);
        this.setState({MADDetail: mad, loaded: false});
    };

    checkScheduleAvailability = async (madDetail) => {
        if (madDetail.schedules && madDetail.schedules.length > 0 && (Number(madDetail.internalUsage.FD.remainingCredits) >= 1) && madDetail.connectedDevice && madDetail.connectedDevice.length > 0) {
            let findObj = {
                deviceID: madDetail.connectedDevice[0].deviceID
            };
            let schedule = await this.userDeviceSchedule(findObj);
            if (schedule[0] && schedule[0].schedules && schedule[0].schedules.length > 1) {
                for (let index = 0; index < schedule[0].schedules.length; index++) {
                    if ((schedule[0].schedules[index].endTime > new Date().getTime() && new Date().getTime() > schedule[0].schedules[index].startTime)) {
                        this.setState({active: true});
                        break;
                    } else {
                        this.setState({active: false});
                    }
                }
            }
            let webCamDevice = madDetail.connectedDevice.find(device => device.executingServer === 'webCam');
            if (webCamDevice && Object.keys(webCamDevice).length > 0) {
                this.setState({isWebCam: true, webCamDevice: webCamDevice});
            }
        }
    }

    removeDeviceSchedule = async (deviceID) => {
        let findObj = {
            "deviceID": deviceID
        };
        await Utils.parseResponse(MADService.removeDeviceSchedule(findObj));
    };

    addMADSchedule = async (madDetails) => {
        if (!madDetails || !madDetails._id || !madDetails.schedules || madDetails.schedules.length <= 0)
            return;
        let reqObj = {
            "madID": madDetails._id,
            "schedules": madDetails.schedules,
            "isRecurring": 1
        };
        await Utils.parseResponse(MADService.addMADSchedule(reqObj));
    };
    updateMAD = async (mad, connectedDevice) => {
        let findObj = {
            "userID": mad.userID,
            "_id": mad._id,
            "appMeta.appID": mad.appMeta.appID
        };
        let updateObj = {
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "connectedDevice": connectedDevice
        };
        let [error, updateMadResponse] = await Utils.parseResponse(MADService.updateMAD(findObj, updateObj));
    };

    async componentDidMount() {
        window.scrollTo(0,0);
        if (!this.props.isLoggedIn) {
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        let accessToken = sessionManager.getDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN);
        this.setState({loaded: true, accessToken: accessToken});
        await this.userAllMads(this.props.userDetails.sub);
        // this.userAllMads("google-oauth2|101540457649026071749");
        this.userAllDevice();
        await this.getEntityDetails();
        this.checkLocalUser();
        this.refreshDeviceFrame();
        this.refreshUserData();
        // this.setState({madID: sessionManager.getDataFromCookies(cookiesConstants.MAD_ID)});
        this.setState({loaded: false});
    }

    checkLocalUser = async () => {
        // If local media-client server exists for user then show from local otherwise from cloud
        if (this.state.entityDetail && this.state.entityDetail.length > 0) {
            await this.userLocalDeviceFrames();
            return true;
        }
        // await this.getDeviceFrames();
    };

    getEntityDetails = async () => {
        let entityDetail;
        try {
            if (this.state.MADDetail) {
                entityDetail = await getMediaClient(this.state.MADDetail._id);
                if (entityDetail && entityDetail.responseData && entityDetail.responseData.length > 0) {
                    this.setState({entityDetail: entityDetail.responseData});
                }
            }
        } catch (e) {
        }
    };

    userLocalDeviceFrames = async () => {
        let madRes = this.state.MADDetail;
        let deviceFrames = [];
        let obj = {};
        let flag = false;
        for (let index = 0; index < madRes.connectedDevice.length; index++) {
            let imageUri = 'https://' + this.state.entityDetail[0].entityData.IP + ':' + this.state.entityDetail[0].entityData.port + "/public/deviceFrames/" + madRes.connectedDevice[index].deviceID + "/" + madRes.connectedDevice[index].deviceID + `.jpg?${Date.now()}`;
            let exifRes = this.getExifData(imageUri);
            if (exifRes && Object.values(exifRes).length > 0) {
                flag = true;
            }
            obj = {
                image: imageUri,
                deviceName: madRes.connectedDevice[index].deviceName,
                faceCount: flag ? exifRes.persons : 0,
                time: flag ? exifRes.time : 0,
                local: true
            }
            let detail = this.state.userAllDevices.find(device => device._id === madRes.connectedDevice[index].deviceID);
            if (detail && detail.entityData && detail.entityData.ffmpegFailureCount < 10 && detail.entityData.executingServer && detail.entityData.executingServer === 'local') {
                deviceFrames.push(obj);
            }
        }
        this.setState({deviceFrames: deviceFrames});
    };

    getExifData = (imageUri) => {
        let make;
        make = EXIF.getData(imageUri, () => {
            return EXIF.getTag(this, "Make");
        });
        if (make && make.length > 6)
            return {persons: make.split('p')[0], time: this.getTimeFromNow(make.split('p')[1])};
        return false;
    }

    getTimeFromNow = (time) => {
        let timestamp = new Date(Number(time))
        let givenTimeMoment = new moment(timestamp);
        return moment(givenTimeMoment).fromNow();
    };

    userAllDevice = async () => {
        let query = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID)
                // 'entityData.userDetails.userID': "google-oauth2|101540457649026071749"

            }),
            limit: 0, skip: 0
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.getDevicesByQueryObj(query));
        if (userDevicesResponse && userDevicesResponse.length === 0) {
            utility.navigateToPath(pathConstants.USER_DEVICES);
            return utility.apiFailureToast("Please add a Camera")
        }
        this.setState({userAllDevices: userDevicesResponse});
    };

    userAllMads = async (userID) => {
        let [error, userMADResponse] = await Utils.parseResponse(MADService.getUserMAD(userID));
        if (error || !userMADResponse) {
            // Utils.apiFailureToast(apiFailureConstants.GET_USER_MAD);
            
            // Utils.navigateToPath(pathConstants.HOME);
            // return;
            utility.navigateToPath(pathConstants.USER_DEVICES);
            return utility.apiFailureToast("Please add a Camera")
        }
        let madID = sessionManager.getDataFromCookies(cookiesConstants.MAD_ID);
        let madDetail ;
        let ifMadDetailsExists = this.props.madDetails == null ? {} : this.props.madDetails ;
        console.log(ifMadDetailsExists , "ifMadDetailsExists")

        userMADResponse.sort((a, b) => (a.modifiedOn - b.modifiedOn));
        if (madID) {
            madDetail = userMADResponse.find((mad) => {
                return madID === mad._id;
            })
        } else if (!madDetail) {
            madDetail = userMADResponse[0];
        }
        if(Object.keys(ifMadDetailsExists).length){
            console.log(ifMadDetailsExists , "ifMadDetailsExists")
            madDetail = ifMadDetailsExists
        }
        window.history.pushState("", "", `/my-apps/${madDetail.appMeta.appRoute ? madDetail.appMeta.appRoute : this.getAppRoute(madDetail.appMeta.appName)}`)
        this.setState({userMADDetails: userMADResponse, MADDetail: madDetail});
        await this.checkScheduleAvailability(madDetail);
    };

    getAppRoute = (name) => {
        let nameArray = name.split(" ");
        let route = [];
        for (let index = 0; index < nameArray.length; index++) {
            route.push(nameArray[index].slice(0, 1).toLocaleLowerCase() + nameArray[index].slice(1))
        }
        return route.join("-");
    }

    refreshUserData = async () => {
        this.state.interval = setInterval(() => {
            if (!Number(this.state.MADDetail.frameRate))
                return;
            const appSettings = this.getPhotoSetting(this.state.MADDetail?.appMeta?.appSettings)
            if (appSettings === "MD") this.captureVideo();
            else this.capture();
        }, (Number(this.state.MADDetail.frameRate) * 1000) >= 1000 ? Number(this.state.MADDetail.frameRate) * 1000 : 10000);
    };

    refreshDeviceFrame = () => {
        setInterval(() => {
            this.checkLocalUser();
        }, (Number(this.state.MADDetail.frameRate) * 1000) >= 1000 ? Number(this.state.MADDetail.frameRate) * 1000 : 15000)
    }

    capture = () => {
        try {
            if (this.state.active && this.state.isWebCam) {
                const imageSrc = this.state.webcam.getScreenshot();
                this.setState({webCamImage: imageSrc});
                this.uploadToS3(imageSrc);
            }
        } catch (err) {
            return false;
        }
    };

    setRef = webcam => {
        this.state.webcam = webcam;
    };

    uploadToS3 = async (base64) => {
        if (!base64)
            return;
        let callbackIdentifier = this.state.MADDetail.schedules[0].callbackIdentifier;
        let base64Image = base64.split("data:image/jpeg;base64,")[1];
        let u8Array = new Buffer.from(base64Image, 'base64');
        let appSettings = this.state.MADDetail.appMeta.appSettings;
        let settings = this.getPhotoSetting(appSettings);
        let fileName = this.state.webCamDevice.deviceID + "_" + settings + "_" + Date.now() + "_" + callbackIdentifier + "_000000001.jpg";
        let blob = new Blob([u8Array], {type: 'image/jpeg'});
        let file = new File([blob], fileName, {type: 'image/jpeg'});
        let formData = new FormData();
        formData.append("uploadedFile", file, fileName);
        formData.append("isSignedURL", "true");
        formData.append("path", "unprocessedQueue/" + fileName);
        await UploadVideoService.uploadImageToS3(formData);
    };

    getPhotoSetting(appSettings) {
        let settings = {};
        let imageSetting = "";
        for (let apps of appSettings) {
            settings[apps.type] = (settings[apps.type] ? settings[apps.type] : 0) + apps.isEnable;
        }
        if (settings['FD'] >= 1 && settings['MD'] >= 1) {
            imageSetting = 'MDFD';
        } else if (settings['FD'] >= 1)
            imageSetting = 'FD';
        else if (settings['MD'] >= 1)
            imageSetting = 'MD';
        else if (settings['PHOTO'] >= 1)
            imageSetting = 'PHOTO';
        else
            imageSetting = 'PHOTO';
        return imageSetting;
    }

    userDeviceSchedule = async (deviceID) => {
        let [error, userScheduleResponse] = await Utils.parseResponse(MADService.getMADSchedules(deviceID));
        if (error || !userScheduleResponse)
            return false;
        return userScheduleResponse;
    };

    navigateToAppInput = () => {
        if (!this.state.MADDetail || !this.state.MADDetail._id)
            return;
        Utils.navigateToPath(pathConstants.APP_INPUT + this.state.MADDetail._id);
    };

    onAppsClicked = async (appObject) => {
        this.setState({MADDetail: appObject, loaded: true});
        console.log("test redux mad" , this.props.madDetails)
        window.history.pushState("", "", `/my-apps/${appObject.appMeta.appRoute ? appObject.appMeta.appRoute : this.getAppRoute(appObject.appMeta.appName)}`)
        this.props.dispatchAction(actionsConst.SELECTED_APP, {selectedApp: appObject});
        this.props.dispatchAction(actionsConst.MAD_DETAILS , {madDetails : appObject})
        let isWebCam = false;
        if (appObject.connectedDevice && appObject.connectedDevice.length > 0) {
            for (let index = 0; index < appObject.connectedDevice.length; index++) {
                if (appObject.connectedDevice[index].executingServer === "webCam")
                    this.setState({isWebCam: true})
                else
                    this.setState({isWebCam: false})
            }
        } else
            this.setState({isWebCam: isWebCam});
        await this.checkScheduleAvailability(appObject);
        this.setState({loaded: false});
        clearInterval(this.state.interval);
        this.refreshUserData();
    };

    actionToggle = (event) => {
        this.setState({isPopoverOpen: !this.state.isPopoverOpen, target: event.currentTarget})
    }

    toggleMenu = () => {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    uploadVideo = async (event) => {
        let deviceID = null;
        let response = null;
        if (this.state.MADDetail.connectedDevice.length > 0)
            deviceID = this.state.MADDetail.connectedDevice[0].deviceID;
        else {
            deviceID = utility.generateRandomAlphaNumericString(24);
        }
        let callbackIdentifier = String(new Date().getTime()) + "-" + String(new Date().getTime())
        let formData = new FormData()
        formData.append("deviceID", deviceID);
        formData.append("callbackIdentifier", callbackIdentifier);
        formData.append("settings", "FD");
        formData.append("frameRates", 1);
        formData.append("videos", event.target.files[0])
        try {
            this.setState({loaded: true});
            response = await Utils.parseResponse(UploadVideoService.uploadVideos(formData));
            this.updateIsVideoUploaded(this.state.MADDetail)
        } catch (err) {
            // console.log(err)
        }
        if (response)
            await utility.uploadVideoAlert()
        this.setState({loaded: false});
        await this.componentDidMount()
    };

    updateIsVideoUploaded = (mad) => {
        let findObj = {
            "userID": mad.userID,
            "appMeta.appID": mad.appMeta.appID,
            "_id": mad._id,
        };
        let updateObj = {
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "isVideoUploaded": true
        };
        try {
            Utils.parseResponse(MADService.updateMAD(findObj, updateObj));
        } catch (err) {
            // console.log(err)
        }
    }

    disableApp = async () => {
        await MADService.disableApp(this.state.MADDetail._id).catch(err => {
            // console.log("server error", err);
        });
    }

    addAnotherDevice = (path) => {
        Utils.navigateToPath(path);
    };

    handleMobileMenu = () => {
        this.setState({mobileMenuOpen: !this.state.mobileMenuOpen})
    }

    onAvailableData = ({ data }) => {
        if (data.size > 0) {
            this.setState((prev) => ({
                recordedChunks:  [...prev.recordedChunks, data]
            }));
        }
    }
       
    captureVideo = async () => {
        const segmentTime = this.state.MADDetail?.appMeta?.segmentTime || 15
        if(!this.state.webcam.stream) return
        
        this.mediaRecorderRef.current = new MediaRecorder(this.state.webcam.stream, {
            mimeType: 'video/webm',
        });
        const imageSrc = this.state.webcam.getScreenshot();
        this.setState({ webCamImage: imageSrc });
        this.mediaRecorderRef.current.start();
        this.mediaRecorderRef.current.addEventListener("dataavailable", this.onAvailableData);
      
        await new Promise(resolve => setTimeout(resolve, Number(segmentTime) * 1000))
        this.mediaRecorderRef.current.stop();
        this.uploadVideoToS3()
    }

    uploadVideoToS3 = async () => {
        const { MADDetail, webCamDevice, recordedChunks } = this.state
        const chucks = [...recordedChunks]
        this.setState({ recordedChunks:  [] });
        if (!chucks?.length) return
        const blob = new Blob(chucks, { type: 'video/webm' });
        const callbackIdentifier = MADDetail.schedules[0].callbackIdentifier;
        const settings = this.getPhotoSetting(this.state.MADDetail?.appMeta?.appSettings)

        const fileName = webCamDevice.deviceID + "_" + settings + "_" + Date.now() + "_" + callbackIdentifier + "_000000001.webm";

        const file = new File([blob], fileName, { type: 'video/webm' });
        const form = new FormData();
        form.append("uploadedFile", file, fileName);
        form.append("isSignedURL", "true");
        form.append("path", "unprocessedQueue/" + fileName);
        await UploadVideoService.uploadImageToS3(form);
    }


    render() {
        // console.log("MyAppComponent --> render", this.props);
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
              <HeaderComponent />
            </div>
            {/*<hr className="m-t-0 m-b-0"/>*/}
            <div className="base-container px-120 md-px-24 xxl-px-120 xl-px-70">
              <MyAppComponent
                uploadVideo={this.uploadVideo}
                toggleMenu={this.toggleMenu}
                state={this.state}
                onAppsClicked={this.onAppsClicked}
                userMADDetails={this.state.userMADDetails}
                MADDetail={this.state.MADDetail}
                madID={this.state.madID}
                navigateToAppInput={this.navigateToAppInput}
                userAllDevices={this.state.userAllDevices}
                handleChange={this.handleChange}
                actionToggle={this.actionToggle}
                disableApp={this.disableApp}
                addAnotherDevice={this.addAnotherDevice}
                accessToken={this.state.accessToken}
                setRef={this.setRef}
                handleMobileMenu={this.handleMobileMenu}
              />
            </div>
            <FooterComponent2 />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails,madDetails :state.user.madDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(MyApps);
