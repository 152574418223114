import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../baseComponent";
import Utils, { dispatchAction } from "../../utility";
import {
  actionsConst,
  apiFailureConstants,
  cookiesConstants,
  pathConstants,
} from "../../constants";
import { sessionManager } from "../../managers/sessionManager";
import AuthService from "../../services/auth0Service";
import { Column, Row } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  Dropdown
} from "react-bootstrap";
import { getUserEntityDetails } from "../../services/deviceUserMapService";
import { AppDirectoryService, MADService } from "../../services";
import { PopupButton } from "react-calendly";
import {BsSearch} from "react-icons/bs";
import SearchBar from "./searchBar";

class HeaderComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      entityDetail: [],
      showMobileMenu: false,
      openSearchModal:false,
    };
  }

  async componentDidMount() {
    await this.authCallback();
    if (this.props.isLoggedIn) await this.getExistingMediaClientId();
    let [error, upcomingAppResponse] = await Utils.parseResponse(
      AppDirectoryService.getUpcomingApps()
    );
    if (error) Utils.apiFailureToast(apiFailureConstants.GET_UPCOMING_APPS);
    else if (upcomingAppResponse) {
      this.props.dispatchAction("upcomingApps", upcomingAppResponse.responseData.entityList);
  }
}

  getExistingMediaClientId = async () => {
    let entityDetail;
    let userID = sessionManager.getDataFromCookies(cookiesConstants.USER_ID)
      ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID)
      : "";
    let findObj = {
      queryObj: JSON.stringify({
        "entityData.userDetails.userID": userID,
        // 'entityData.userDetails.userID': "google-oauth2|117602483847868762045"
      }),
      limit: 0,
      skip: 0,
    };
    try {
      entityDetail = await getUserEntityDetails(findObj);
      if (entityDetail) {
        sessionManager.setDataInCookies(
          entityDetail,
          cookiesConstants.ENTITY_DETAILS
        );
        this.props.dispatchAction(actionsConst.USER_CONNECTED_MACHINE, {
          connectedMachine: entityDetail,
        });
        this.setState({ entityDetail: entityDetail });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  onSignUpClicked = async () => {
    let loginRedirectEndPoint = this.props.loginRedirectEndPoint
      ? this.props.loginRedirectEndPoint
      : "";
    this.authObject = new AuthService(
      process.env.REACT_APP_WEB_APP + loginRedirectEndPoint
    );
    await this.authObject.signIn(process.env.REACT_APP_WEB_APP);
  };

  authCallback = async () => {
    this.authObject = new AuthService(process.env.REACT_APP_WEB_APP);
    let [err, authResponse] = await this.authObject.handleAuthentication();
    if (
      err ||
      !authResponse ||
      !authResponse.accessToken ||
      !authResponse.idTokenPayload ||
      !authResponse.idTokenPayload.name ||
      !authResponse.idTokenPayload.sub ||
      !authResponse.idTokenPayload.email
    )
      return;
    sessionManager.setDataInCookies(
      authResponse.accessToken,
      cookiesConstants.AUTH0_ACCESS_TOKEN
    );
    sessionManager.setDataInCookies(
      authResponse.idToken,
      cookiesConstants.AUTH0_ID_TOKEN
    );
    sessionManager.setDataInCookies(
      JSON.stringify(authResponse.idTokenPayload),
      cookiesConstants.USER_DETAIL
    );
    sessionManager.setDataInCookies(
      authResponse.idTokenPayload.email,
      cookiesConstants.EMAIL_ID
    );
    sessionManager.setDataInCookies(
      authResponse.idTokenPayload.sub,
      cookiesConstants.USER_ID
    );
    let [error, jwtResponse] = await Utils.parseResponse(
      MADService.getJWTToken()
    );
    if (jwtResponse && jwtResponse.length > 0)
      sessionManager.setDataInCookies(
        jwtResponse,
        cookiesConstants.AUTH0_ACCESS_TOKEN
      );
    this.props.dispatchAction(actionsConst.USER_AUTH_CALLBACK, {
      userDetails: authResponse.idTokenPayload,
    });
    // let [errOr, userMADResponse] = await Utils.parseResponse(MADService.getUserMAD(authResponse.idTokenPayload.sub));
    // if (error || !userMADResponse) {
    //     return;
    // } else {
    //     if (userMADResponse.length > 0) {
    //         utility.navigateToPath(pathConstants.MY_APPS);
    //     }
    // }
  };

  navigateToMyApps() {
    Utils.navigateToPath(pathConstants.MY_APPS);
  }

  navigateToMyCameras() {
    Utils.navigateToPath(pathConstants.MY_CAMERAS);
  }

  navigateToHome = () => {
    Utils.navigateToPath(pathConstants.HOME);
  };

  navigateToAboutUs = () => {
    Utils.navigateToPath(pathConstants.ABOUT_US);
  };

  navigateToDeveloperApp = () => {
    Utils.navigateToPath(pathConstants.DEVELOPER);
  };

  navigateToConnectedMachine = () => {
    Utils.navigateToPath(pathConstants.CONNECTED_MACHINE);
  };

  logoutUser = () => {
    this.props.dispatchAction(actionsConst.LOGOUT_USER, {});
    sessionManager.removeDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN);
    sessionManager.removeDataFromCookies(cookiesConstants.USER_DETAIL);
    sessionManager.removeDataFromCookies(cookiesConstants.USER_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_RTSP);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_USERNAME);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_PASSWORD);
    sessionManager.removeDataFromCookies(cookiesConstants.MAD_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.EMAIL_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.AUTH0_ID_TOKEN);
    sessionManager.removeDataFromCookies(cookiesConstants.ENTITY_DETAILS);
    sessionManager.removeDataFromCookies(cookiesConstants.JWT_TOKEN);
    localStorage.clear();
    this.authObject.logout(process.env.REACT_APP_WEB_APP);
  };

  getMediaClientId = () => {
    window.open("/client-download");
  };

  toggleMobileMenu = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };

  getHeaderLogo() {
    return (
      <Row
        className="cursor-pointer w-10-per align-item-center"
        onClick={this.navigateToHome}
      >
        <img
          src="/images/appsStoreAiLogo.svg"
          alt="Appsstore.ai"
          height="23px"
          className="h-25px-m "
        />
      </Row>
    );
  }

  loggedInHeader() {
    let pages = window.location.href.split("//")[1].split("/")[1];
    // console.log("href", pages)
    return (
      <div>
        <Row vertical="center" className="w-100 align-item-center position-relative">
          <span className="display-none-m">{this.getHeaderLogo()}</span>
          <Row
            vertical="center"
            className="justify-content-between w-100 headerComponent p-t-sm display-none-m"
          >
            {pages === "user-device" ? (
              ""
            ) : (
              <Row className="align-item-center p-t-sm">
                {pages !== "my-apps" ? (
                  <Column
                    vertical="center"
                    className="fc-dark-grey w-fit-content ml-5 fs-15  cursor-pointer"
                    onClick={this.navigateToMyApps}
                  >
                    <Row className="align-items-center">
                      {/* <img
                        height="20px"
                        src={"/images/my-apps-icon-inactive.png"}
                      /> */}
                      <span className="m-l-xs p-sm-m">Dashboard</span>
                    </Row>
                    <hr className="invisible m-t-15 m-b-0" />
                  </Column>
                ) : (
                  <Column
                    vertical="center"
                    className="fc-dark-grey w-fit-content ml-5 fs-15  cursor-pointer"
                    onClick={this.navigateToMyApps}
                  >
                    <Row className="align-items-center">
                      <img height="20px" src={"/images/my-apps.png"} />
                      <span className="m-l-xs fc-blue p-sm-m">Dashboard</span>
                    </Row>
                    <hr color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                  </Column>
                )}

                {pages !== "my-cameras" ? (
                  <Column
                    vertical="center"
                    className="fc-dark-grey w-fit-content fs-15 ml-5 cursor-pointer"
                    onClick={this.navigateToMyCameras}
                  >
                    <Row className="align-items-center">
                      {/* <img height="20px" src={"/images/my-cameras.png"} /> */}
                      <span className="m-l-xs p-sm-m">My Cameras</span>
                    </Row>
                    <hr className="invisible m-t-15 m-b-0" />
                  </Column>
                ) : (
                  <Column
                    vertical="center"
                    className="fc-dark-grey w-fit-content fs-15 ml-5 cursor-pointer"
                    onClick={this.navigateToMyCameras}
                  >
                    <Row className="align-items-center">
                      {/* <img
                        height="20px"
                        src={"/images/my-cameras-active.png"}
                      /> */}
                      <span className="fc-blue m-l-xs">My Cameras</span>
                    </Row>
                    <hr color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                  </Column>
                )}

                {this.state.entityDetail &&
                this.state.entityDetail.length > 0 ? (
                  pages !== "connected-machine" ? (
                    <Column
                      vertical="center"
                      className="fc-dark-grey  fs-15 ml-5 cursor-pointer"
                      onClick={this.navigateToConnectedMachine}
                    >
                      <Row>
                        <img
                          height="20px"
                          alt=""
                          // src={"/images/connectedMachineInactive.svg"}
                        />
                        <span className="m-l-xs p-sm-m">Connected Machine</span>
                      </Row>
                      <hr className="invisible m-t-15 m-b-0" />
                    </Column>
                  ) : (
                    <Column
                      vertical="center"
                      className="fc-dark-grey  fs-15 ml-5 cursor-pointer"
                      onClick={this.navigateToConnectedMachine}
                    >
                      <Row className="align-item-center">
                        <img
                          height="20px"
                          // src={"/images/connectedMachineActive.svg"}
                          alt=""
                        />
                        <span className="m-l-xs fc-blue p-sm-m">
                          Connected Machine
                        </span>
                      </Row>
                      <hr color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                    </Column>
                  )
                ) : (
                  ""
                )}
                {pages !== "my-apps" &&
                pages !== "my-cameras" &&
                pages !== "app-input" ? (
                  <Row className="align-items-center">
                    {pages !== "about-us" ? (
                      <Column
                        vertical="center"
                        className="fc-dark-grey fs-15 ml-5 cursor-pointer"
                        onClick={this.navigateToAboutUs}
                      >
                        <Row className="align-items-center">
                          <img
                            height="20px"
                            // src={"/images/aboutUsInactive.svg"}
                            alt=""
                          />
                          <span className="m-l-xs p-sm-m">About Us</span>
                        </Row>
                        <hr className="invisible m-t-15 m-b-0" />
                      </Column>
                    ) : (
                      <Column
                        vertical="center"
                        className="fc-dark-grey fs-15 ml-5 cursor-pointer"
                        onClick={this.navigateToAboutUs}
                      >
                        <Row className="align-items-center">
                          <img
                            height="20px"
                            // src={"/images/aboutUsActive.svg"}
                            alt=""
                          />
                          <span className="fc-blue m-l-xs">About Us</span>
                        </Row>
                        <hr color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                      </Column>
                    )}

                    {pages !== "developer" ? (
                      <Column
                        vertical="center"
                        className="fc-dark-grey  fs-15 ml-5 cursor-pointer"
                        onClick={this.navigateToDeveloperApp}
                      >
                        <Row className="align-items-center">
                          <img
                            height="20px"
                            // src={"/images/developerInactive.svg"}
                            alt=""
                          />
                          <span className="m-l-xs p-sm-m">Developer</span>
                        </Row>
                        <hr className="invisible m-t-15 m-b-0" />
                      </Column>
                    ) : (
                      <Column
                        vertical="center"
                        className="fc-dark-grey  fs-15 ml-5 cursor-pointer"
                        onClick={this.navigateToDeveloperApp}
                      >
                        <Row className="align-items-center">
                          <img
                            height="20px"
                            // src={"/images/developerActive.svg"}
                            alt=""
                          />
                          <span className="m-l-xs fc-blue p-sm-m">
                            Developer
                          </span>
                        </Row>
                        <hr color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                      </Column>
                    )}
                  </Row>
                ) : (
                  ""
                )}
                <Column
                  vertical="center"
                  className="fc-dark-grey  fs-15 ml-5 cursor-pointer"
                >
                  <Row vertical={"center"}>
                    {/* <img src="/images/Support-Inactive.svg" height="20px" alt="" /> */}
                    <PopupButton
                      className="m-l-xs p-sm-m border-0 bg-transparent"
                      url={process.env.REACT_APP_CALENDLY}
                      rootElement={document.getElementById("root")}
                      text="Contact"
                    />
                  </Row>
                  <hr className="invisible m-t-15 m-b-0" />
                </Column>
                <Column
                  vertical="center"
                  className="fc-dark-grey  fs-15 ml-5 cursor-pointer"
                >
                  <Row vertical={"center"}>
                    {/* <img src="/images/Support-Inactive.svg" height="20px" alt="" /> */}

                    <span
                      className="m-l-xs p-sm-m"
                      onClick={() => window.open("https://blog.appsstore.ai/")}
                    >
                      Blogs
                    </span>
                  </Row>
                  <hr className="invisible m-t-15 m-b-0" />
                </Column>
                
                <Column
                vertical="center"
                className="fc-dark-grey  fs-15 ml-5 cursor-pointer">
                  {this.searchBar()}
                </Column>
              </Row>
            )}
            <Row className="m-l-auto">
              <Column>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-profile"
                    className="profile-dropdown"
                  >
                    <Row className="mr-2" alignItems="center">
                      {this.props.userDetails?.picture ? (
                        <div className="mr-3">
                          <img
                            className="profile-img"
                            src={this.props.userDetails?.picture}
                            alt="profile"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {
                        <div onClick={this.toggleShowUserId}>
                          <label className="fc-dark-grey fs-15 m-0">
                            {this.props?.userDetails &&
                            this.props?.userDetails?.name
                              ? this.props.userDetails?.name
                              : ""}
                          </label>
                        </div>
                      }
                    </Row>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropdown-menu-top">
                    <Dropdown.Item onClick={this.getMediaClientId}>
                      My Access Key
                    </Dropdown.Item>
                    <Dropdown.Item className="m-t-xs fc-black">
                      {" "}
                      <PopupButton
                        className="m-l-xs p-sm-m border-0 bg-transparent"
                        url={process.env.REACT_APP_CALENDLY}
                        rootElement={document.getElementById("root")}
                        text="Contact"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="1" onClick={this.logoutUser}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <hr className="invisible m-t-2 m-b-0" />
              </Column>
            </Row>
          </Row>
        </Row>
        <Row className="p-t-sm w-100-per align-item-center display-none-desktop m-b-sm">
          <span>{this.getHeaderLogo()}</span>
          <Column className="ml-auto" onClick={this.toggleMobileMenu}>
            <div className="hamburger-menu"></div>
            <div className="hamburger-menu"></div>
            <div className="hamburger-menu"></div>
          </Column>
        </Row>
        {this.state.showMobileMenu ? (
          <div className="display-none-desktop">
            {this.dropDownMenuSignIn()}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  dropDownMenuSignIn = () => {
    let pages = window.location.href.split("//")[1].split("/")[1];
    return (
      <div>
        <hr />
        <div className="m-t-15">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-profile" className="profile-dropdown">
              <Row className="mr-2" alignItems="center">
                {this.props.userDetails.picture ? (
                  <div className="mr-3 mls">
                    <img
                      className="profile-img"
                      src={this.props.userDetails.picture}
                      alt="profile"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div onClick={this.toggleShowUserId}>
                  <label className="fc-dark-grey fs-15 m-0">
                    {this.props.userDetails && this.props.userDetails.name
                      ? this.props.userDetails.name
                      : ""}
                  </label>
                </div>
              </Row>
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors dropdown-menu-top">
              <Dropdown.Item onClick={this.getMediaClientId}>
                My Access Key
              </Dropdown.Item>
              <Dropdown.Item className="m-t-xs fc-black">
                {" "}
                <PopupButton
                  className="m-l-xs p-sm-m border-0 bg-transparent"
                  url={process.env.REACT_APP_CALENDLY}
                  rootElement={document.getElementById("root")}
                  text="Contact"
                />
              </Dropdown.Item>
              <Dropdown.Item className="m-t-xs fc-black">
                <span
                  className="m-l-xs p-sm-m"
                  onClick={() => window.open("https://blog.appsstore.ai/")}
                >
                  Blogs
                </span>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={this.logoutUser}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <hr className="invisible m-t-2 m-b-0" />
        </div>
        <hr />
        {pages === "user-device" ? (
          ""
        ) : pages !== "my-apps" ? (
          <div
            vertical="center"
            className="fc-dark-grey w-100-per m-t-15 fs-15  cursor-pointer"
            onClick={this.navigateToMyApps}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/my-apps-icon-inactive.png"} /> */}
              <span className="m-l-xs p-sm-m">Dashboard</span>
            </Row>
          </div>
        ) : (
          <div
            vertical="center"
            className="fc-dark-grey w-100-per m-t-15 fs-15  cursor-pointer"
            onClick={this.navigateToMyApps}
          >
            <Row className="align-items-center">
              <img height="20px" src={"/images/my-apps.png"} alt="" />
              <span className="m-l-xs fc-blue p-sm-m">Dashboard</span>
            </Row>
          </div>
        )}

        {pages !== "my-cameras" ? (
          <div
            vertical="center"
            className="fc-dark-grey m-t-15 w-100-per fs-15  cursor-pointer"
            onClick={this.navigateToMyCameras}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/my-cameras.png"} /> */}
              <span className="m-l-xs p-sm-m">My Cameras</span>
            </Row>
          </div>
        ) : (
          <div
            vertical="center"
            className="fc-dark-grey m-t-15 w-100-per fs-15  cursor-pointer"
            onClick={this.navigateToMyCameras}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/my-cameras-active.png"} /> */}
              <span className="fc-blue p-sm-m m-l-xs">My Cameras</span>
            </Row>
          </div>
        )}

        {this.state.entityDetail && this.state.entityDetail.length > 0 ? (
          pages !== "connected-machine" ? (
            <div
              vertical="center"
              className="fc-dark-grey w-100-per m-t-15 fs-15  cursor-pointer"
              onClick={this.navigateToConnectedMachine}
            >
              <Row className="align-items-center">
                <img
                  height="20px"
                  // src={"/images/connectedMachineInactive.svg"}
                  alt=""
                />
                <span className="m-l-xs p-sm-m">Connected Machine</span>
              </Row>
            </div>
          ) : (
            <div
              vertical="center"
              className="fc-dark-grey w-100-per fs-15 w-100-per m-t-15 cursor-pointer"
              onClick={this.navigateToConnectedMachine}
            >
              <Row className="align-item-center">
                {/* <img height="20px" src={"/images/connectedMachineActive.svg"} /> */}
                <span className="m-l-xs fc-blue p-sm-m">Connected Machine</span>
              </Row>
            </div>
          )
        ) : (
          ""
        )}
        {pages !== "my-apps" &&
        pages !== "my-cameras" &&
        pages !== "app-input" ? (
          <div className="align-items-center">
            {pages !== "about-us" ? (
              <div
                vertical="center"
                className="fc-dark-grey fs-15 w-100-per m-t-15 cursor-pointer"
                onClick={this.navigateToAboutUs}
              >
                <Row className="align-items-center">
                  {/* <img height="20px" src={"/images/aboutUsInactive.svg"} /> */}
                  <span className="m-l-xs p-sm-m">About Us</span>
                </Row>
              </div>
            ) : (
              <div
                vertical="center"
                className="fc-dark-grey fs-15 w-100-per m-t-15 cursor-pointer"
                onClick={this.navigateToAboutUs}
              >
                <Row className="align-items-center">
                  {/* <img height="20px" src={"/images/aboutUsActive.svg"} /> */}
                  <span className="fc-blue p-sm-m m-l-xs">About Us</span>
                </Row>
              </div>
            )}

            {pages !== "developer" ? (
              <div
                vertical="center"
                className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer"
                onClick={this.navigateToDeveloperApp}
              >
                <Row className="align-items-center">
                  {/* <img height="20px" src={"/images/developerInactive.svg"} /> */}
                  <span className="m-l-xs p-sm-m">Developer</span>
                </Row>
              </div>
            ) : (
              <div
                vertical="center"
                className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer"
                onClick={this.navigateToDeveloperApp}
              >
                <Row className="align-items-center">
                  {/* <img height="20px" src={"/images/developerActive.svg"} /> */}
                  <span className="m-l-xs fc-blue p-sm-m">Developer</span>
                </Row>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div
          vertical="center"
          className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer m-b-20"
        >
          <Row vertical={"center"}>
            {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
            <PopupButton
              className="m-l-xs p-sm-m border-0 bg-transparent"
              url={process.env.REACT_APP_CALENDLY}
              rootElement={document.getElementById("root")}
              text="Contact"
            />
            {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
          </Row>
          <Row vertical={"center"}>
            {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
            <span
              className="m-l-xs p-sm-m"
              onClick={() => window.open("https://blog.appsstore.ai/")}
            >
              Blogs
            </span>
            {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
          </Row>
        </div>
      </div>
    );
  };

  searchBar=()=>{
    return (
      <>
     
        <Row vertical={"center"}>
          <span
           onClick={()=>this.setState({openSearchModal:!this.state.openSearchModal})}
          className="m-l-xs p-sm-m"
          >
          <BsSearch className="" />
            </span>
            </Row>
            <hr className="invisible m-t-15 m-b-0" />
          {this.state.openSearchModal && <SearchBar upComingApps={this.props.upcomingApps} />}
       
        </>
    )
  }

  loggedOutHeader() {
    let pages = window.location.href.split("//")[1].split("/")[1];
    return (
      <div>
        <Row
          vertical="center"
          className="m-t-xs w-100 align-item-center display-none-m position-relative"
        >
          <span>{this.getHeaderLogo()}</span>
          {pages === "user-device" ? (
            ""
          ) : (
            <Row className="justify-content-between w-100 align-items-center ">
              <Row className="align-items-center  p-t-sm ">
                {pages !== "about-us" ? (
                  <Column
                    vertical="center"
                    className="fc-dark-grey fs-15 ml-5 cursor-pointer p-t-xs"
                    onClick={this.navigateToAboutUs}
                  >
                    <Row className="align-items-center">
                      {/* <img height="20px" src={"/images/aboutUsInactive.svg"} alt="" /> */}
                      <span className="m-l-xs p-sm-m">About Us</span>
                    </Row>
                    <hr className="invisible m-t-15 m-b-0" />
                  </Column>
                ) : (
                  <Column
                    vertical="center"
                    className="fc-dark-grey fs-15 ml-5 cursor-pointer p-t-xs"
                    onClick={this.navigateToAboutUs}
                  >
                    <Row className="align-items-center">
                      {/* <img height="20px" src={"/images/aboutUsActive.svg"} /> */}
                      <span className="fc-blue m-l-xs">About Us</span>
                    </Row>
                    <hr color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                  </Column>
                )}

                {pages !== "developer" ? (
                  <Column
                    vertical="center"
                    className="fc-dark-grey  fs-15 ml-5 cursor-pointer p-t-xs"
                    onClick={this.navigateToDeveloperApp}
                  >
                    <Row className="align-items-center">
                      {/* <img
                        height="20px"
                        src={"/images/developerInactive.svg"}
                        alt=""
                      /> */}
                      <span className="m-l-xs p-sm-m">Developer</span>
                    </Row>
                    <hr className="invisible m-t-15 m-b-0" />
                  </Column>
                ) : (
                  <Column
                    vertical="center"
                    className="fc-dark-grey  fs-15 ml-5 cursor-pointer p-t-xs"
                    onClick={this.navigateToDeveloperApp}
                  >
                    <Row className="align-items-center">
                      {/* <img height="20px" src={"/images/developerActive.svg"} alt=""/> */}
                      <span className="m-l-xs fc-blue p-sm-m">Developer</span>
                    </Row>
                    <hr  color="blue" className="w-100-per br-1-blue m-t-15 m-b-0" />
                  </Column>
                )}

                <Column
                  vertical="center"
                  className="fc-dark-grey  fs-15 ml-5 cursor-pointer p-t-xs"
                >
                  <Row vertical={"center"}>
                    {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
                    <PopupButton
                      className="m-l-xs p-sm-m border-0 bg-transparent"
                      url={process.env.REACT_APP_CALENDLY}
                      rootElement={document.getElementById("root")}
                      text="Contact"
                    />
                  </Row>
                  <hr className="invisible m-t-15 m-b-0" />
                </Column>
                <Column
                  vertical="center"
                  className="fc-dark-grey  fs-15 ml-5 cursor-pointer p-t-xs"
                >
                  <Row vertical={"center"}>
                    {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
                    <span
                      className="m-l-xs p-sm-m"
                      onClick={() => window.open("https://blog.appsstore.ai/")}
                    >
                      Blogs
                    </span>
                  </Row>
                  <hr className="invisible m-t-15 m-b-0" />
                </Column>

                <Column
                vertical="center"
                className="fc-dark-grey  fs-15 ml-5 cursor-pointer p-t-xs">
                  {this.searchBar()}
                </Column>
                


              </Row>

              <Row>
                {/* <Column
                  vertical="center"
                  className="fc-dark-grey fw-bold fs-16 px-2 py-1 m-l-20 cursor-pointer"
                  onClick={this.onSignUpClicked}
                >
                  Login
                </Column> */}
                <Button
                  className="text-capitalize w-150 fs-16 fc-white fw-bold bg-theme outline-none m-l-20 br-30"
                  onClick={this.onSignUpClicked}
                >
                  Login/Sign Up
                </Button>
              </Row>
            </Row>
          )}
        </Row>
        <Row className="p-t-sm w-100-per align-item-center display-none-desktop m-b-sm">
          <span>{this.getHeaderLogo()}</span>
          <Column className="ml-auto" onClick={this.toggleMobileMenu}>
            <div className="hamburger-menu-first"></div>
            <div className="hamburger-menu"></div>
            <div className="hamburger-menu"></div>
          </Column>
        </Row>
        {this.state.showMobileMenu ? (
          <div className="display-none-desktop">
            {this.dropDownMenuLoggedOut()}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  dropDownMenuLoggedOut = () => {
    let pages = window.location.href.split("//")[1].split("/")[1];
    return pages === "user-device" ? (
      ""
    ) : (
      <div className=" w-100">
        <hr className="m-b-0" />
        <div className="m-t-15 w-100-per jc-space-between">
          <Row className="w-100-per jc-space-between">
            <div
              className="fc-dark-grey display-flex align-item-center fw-bold fs-16  cursor-pointer"
              onClick={this.onSignUpClicked}
            >
              Login
            </div>
            <Button
              className="text-capitalize w-150 fs-16 fc-white fw-bold bg-theme outline-none"
              onClick={this.onSignUpClicked}
            >
              Sign Up
            </Button>
          </Row>
        </div>
        <hr />
        {pages !== "about-us" ? (
          <div
            vertical="center"
            className="fc-dark-grey fs-15 w-100-per m-t-15 cursor-pointer p-t-xs"
            onClick={this.navigateToAboutUs}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/aboutUsInactive.svg"} /> */}
              <span className="m-l-xs p-sm-m">About Us</span>
            </Row>
          </div>
        ) : (
          <div
            vertical="center"
            className="fc-dark-grey fs-15 w-100-per m-t-15 cursor-pointer p-t-xs"
            onClick={this.navigateToAboutUs}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/aboutUsActive.svg"} /> */}
              <span className="fc-blue p-sm-m m-l-xs">About Us</span>
            </Row>
          </div>
        )}

        {pages !== "developer" ? (
          <div
            vertical="center"
            className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer p-t-xs"
            onClick={this.navigateToDeveloperApp}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/developerInactive.svg"} /> */}
              <span className="m-l-xs p-sm-m">Developer</span>
            </Row>
          </div>
        ) : (
          <div
            vertical="center"
            className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer"
            onClick={this.navigateToDeveloperApp}
          >
            <Row className="align-items-center">
              {/* <img height="20px" src={"/images/developerActive.svg"} /> */}
              <span className="m-l-xs fc-blue p-sm-m">Developer</span>
            </Row>
          </div>
        )}

        <div
          vertical="center"
          className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer p-t-xs m-b-20"
        >
          <Row vertical={"center"}>
            {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
            <PopupButton
              className="m-l-xs p-sm-m border-0 bg-transparent"
              url={process.env.REACT_APP_CALENDLY}
              rootElement={document.getElementById("root")}
              text="Contact"
            />
            {/* <img src="/images/Support-Inactive.svg" height="20px" /> */}
          </Row>
        </div>

        <div
          vertical="center"
          className="fc-dark-grey  fs-15 w-100-per m-t-15 cursor-pointer p-t-xs m-b-20"
        >
          <Row vertical={"center"}>
            <span
              className="m-l-xs p-sm-m"
              onClick={() => window.open("https://blog.appsstore.ai/")}
            >
              Blogs
            </span>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    let pages = window.location.href.split("//")[1].split("/")[1];
    return (
      <div>
        {this.props.isLoggedIn ? this.loggedInHeader() : this.loggedOutHeader()}
        {/* {pages !== "" && pages.charAt(0) !== "#" && pages !== "user-device" ? (
          // <hr className="m-t-0 m-b-0 m-t-10-m" />
          ""
        ) : (
          ""
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userDetails: state.user.userDetails,
    isLoggedIn: state.user.isLoggedIn,
    upcomingApps:state.user.upcomingApps,
  };
};

export default connect(mapStateToProps, { dispatchAction })(HeaderComponent);
