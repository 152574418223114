import React from "react";
import BaseComponent from "../baseComponent";
import { history } from "../../managers/history";
import {
  AiFillLinkedin,
  AiFillInstagram,
  AiFillTwitterCircle,
  AiFillYoutube,
  AiFillFacebook,
} from "react-icons/ai";
import { socialMediaConstant, footerDataConstant } from "../../constants/index";
import Utils from "../../utility";
import utility from "../../utility";

let { footerData, privacyTerms } = footerDataConstant;
export class FooterComponent2 extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      blankField: "bg-theme",
      openDialog: false,
      errorMsg: "",
    };
  }

  onChangeEvent = (event) => {
    if (event.target.value.length !== 0)
      this.setState({ blankField: "bg-theme" });
    this.setState({ [event.target.id]: event.target.value });
  };

  onClickHandler = () => {
    if (this.state.email.trim() <= 0) {
      this.setState({
        blankField: "bg-red",
        errorMsg: "( Please enter your e-mail address )",
      });
      return;
    } else if (!this.isValidEmail(this.state.email)) {
      this.setState({
        blankField: "bg-red",
        errorMsg: "( Email is not valid )",
      });
      return;
    }
    this.setState({ blankField: "bg-theme" });
    Utils.apiSuccessToast("we‘ll be in touch soon");
  };

  isValidEmail = (email) => {
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  };

  handleClick = (to, isBlank) => {
    utility.navigate(to, isBlank);
  };
  render() {
    return (
      <>
        <div className="footer2 p-t-xs m-t-55 home-container">
          <div className="w-full display-grid grid-place-items-center m-t-66">
            <p
              className="text-white fs-200 cursor-pointer"
              onClick={() => history.push("/")}
            >
              Apps<span className="color-light-blue fs-200">Store</span>.ai
            </p>
          </div>
          <div className="flex jc-space-between flex-wrap p-t-68 md-p-t-0 base-container lg-flex-col-reverse px-120 md-px-24">
            <div className="">
              <div className="m-b-30 fc-white fs-24-29-600">Quick Links</div>
              <div>
                <div className="display-grid footer-component-grid gap-59 lg-grid-cols-3">
                  {footerData.map(({ firstData }, idx) => (
                    <div
                      key={idx}
                      className="display-grid gap-23 w-fit-content"
                    >
                      {firstData.map(({ to, isBlank, name }, idx) => (
                        <div
                          key={idx}
                          onClick={this.handleClick.bind(this, to, isBlank)}
                          className="fc-white fs-16 fs-16-19-400 cursor-pointer lg-fs-12-14-400"
                        >
                          {name}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="display-none  lg-block all-items-center m-t-40 m-b-20">
                  <SocialMediaIcon center="justify-content-center" />
                </div>
              </div>
            </div>

            <div className="m-t-55 lg-m-t-20">
              <div className="fs-13  fs-16-19-400 fc-white ">
                Please subscribe here to know more for{" "}
                <br className="subscribeFooter" />
                upcoming details
              </div>

              <div
                className={`${
                  this.state.blankField !== "bg-red"
                    ? "display-none"
                    : "display-block"
                } fs-18 mb-2  font-color-red m-t-8`}
              >
                {this.state.errorMsg}
              </div>
              <div className="flex m-t-16 m-b-20 ">
                <input
                  id="email"
                  type="email"
                  onChange={this.onChangeEvent}
                  className=" fs-12-14-400-lato p-l fc-black button-style bg-off-white w-177 h-48"
                  placeholder="Add your email"
                ></input>
                <button
                  onClick={this.onClickHandler}
                  className={`${this.state.blankField} text-white button-style w-154 h-48 fs-18-22-500 cursor-pointer`}
                >
                  Subscribe
                </button>
              </div>

              {/* desktop Social Media Icons */}
              <div className="lg-hidden">
                <SocialMediaIcon />
              </div>
            </div>
          </div>
          <div className="flex justify-between  p-t-68 md-p-t-0 base-container flex-direction-column-reverse-m px-120 md-px-24">
            <div className="flex md-justify-between m-t-20">
              {privacyTerms.map(({ name, to }, idx) => {
                const isLastElement = idx === privacyTerms.length - 1;
                return (
                  <p
                    key={idx}
                    onClick={() => history.push(to)}
                    className={`${
                      isLastElement ? "m-r-0" : "m-r-20"
                    } fs-12  text-grey pointer`}
                  >
                    {name}
                  </p>
                );
              })}
            </div>

            <div className="text-grey  w-auto-m w-331 md-text-center">
              ©{new Date().getFullYear()} AppsStore.ai |
              <a href="mailto:support@appsstore.ai">&nbsp;hello@appsstore.ai</a>
            </div>
          </div>
        </div>
        {/* <Row className="footer3">
          <Column className="text-white  m-l-340">
            <h5 className="fs-12">Terms of use</h5>
          </Column>
          <Column className="text-white m-l-450">
            ©2021 AppsStore.ai | support@appsstore.ai
          </Column>
        </Row> */}
      </>
    );
  }
}

const SocialMediaIcon = ({ center }) => {
  const mediaData = [
    { link: socialMediaConstant.LINKED_IN, Icon: AiFillLinkedin },
    { link: socialMediaConstant.FACEBOOK, Icon: AiFillFacebook },
    { link: socialMediaConstant.INSTAGRAM, Icon: AiFillInstagram },
    { link: socialMediaConstant.TWITTER, Icon: AiFillTwitterCircle },
    { link: socialMediaConstant.YOUTUBE, Icon: AiFillYoutube },
  ];
  return (
    <div className={`flex gap-26  m-t-sm m-b-20 w-100-per ${center}`}>
      {mediaData.map(({ link, Icon }, idx) => (
        <Icon
          key={idx}
          onClick={() => window.open(link)}
          className=" fs-25 font-color-lightgrey cursor-pointer"
        />
      ))}
    </div>
  );
};

export default FooterComponent2;
