import React from 'react';
import {Column, Row} from "simple-flexbox";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Button, Dialog} from "@material-ui/core";
import CheeseburgerMenu from "cheeseburger-menu";

const MachineDetailComponent = (props) => {
    return (
        <Row className="h-100-vh">
            {props.state.menuOpen ?
                <Column className='display-none-m w-300 overflow-auto'>
                    <div className="display-flex jc-space-between align-item-center m-t-35 p-r-xs p-l-sm">
                        <span className="">MACHINE</span>
                        <img src="/images/close-menu.png" height="30px" width="30px" onClick={() => {
                            props.toggleMenu()
                        }} className={"cursor-pointer"} alt="" />
                    </div>
                    <div>
                        {props.state.connectedMachine.map((machine, index) => {
                            return renderAllConnectedMachine(props, machine, index);
                        })}
                    </div>
                </Column> :
                <Column className="display-none-m m-t-35 overflow-auto">
                    <Row className="align-item-center p-l-sm fs-14">
                        MACH<img src="/images/open-menu.png" height="30px" width="30px" onClick={() => {
                        props.toggleMenu()
                    }} className={"cursor-pointer"} alt=""/>
                    </Row>
                    <div>
                        {props.connectedMachine.map((machine, index) => {
                            return onMenuClose(props, machine, index);
                        })}
                    </div>
                </Column>}
            {props.state.isDetails ?
                <div className="w-100-per-m">
                    {connectedMachineDetail(props)}
                </div> :
                <Column className="display-none-desktop w-100-per-m m-t-35 overflow-auto">
                    <Row className="align-item-center">
                        <span className="margin-auto fs-14">MACHINE</span>
                        {/* <img src="/images/open-menu.png" height="30px" width="30px" onClick={() => { props.toggleMenu() }} /> */}
                    </Row>
                    <div>
                        {props.connectedMachine.map((machine, index) => {
                            return onMenuClose(props, machine, index);
                        })}
                    </div>
                </Column>}
            <div className='b-vertical-line display-none-m'/>
            <Column className='flex-grow-1 w-50-per m-l-15 m-t-20 display-none-m'>
                {props.selectedMachine && props.selectedMachine.length > 0 ?
                    <div>
                        <Row className="p-md m-r-15 align-item-center m-t-sm">
                            <label className="fs-16 fc-dark-grey w-25">Device ID</label>
                            <label className="fs-16 fc-dark-grey w-25">Local IP</label>
                            <label className="fs-16 fc-dark-grey w-25">Public IP</label>
                            <label className="fs-16  fc-dark-grey w-25">Status</label>
                        </Row>
                    </div> : ''}
                {props.selectedMachine && props.selectedMachine.length > 0 ? props.selectedMachine.map((machine) => {
                    return renderMachine(props, machine);
                }) : ''}
            </Column>
            {appMenuMobile(props)}
            {confirmation(props)}
        </Row>
    )
}

const renderAllConnectedMachine = (props, machine) => {
    let isActiveApp = props.machineId === machine._id ? 'selected-app' : 'app';
    return (
        <Row className={"cursor-pointer p-15 m-l-sm m-r-sm m-t-xs " + isActiveApp}
             onClick={() => props.onMachineClicked(machine)}>
            <Row className="w-250">
                <div className="p-sm display-flex align-item-center">
                    <img src='/images/my-cameras.png' alt='my-camera'/>
                </div>
                <div className="b-1-grey m-l-10"/>
                <div className="m-l-20">
                    <div className="fs-17">{machine.entityData.IP}</div>
                </div>
            </Row>
        </Row>
    )
}

const onMenuClose = (props, machine) => {
    let isActiveApp = props.machineId === machine._id ? 'selected-app' : 'app';
    return (
        <OverlayTrigger
            placement="top-end"
            delay={{show: 100, hide: 50}}
            overlay={MachineIpToolTip(machine.entityData.IP, props)}>
            <div className="m-l-sm-m m-r-sm-m m-t-sm-m" onClick={props.toggleMachineDetail}>
                <Row className={"cursor-pointer margin-10 margin-auto-m p-sm w-62px h-63 w-100-per-m " + isActiveApp}
                     onClick={() => props.onMachineClicked(machine)}>
                    <img width="35px" height="35px" className="margin-auto" src='/images/my-cameras.png'
                         alt='my-camera'/>
                    <Column className="w-100-per-m display-none-desktop m-l-20">
                        <Row>
                            <span>Private IP</span>
                        </Row>
                        <Row>
                            <span>{machine.entityData.IP}</span>
                        </Row>
                    </Column>
                    <Column className="ml-auto display-none-desktop align-item-center justify-content-center">
                        <img src="/images/right-chevron.png" height="15px" alt=""/>
                    </Column>
                </Row>
            </div>
        </OverlayTrigger>
    )

}


function MachineIpToolTip(IP, props) {
    return (
        <Tooltip id="button-tooltip" {...props}>
            {IP}
        </Tooltip>
    );
}


const renderMachine = (props, machine) => {
    return (
        <div>
            <Row className={"p-md m-r-15 align-item-center m-t-sm bg-creme"}>
                <label className="fs-16  fc-dark-grey w-25">{machine.entityData.deviceID}</label>
                <label for={"IP"} className="fs-16 w-25 fc-dark-grey br-none"><input onChange={props.onInputChange}
                                                                                     placeholder="Add your Private IP"
                                                                                     className={'fs-15 mr-2 w-200 outline-none h-45 w-140 p-2 b-1-grey'}
                                                                                     value={props.state.IP}/></label>
                <label className="fs-16  fc-dark-grey w-25">{machine.entityData.publicIP}</label>
                <label className="fs-16  fc-dark-grey">{props.state.status ? props.state.status : "InActive"}</label>
                {props.state.edited ?
                    <Button className='fs-15 fc-white bg-theme outline-none w-80-px text-transform-none m-l-auto'
                            onClick={props.updateMachine}>Save</Button> : ''}
                <Button className='fs-15 fc-white bg-theme outline-none w-80-px text-transform-none m-l-auto'
                        onClick={props.handleConfirmation}>Delete</Button>
            </Row>
        </div>
    )
}


const confirmation = (props) => {
    return (
        <Dialog open={props.state.confirmation}>
            <div className="w-450 h-170">
                <Row className="justify-center p-15 w-100 fs-16">
                    Are you sure, you would like to Delete the connected device captured media and images?
                </Row>
                <Row className="fs-12 justify-content-center">
                    Once you delete a local client all captured images will be deleted permanently.
                </Row>
                <Row className="jc-space-between m-t-30">
                    <button className="h-40  b-1-ccc w-80-px m-l-15" onClick={props.handleConfirmation}>Cancel</button>
                    <button className="h-40 w-80-px m-r-15 br-none bg-theme fc-white"
                            onClick={props.deleteConnectedMachine}>Ok
                    </button>
                </Row>
            </div>
        </Dialog>
    )
}

const connectedMachineDetail = (props) => {
    return (
        props.selectedMachine && props.selectedMachine.length > 0 ?

            <div className="display-none-desktop p-l-sm p-r-sm m-t-60 p-10 m-t-0-m">
                <Row className="align-item-center m-b-20 m-t-xs">
                    <div className="m-t-min1">
                        <img src="/images/open-menu.png" height="35px" width="40px" onClick={props.handleMobileMenu} alt=""/>
                    </div>
                    <span className="fc-black fw-bold fs-15 cursor-pointer m-l-20">Connected Machines</span>


                </Row>
                <div className="bg-creme p-sm">
                    <Column className="jc-space-between m-t-15">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Device ID</span>
                        <span className="fs-15 fc-black m-b-10-m">{props.selectedMachine[0].entityData.deviceID}</span>
                    </Column>
                    <Column className="jc-space-between m-t-15">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Public IP</span>
                        <span className="fs-15 fc-black m-b-10-m">{props.selectedMachine[0].entityData.publicIP}</span>
                    </Column>

                    <Column className="jc-space-between m-t-15">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Private IP</span>
                        <row>
                            <label htmlFor={"IP"} className=" fs-16 w-25 font-color-lightgrey br-none m-b-10-m"><input
                                onChange={props.onInputChange} placeholder="Add your Private IP"
                                className={'fs-15 mr-2 w-200 m- outline-none h-45 p-2 b-1-grey'}
                                value={props.state.IP}/></label>
                            {props.state.edited ?
                                <Button className="unstyled-button m-l-120 fc-theme font-weight-normal"
                                        onClick={props.updateMachine}>Save</Button> : ''}

                        </row>
                    </Column>
                    <Column className="jc-space-between m-t-15 display-none-desktop">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Port Number</span>
                        <span className="fs-15 fc-black m-b-10-m">{props.selectedMachine[0].entityData.port}</span>
                    </Column>

                    <Column className="jc-space-between m-t-15 display-none-desktop">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Total Memory</span>
                        <span

                            className="fs-15 fc-black m-b-10-m">{Number(props.selectedMachine[0].entityData.memory.totalMemory / 1024 / 1024 / 1024).toFixed(2) + " GB"}</span>
                    </Column>
                    <Column className="jc-space-between m-t-15 display-none-desktop">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Architecture</span>
                        <span
                            className="fs-15 fc-black m-b-10-m">{props.selectedMachine[0].entityData.os.architecture}</span>
                    </Column>
                    <Column className="jc-space-between m-t-15 display-none-desktop">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Model</span>
                        <span
                            className="fs-15 fc-black m-b-10-m">{props.selectedMachine[0].entityData.os.cpus[0].model}</span>
                    </Column>
                    <Column className="jc-space-between m-t-15 display-none-desktop">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Operating System</span>
                        <span className="fs-15 fc-black m-b-10-m">{props.selectedMachine[0].entityData.os.type}</span>
                    </Column>


                    <Column className="jc-space-between m-t-15">
                        <span className="fs-14 fc-7c7c7c m-b-10-m">Status</span>
                        <label
                            className="fs-15 fc-black m-b-10-m">{props.state.status ? props.state.status : "Inactive"}</label>
                    </Column>
                </div>
                <Column className="jc-space-between p-l-15-m p-r-15-m">
                    <Button
                        className='fs-15  fc-white bg-theme outline-none width-100px text-transform-none m-l-auto w-100-per-m'
                        onClick={props.handleConfirmation}>Delete</Button>
                </Column>

            </div> : ""

    )
}


const appMenuMobile = (props) => {
    return (
        <CheeseburgerMenu className="display-none-desktop" isOpen={props.state.mobileMenuOpen}
                          closeCallback={props.handleMobileMenu}>
            <Row className='cursor-pointer m-b-sm align-item-center m-t-sm jc-space-between'>
                <img src="/images/appsStoreLogo.svg" alt="Appsstore.ai" height="23px" className="h-25px-m m-l-sm"/>
                <img src="/images/close-menu.png" height="35px" width="40px" onClick={() => {
                    props.handleMobileMenu()
                }} className="cursor-pointer display-none-desktop m-r-sm" alt=""/>
            </Row>
            {props.state.connectedMachine.map((machine, index) => {
                return renderAllConnectedMachine(props, machine, index);
            })}
        </CheeseburgerMenu>
    )
}

export default MachineDetailComponent;
