import React from "react";
import { Row } from "simple-flexbox";

const bigBrands = [
  "/images/amazonImage.png",
  "/images/tesla.png",
  "/images/terexImage.png",
  "/images/johnDeere.png",
  "/images/lgImage.png",
  "/images/komatsuImage.png",
];

const BigBrands = () => {
  return (
    <Row className="home-container bg-light-voilet  lg-mt-0 m-t-10per flex-direction-column display-flex px-120 pt-48 pb-36 md-px-24 lg-bg-white">
      <p className="font-lato text-ft32-40 font-semibold text-align-center md-text-ft24-30">
        Some of the Big Brands already using AI technology for improving their
        business results
      </p>
      <div className="m-t-44 lg-mt-16  display-flex  justify-center lg-grid lg-grid-cols-3 lg-place-items-center grid-gap-align-item">
        {bigBrands.map((brandImg, idx) => (
          <div
            key={idx}
            className="h-90 grid-place-items-center contain-width-m w-180 bg-white br-18 md-h-55 md-w-94 md-br-11 border-1 border-lightBlue-10"
          >
            <img className="md-w-50" src={brandImg} alt="" />
          </div>
        ))}
      </div>
    </Row>
  );
};

export default BigBrands;
