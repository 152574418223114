import React from "react";
import FooterComponent from "../common/footerComponentSecond";
import HeaderComponent from "../common/headerComponent";
import DeveloperForm from "./developerForm";
import DeveloperComponent from "./developersComponent";
import SuccessPopUp from "../common/successMsg";

const Developer = (props) => {
  const { type ,changeState } = props.state;

  return (
    <div className="home-container">
      <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
        <HeaderComponent loginRedirectEndPoint="" />
      </div>
      <div className="overflow-auto">
        {(() => {
          switch (type) {
            case "developer":
              return <DeveloperComponent {...props} />;
            case "developer-form":
              return <DeveloperForm {...props} />;
            case "success-msg":
              return (
                <SuccessPopUp
                  closeDialog={() => changeState("type", "developer")}
                  {...props}
                />
              );
            default:
              return;
          }
        })()}
      </div>
      <div className="m-b-200" />
      <FooterComponent />
    </div>
  );
};

export default Developer;
