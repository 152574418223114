import React from 'react';
import BaseComponent from "../baseComponent";
import CostCalculatorComponent from './costCalculatorComponent'
import { connect } from "react-redux";
import Utils, { dispatchAction } from "../../utility";
import { AppDirectoryService, MADService } from "../../services";
import HeaderComponent from "../common/headerComponent";
import { sessionManager } from "../../managers/sessionManager";
import { apiFailureConstants, cookiesConstants, pathConstants, appStoreSettingConstants } from "../../constants";

class CostCalculator extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            frameRate: [5],
            frameInterval: [30],
            appDetails: {},
            estimatedCost: 0,
            selectedPlan: {
                timeInMillis: 2592000000,
                credits: 1000,
                amount: 100,
                unit: 'face'
            }
        }
    }

    async componentDidMount() {
        let { appID } = this.props.match.params;
        let [error, appDetailResponse] = await Utils.parseResponse(AppDirectoryService.getAppDetails(appID));
        this.selectPlan(appDetailResponse);
        this.getEstimatedCost(appDetailResponse);
        this.setState({ appDetails: appDetailResponse });
        await this.getOrder();
    }

    selectPlan = (appDetails) => {
        if (!appDetails || !appDetails.entityData || !appDetails.entityData.subscriptionPlans || !appDetails.entityData.subscriptionPlans.length)
            return false;
        let subscriptionPlan = {
            timeInMillis: appDetails.entityData.subscriptionPlans[0].validity,
            credits: appDetails.entityData.subscriptionPlans[0].count,
            amount: appDetails.entityData.subscriptionPlans[0].amount,
            unit: appDetails.entityData.subscriptionPlans[0].unit,
        }
        this.setState({ selectedPlan: subscriptionPlan });
    }

    getOrder = async () => {
        if (!sessionManager.getDataFromCookies(cookiesConstants.USER_ID)) {
            return false;
        }
        let requestBody = {
            'purchasedBy.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID),
            'appDetails.appId': this.state.appDetails._id
        };

        let [error, getOrderResponse] = await Utils.parseResponse(MADService.getOrder(requestBody));
        if (getOrderResponse)
            this.setState({ orderDetails: getOrderResponse });
    }


    getEstimatedCost = (appDetails) => {
        let estimatedCost = this.state.selectedPlan.amount + this.state.frameRate[0];
        if (appDetails.entityData.appStoreSettingType === appStoreSettingConstants.FD)
            estimatedCost = estimatedCost + 30 * this.state.selectedPlan.amount / 100
        this.setState({ estimatedCost })
    }

    onChangeFrameRate = async (frameRate) => {
        await this.setState({ frameRate })
        this.getEstimatedCost(this.state.appDetails)
    };

    payButtonClick = async () => {
        this.openRazorPayPopup(this.state.orderDetails.orderDetails);
    };

    openRazorPayPopup = (orderDetails) => {
        let _this = this;
        let options = {
            key: process.env.REACT_APP_RAZOR_PAY_ID,
            amount: this.state.estimatedCost * 100,
            name: 'Make Payment',
            image: '/images/logo.png',
            description: '',
            async handler(response) {
                await _this.updateOrder(response, orderDetails)
            },
            notes: {
                order_id: orderDetails.id
            },
            theme: {
                color: '#4c84ff',
            }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    updateOrder = async (paymentRes, orderDetails) => {
        let requestBody = {
            orderId: orderDetails.id,
            paymentId: paymentRes.razorpay_payment_id,
            subscriptionPlan: 'MONTHLY'
        };
        let [error, updateOrderResponse] = await Utils.parseResponse(MADService.updateOrder(requestBody));
        if (!error)
            this.updateMadInternalUsage();
    };

    updateMadInternalUsage = async () => {
        let requestData = {
            userID: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : process.env.REACT_APP_USER_ID,
            appMeta: this.state.appDetails.entityData
        };
        requestData.internalUsage = this.getCredits();
        requestData.modifiedOn = Date.now();
        let [error, addMADResponse] = await Utils.parseResponse(MADService.updateMADInternalUsage(requestData));
        if (error || !addMADResponse) {
            return Utils.apiFailureToast(apiFailureConstants.ADD_MAD);
        } else if (addMADResponse) {
            Utils.navigateToPath(pathConstants.APP_INPUT + addMADResponse._id);
        }
    };

    getCredits = () => {
        let credits = {
            FD: {
                unit: this.state.selectedPlan.unit || 'face',
                endTime: Date.now() + this.state.selectedPlan.timeInMillis,
                allocatedCredits: this.state.selectedPlan.credits,
                remainingCredits: this.state.selectedPlan.credits
            }
        }
        return credits;
    }

    render() {
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
              <HeaderComponent />
            </div>
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
            <CostCalculatorComponent
              state={this.state}
              frameRate={this.state.frameRate}
              frameInterval={this.state.frameInterval}
              appDetails={this.state.appDetails}
              onChangeFrameRate={this.onChangeFrameRate}
              payButtonClick={this.payButtonClick}
            />
            </div>
            <div className="m-b-300" />
            <div className="footer m-t-500">
              ©2021 AppsStore.ai |{" "}
              <a href="mailto:support@appsstore.ai">hello@appsstore.ai</a>
            </div>
          </div>
        );
    }

}

const mapStateToProps = (state) => {
    return { user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn }
};
export default connect(mapStateToProps, { dispatchAction })(CostCalculator);
