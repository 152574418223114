import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button, Typography } from "@material-ui/core";
import HeaderComponent from "../common/headerComponent";

function cameraSignUpComponent(props) {
    let buttonColor = '';
    if (props.state.color === "grey") {
        buttonColor = 'bg-grey'
    } else if (props.state.color === "green") {
        buttonColor = 'bg-green';
    } else {
        buttonColor = 'bg-theme';
    }
    return (
      <div className="home-container">
        <div className="px-120 md-px-24 display-none-desktop base-container xxl-px-120 xl-px-70">
          <HeaderComponent loginRedirectEndPoint="" />
        </div>

        <Column className="p-3">
          <div className="w-100 fc-black fw-bold text-center fs-32 fs-28-m mt-3 l-h-3-m">
            Allow AppsStore.ai to access your IP camera
          </div>
          <div className="max-w-500 margin-auto">
            <form className="m-t-60 border-input p-40 p-0-m m-t-sm-m bg-white">
              <img
                className="w-100 display-none-m"
                src="/images/ip-camera-access.png"
                alt="verizon"
              />
              <div className="fc-bluish fw-bold m-t-20 p-md text-center m-t-min-10-m">
                Provide your IP camera credentials
              </div>
              <div className="mt-3">
                {/*<DropboxChooser*/}
                {/*    success={files => props.dropboxSuccess(files)}*/}
                {/*    cancel={() => props.onCancelDropBox()}*/}
                {/*    appKey={"svnmnf0kh7i8d6u"}*/}
                {/*    extensions={['.mp4']}*/}
                {/*    multiselect={true}>*/}
                {/*    <img className="w-50-px h-50 text-capitalize fc-white m-l-200"*/}
                {/*         src="/images/dropbox.png">*/}
                {/*    </img>*/}
                {/*</DropboxChooser>*/}
              </div>
              <div className="mt-3">
                <Row className="fc-dark-grey fs-16 fw-bold align-item-center jc-space-between m-b-0 h-30">
                  RTSP Camera URL
                  <Row className="align-item-center m-b-0">
                    {props.state.executingServer ? (
                      <div>
                        <img
                          src="/images/cameraActive.png"
                          height="10px"
                          className="margin-auto"
                        />
                        <span className="m-l-xs">Local Client</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/*<Checkbox*/}
                    {/*    checked={props.state.executingServer}*/}
                    {/*    color={'primary'}*/}
                    {/*    onChange={props.handleChange}/>Local*/}
                  </Row>
                </Row>
                <input
                  id="IP"
                  type="text"
                  onChange={props.onChangeEvent}
                  value={props.IP}
                  className="w-100 fs-16 p-2 outline-none h-45 b-1-input m-b-10-m"
                  placeholder="eg: rtsp://212.31.54.15:554/profile5/media.smp"
                />
                {/*<a href="/connect-to-local-server">How to connect to local server?</a>*/}
              </div>
              {props.state.executingServer ? (
                props.state.executingServerDetails &&
                Object.keys(props.state.executingServerDetails).length >= 1 ? (
                  ""
                ) : (
                  <div className="mt-3">
                    <div className="fc-dark-grey fs-14 m-t-30 m-b-20">
                      You are trying to add a local camera. In order to use a
                      local RTSP camera, you need to setup the AppsStore client
                      on a Ubuntu Machine.
                      <a href="/client-download">
                        {" "}
                        Click here to see the instructions.
                      </a>
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
              <div className="mt-3">
                <div className="fc-dark-grey fs-16 fw-bold">
                  Give name to your camera
                </div>
                <input
                  id="deviceName"
                  type="text"
                  onChange={props.onChangeEvent}
                  value={props.deviceName}
                  className="w-100 fs-16 p-2 mt-1 outline-none h-45 b-1-input m-b-10-m"
                  placeholder="Office.Reception"
                />
              </div>
              <div className="mt-3">
                <div className="fc-dark-grey fs-16 fw-bold">
                  Camera Username
                </div>
                <input
                  id="username"
                  type="text"
                  onChange={props.onChangeEvent}
                  value={props.username}
                  className="w-100 fs-16 p-2 mt-1 outline-none h-45 b-1-input m-b-10-m"
                />
              </div>
              <div className="mt-3">
                <div className="fc-dark-grey fs-16 fw-bold">
                  Camera Password
                </div>
                <input
                  id="password"
                  type="password"
                  onChange={props.onChangeEvent}
                  value={props.password}
                  className="w-100 fs-16 p-2 mt-1 outline-none h-45 b-1-input m-b-20-m"
                />
              </div>
              <div className="mt-3">
                <Button
                  className={
                    "w-100 fc-white h-45 text-capitalize fs-18 fw-500 cursor-pointer " +
                    buttonColor
                  }
                  onClick={props.onCameraConnectClicked}
                  disabled={props.state.buttonDisable}
                >
                  {props.state.text}
                </Button>
              </div>
              {props.state.image && props.state.imageLoaded ? (
                <div className="fc-brownish-grey fs-16 text-center">
                  <img
                    src={props.state.image}
                    className="max-w-500 max-h-200 m-t-20"
                    alt=""
                  />
                </div>
              ) : (
                ""
              )}
              <div className=" fc-warm-grey m-t-40 text-center fs-14">
                <Typography className="">
                  By connecting your IP camera you agree to our <br />{" "}
                  <a href="https://www.appsstore.ai/terms">
                    terms & conditions
                  </a>{" "}
                  and{" "}
                  <a href="https://www.appsstore.ai/privacy">privacy policy.</a>
                </Typography>
              </div>
            </form>
          </div>
        </Column>
      </div>
    );
}
export default cameraSignUpComponent;