import React from "react";

export const StartFreeTrial = (props) => {
  const { onStarted } = props;
  const data = [
    {
      text: "Select from 100 of Apps",
      image: "./images/select-trial.svg"
    },
    {
      text: "No Code Easy Set up",
      image: "./images/code-trial.svg"
    },
    {
      text: "Free Onboarding and Support",
      image: "./images/support-trial.svg"
    },
    {
      text: "Use on CCTVS, Drone-Cams or Dashcams",
      image: "./images/cctv-trial.svg"
    }
  ];

  const onClickHandler = () => {
     onStarted();
  };
  return (
    <div className="m-t-100">
      <div className="bg-lightBlue-10 py-72 h-531 lg-h-auto t-a-c position-relative">
        <img src="./images/small-dot.svg" alt="" className="position-absolute right-0 -top-22" />
        <div className="fc-dark-slate-blue fs-40-48-700 md-fs-21-140per-700 lg-fs-24-29-700 ">
          START YOUR FREE TRIAL
        </div>
        <div className="fc-lightBlue30 fs-28-42-600 m-t-8 md-text-ft18-22 lg-fs-20-72-700">
           No Contract, No Credit Card required
        </div>
       
        <div className="display-grid grid-cols-4 lg-cols-2 md-grid-cols-2 lg-place-items-center m-t-55 px-120 gap-40 lg-grid-cols-2 md-grid-cols-1 md-px-24 ">
          {data.map(({ text, image }, idx) => {
            const isLastElement = idx === data.length - 1;
            return (
              <div
                className="display-grid grid-cols-2 md-grid-cols-1 lg-grid-cols-1 md-place-items-center grid-place-items-center"
                key={idx}
              >
                <div className="max-w-214">
                  <img src={image} alt="" />
                  <div className="m-t-24 fs-20-24-600 md-fs-18-130per-400 font-color-light-grey-black">
                    {text}
                  </div>
                </div>
                <div
                  className={`vertical-line-trial m-l-40 md-hide ${
                    isLastElement && "display-none"
                  } lg-hidden`}
                />
              </div>
            );
          })}
        </div>
        <div>
          <button
            onClick={onClickHandler}
            className="br-25 fc-white m-t-32 h-53 w-191 bg-black fs-20-24-600 cursor-pointer border-0 talk-ai-expert-button"
          >
            Get Started Now
          </button>
        </div>
        <img src="./images/small-dot.svg" alt="" className="position-absolute -left-12 bottom-75 rotate-90 " />
      </div>
    </div>
  );
};
