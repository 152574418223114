import { Column } from "simple-flexbox";
import React from "react";

const SuccessPopUp = ({ closeDialog }) => {
  return (
    <Column
      className={`justify-center align-item-center min-w-100vw  bg-white m-t-68`}
    >
      <div className="position-absolute cross-icon">
        <img
          src="/images/close.svg"
          className="cursor-pointer"
          alt=""
          onClick={closeDialog}
        />
      </div>
      <div className="green-dot" />
      <img src="/images/thumbs-up.svg" alt="" />
      <p className="m-t-60 fs-40-48-800 font-color-green">Thank You!</p>
      <p className="text-align-center m-t-55 fs-36-43-500 font-font-color-light-grey-black max-w-588">
        We have received your submission, and we‘ll be in touch soon!
      </p>
    </Column>
  );
};
export default SuccessPopUp;
