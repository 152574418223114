import auth0 from 'auth0-js';
import {cookiesConstants, httpConstants} from "../constants";
import {sessionManager} from "../managers/sessionManager";

export default class AuthService {

    constructor(redirectUri) {
        this.auth0 = new auth0.WebAuth({
            domain: "dev-hetfa8uo.auth0.com",
            clientID: "3ITuQHuqsPFuPYZtsSHp3fsw2wQxd0P0",
            redirectUri: redirectUri,
            responseType: 'code',
        });

        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.signIn = this.signIn.bind(this);
        this.logout = this.logout.bind(this);
    }

    async signIn(redirectUri) {
        this.auth0.authorize({
            audience: 'https://www.appsstore.ai:3001/',
            scope: 'openid profile email read:mad',
            responseType: 'token id_token',
            redirectUri: redirectUri,
        });
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash(async (err, authResult) => {
                // console.log("handleAuthentication -- err -- authResult --> ", err, authResult);
                if (err || !authResult || !authResult.idToken)
                    return resolve([err], null);

                // await this.saveJWTToken();
                resolve([null, authResult]);
            });
        });
    }

    async saveJWTToken() {
        const requestOptions = {
            method: "GET",
            headers: {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}
        };
        let url = "https://www.appsstore.ai:3002/getJWTToken";
        return fetch(url, requestOptions)
            .then(function handleResponse(response) {
                //in case API is down-
                if (!response || !response.ok)
                    return Promise.reject("Unable to fetch data");

                return response.text().then(responseText => {

                    if (!responseText)
                        return Promise.reject(responseText);

                    let data;
                    try {
                        data = typeof responseText === 'object' ? responseText : JSON.parse(responseText);
                        if (data && !data.success)
                            return Promise.reject((data && data.responseCode) === 404 ? data : (data && data.message) || response.statusText);
                        sessionManager.setDataInCookies(data.responseData.access_token, cookiesConstants.JWT_TOKEN)
                    } catch (err) {
                        // console.log('Class: httpService, Function: fetch ==', err);
                        return Promise.reject(err)
                    }
                    return data;
                });
            }).catch(function (err) {
                // console.log('Class: httpService, Function: fetch ==', err);
                return Promise.reject(err);
            })
    }

    logout(redirectUri) {
        this.auth0.logout({
            returnTo: redirectUri
        });
    }
}
