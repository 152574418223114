import React from 'react';
import {Column, Row} from "simple-flexbox";
import {Button, Typography} from "@material-ui/core";
import {Handles, Rail, Slider, Ticks, Tracks} from "react-compound-slider";
import {Handle, SliderRail, Tick, Track} from "../appInput/sliderComponent";

const frameRateDomain = [1, 30];
const frameIntervalDomain = [10, 300];

const sliderStyle = {
    position: 'relative',
    width: '100%',
    touchAction: 'none',
};

function frameRateComponent(props) {
    const {frameRate, frameInterval} = props;

    return (
        <Column className='mt-3 pt-4'>
            <Row>
                <Typography className="fs-18 fc-dark-grey">How many photos shall we check per second fps</Typography>
            </Row>
            <Row className='mt-3 mb-5'>
                <Slider mode={1} step={1} domain={frameRateDomain}
                        rootStyle={sliderStyle} onChange={props.onChangeFrameRate} values={frameRate}>
                    <Rail>
                        {({getRailProps}) => <SliderRail getRailProps={getRailProps}/>}
                    </Rail>
                    <Handles>
                        {({handles, getHandleProps}) => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle key={handle.id} handle={handle}
                                            domain={frameRateDomain} getHandleProps={getHandleProps}/>
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks right={false}>
                        {({tracks, getTrackProps}) => (
                            <div className="slider-tracks">
                                {tracks.map(({id, source, target}) => (
                                    <Track key={id} source={source} target={target}
                                           getTrackProps={getTrackProps}/>
                                ))}
                            </div>
                        )}
                    </Tracks>
                    <Ticks count={5}>
                        {({ticks}) => (
                            <div className="slider-ticks">
                                {ticks.map(tick => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length}/>
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            </Row>
        </Column>
    );
}


function CostCalculatorComponent(props) {
    return (
        <div className="home-container p-l p-r pt-5">
            <Row className="justify-content-between">
                <Column className="fs-18 fc-dark-grey">App Cost</Column>
                <Column>${props.state.selectedPlan.amount}</Column>
            </Row>
            {frameRateComponent(props)}
            <Row className="pt-2 justify-content-between">
                <Column className="fs-18 fc-dark-grey">Time</Column>
                <Column className="">{props.state.selectedPlan.timeInMillis/86400000} days</Column>
            </Row>
            <Row className="pt-5 justify-content-between">
                <Column className="fs-18 fc-dark-grey">Number of Recognitions</Column>
                <Column className="">{props.state.selectedPlan.credits}</Column>
            </Row>
            <Row className="justify-content-between pt-5">
                <Column className="fs-18 fc-dark-grey">Estimated Cost</Column>
                <Column>${props.state.estimatedCost}</Column>
            </Row>
            <Row className="pt-5 justify-content-end">
                <Button variant="outlined" onClick={() => props.payButtonClick()}
                        className='max-w-200 w-100 float-right fs-16 bg-theme fc-white outline-none text-transform-none'>
                    Pay
                </Button>
            </Row>
        </div>)
}

export default CostCalculatorComponent;
