import {httpService} from "./httpService";
import {httpConstants} from "../constants";

export default {
    createVideoStream
}

async function createVideoStream(data) {
    let url = process.env.REACT_APP_VIDEO_STREAMING_SERVICE + httpConstants.API_END_POINT.GET_RTSP;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, data, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
