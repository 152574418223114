import {cookiesConstants, httpConstants} from "../constants";
import {sessionManager} from "../managers/sessionManager";
import AuthService from "./auth0Service";

export const httpService = (method, headers, data, url) => {
    let jwtToken = sessionManager.getDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN);
    let header = new Headers();
    header.append("Content-Type", httpConstants.CONTENT_TYPE.APPLICATION_JSON);
    header.append("Authorization", "Bearer " + jwtToken);
    const requestOptions = {
        method: method,
        headers: header
    };

    if (method !== httpConstants.METHOD_TYPE.GET)
        requestOptions.body = JSON.stringify(data);

    return fetch(url, requestOptions)
        .then(function handleResponse(response) {
            //in case API is down-
            if (!response || !response.ok) {
                unauthorizedUser();
                return Promise.reject("Unable to fetch data");
            }
            return response.text().then(responseText => {

                if (!responseText) {
                    // unauthorizedUser();
                    return Promise.reject(responseText);
                }
                let data;
                try {
                    data = typeof responseText === 'object' ? responseText : JSON.parse(responseText);
                    if (data && !data.success)
                        return Promise.reject((data && data.responseCode) === 404 ? data : (data && data.message) || response.statusText);

                } catch (err) {
                    // unauthorizedUser();
                    return Promise.reject(err)
                }
                return data;
            });
        }).catch(function (err) {
            return Promise.reject(err);
        })

};


function unauthorizedUser() {
    let authObject = new AuthService(process.env.REACT_APP_WEB_APP);
    sessionManager.removeDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN);
    sessionManager.removeDataFromCookies(cookiesConstants.USER_DETAIL);
    sessionManager.removeDataFromCookies(cookiesConstants.USER_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_RTSP);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_USERNAME);
    sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_PASSWORD);
    sessionManager.removeDataFromCookies(cookiesConstants.MAD_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.EMAIL_ID);
    sessionManager.removeDataFromCookies(cookiesConstants.AUTH0_ID_TOKEN);
    sessionManager.removeDataFromCookies(cookiesConstants.ENTITY_DETAILS);
    sessionManager.removeDataFromCookies(cookiesConstants.JWT_TOKEN);
    localStorage.clear();
    authObject.logout(process.env.REACT_APP_WEB_APP);
}
