import React from 'react';
import {Column, Row} from "simple-flexbox";
import {Breadcrumbs, Typography, Link, Button, Dialog} from '@material-ui/core';
import {Carousel} from 'react-bootstrap';
import {Player} from 'video-react';
import renderHTML from 'react-render-html';
import {appStoreSettingConstants, genericConstants, pathConstants } from '../../constants'
import Utils from "../../utility";
import SuggestApps from "./suggestApps";
import { Heading,  AboutApp } from "./firstSections";
import { Benefits, TestimonialSection } from "./secondSections";
import HowItsWork from './howItsWork';




const  AppListComponent=(props)=> {
    return (
      <div>
        <Column className="justify-content-between">
          {!props.appDetail || !Object.keys(props.appDetail).length ? (
            <div className="text-center">
              <img src={"/images/loader.svg"} alt="" />
            </div>
          ) : (
              <NewAppDetails {...props} />
            // renderAppDetailView(props) //old app-details page
          )}
        </Column>
      </div>
    );
}


const NewAppDetails = (props) => {
  let appData =
    props.appDetail && props.appDetail.entityData
      ? props.appDetail.entityData
      : {};

  return (
    <div className="m-t-74">
      <Heading {...props} />
      <AboutApp appData={appData} />
      {appData.workFlow.length!==0 && (  //temp purpose -until the content is not ready
        <>
      <HowItsWork appData={appData} />
      <Benefits appData={appData} />
      <TestimonialSection appData={appData} /> 
        </>
      )}
     
      <SuggestApps
        upcomingApps={props.upComingApps}
        onUpcomingAppsClicked={props.onUpcomingAppsClicked}
      />
      <HandlePopUp {...props} />
    </div>
  );
};

const HandlePopUp = (props) => {
  const {state} = props;
  return (
      <Dialog open={state.isPopOver}>
          <div className="w-500">
              {/* <Row className="display-flex jc-space-between p-20">
                  <div>
                      <label className="fs-18 margin-none">
                          Take Photo
                      </label>
                  </div>
                  <div>
                      <img onClick={() => props.tryButtonClick()} alt="close-icon"
                          className={'cursor-pointer'}
                          src="/images/close-icon.png" />
                  </div>
              </Row> */}
              <Row className="fs-18  fc-light-black p-25">
                  {appStoreSettingConstants.FREE_TRIAL}
              </Row>
              <Row className="jc-space-between p-25">
                  <button className="bg-theme h-45 p-xs outline-none fc-white br-4 w-35 border-0"
                          onClick={() => {
                              props.tryButtonClick()
                          }}>Cancel
                  </button>
                  <button className="bg-theme h-45 p-xs outline-none fc-white br-4 w-35 border-0"
                          onClick={() => {
                              props.onContinueClick()
                          }}>Continue
                  </button>
              </Row>
          </div>
      </Dialog>
  );
}
function renderAppDetailView(props) {
  let appData = (props.appDetail && props.appDetail.entityData) ? props.appDetail.entityData : {};
  let mimeType;
  return (
    <Column>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link
          className="fc-theme fs-15"
          onClick={props.handleClick(pathConstants.HOME)}
        >
          Home
        </Link>
        <Link
          className="fc-theme fs-15"
          onClick={props.handleClick(pathConstants.MARKET_PLACE)}
        >
          Apps
        </Link>
        <Typography className="fs-15 fc-grey">
          {appData && appData.appName ? appData.appName : ""}
        </Typography>
      </Breadcrumbs>
      <Row className="m-t-20 m-b-20 flex-wrap-m ">
        <Column className="mr-4-desktop jc-center-m w-100-per-m align-items-center-m p-t-20-m ">
          <div className="logo-container-home p-2">
            <img className="app-logo" src={appData.logo} alt="app-logo" />
          </div>
        </Column>
        <Column className="mr-4-desktop margin-auto-m align-items-center-m m-t-20-m p-t-20-m m-l-20">
          <Typography className="fs-24 fw-bold fc-light-black md-text-center">
            <div className='flex md-flex-col'>
            {appData && appData.appName ? appData.appName : ""}

            {!props.appDetail.isActive ? (
              <div className='flex justify-center w-auto'>
              <img className="m-l-lg " src={"/images/coming-soon.svg"} alt="coming-soon" />
              <span className=" m-l-sm fs-17 fw-100 white-space-no-wrap ">Coming soon</span>
              </div>
            ) : (
              ""
            )}
           
            </div>
          </Typography>
          <Typography className="fs-18 fc-brownish-grey">
            {appData && appData.description ? appData.description : ""}
          </Typography>
          <Typography className="fs-15 fc-theme m-t-20-m">
            {appData && appData.appType ? appData.appType : ""}
          </Typography>
        </Column>

        <Row className="flex-grow-1 justify-content-center-m justify-content-end m-t-20-m">
          {props.appDetail &&
          props.appDetail.isActive &&
          props.appDetail.entityData.amount ? (
            <Button
              variant="outlined"
              onClick={() => props.tryButtonClick()}
              className="max-w-200 w-100 float-right fs-16 bg-theme fc-white h-40 outline-none text-transform-none"
            >
              {!props.state.isAppPurchased &&
              !props.state.isFreeTrialActive &&
              !props.state.isFreeTrialExpired ? (
                !props.state.loader ? (
                  "Start free trial"
                ) : (
                  <img src={"/images/loader.svg"} alt="" />
                )
              ) : props.state.isAppPurchased ||
                props.state.isFreeTrialActive ? (
                <span>
                  {!props.state.loader ? (
                    "Open Dashboard"
                  ) : (
                    <img src={"/images/loader.svg"} alt="" />
                  )}
                </span>
              ) : !props.state.loader ? (
                "Purchase"
              ) : (
                <img src={"/images/loader.svg"} alt="" />
              )}
              {/*From ₹{(props.appDetail.entityData.amount / 100).toFixed(2)}/m'</span>)}*/}
            </Button>
          ) : (
            <Button
              variant="outlined"
              className=" border-dark button-text-transform max-w-200 w-100 fw-300 float-right fs-20 fc-dark-grey h-40 outline-none"
            >
              Pre-register
            </Button>
          )}
        </Row>
        {/* {handlePopOver(props)} */}
      </Row>

      <div className="b-1-grey m-t-20 m-b-20" />
      <div>
        <div className="mt-2 mb-4">
          <Typography className="fs-18 fc-dark-grey fw-bold">
            Screenshots and Features
          </Typography>
        </div>
        <Carousel
          className="w-100 b-1-grey"
          pauseOnHover={true}
          slide={true}
          wrap={true}
        >
          {appData.media
            ? appData.media.map((mediaObj, index) => {
                mimeType = Utils.getMimeType(mediaObj.mimeType);
                return mimeType === genericConstants.MIME_TYPE.IMAGE ? (
                  <Carousel.Item key={"carousel-item" + index}>
                    <img
                      className="d-block w-100"
                      src={mediaObj.url}
                      alt={"Media-" + index}
                    />
                  </Carousel.Item>
                ) : mimeType === genericConstants.MIME_TYPE.VIDEO ? (
                  <Carousel.Item key={"carousel-item" + index}>
                    <Player>
                      <source src={mediaObj.url} />
                    </Player>
                  </Carousel.Item>
                ) : (
                  ""
                );
              })
            : ""}
        </Carousel>
      </div>
      <div className="b-1-grey m-t-20 m-b-20" />

      <div>
        <div className="mt-2 mb-4">
          <Typography className="fs-18 fc-dark-grey fw-bold">
            Overview
          </Typography>
        </div>
        <div className="fs-16 fc-dark-grey fw-400">
          {renderHTML(appData.overview)}
        </div>
      </div>
      {props.appDetail.isActive ? (
        <div>
          <div className="b-1-grey m-t-20 m-b-20" />

          <div>
            <div className="mt-2 mb-4">
              <Typography className="fs-18 fc-dark-grey fw-bold">
                Information
              </Typography>
            </div>
            <div className="fs-16 fc-dark-grey fw-400">
              {appData.appMetaData && Object.keys(appData.appMetaData).length
                ? Object.keys(appData.appMetaData).map((key, index) => {
                    return (
                      <Row key={"info" + key} className="p-xs">
                        <Typography className="text-capitalize app-info fs-16">
                          {key}:
                        </Typography>
                        <Typography className="fs-16">
                          {appData.appMetaData[key]}
                        </Typography>
                      </Row>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Column>
  );
}
export default AppListComponent;
