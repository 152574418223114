import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

const ImageDropZone = ({ setSelectFile, text, file }) => {
  const [photoUrl, setPhotoURL] = useState("");

  const [logoSelected, setLogoSelected] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxSize: "10485760",
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            return;
          } else if (err.code === "file-invalid-type") {
            return;
          } else {
            return;
          }
        });
      });
      
      setSelectFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )[0]
      );
      setPhotoURL(acceptedFiles.map((file) => URL.createObjectURL(file))[0]);
      let formData = new FormData();
      formData.append(
        "attachment",
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )[0]
      );
      (async () => {
        //api
        setLogoSelected(true);
      })();
    }
  });
  return (
    <>
      {logoSelected ? (
        <div
          className="br-1-grey h-140 br-30 all-items-center cursor-pointer position-relative"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img
            src={photoUrl}
            className="width-184 h-140 position-absolute br-30"
            alt=""
          />
        </div>
      ) : (
        <div
          className="br-1-grey h-140 br-30 all-items-center cursor-pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img src="/images/upload-image.svg" className="w-40 h-40 " alt="" />
          <p className="font-color-light-grey-black  fs-18-22-500">{text}</p>
        </div>
      )}
    </>
  );
};

export default ImageDropZone;
