import React, { useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { errorConstant, faqsConstant } from "../../constants";
import { useForm } from "react-hook-form";
import { AppDirectoryService } from "../../services";
import utility from "../../utility";
let { faqsData } = faqsConstant;

const Faqs = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [displayedFaqs, setDisplayedFaqs] = useState(faqsData.slice(0, 6));
  const [allDisplayed, setAllDisplayed] = useState(false);
  let moreIndex = 6;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (event) => {
    const obj = getValues();
    try {
      await AppDirectoryService.addContact(obj);
      utility.apiSuccessToast("We will get back to you soon.");
    } catch (err) {
      utility.apiFailureToast("server error");
    }
  };

  const PlusMinusIcon = ({ active }) => (
    <span className={`${active && "fc-4c84ff"}`}>
      {active ? (
        <BiMinus className="w-24 h-24" />
      ) : (
        <BiPlus className="w-24 h-24" />
      )}
    </span>
  );

  const handleTabClick = (e) => {
    if (e === currentTab) {
      setCurrentTab((prev) => null);
      return;
    }
    setCurrentTab(e);
  };

  const handleMoreClick = () => {
    const lastIndex = displayedFaqs[displayedFaqs.length - 1].index;
    setDisplayedFaqs(faqsData.slice(0, displayedFaqs.length + moreIndex));
    if (displayedFaqs.length + moreIndex >= faqsData.length) {
      setAllDisplayed(true);
    }
    const moreElement = document.getElementById(`item-${lastIndex}`);
    moreElement.scrollIntoView({ behavior: "smooth" });
  };

  const handleLessClick = () => {
    setDisplayedFaqs(faqsData.slice(0, moreIndex));
    setAllDisplayed(false);
    const lessElement = document.getElementById("faqs");
    lessElement.scrollIntoView({ behavior: "smooth" });
  };

  const handleMoreText = (e, value, index) => {
    e.stopPropagation();
    displayedFaqs[index].showFullText = value;
    setDisplayedFaqs([...displayedFaqs]);
  };

  return (
    <div className="">
      <div className="m-t-100 px-120 md-px-24 base-container xxl-px-120 xl-px-70">
        <div className="flex w-100-per justify-content-center-m m-b-24">
          <span
            id="faqs"
            className="fs-40-32-500 fs-20-72-700-m white-space-no-wrap mr-3 "
          >
            FAQS
          </span>
          <hr
            className="horizontal-work w-100-per rounded display-none-m"
            color="#b6b6b6"
          />
        </div>
        {displayedFaqs.map((ele, i) => {
          let activeTab = currentTab === i;

          return (
            <React.Fragment key={i}>
              <div
                id={`item-${ele.index}`}
                className={` py-24 faq-container  cursor-pointer`}
                onClick={() => handleTabClick(i)}
              >
                <div className="flex gap-16 w-fit-content">
                  <PlusMinusIcon active={activeTab} />

                  <div
                    className={`fs-20-24-600  fc-dark-grey md-fs-18-150per-700 ${
                      activeTab && "fc-4c84ff"
                    }`}
                  >
                    {ele.question}
                  </div>
                </div>
                <div
                  className={`fs-16-24-400 md-fs-16-24-400 fc-dark-grey mt-2 m-l-50 ${
                    activeTab ? "display-block" : "display-none "
                  }`}
                >
                  {ele.showFullText || ele.answer.length < 190
                    ? ele.answer
                    : `${ele.answer.substring(0, 190) + "..."}`}
                  <span
                    onClick={(e) => handleMoreText(e, true, i)}
                    className="fs-16-19-600  font-color-light-grey"
                  >
                    {ele.showFullText || ele.answer.length < 190 ? "" : "more"}
                  </span>
                </div>
              </div>
              <hr color="#E6E6E6" className="w-100-per br-1-white m-0" />
            </React.Fragment>
          );
        })}
        {!allDisplayed ? (
          <div
            id="more"
            className="cursor-pointer fs-20-24-600 gap-10 font-color-light-grey-black m-t-43 flex align-items-center max-w-150"
            onClick={handleMoreClick}
          >
            More
            <IoIosArrowDown />
          </div>
        ) : (
          <div
            className="cursor-pointer fs-20-24-600 gap-10 font-color-light-grey-black m-t-43 flex align-items-center max-w-150"
            onClick={handleLessClick}
          >
            Less
            <IoIosArrowUp />
          </div>
        )}
      </div>
      <div className="bg-lightBlue-20 min-h-654 py-50 w-100-per m-t-80 lg-h-auto">
        <div className=" margin-auto  display-grid grid-cols-2 lg-grid-cols-1 lg-place-items-center gap-84">
          <div className="fs-40-60-700 lg-fs-24-29-700 md-fs-24-140per-700 fc-white display-grid grid-place-items-center h-fit m-t-30">
            Have more questions?
            <img src="/images/contact-us.svg" alt="" className="m-t-78" />
          </div>
          <div className="bg-lightBlue-10 br-30 p-t-32 w-510  md-w-90-per">
            <div className="fs-32-38-600 md-fs-24-140per-700 fc-dark-slate-blue text-align-center">
              Write To Us
            </div>
            <div className="">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="m-t-40 w-100-per px-32 display-grid gap-24">
                  <div>
                    <div className="flex align-items-baseline mb-2 fs-18-150-400">
                      <div className=" fc-dark-grey">
                        Name*&nbsp;
                      </div>
                      {errors.name && (
                        <div className="font-color-red">
                          {"( " + errors.name.message + " )"}
                        </div>
                      )}
                    </div>
                    <input
                      type={"text"}
                      placeholder="Name"
                      className="p-l-15 form-input width-100-per "
                      {...register("name", {
                        required: errorConstant.NAME,
                      })}
                    />
                  </div>
                  <div>
                    <div className="flex align-items-baseline mb-2 fs-18-150-400">
                      <div className="fc-dark-grey"> Email*&nbsp;</div>
                      {errors.email && (
                        <div className="font-color-red ">
                          {"( " + errors.email.message + " )"}
                        </div>
                      )}
                    </div>
                    <input
                      type={"text"}
                      placeholder="Email ID"
                      className="p-l-15 form-input width-100-per"
                      {...register("email", {
                        required: errorConstant.EMAIL,
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: errorConstant.EMAIL_VALID,
                        },
                      })}
                    />
                  </div>
                  <div>
                    <div className="flex align-items-baseline mb-2 fs-18-150-400 ">
                      <div className="fc-dark-grey">Description*&nbsp;</div>
                      {errors.message && (
                        <div className="font-color-red">
                          {"( " + errors.message.message + " )"}
                        </div>
                      )}
                    </div>

                    <textarea
                      type={"text"}
                      placeholder="Description"
                      className="p-l-15 form-input width-100-per h-153 resize-none p-t-16"
                      {...register("message", {
                        required: errorConstant.DESCRIPTION,
                      })}
                    />
                  </div>
                </div>
                <div className="display-grid grid-place-items-center w-100-per m-t-32 m-b-20">
                  <button className={`${Object.keys(errors).length > 0 ?"bg-red":"blue-button-1"} fc-white w-207 h-53 border-0 br-30`}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
