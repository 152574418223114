import React, { useState, useEffect } from "react";
import FacesComponent from "./facesComponent";
import ImageRecognition from "./imageRecognition";
import TopBar from "./topBar";
import TrainSystem from "./trainSystem";
import Footer from "../common/footerComponentSecond";
import Utils from "../../utility";
import { AttendanceSystemService } from "../../services";

const FaceRecognition = () => {
  const [faces, setFaces] = useState({});

  const fetchGetData = async () => {
    try {
      const data = await new AttendanceSystemService().getData();
      if (!!data.length) {
        const result = Utils.groupBy(data, "name");
        setFaces(result);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    fetchGetData();
    // eslint-disable-next-line
  }, []);

  const onTrainModel = async (image, name) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      const response = await new AttendanceSystemService().train(formData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const onPredict = async (image) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      const response = await new AttendanceSystemService().predict(formData);
      fetchGetData();
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <div>
      <TopBar />
      <TrainSystem onTrainModel={onTrainModel} />
      <ImageRecognition onPredict={onPredict} />
      <FacesComponent faces={faces} />
      <Footer />
    </div>
  );
};

export default FaceRecognition;
