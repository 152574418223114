import React from "react";
import { Column, Row } from "simple-flexbox";
import { howItsWorkConstant } from "../../constants";
let { STEPS } = howItsWorkConstant;

const HowItsWork = () => {
  return (
    <div className=" px-120 md-px-24  base-container xxl-px-120 xl-px-70 ">
      <Row className="w-100-per justify-content-center-m m-t-79">
        <span className="fs-40-32-500 fs-20-72-700-m white-space-no-wrap mr-3 ju">
          HOW IT <span className="fs-40-32-500 fs-20-72-700-m">WORKS</span>
        </span>
        <hr
          className="horizontal-work w-100-per rounded display-none-m"
          color="#b6b6b6"
        />
      </Row>
      <div className="fs-20-143per-400 md-fs-14-150per-400 m-t-8 fs-12-143per-400-m t-a-c-m">
        Link your camera feeds to AI apps with a few clicks and increase
        productivity
      </div>
      <MobHowItsWork />

      <Row className="gap-110 gap-85-div justify-center m-t-120 position-relative display-mobile-none-m">
        {STEPS.map(({ index, text }, idx) => (
          <Column className="position-relative align-item-center" key={idx}>
            <div className="text-how-its-work position-absolute ">{index}</div>
            <div className="slight-div-square"></div>
            <div className="m-t-60 fs-20-24-600 fc-dark-slate-blue max-w-200">
              {text}
            </div>
          </Column>
        ))}
        <div className="position-absolute ">
          <img
            className="how-it-work-image"
            src="/images/how-its-work-image.svg"
            alt=""
          />
        </div>
      </Row>
      <div className="m-t-80">
        <div className="br-32 br-1-cff w-100-per display-grid grid-place-items-center policy-container lg-grid-cols-1">
          <img
            src="./images/computer-security.gif"
            alt=""
            className="w-402 lg-w-full"
          />
          <div>
            <div className="fs-32-38-600 md-fs-21-140per-700 fc-dark-slate-blue  lg-fs-24-29-700 t-a-c ">
              We adhere to strict data protection policies
            </div>
            <div className="fs-24-32-400 t-a-c md-px-28 font-color-light-grey-black m-b-8 m-t-8 md-fs-16-150per-400 lg-fs-20-72-700">
              We are committed to protecting your personal Data.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const MobHowItsWork = () => {
  return (
    <Row className="justify-between display-none display-mobile-flex-m ">
      <Column className="justify-around">
        {STEPS.map(({ index, text }, idx) => (
          <div className="" key={idx}>
            <div className="text-shadow-4b2 fc-white fs-24-29-600">{index}</div>
            <div className="fs-12-18-500 fc-dark-slate-blue md-fs-14-150per-400">{text}</div>
          </div>
        ))}
      </Column>
      <img src="/images/mob-how-its-work.svg" alt="" />
    </Row>
  );
};

export default HowItsWork;
