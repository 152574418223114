import React from "react";
import BaseComponent from '../baseComponent'
import {Row} from "simple-flexbox";
import {connect} from "react-redux";
import {dispatchAction} from "../../utility";

class ConnectToLocalServer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: ''
        }
    }



    render() {
        return (
            <Row style={{backgroundColor: '#110a4e'}} className="h-100-vh fc-white">
                <span className="margin-auto text-align-center">
                                    <h1>AppsStore.ai</h1>
                    <span className="m-t-sm display-flex">Connect to local server</span>
                </span>
            </Row>
        );
    }
}


const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(ConnectToLocalServer);
