import React from "react";
import BaseComponent from '../baseComponent';
import Webcam from "react-webcam";
import {UploadVideoService, MADService, DeviceUserMapService} from "../../services"
import {sessionManager} from "../../managers/sessionManager";
import {apiFailureConstants, cookiesConstants, pathConstants} from "../../constants";
import Utils, {dispatchAction} from "../../utility";
import FooterComponent2 from "../common/footerComponentSecond";
import {connect} from "react-redux";


class DeviceCameraComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            madDetails: {},
            userID: '',
            userAllDevices: [],
            webCamDetails: {}
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = () => {
        try {
            const imageSrc = this.webcam.getScreenshot();
            this.uploadToS3(imageSrc);
        } catch (err) {
            return false;
        }
    };

    uploadToS3 = async (base64) => {
        if (!this.state.webCamDetails || Object.keys(this.state.webCamDetails).length <= 0) {
            Utils.apiFailureToast(apiFailureConstants.DEVICE_NOT_ADDED);
            return Utils.navigateToPath(pathConstants.HOME);
        }
        let callbackIdentifier = this.state.madDetails.schedules[0].callbackIdentifier;
        let base64Image = base64.split("data:image/jpeg;base64,")[1];
        let u8Array = new Buffer.from(base64Image, 'base64');
        let fileName = this.state.webCamDetails._id + "_PHOTO_" + Date.now() + "_" + callbackIdentifier + "_000000001.jpg";
        let blob = new Blob([u8Array], {type: 'image/jpeg'});
        let file = new File([blob], fileName, {type: 'image/jpeg'});
        let formData = new FormData();
        formData.append("uploadedFile", file, fileName);
        formData.append("isSignedURL", "true");
        formData.append("path", "unprocessedQueue/" + fileName);
        await UploadVideoService.uploadImageToS3(formData);
    };


    async componentDidMount() {
        let userID = sessionManager.getDataFromCookies(cookiesConstants.USER_ID);
        // let userID = "auth0|5eb98232e8c4f20c13c47f70";
        if (!userID) {
            Utils.apiFailureToast(apiFailureConstants.NOT_LOGIN);
            return Utils.navigateToPath(pathConstants.HOME);
        }
        this.setState({userID: userID});
        await this.getUserMad(userID);
        await this.userAllDevice(userID);
        await this.isDeviceWebCamRegistered();
        if (!this.state.webCamDetails || Object.keys(this.state.webCamDetails).length <= 0) {
            await this.storeDeviceDetail();
            await this.updateMAD(userID);
        }
        localStorage.clear();
        this.refreshUserData();
    }


    updateMAD = async (userID) => {
        if (!userID || !this.state.madDetails || !this.state.madDetails._id || !this.state.madDetails.appMeta.appID)
            return false;
        let connectedDevice = [];
        let devices = {
            "deviceName": this.state.webCamDetails.entityData.deviceName,
            "deviceID": this.state.webCamDetails._id,
            "rtsp": this.state.webCamDetails.entityData.rtsp.url,
            "executingServer": this.state.webCamDetails.entityData.executingServer ? this.state.webCamDetails.entityData.executingServer : "webCam"
        };
        connectedDevice = [...this.state.madDetails.connectedDevice, devices];
        let findObj = {
            "userID": userID,
            "_id": this.state.madDetails._id,
            "appMeta.appID": this.state.madDetails.appMeta.appID
        };
        let updateObj = {
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "connectedDevice": connectedDevice
        };
        let [error, updateMadResponse] = await Utils.parseResponse(MADService.updateMAD(findObj, updateObj));
    };

    isDeviceWebCamRegistered = async () => {
        let deviceWebCamDetails = this.state.userAllDevices.find((device) => {
            return (device.entityData.executingServer === "webCam");
        });
        if (deviceWebCamDetails && Object.keys(deviceWebCamDetails).length >= 1)
            await this.setState({webCamDetails: deviceWebCamDetails});
    };


    getUserMad = async (userID) => {
        let userMADResponse = await MADService.getUserMAD(userID);
        if (!userMADResponse || userMADResponse.length <= 0 || !userMADResponse[0].frameRate) {
            Utils.apiFailureToast(apiFailureConstants.USER_APP_NOT_REGISTERED);
            return Utils.navigateToPath(pathConstants.HOME);
        }
        await this.setState({madDetails: userMADResponse[0]});
    };

    refreshUserData = async () => {
        let frameRate = Number(this.state.madDetails.frameRate);
        if (!frameRate)
            return;
        setInterval(() => {
            this.capture();
        }, frameRate * 1000);
    };

    userAllDevice = async (userID) => {
        let query = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': userID
            }),
            limit: 0, skip: 0
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.getDevicesByQueryObj(query));
        if (!error)
            this.setState({userAllDevices: userDevicesResponse});
    };

    storeDeviceDetail = async () => {
        let deviceDetails = {
            'entityMetaData': JSON.stringify({}),
            'entityType': 'connected_device',
            'entityData': JSON.stringify({
                "executingServer": 'webCam',
                "deviceID": Utils.generateRandomNumber(9),
                "IP": "",
                "deviceName": localStorage.getItem("webCam") ? localStorage.getItem("webCam") : "Device Web Cam",
                "username": "",
                "password": "",
                "rtsp": {
                    "proxy": "",
                    "url": ""
                },
                "camera-meta-data": {
                    "companyName": "",
                    "model": "",
                },
                "ffmpegFailureCount": 0,
                "userDetails": {
                    "userID": this.props.userDetails.sub,
                    "userName": this.props.userDetails.name,
                    "emailID": this.props.userDetails.email
                }
            })
        };
        let [error, deviceDetailsResponse] = await Utils.parseResponse(DeviceUserMapService.saveDeviceDetails(deviceDetails));
        if (error || !deviceDetailsResponse || !Object.keys(deviceDetailsResponse).length) {
            Utils.apiFailureToast(apiFailureConstants.DEVICE_NOT_ADDED);
            return Utils.navigateToPath(pathConstants.HOME);
        }
        await this.setState({webCamDetails: deviceDetailsResponse});
        return deviceDetailsResponse;
    };

    render() {
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "user"
        };
        return (
            <div>
                {this.state.userID ?
                    <div><Webcam
                        audio={false}
                        height={720}
                        ref={this.setRef}
                        screenshotFormat="image/jpeg"
                        width={1280}
                        screenshotQuality={0.95}
                        videoConstraints={videoConstraints}
                    />
                        <div className="margin-auto w-300 fs-16 h-200">Capturing photo using device Web-cam</div>
                    </div>
                    : ""}
                {/* <FooterComponent/> */}
                <FooterComponent2/>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(DeviceCameraComponent);

