import {httpService} from "./httpService";
import {httpConstants} from "../constants";

export default {
    saveDeviceDetails,
    getDevicesByQueryObj,
    updateCameraDetails,
    getEntityDetails,
    deleteCamera,
    getUserEntityDetails,
    deleteConnectedMachine,
    updateConnectedMachine,
    checkConnectedMachineStatus
}

async function saveDeviceDetails(data) {
    let url = process.env.REACT_APP_DEVICE_USER_MAP_SERVICE + httpConstants.API_END_POINT.ADD_CONNECTED_DEVICE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, data, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getDevicesByQueryObj(query) {
    let url = process.env.REACT_APP_DEVICE_USER_MAP_SERVICE + httpConstants.API_END_POINT.GET_ENTITY_LIST_BY_QUERY_OBJ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateCameraDetails(query) {
    let url = process.env.REACT_APP_DEVICE_USER_MAP_SERVICE + httpConstants.API_END_POINT.UPDATE_ENTITY_BY_QUERY_OBJ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function getEntityDetails(query) {
    let url = process.env.REACT_APP_MEDIA_CAPTURE_SERVICE + httpConstants.API_END_POINT.GET_ENTITY_LIST_BY_QUERY_OBJ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


export async function deleteCamera(query) {
    let url = process.env.REACT_APP_DEVICE_USER_MAP_SERVICE + httpConstants.API_END_POINT.DELETE_CAMERA + `${query._id}`;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, '', url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

export async function deleteConnectedMachine(query) {
    let url = process.env.REACT_APP_DEVICE_MEDIA_CLIENT_SERVICE + `delete-entity/${query._id}`;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

export async function updateConnectedMachine(query) {
    let url = process.env.REACT_APP_DEVICE_MEDIA_CLIENT_SERVICE + httpConstants.API_END_POINT.UPDATE_ENTITY_BY_QUERY_OBJ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


export async function getUserEntityDetails(query) {
    let url = process.env.REACT_APP_MEDIA_CAPTURE_SERVICE + httpConstants.API_END_POINT.GET_ENTITY_LIST_BY_QUERY_OBJ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

export async function publishActionQueueData(query) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.PUBLISH_MEDIA_CLIENT_DATA;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


export async function checkConnectedMachineStatus(query) {
    let url = process.env.REACT_APP_PROD_URL + httpConstants.API_END_POINT.GET_MACHINE_STATUS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, query, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}