import React from "react";
import BaseComponent from '../baseComponent'
import AppInputComponent from './appInputComponent'
import Utils, {dispatchAction} from "../../utility";
import {AppDirectoryService, MADService, VideoStreamingService} from "../../services";
import {connect} from "react-redux";
import {apiFailureConstants, apiSuccessConstants, cookiesConstants, pathConstants} from "../../constants";
import HeaderComponent from "../common/headerComponent";
import {sessionManager} from "../../managers/sessionManager";
import moment from 'moment';
import FooterComponent2 from "../common/footerComponentSecond";

class AppInput extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            MADDetail: {},
            isMadInputIFrameChanged: false,
            frameRate: [1],
            frameInterval: [30],
            appSetting: "",
            rtsp: "",
            deviceID: "",
            estimatedCredits: "",
            orderDetail: {},
            loaded: true,
            frameTimes: ["5 sec", "10 sec", "15 sec", "30 sec", "1 min", "2 min", "5 min", "10 min", "30 min", "1 hr"],
            schedules: [],
            creditPerDollar: process.env.REACT_APP_CREDIT_PER_DOLLAR,
            accessToken: '',
            loading:''
        }
    }

    async componentDidMount() {
        // console.log("AppInput --> componentDidMount");
        if (!this.props.isLoggedIn) {
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        let {madID} = this.props.match.params;
        if (!madID) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        let [error, madResponse] = await Utils.parseResponse(MADService.getMAD(madID));
        if (error || !madResponse) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            Utils.navigateToPath(pathConstants.HOME);
        } else if (madResponse) {
            if (madResponse.schedules.length >= 1) {
                let schedules = this.populateSchedules(madResponse.schedules);
                this.setState({schedules: schedules});
            }
            // else
            //     this.addschedules();
            let settings = this.getMADSetting(madResponse.appMeta.appSettings);
            let connectedDevice = madResponse.connectedDevice && madResponse.connectedDevice.length ? madResponse.connectedDevice.length : 1;
            settings = (settings === 'PHOTO' || !settings) ? "" : settings;
            let frameRate;
            if (madResponse.frameRate < 60)
                frameRate = madResponse.frameRate + " sec";
            else {
                if (madResponse.frameRate < 3600)
                    frameRate = parseInt(Number(madResponse.frameRate) / 60) + " min";
                else
                    frameRate = parseInt(Number(madResponse.frameRate) / 3600) + " hr";
            }
            this.setState({
                MADDetail: madResponse,
                frameRate: frameRate,
                estimatedCredits: this.getEstimatedCredits(madResponse, connectedDevice),
                frameInterval: [madResponse.frameInterval],
                appSetting: settings
            });
            let [error, appDetailResponse] = await Utils.parseResponse(AppDirectoryService.getAppDetails(madResponse.appMeta.appID));
            this.selectPlan(appDetailResponse);
            if (madResponse.userID && madResponse.userID.length > 0)
                this.getOrder(madResponse.userID, madResponse.appMeta.appID);
        }
        await this.createVideoStream(true);
        let accessToken = sessionManager.getDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN);
        this.setState({accessToken: accessToken});
        setTimeout(() => {
            this.setState({loaded: false})
        }, 5000);
    }


    populateSchedules = (schedules) => {
        for (let index = 0; index < schedules.length; index++) {
            schedules[index].day = String(new Date(schedules[index].startTime).getDay());
        }
        return schedules;
    };


    addschedules = () => {
        let schedulesAdded = [];
        // let start = moment((moment().startOf('isoweek')).startOf('D')).add(9, "hours").valueOf();
        // let end = moment((moment().startOf('isoweek')).startOf('D')).add(17, "hours").valueOf()
        let start = moment().startOf('day').valueOf();
        let end = moment().endOf('day').valueOf();
        for (let i = 1; i < 7; i++) {
            schedulesAdded.push({
                day: i,
                startTime: start,
                endTime: end
            });
            // start = moment((moment().startOf('isoweek')).add(i, "day")).startOf('D').add(9, "hours").valueOf();
            // end = moment((moment().startOf('isoweek')).add(i, "day")).startOf('D').add(17, "hours").valueOf();
            start = moment().startOf('day').valueOf();
            end = moment().endOf('day').valueOf();
        }
        this.setState({schedules: schedulesAdded})
    };

    changeShiftTime = (date, keyName, index) => {
        this.state.schedules[index][keyName] = date;
        this.setState({isScheduleUpdated: true})
    };

    getEstimatedCredits = (madResponse, devicesLength) => {
        let hours = this.getHoursInSchedule(madResponse);
        return hours > 0 ?
            parseInt((Number(process.env.REACT_APP_RECOGNITION_PER_MONTHS) / Number(madResponse.frameRate)) / devicesLength) :
            parseInt(((Number(process.env.REACT_APP_RECOGNITION_PER_MONTHS) / madResponse.frameRate) / 180) * hours * 4)
    };


    getHoursInSchedule = (madInfo) => {
        // console.log("madInfo", madInfo);
        let hours = 0;
        madInfo.schedules.map(schedule => {
            let millis = schedule.endTime - schedule.startTime;
            hours += millis / (1000 * 60 * 60)
        });
        return hours;
    }

    selectPlan = (appDetails) => {
        if (!appDetails || !appDetails.entityData || !appDetails.entityData.subscriptionPlans || !appDetails.entityData.subscriptionPlans.length)
            return false;
        let subscriptionPlan = {
            timeInMillis: appDetails.entityData.subscriptionPlans[0].validity,
            credits: appDetails.entityData.subscriptionPlans[0].count,
            amount: appDetails.entityData.subscriptionPlans[0].amount,
            unit: appDetails.entityData.subscriptionPlans[0].unit,
        };
        this.setState({selectedPlan: subscriptionPlan});
    };

    getOrder = async (userID, appId) => {
        if (!userID) {
            return false;
        }
        let requestBody = {
            'purchasedBy.userID': userID,
            'appDetails.appId': appId
        };

        let [error, getOrderResponse] = await Utils.parseResponse(MADService.getOrder(requestBody));
        // console.log("getOrderResponse", getOrderResponse);
        if (getOrderResponse)
            this.setState({orderDetail: getOrderResponse});
    };

    addCreditsButtonClick = async () => {
        // console.log("addCreditsButtonClick");
        // console.log("addCreditsButtonClick orderDetails", this.state.orderDetail);
        this.openRazorPayPopup();
    };

    openRazorPayPopup = () => {
        let _this = this;
        let cost = this.state.MADDetail.appMeta.amount;
        let options = {
            key: process.env.REACT_APP_RAZOR_PAY_ID,
            amount: cost * 100,
            name: 'Make Payment',
            image: '/images/logo.png',
            description: '',
            async handler(response) {
                await _this.updateOrder(response)
            },
            notes: {
                order_id: this.state.orderDetail.orderDetails.id
            },
            theme: {
                color: '#4c84ff',
            }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    updateOrder = async (paymentRes) => {
        let requestBody = {
            orderId: this.state.orderDetail.orderDetails.id,
            paymentId: paymentRes.razorpay_payment_id,
            subscriptionPlan: 'MONTHLY'
        };
        let [error, updateOrderResponse] = await Utils.parseResponse(MADService.updateOrder(requestBody));
        if (!error)
            this.updateMadInternalUsage(updateOrderResponse);
    };

    updateMadInternalUsage = async (updateOrderResponse) => {
        let requestData = {
            userID: updateOrderResponse.purchasedBy.userID,
            appMeta: {
                appID: updateOrderResponse.appDetails.appId
            }
        };
        requestData.internalUsage = this.getCredits();
        requestData.modifiedOn = Date.now();
        let [error, addMADResponse] = await Utils.parseResponse(MADService.updateMADInternalUsage(requestData));
        if (error || !addMADResponse) {
            return Utils.apiFailureToast(apiFailureConstants.ADD_MAD);
        } else if (addMADResponse) {
            Utils.navigateToPath(pathConstants.APP_INPUT + addMADResponse._id);
        }
    };

    getCredits = () => {
        let credits = {
            FD: {
                unit: this.state.selectedPlan.unit || 'face',
                endTime: Date.now() + this.state.selectedPlan.timeInMillis,
                allocatedCredits: this.state.selectedPlan.credits,
                remainingCredits: this.state.selectedPlan.credits
            }
        };
        return credits;
    };

    getMADSetting = (appSettings) => {
        if (!appSettings || !appSettings.length)
            return false;
        let settings;
        for (let index = 0; index < appSettings.length; index++) {
            if (appSettings[index].isEnable >= 1) {
                settings = appSettings[index].type;
                break;
            }
        }
        return settings;
    };

    createVideoStream = async (isStream) => {
        let url = (this.state.MADDetail.connectedDevice && this.state.MADDetail.connectedDevice[0] && this.state.MADDetail.connectedDevice[0].rtsp && this.state.MADDetail.connectedDevice[0].rtsp.length > 0) ? this.state.MADDetail.connectedDevice[0].rtsp : "";
        let deviceID = (this.state.MADDetail.connectedDevice && this.state.MADDetail.connectedDevice[0] && this.state.MADDetail.connectedDevice[0].deviceID && this.state.MADDetail.connectedDevice[0].deviceID.length > 0) ? this.state.MADDetail.connectedDevice[0].deviceID : 12345;
        this.setState({rtsp: url, deviceID: deviceID});
        let rtsp = {
            "rtsp": {
                "uri": url,
                "resolution": "1080*1200",
                "rate": 0.001,
                "quality": 10
            },
            "isStream": isStream,
            "id": deviceID
        };

        let [error, videoStreamResponse] = await Utils.parseResponse(VideoStreamingService.createVideoStream(rtsp));
        if (error || !videoStreamResponse) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            return false;
        }
        return videoStreamResponse;
    };

    async componentWillUnmount() {
        await this.createVideoStream(false);
    }

    async onIFrameLoad(madID, _this) {
        // console.log("onIFrameLoad", madID)
        // if (_this.state.isMadInputIFrameChanged) {
        //     await _this.setState({isMadInputIFrameChanged: false});
        //     Utils.navigateToPath(pathConstants.MY_APPS + madID);
        // }
        // await _this.setState({isMadInputIFrameChanged: true});
    };

    onChangeFrameRate = (frameRate) => {
        let connectedDevice = this.state.MADDetail.connectedDevice && this.state.MADDetail.connectedDevice.length ? this.state.MADDetail.connectedDevice.length : 1;
        let rate = parseInt(frameRate.split(" ")[0]);
        if (frameRate.includes("hr"))
            rate = rate * 3600;
        else if (frameRate.includes("min"))
            rate = rate * 60;
        this.setState({frameRate});
        let estimated = parseInt((Number(process.env.REACT_APP_RECOGNITION_PER_MONTHS) / Number(rate)) / connectedDevice);
        this.setState({estimatedCredits: estimated});
    };

    onChangeFrameInterval = frameInterval => {
        this.setState({frameInterval})
    };


    onUpdateMADFrames = async () => {
        this.setState({
            loading: true
        })
        let frameInterval = this.state.frameInterval[0];
        let rate = parseInt(this.state.frameRate.split(" ")[0]);
        if (this.state.frameRate.includes("hr"))
            rate = rate * 3600;
        else if (this.state.frameRate.includes("min"))
            rate = rate * 60;

        if (!this.state.appSetting || this.state.appSetting === "PHOTO") {
            frameInterval = rate;
        }
        let requestObj = {
            madID: this.state.MADDetail._id,
            frameRate: rate,
            frameInterval: rate,
            isRecurring: true
        };
        // console.log(requestObj)
        let [error, madResponse] = await Utils.parseResponse(MADService.updateMadFrames(requestObj));
        if (error || !madResponse)
            Utils.apiFailureToast(apiFailureConstants.UPDATE_FRAME_RATE);
        else
            Utils.apiSuccessToast(apiSuccessConstants.UPDATE_FRAME_RATE);
        // Utils.navigateToPath(pathConstants.MY_APPS);
        this.setState({
            loading: false
        })

    };

    navigateToDashboard = () => {
        Utils.navigateToPath(pathConstants.MY_APPS);
    };

    createPayment = async (amount, month) => {
        this.setState({
            loading: true
        })
        let reqObj = {
            "name": this.props.userDetails.name,
            "amount": amount,
            "currency": "INR",
            "credits": this.state.estimatedCredits
        };       
        let response = await MADService.createPaymentLink(reqObj);
        if (!response || !response.short_url)
            return Utils.apiFailureToast(apiFailureConstants.PAYMENT_NOT_DONE);
        this.updatePaymentDetails(response, month);
        this.setState({
            loading: false
        })
        window.open(response.short_url);
        await this.onUpdateMADFrames();
    };

    updatePaymentDetails = async (paymentDetails, month) => {
        let requestObj = {
            findObj: {
                purchasedBy: {
                    userID: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : process.env.REACT_APP_USER_ID
                },
                appDetails: {
                    appId: this.state.MADDetail.appMeta.appID
                }
            },
            updateObj: {
                isPaymentLink: true,
                invoiceId: paymentDetails.id,
                orderDetails: {
                    id: paymentDetails.order_id,
                    amount: Number(paymentDetails.amount) / 100,
                    created_at: paymentDetails.issued_at
                },
                credits: this.state.estimatedCredits * month
            }
        };
        let res = await Utils.parseResponse(MADService.updatePayment(requestObj));
        // console.log("updatePaymentDetails res", res);

    };

    removeScheduleFields = (index) => {
        if (index < 0)
            return false
        this.state.schedules.splice(index, 1);
        this.setState({"dummy": "dummy"})
    }

    addScheduleFields = () => {
        this.state.schedules.push({
            day: '1',
            startTime: new Date(new Date().setHours(9, 0, 0, 0)).getTime(),
            endTime: new Date(new Date().setHours(17, 0, 0, 0)).getTime()
        })
        this.setState({"dummy": "dummy"})
    }

    saveSchedules = async () => {
        this.setState({
            loading: true
        })
        let mad = this.state.MADDetail;
        let requestObj = {
            "deviceID": mad.connectedDevice[0].deviceID,
            "madID": mad._id,
            'rtspUrl': mad.connectedDevice[0].rtsp,
            'appSettings': mad.appMeta.appSettings,
            'frameRate': mad.frameRate,
            'frameInterval': mad.frameInterval,
            'segmentTime': mad.appMeta.segmentTime,
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "schedules": this.parseSchedules(),
            "isRecurring": true
        };
        try {
            await Utils.parseResponse(MADService.addMadSchedules(requestObj));
            Utils.apiSuccessToast("Schedules updated successfully")
        } catch (err) {
            // console.log(err)
        }
        this.setState({
            loading: false
        })
    };

    parseSchedules = (schedules) => {
        schedules = this.state.schedules.map(schedule => {
            let startTime = new Date(schedule.startTime);
            let endTime = new Date(schedule.endTime);
            startTime.setDate(startTime.getDate() + ((7 - startTime.getDay()) % 7 + Number(schedule.day)) % 7);
            endTime.setDate(endTime.getDate() + ((7 - endTime.getDay()) % 7 + Number(schedule.day)) % 7);
            let callbackIdentifier = String(new Date(startTime).getTime()) + "-" + String(new Date(endTime).getTime());
            return {
                startTime: new Date(startTime).getTime(),
                endTime: new Date(endTime).getTime(),
                type: 'WEEKLY',
                callbackIdentifier
            }
        });
        return schedules
    };

    render() {
        let {madID} = this.props.match.params;
        if (!madID) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        // console.log("AppInput --> render", this.state);
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
              <HeaderComponent />
            </div>
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
            <AppInputComponent
              MADDetail={this.state.MADDetail}
              madID={madID}
              state={this.state}
              _this={this}
              frameRate={this.state.frameRate}
              frameInterval={this.state.frameInterval}
              onChangeFrameRate={this.onChangeFrameRate}
              onChangeFrameInterval={this.onChangeFrameInterval}
              onIFrameLoad={this.onIFrameLoad}
              onUpdateMADFrames={this.onUpdateMADFrames}
              videoStreamUrl={"https://www.appsstore.ai:3003/"}
              myAppsPage={this.myAppsPage}
              appSetting={this.state.appSetting}
              rtsp={this.state.rtsp}
              deviceID={this.state.deviceID}
              estimatedCredits={this.state.estimatedCredits}
              addCreditsButtonClick={this.addCreditsButtonClick}
              frameTimes={this.state.frameTimes}
              navigateToDashboard={this.navigateToDashboard}
              createPayment={this.createPayment}
              schedules={this.state.schedules}
              removeScheduleFields={this.removeScheduleFields}
              changeShiftTime={this.changeShiftTime}
              addScheduleFields={this.addScheduleFields}
              saveSchedules={this.saveSchedules}
              accessToken={this.state.accessToken}
            />
            </div>
            {/* <FooterComponent /> */}
            <FooterComponent2 />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(AppInput);
