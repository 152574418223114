import React from "react";

const images = [
  {
    name: "Suspicious Behaviour",
    image: "/images/suspicious-behaviour-image.png",
  },
  {
    name: "Theft Detection",
    image: "/images/theft-detection-image.png",
  },
  {
    name: "Send Alerts",
    image: "/images/send-alerts-image.png",
  },
  {
    name: "Action Taken",
    image: "/images/action-taken-image.png",
  },
];

const PreventTheft = () => {
  return (
    <div className="home-container bg-lightBlue-10  m-t-24 flex-direction-column display-flex px-120 p-t-32 pb-36 md-px-24 lg-bg-white">
      <p className="font-lato text-ft28-140 fc-dark-slate-blue font-semibold text-align-center md-text-ft24-30">
        How it prevents thefts
      </p>
      <div className="m-t-32  display-flex justify-between align-items-center-m">
        {images.map(({ image, name }, idx) => (
          <>
            <div key={idx} className="grid-place-items-center">
              <img className="w-200 h-200 br-20" src={image} alt="" />
              <div className="m-t-16 fs-18-150-400">{name}</div>
            </div>
            {images.length !== idx + 1 && (
              <img src="/images/long-arrow.svg" alt="" />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default PreventTheft;
