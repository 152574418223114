import React from "react";

const Form = () => {
  return (
    <form className="width-100-per md-bg-lightBlue-10 md-px-24 md-pt-24 md-pb-36">
      <div className="flex-direction-column display-flex ">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <div className="display-flex gap-24">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="First Name"
            className="p-l-15 br-1-grey br-16 h-48 outline-none-focus width-100-per"
            required
          />
          <input
            type="text"
            name="name"
            placeholder="Last Name"
            className="p-l-15 br-1-grey br-16 h-48 outline-none-focus width-100-per"
            required
          />
        </div>
      </div>
      <div className="flex-direction-column display-flex m-t-30">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="username@example.com"
          className="p-l-15 br-1-grey br-16 h-48 outline-none-focus width-100-per"
          required
        />
      </div>
      <div className="flex-direction-column display-flex m-t-30">
        <label htmlFor="company-name" className="form-label">
          Company Name
        </label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          placeholder="Your company name"
          className="p-l-15 br-1-grey br-16 h-48 outline-none-focus width-100-per"
          required
        />
      </div>
      <div className="m-t-40">
        <button className="blue-styled-btn br-16 width-100-per">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Form;
