import React, { useState } from "react";
import { genericConstants } from "../../constants";
import Utils from "../../utility";

export const Benefits = ({ appData }) => {
  let mimeType;
  let checkVideo = appData.media.map(
    (mediaObj) =>
      Utils.getMimeType(mediaObj.mimeType) === genericConstants.MIME_TYPE.VIDEO
  )[0];

  return (
    <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
    <section className="flex lg-flex-col m-t-78 gap-26">
      <div className="flex-1 flex-basis-50 xxl-flex-basis-36">
        <div className="">
          {checkVideo
            ? appData.media.map((mediaObj, index) => {
                mimeType = Utils.getMimeType(mediaObj.mimeType);

                return (
                  mimeType === genericConstants.MIME_TYPE.VIDEO && (
                    <video
                      autoPlay
                      loop
                      className="d-block w-100-per  br-20 min-h-494"
                    >
                      <source src={mediaObj.url} type="video/mp4" />
                    </video>
                  )
                );
              })
            : appData.media.slice(0, 1).map((mediaObj, index) => {
                mimeType = Utils.getMimeType(mediaObj.mimeType);
                return (
                  mimeType === genericConstants.MIME_TYPE.IMAGE && (
                    <div
                      className="main-div shadow-white br-30 br-136-4ff"
                      key={index}
                    >
                      <img
                        className="d-block w-100-per br-20 min-h-494 base-div"
                        key={"carousel-item" + index}
                        src={mediaObj.url}
                        alt={"Media-" + index}
                      />
                    </div>
                  )
                );
              })}
        </div>
      </div>
      <div className="flex flex-1  flex-column  gap-14 justify-center flex-basis-50">
        <div className="full-width fs-36-43-600 md-fs-21-140per-700 fc-dark-slate-blue">
          Benefits
        </div>
        <div className="full-width display-grid gap-24 m-t-20">
          {appData.benefits?.map(({ value, img }, index) => {
            return (
              <div className="flex align-items-center gap-18" key={index}>
                <img src={img} loading="lazy" alt="" />
                <div className="fs-21-140-400 md-fs-16-24-500 fc-black">
                  {value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
    </div>
  );
};

export const TestimonialSection = ({ appData }) => {
  const [state, setState] = useState({
    index: 0,
    data: appData?.testimonial[0],
  });

  function handleNext() {
    const newIndex = (state.index + 1) % appData.testimonial.length;
    setState({
      ...state,
      index: newIndex,
      data: appData?.testimonial[newIndex],
    });
  }
  return (
  <div className="px-120 md-px-0 base-container xxl-px-120 xl-px-70">
    <section className="m-t-64">
      <div className="fs-36-43-600 md-fs-21-140per-700 fc-dark-slate-blue md-px-24">Testimonials</div>
      <div className="flex h-551 m-t-32 lg-flex-col md-grid">
        <div className="flex-1 full-width">
          <div className="w-46-per lg-w-full lg-max-w-none bg-theme h-100-per lg-h-193 md-h-68 position-relative  max-w-249 flex align-items-center">
            <div className="testimonial-dots">
              <div className="display-grid gap-10 m-l-15"> 
              {appData.testimonial.map((ele,idx)=>(
                <div key={idx} className={` w-8 bg-white   ${idx === state.index ? "h-28 br-4 md-bg-lightBlue-20" :"h-8 br-50-per md-bg-lightBlue-30  "} `} />
              ))}
              </div>
            </div>
            <div className="position-absolute left-32-per top-72 lg-top-0 md-top-15 md-left-24">
              <div className="flex">
                <img
                  src={state.data?.pic}
                  loading="lazy"
                  className="lg-w-250 lg-h-250 md-w-90 md-h-90 "
                  alt=""
                ></img>
                <div className="m-l-16">
                  <div className="fs-32-75-700-lato md-fs-28-74-700 fc-dark-slate-blue display-none md-block">
                    {state.data?.name}
                  </div>
                  <div className="fs-24-29-500-lato md-fs-12-14-500 font-color-264780 display-none md-block ">
                    {state.data?.details}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 full-width  h-100-per flex justify-content-between md-px-24">
          <div className="flex flex-column justify-content-center m-b-122  gap-36 max-w-512 md-grid">
            <div className="fs-32-75-700-lato fc-dark-slate-blue md-hide">
              {state.data?.name}
            </div>
            <div className="fs-24-29-500-lato font-color-264780 md-hide">
              {state.data?.details}
            </div>
            <div className="fs-20-30-500  font-color-light-grey-black">
              {state.data?.description}
            </div>
          </div>
          {appData.testimonial.length > 1 && (
            <div className="margin-auto cursor-pointer ">
              <img
                src="/images/next-blue.svg"
                alt=""
                className=""
                onClick={handleNext}
              />
            </div>
          )}
        </div>
      </div>
    </section>
    </div>
  );
};
