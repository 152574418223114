import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { PopupButton } from "react-calendly";
import { Column, Row } from "simple-flexbox";
import HowItsWork from "./howItWorks";
import { IoIosArrowDown } from "react-icons/io";
import Faqs from "./faqs";
import { StartFreeTrial } from "./smallSection";

const LoginForm = (props) => {
  let { inputFieldActive } = props.uiState && props.uiState;
  return (
    <>
      <form className=" pt-5-desktop w-100 flex-grow-1 position-relative">
        <img
          src="/images/small-dot.svg"
          className="position-absolute small-dot"
          alt=""
        ></img>
        <br />
        <div className=" p-l-0-m  m-t-sm">
          <Row className="lg-justify-center">
            <Typography className="max-w-588 fs-48 lg-fs-24-29-700 md-fs-24-36-700 t-a-c-m l-h-1 fw-bold m-b-5 fc-dark-slate-blue">
              Make any Camera feed smarter with{" "}
              <span className="fc-4c84ff">AI</span>
            </Typography>
          </Row>

          <Row className="mt-3">
            <Typography className="justify-content-center l-h-1 w-100 fs-18 fc-blackish md-fs-16-24-600 m-auto-m md-max-w-305 lg-text-center">
              A Marketplace of computer vision apps to enable AI skills on any
              camera within minutes.
            </Typography>
          </Row>

          <div className="display-grid md-grid-cols-2 m-t-20 m-b-49 lg-place-items-center md-gap-y-27 md-gap-x-24 ">
            <div className="fs-24-29-600 fc-4c84ff p-b md-pb-0 flex md-flex-col align-items-center t-a-c gap-16  fs-12-14-500-m">
              <img src="/images/cctv.svg" alt="" className="md-w-48 md-h-48" />
              {/* <span className="dot "></span>           */}
              <div className="md-fs-16-19-500 fs-24-29-600 ">
                Easily Connect any Internet Camera
              </div>
            </div>
            <div className="fs-24-29-600 fc-4c84ff color-dark-blue p-b md-pb-0 flex md-flex-col align-items-center t-a-c  gap-16  fs-12-14-500-m">
              {/* <span className="dot"></span> */}
              <img
                src="/images/no-code.svg"
                className="md-w-48 md-h-48"
                alt=""
              />
              <div className="md-fs-16-19-500 fs-24-29-600 ">
                No-Code 2 minutes set up
              </div>
            </div>
            <div className="fs-24-29-600 fc-4c84ff color-dark-blue p-b md-pb-0 flex md-flex-col align-items-center t-a-c gap-16  fs-12-14-500-m">
              {/* <span className="dot"></span> */}
              <img src="/images/apps.svg" className="md-w-48 md-h-48" alt="" />
              <div className="md-fs-16-19-500  fs-24-29-600 ">
                Choose from 100+ Apps
              </div>
            </div>
            <div className="fc-4c84ff color-dark-blue p-b md-pb-0 flex md-flex-col align-items-center t-a-c gap-16  fs-12-14-500-m">
              {/* <span className="dot"></span> */}
              <img
                src="/images/artificial-intelligence.svg"
                className="md-w-48 md-h-48"
                alt=""
              />
              <div className="md-fs-16-19-500 fs-24-29-600  ">
                Highly Trained and Scalable AI Models
              </div>
            </div>
          </div>
          <div
            className={`${
              props.blankField !== "bg-red" ? "display-none" : "display-block"
            } fs-18 mb-2  font-color-red`}
          >
            ( Enter your camera url here )
          </div>
          <Row className="flex-wrap-m m-b-15 lg-max-w-588 lg-mx-auto">
            <div
              className={`flex br-25  align-items-center h-42 h-38-m ${
                inputFieldActive ? "w-480  br-1-lightGrey " : "w-200"
              } w-100-per-m`}
            >
              <input
                id="IP"
                type="text"
                placeholder="Enter your rtsp camera url here"
                className={`fs-16-19-500 input-autofill-camera fs-12-14-500-m outline-none border-0 w-407  m-l-10 w-100-per h-81-per ${
                  inputFieldActive ? "display-flex" : "display-none"
                }`}
                onChange={props.onChangeEvent}
                value={props.IP}
                required
              />
              <Button
                onClick={props.onGetStartedClicked}
                className={`h-inherit fs-16-19-500 fs-12-14-500-m blue-button-1 ${
                  props.blankField
                } m-t-0-m fc-white outline-none ${
                  inputFieldActive ? "w-450" : "lg-w-full"
                }  text-capitalize m-t-sm-m border-0 br-25`}
              >
                Connect Internet Camera
              </Button>
            </div>
            <PopupButton
              className="fs-16-19-500 fs-12-14-500-m bg-black fc-white outline-none w-288 h-42 text-capitalize m-t-sm-m  ml-2 m-l-0-m  br-25 border-0 lg-w-full talk-ai-expert-button"
              url={process.env.REACT_APP_CALENDLY}
              rootElement={document.getElementById("root")}
              text="Book a Free 30-mins consultation"
            />
          </Row>
        </div>
      </form>
    </>
  );
};

function upcomingAppsCell(props, appObject, index) {
  let appIcon = appObject.entityData.appImage;
  return (
    <div
      key={"ai-apps-" + index}
      className="w-300 cursor-pointer  mb-5 m-r-15-m feature-card"
      onClick={() =>
        props.onUpcomingAppsClicked(appObject._id, false, appObject)
      }
    >
      <img className="w-100 max-h-169 h-169 " src={appIcon} alt="meeting" />
      <Row className="mt-3">
        <div className="p-2 logo-container-home">
          <img
            className="w-75-px"
            src={appObject.entityData.logo}
            alt="meeting"
          />
        </div>
        <div className="m-l-20">
          <div className="fc-black fs-20">{appObject.entityData.appName}</div>
          <div className="fc-warm-grey fs-14">
            {appObject.entityData.description}
          </div>
        </div>
      </Row>
    </div>
  );
}

const MobileCurrentApps = (props) => {
  const [countApps, setAppsCount] = useState(7);
  return (
    <div className="display-none lg-block">
      <div className="container-current-apps mt-5 column-gap-15 md-place-items-center">
        {props.upcomingApps.slice(0, countApps).map((appObject, index) => {
          return !appObject.isActive
            ? upcomingAppsCell(props, appObject, index)
            : "";
        })}
      </div>
      {countApps != props?.upcomingApps?.length && (
        <div
          className="display-flex justify-center gap-10 align-item-center cursor-pointer"
          onClick={() => {
            setAppsCount(props?.upcomingApps?.length);
          }}
        >
         <span className="fs-22 fc-4c84ff font-bold">More Apps</span>
          <IoIosArrowDown className="fc-4c84ff" />
        </div>
      )}
    </div>
  );
};

const HomePage = (props) => {
  const [upcomingAppDisplayCount, setUpcomingAppDisplayCount] = useState(13);

  return (
    <div className="h-100-per">
      <div className=" ">
        <Row className="base-container md-px-24 px-120 lg-flex-col-reverse w-100 m-t-40-desktop m-t-40 m-b-90 justify-content-between  flex-wrap-home xxl-px-120 xl-px-70">
          {LoginForm(props)}
          <div className="margin-auto-m position-relative">
            <img
              className="position-absolute right-0 top-7"
              src="/images/medium-dot.svg"
              alt="home-component"
            />
            <img
              className="home-img-h-w"
              src="/images/home-page-image.png"
              alt="home-component"
            />
          </div>
        </Row>

        <div className="bg-light-blue-50 position-relative height-auto m-t-94 ">
          <img
            src="/images/medium-dot.svg"
            alt=""
            className="position-absolute right-side-dot display-mobile-none-m"
          />
          <Row className="justify-content-center fs-20-72-700 text-uppercase color-dark-blue display-none display-mobile-flex-m ">
            Featured Apps
          </Row>
          <Row className=" featured-apps-container gap-24 w-100-per flex-wrap justify-center p-t-80 md-p-t-40 ">
            <Row
              id="featured-apps"
              className="display-mobile-none-m shadow-white w-331 h-442 all-items-center br-30 br-1-4b2 bg-white fs-48-72-700 color-dark-blue t-a-l p-l-42 p-r-42 "
            >
              FEATURED APPS
            </Row>
            {props.upcomingApps && props.upcomingApps.length
              ? props.upcomingApps.map((appObject, index) => {
                  return appObject.isActive ? (
                    <Row
                      key={"ai-apps-" + index}
                      className="w-331 h-442 md-p-t-8 md-p-b-16 md-h-auto md-w-144 md-h-216 p-x-16 md-px-8  br-30 md-br-20 md-border-none shadow-white br-1-4b2 bg-white color-dark-blue all-items-center  "
                    >
                      <Column className="h-375 md-h-auto align-items-center md-max-w-128">
                        <img
                          src={appObject?.entityData.appImage}
                          className="br-30 w-288 h-194 md-w-128 md-h-94 "
                          alt=""
                        />
                        <div className="fs-24-29-700 fc-black m-t-16 md-fs-12-14-600 t-a-c md-m-t-10 md-max-w-105">
                          {appObject.entityData.appName}
                        </div>
                        <div className="fs-16-19-400 m-t-8 flex-grow-1 md-fs-10-12-400 t-a-c md-m-t-6 md-max-w-105">
                          {appObject.entityData.description}
                        </div>

                        {props.activeAppPurchased.map((activeApps, key) => {
                          return activeApps.appID === appObject._id ? (
                            !activeApps.isAppPurchased &&
                            !activeApps.isFreeTrialActive &&
                            !activeApps.isFreeTrialExpired ? (
                              <Button
                                key={key}
                                onClick={() =>
                                  props.onUpcomingAppsClicked(
                                    appObject._id,
                                    false,
                                    appObject
                                  )
                                }
                                className="br-25 w-277 h-53 fs-20-24-500 md-m-t-14  md-w-101 md-h-auto md-min-h-30 md-fs-12-14-500"
                              >
                                Try Now
                              </Button>
                            ) : (
                              <Button
                                className="br-25 w-277 h-53 fs-20-24-500 md-m-t-14  md-w-101 md-h-auto md-min-h-30 md-fs-12-14-500"
                                onClick={() =>
                                  props.onUpcomingAppsClicked(
                                    appObject._id,
                                    true,
                                    appObject
                                  )
                                }
                              >
                                Open Dashboard
                              </Button>
                            )
                          ) : (
                            ""
                          );
                        })}
                      </Column>
                    </Row>
                  ) : (
                    ""
                  );
                })
              : ""}
          </Row>
        </div>

        <HowItsWork />

        <StartFreeTrial onStarted={props.onStarted} />

        {props.upcomingApps && props.upcomingApps.length ? (
          <div className="m-t-20-m mb-4  m-t-100 xxl-px-120 xl-px-70 px-120 md-px-24 base-container">
            <Row className="w-100 m-t-15 justify-content-center-m m-b-5">
              <span className="fs-40-32-500 fs-20-72-700-m white-space-no-wrap mr-3">
                CURRENT{" "}
                <span className="fs-40-32-500 fs-20-72-700-m">APPS</span>
              </span>
              <hr
                className="horizontal-work w-100-per rounded display-none-m"
                color="#b6b6b6"
              />
            </Row>

            {/* <Row className="flex-wrap mt-5 justify-content-between jc-center-m justify-between-last-child"> */}
            <div className="container-current-apps mt-5 column-gap-15 md-place-items-center lg-hidden display-block">
                {props.upcomingApps
                  .slice(0, upcomingAppDisplayCount)
                  .map((appObject, index) => {
                    return !appObject.isActive
                      ? upcomingAppsCell(props, appObject, index)
                      : "";
                  })}
            </div>
            <MobileCurrentApps {...props} />
            {upcomingAppDisplayCount !== props?.upcomingApps?.length && (
              <div
                className="display-flex justify-center gap-10 align-item-center cursor-pointer lg-hidden display-block"
                onClick={() => {
                  setUpcomingAppDisplayCount(props?.upcomingApps?.length);
                }}
              >
                <span className="fs-22 fc-4c84ff font-bold">More Apps</span>
                <IoIosArrowDown className="fc-4c84ff" />
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        <Faqs />
      </div>
    </div>
  );
};

export default HomePage;
