import React, { useState } from "react";
import Utils from "../../utility";

const TrainSystem = ({ onTrainModel }) => {
  const [image, setImage] = useState({});
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTrain = async () => {
    if (!image.name) return Utils.apiFailureToast("Image is required!");
    if (!name.trim()) return Utils.apiFailureToast("Name is required!");
    try {
      setLoading(true);
      const response = await onTrainModel(image, name);
      setLoading(false);
      setName("");
      setImage({});
      if (response) Utils.apiSuccessToast(response);
    } catch (error) {
      setLoading(false);
      Utils.apiFailureToast(
        "An error occured while training model. Please try again"
      );
    }
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    const name = file.name;
    const ext = name.split(".").pop().toLowerCase();
    const validExtensions = ["jpg", "png", "jpeg"];
    if (validExtensions.includes(ext)) setImage(file);
    else
      Utils.apiFailureToast(
        "File format is not supported, try .jpg, .png, .jpeg instead."
      );
  };

  return (
    <div className="px-120">
      <div className="m-t-80 min-h-300 b-1-lightBlue-100 br-24 display-flex align-item-center justify-between p-l-75 p-r-20">
        <div className="">
          <div className="text-ft40-56 font-lato font-medium fc-dark-slate-blue">
            Train the system
          </div>
          <div className="max-w-400 font-regular font-lato text-ft18-22 fc-dark-grey m-t-8">
            Please upload an image of the face you want to train the modal
          </div>
        </div>
        <div className="w-444">
          <div>
            <input
              type="file"
              className="display-none"
              accept="image/*"
              id="train_image"
              onChange={onImageChange}
            />
            <label
              htmlFor="train_image"
              className="w-100-per b-1-lightBlue-100 br-24 h-60 display-flex align-item-center justify-center font-lato font-medium fc-dark-slate-blue gap-10 cursor-pointer"
            >
              {!!image?.name ? (
                image.name
              ) : (
                <>
                  <img src="/images/upload-icon.svg" alt="" />
                  Upload Image
                </>
              )}
            </label>
          </div>
          <div className="m-t-20">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name of the user"
              className="w-100-per b-1-lightBlue-100 br-24 h-48 text-align-center outline-none display-flex align-item-center justify-center font-lato font-medium text-ft18-22"
            />
          </div>
          <div className="m-t-30">
            <button
              onClick={handleTrain}
              disabled={loading}
              className="w-100-per grid-place-items-center br-24 h-42 bg-light-blue-20 br-1-blue cursor-pointer fc-white font-lato font-medium fs-16"
            >
              {loading ? (
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Train AI Model"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainSystem;
