import { useEffect, useState } from "react"

export const useScrollPosition =()=>{
    const [scrollPosition,setScrollPosition] =useState(0)
    useEffect(()=>{
        const updatePosition =()=>{
            setScrollPosition(window.pageYOffset)
        }
        window.addEventListener("scroll",updatePosition)
        updatePosition()
        return ()=> window.removeEventListener("scroll",updatePosition)
    },[])
    return scrollPosition
}

export const useWideScreen = (value) => {
    const [isWideScreen, setIsWideScreen] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        setIsWideScreen(window.innerWidth >= value);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return isWideScreen;
  };