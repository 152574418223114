import React from "react";
import BaseComponent from '../baseComponent'
import Utils, {dispatchAction} from "../../utility";
import {connect} from "react-redux";
import {apiFailureConstants, cookiesConstants, pathConstants} from "../../constants";
import MarketPlaceComponent from "../marketPlace/marketPlaceComponent";
import {sessionManager} from '../../managers/sessionManager'
import {AppDirectoryService, VideoStreamingService} from "../../services";
import HeaderComponent from "../common/headerComponent";
import FooterComponent2 from "../common/footerComponentSecond";


class MarketPlace extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            videoStreamURL: "",
            popularApps: [],
            userDetails: "",
            deviceID: "",
            deviceRTSP: "",
            loaded: true
        }
    }

    async componentDidMount() {
        let deviceRTSP = sessionManager.getDataFromCookies(cookiesConstants.DEVICE_RTSP);
        let deviceID = sessionManager.getDataFromCookies(cookiesConstants.DEVICE_ID);
        if (!deviceRTSP || !deviceID) {
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        // console.log("componentDidMount -- > deviceID-deviceRTSP : ", deviceID, deviceRTSP);

        let videoStreamURL = "https://www.appsstore.ai:3003/" + deviceID;
        await this.setState({deviceID: deviceID, deviceRTSP: deviceRTSP, videoStreamURL: videoStreamURL});

        let [error, upcomingAppResponse] = await Utils.parseResponse(AppDirectoryService.getUpcomingApps());
        if (error)
            Utils.apiFailureToast(apiFailureConstants.GET_UPCOMING_APPS);
        else if (upcomingAppResponse) {
            this.setState({popularApps: upcomingAppResponse.responseData.entityList})
        }
        await this.createVideoStream(true);
        setTimeout(() => {
            this.setState({loaded: false})
        }, 10000);
    }

    async componentWillUnmount() {
        await this.createVideoStream(false);
    }

    createVideoStream = async (isStream) => {
        let {deviceID, deviceRTSP} = this.state;
        if (!deviceRTSP || !deviceID) {
            return;
        }
        // console.log("createVideoStream -- > deviceID-deviceRTSP : ", deviceID, deviceRTSP);

        let rtsp = {
            "rtsp": {
                "uri": deviceRTSP,
                "resolution": "1080*1200",
                "rate": 0.001,
                "quality": 10
            },
            "isStream": isStream,
            "id": deviceID
        };

        let [error, videoStreamResponse] = await Utils.parseResponse(VideoStreamingService.createVideoStream(rtsp));
        if (error || !videoStreamResponse) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            return false;
        }
        return videoStreamResponse;
    };


    onPopularAppsClicked = (app) => {
        // Utils.navigateToPath(pathConstants.APP_DETAILS + app.entityData.appRoute);
        Utils.navigateToPath(pathConstants.APP_DETAILS + app._id);
        
    };

    render() {
        return (
          <div className="home-container">
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
              <HeaderComponent loginRedirectEndPoint="market-place" />
            </div>
            <div className="base-container px-120 md-px-24 xxl-px-120 xl-px-70">
              <MarketPlaceComponent
                state={this.state}
                videoStreamURL={this.state.videoStreamURL}
                popularApps={this.state.popularApps}
                onPopularAppsClicked={this.onPopularAppsClicked}
                userDetails={this.state.userDetails}
              />
            </div>
            <FooterComponent2 />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(MarketPlace);
