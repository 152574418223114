import React from "react";
import BaseComponent from '../baseComponent'
import CameraSignUpComponent from './cameraSignUpComponent'
import Utils, {dispatchAction} from "../../utility";
import {connect} from "react-redux";
import {
    cookiesConstants,
    pathConstants,
    apiFailureConstants,
    actionsConst
} from "../../constants";
import {sessionManager} from '../../managers/sessionManager'
import {DeviceUserMapService, MADService} from "../../services";
import AuthService from '../../services/auth0Service'
import isIpPrivate from 'private-ip';
import {publishActionQueueData} from "../../services/deviceUserMapService";
import FooterComponent2 from "../common/footerComponentSecond";


class CameraSignUp extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            IP: props.user.ip || localStorage.getItem("IP") || (props.user.cameraDetails && Object.keys(props.user.cameraDetails).length > 0 ? props.user.cameraDetails.entityData.IP : ""),
            deviceName: (props.user.cameraDetails && Object.keys(props.user.cameraDetails).length > 0 ? props.user.cameraDetails.entityData.deviceName : "") || '',
            username: (props.user.cameraDetails && Object.keys(props.user.cameraDetails).length > 0 ? props.user.cameraDetails.entityData.username : "") || '',
            password: (props.user.cameraDetails && Object.keys(props.user.cameraDetails).length > 0 ? props.user.cameraDetails.entityData.password : "") || '',
            rtspUrl: '',
            userAllDevices: [],
            cameraIp: null,
            executingServer: props.user.cameraDetails && Object.keys(props.user.cameraDetails).length > 0 && props.user.cameraDetails.entityData.executingServer && props.user.cameraDetails.entityData.executingServer !== 'cloud',
            connecting: false,
            image: '',
            deviceDetails: {},
            disabled: true,
            deviceDetailsResponse: {},
            text: 'Connect',
            firstTime: true,
            executingServerDetails: {},
            deviceID: (props.user.cameraDetails && Object.keys(props.user.cameraDetails).length > 0 ? props.user.cameraDetails._id : "") || '',
            buttonDisable: false,
            imageLoaded: false,
            color: '',
            lastServer: false
        }
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        this.setState({lastServer: this.state.executingServer});
        sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_ID);
        sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_USERNAME);
        sessionManager.removeDataFromCookies(cookiesConstants.DEVICE_NAME);
        await this.authCallback();
        await this.userAllDevice();
        await this.getEntityDetail();
        this.checkPrivateIp(this.state.IP);
    }


    getEntityDetail = async () => {
        let findObj = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : ''
            }),
            limit: 0, skip: 0
        };
        let [error, entityDetail] = await Utils.parseResponse(DeviceUserMapService.getEntityDetails(findObj));
        if (error || !entityDetail)
            return false;
        if (entityDetail && entityDetail.length > 0) {
            this.setState({executingServerDetails: entityDetail[0]})
        }
    };

    async componentWillUnmount() {
        this.props.dispatchAction(actionsConst.CAMERA_EDIT, {cameraDetails: {}});
    }

    userAllDevice = async () => {
        let query = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': sessionManager.getDataFromCookies(cookiesConstants.USER_ID)
            }),
            limit: 0, skip: 0
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.getDevicesByQueryObj(query));
        // console.log("userDevicesResponse", userDevicesResponse);
        if (!error)
            this.setState({userAllDevices: userDevicesResponse});
    };

    authCallback = async () => {
        this.authObject = new AuthService(process.env.REACT_APP_WEB_APP + 'market-place');
        let [err, authResponse] = await this.authObject.handleAuthentication();
        if (err || !authResponse || !authResponse.accessToken || !authResponse.idTokenPayload || !authResponse.idTokenPayload.name || !authResponse.idTokenPayload.sub || !authResponse.idTokenPayload.email)
            return;
        sessionManager.setDataInCookies(authResponse.accessToken, cookiesConstants.AUTH0_ACCESS_TOKEN);
        sessionManager.setDataInCookies(authResponse.idToken, cookiesConstants.AUTH0_ID_TOKEN);
        sessionManager.setDataInCookies(JSON.stringify(authResponse.idTokenPayload), cookiesConstants.USER_DETAIL);
        sessionManager.setDataInCookies(authResponse.idTokenPayload.email, cookiesConstants.EMAIL_ID);
        sessionManager.setDataInCookies(authResponse.idTokenPayload.sub, cookiesConstants.USER_ID);
        let [error, jwtResponse] = await Utils.parseResponse(MADService.getJWTToken());
        if (jwtResponse && jwtResponse.length > 0)
            sessionManager.setDataInCookies(jwtResponse, cookiesConstants.AUTH0_ACCESS_TOKEN);

        this.props.dispatchAction(actionsConst.USER_AUTH_CALLBACK, {userDetails: authResponse.idTokenPayload});
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
        if (event.target.id === "IP")
            this.checkPrivateIp(event.target.value);
    };

    checkPrivateIp = (value) => {
        let IP = this.getIPFromRRTSPUrl(value);
        if (this.isPrivateIP(IP)) {
            this.setState({executingServer: true});
        } else
            this.setState({executingServer: this.state.lastServer});
    };

    getIPFromRRTSPUrl = (url) => {
        if (url.includes("rtsp")) {
            let fullIp = url.split("/");
            if (fullIp.length >= 3) {
                return fullIp[2].split(":")[0];
            }
        } else {
            let fullIp = url.split('/')[0];
            return fullIp.split(":")[0];
        }
    };

    isPrivateIP = (ip) => {
        return isIpPrivate(ip);
    };

    validateUserName = () => {
        return Utils.isEmpty(this.state.username);
    };

    validatePassword = () => {
        return Utils.isEmpty(this.state.password);
    };

    validateIP = () => {
        return Utils.isEmpty(this.state.IP);
    };

    validateDeviceName = () => {
        return Utils.isEmpty(this.state.deviceName);
    };

    dropboxSuccess = async (files) => {
        if (!this.props.isLoggedIn) {
            await this.sendForSignIn();
            return;
        }
        // console.log("files", files);
        if (!files || !files.length)
            return;
        for (let index = 0; index < files.length; index++) {
            let deviceDetails = {
                'entityMetaData': JSON.stringify({}),
                'entityType': 'dropbox',
                'entityData': JSON.stringify({
                    "deviceID": Utils.generateRandomNumber(9),
                    "IP": files[index].link,
                    "deviceName": files[index].name,
                    "username": "",
                    "password": "",
                    "rtsp": {
                        "proxy": files[index].link,
                        "url": files[index].link
                    },
                    "camera-meta-data": {
                        "companyName": "",
                        "model": "",
                    },
                    "userDetails": {
                        "userID": this.props.userDetails.sub ? this.props.userDetails.sub : "12345",
                        "userName": this.props.userDetails.name ? this.props.userDetails.name : "12345"
                    }
                })
            };
            let [error, deviceDetailsResponse] = await Utils.parseResponse(DeviceUserMapService.saveDeviceDetails(deviceDetails));
            if (error || !deviceDetailsResponse || !Object.keys(deviceDetailsResponse).length) {
                Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
                return false;
            }
        }
        Utils.navigateToPath(pathConstants.MARKET_PLACE);
    };

    onCancelDropBox = () => {
        Utils.apiFailureToast(apiFailureConstants.VIDEO_ADD);
        Utils.navigateToPath(pathConstants.CAMERA_SIGN_UP);
    };

    onDropBoxClicked = () => {
        window.open("https://www.dropbox.com/oauth2/authorize?client_id=svnmnf0kh7i8d6u&response_type=code")
    };

    isUserLoggedIn = () => {
        return !!(sessionManager.getDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN) && sessionManager.getDataFromCookies(cookiesConstants.USER_DETAIL));
    };

    onCameraConnectClicked = async (event) => {
        if (this.validateUserName() || this.validatePassword() || this.validateIP() || this.validateDeviceName()) {
            return Utils.basicAlert("error","Please give full details");
        }

        if (!this.props.isLoggedIn)
            await this.sendForSignIn();

        let proxyResponse = await this.getRTSPProxy();
        if (!proxyResponse)
            return;
        /*
            validation for URL and name removed
         */

        // let deviceExists = this.state.userAllDevices.find((device) => {
        //     return (device.entityData.rtsp.url === proxyResponse && device.entityData.deviceName === this.state.deviceName);
        // });
        // if (deviceExists && Object.keys(deviceExists).length >= 1) {
        //     return Utils.apiFailureToast(apiFailureConstants.CAMERA_ALREADY_EXISTS);
        // }

        //update camera if user come from my camera page

        if (this.props.user.cameraDetails && Object.keys(this.props.user.cameraDetails).length > 0) {
            if (this.state.executingServer && this.state.executingServerDetails && Object.keys(this.state.executingServerDetails).length < 1) {
                Utils.apiFailureToast(apiFailureConstants.LOCAL_SERVER_FAILURE_MSG);
                setTimeout(() => {
                        window.open('/client-download');
                    }, 2000
                );
                return;
            }

            let localRes = await this.showLocalCameraRecentImage(this.props.user.cameraDetails, this.props.user.cameraDetails.entityData.rtsp.url);
            if (localRes) {
                let url = await this.validateImageUrl();
                if (!url || url.length <= 0) {
                    await this.setState({firstTime: true, buttonDisable: false, text: 'Connect', color: ''});
                    // error comes in local then ffmpeg failure count to maximum
                    await this.updateCameraDetails(this.props.user.cameraDetails._id, this.props.user.cameraDetails.entityData.userDetails.userID, 11);
                    return Utils.apiFailureToast(apiFailureConstants.CAN_NOT_CONNNECT_TO_CAMERA);
                }
                this.setState({text: 'Continue', buttonDisable: false, color: 'green'});
                return;
            }


            await this.updateCameraDetails(this.props.user.cameraDetails._id, this.props.user.cameraDetails.entityData.userDetails.userID, 0);
            await this.updateMADConnectedDevice(proxyResponse);
            this.props.dispatchAction(actionsConst.CAMERA_EDIT, {cameraDetails: {}});
            Utils.apiSuccessToast(apiFailureConstants.CAMERA_DETAILS_UPDATED);
            return Utils.navigateToPath(pathConstants.MY_CAMERAS);
        }

        // add new camera

        if (this.state.executingServer && this.state.executingServerDetails && Object.keys(this.state.executingServerDetails).length <= 0) {
            Utils.apiFailureToast(apiFailureConstants.LOCAL_SERVER_FAILURE_MSG);
            setTimeout(() => {
                    window.open('/client-download');
                }, 2000
            );
            return;
        }

        let deviceResponse = await this.addNewCamera(proxyResponse);

        //after user click start free trial from app details
        if (this.props.user.cameraAdd.length > 0 && Object.keys(this.props.user.madDetails).length > 0) {
            let localRes = await this.showLocalCameraRecentImage(deviceResponse, proxyResponse);
            if (localRes) {
                let url = await this.validateImageUrl();
                if (url.length <= 0) {
                    await this.setState({firstTime: true, buttonDisable: false, text: 'Connect', color: ''});
                    // error comes in local then ffmpeg failure count to maximum
                    await this.updateCameraDetails(sessionManager.getDataFromCookies(cookiesConstants.DEVICE_ID), sessionManager.getDataFromCookies(cookiesConstants.USER_ID), 11);

                    return Utils.apiFailureToast(apiFailureConstants.CAN_NOT_CONNNECT_TO_CAMERA);
                }
                this.setState({text: 'Continue', buttonDisable: false, color: 'green'});
                return;
            }

            let madDetails = this.props.user.madDetails;
            await this.addCameraToMAD(madDetails, this.state.deviceDetailsResponse);
            await this.props.dispatchAction(actionsConst.CAMERA_ADD, {firstCamera: ""});
            await this.props.dispatchAction(actionsConst.MAD_DETAILS, {madDetails: {}});
            localStorage.clear();
            // return Utils.navigateToPath(pathConstants.APP_INPUT + madDetails._id);
            return Utils.navigateToPath(pathConstants.MY_APPS);
        }
        localStorage.clear();
        this.handleNavigation(deviceResponse, proxyResponse);
    };

    showLocalCameraRecentImage = async (cameraDetails, rtspUrl) => {
        if (!this.state.executingServer || !this.state.firstTime || !this.state.executingServerDetails && Object.keys(this.state.executingServerDetails).length >= 1)
            return false;
        await this.publishLocalCameraImageData(cameraDetails, rtspUrl);
        await this.setState({text: 'Connecting...', color: "grey"});
        let image = await this.getImageUrl();
        this.setState({image: image, firstTime: false, buttonDisable: true});
        await this.timeOut(12000);
        return true;
    };

    validateImageUrl = async () => {
        let url = this.state.image;
        if (!url)
            return "";
        return new Promise(async (resolve, reject) => {
            const imgElement = new Image();
            imgElement.src = url;
            imgElement.onload = function () {
                return resolve(url);
            };
            imgElement.onerror = function () {
                return resolve("");
            };
        })
    };

    wait = (ms) => {
        let start = new Date().getTime();
        let end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    };

    addNewCameraToMad = async (deviceResponse) => {
        let [error, userMADResponse] = await Utils.parseResponse(MADService.getUserMAD(this.props.userDetails.sub));
        if (error || !userMADResponse) {
            // Utils.apiFailureToast(apiFailureConstants.GET_USER_MAD);
            Utils.navigateToPath(pathConstants.HOME);
            return;
        }
        for (let index = 0; index < userMADResponse.length; index++) {
            await this.addCameraToMAD(userMADResponse[index], deviceResponse);
        }
    };


    handleNavigation = async (deviceResponse, proxyResponse) => {
        if (this.state.executingServer && this.state.firstTime && this.state.executingServerDetails && Object.keys(this.state.executingServerDetails).length >= 1) {
            await this.showLocalCameraRecentImage(deviceResponse, proxyResponse);
            //await this.getImageRecentFromCamera(deviceResponse, proxyResponse);
            await this.setState({text: 'Connecting...', color: 'grey'});
            let image = await this.getImageUrl();
            this.setState({image: image, firstTime: false, buttonDisable: true});
            await this.timeOut(15000);
            await this.setState({imageLoaded: true});
            let url = await this.validateImageUrl();
            if (url.length <= 0) {
                this.setState({firstTime: true, buttonDisable: false, text: 'Connect', color: ''});
                // error comes in local then ffmpeg failure count to maximum
                await this.updateCameraDetails(sessionManager.getDataFromCookies(cookiesConstants.DEVICE_ID), sessionManager.getDataFromCookies(cookiesConstants.USER_ID), 11);
                return Utils.apiFailureToast(apiFailureConstants.CAN_NOT_CONNNECT_TO_CAMERA);
            }
            this.setState({text: 'Continue', buttonDisable: false, color: 'green'});
            return;
        }

        if (!this.state.executingServer)
            return Utils.navigateToPath(pathConstants.MARKET_PLACE);
        if (this.state.executingServer) {
            let url = await this.validateImageUrl();
            if (url.length <= 0) {
                this.setState({firstTime: true, buttonDisable: false, text: 'Connect', color: ''});
                return Utils.apiFailureToast(apiFailureConstants.CAN_NOT_CONNNECT_TO_CAMERA);
            }

            return Utils.navigateToPath(pathConstants.MY_APPS);
        }
        return Utils.navigateToPath(pathConstants.HOME);
    };

    addNewCamera = async (proxyResponse) => {
        if (this.state.executingServer && !this.state.firstTime)
            return;
        let deviceResponse = await this.storeDeviceDetail(proxyResponse);
        if (!deviceResponse) {
            Utils.apiFailureToast(apiFailureConstants.DEVICE_NOT_ADDED);
            return;
        }
        sessionManager.setDataInCookies(deviceResponse._id, cookiesConstants.DEVICE_ID);
        sessionManager.setDataInCookies(deviceResponse.entityData.deviceName, cookiesConstants.DEVICE_NAME);
        sessionManager.setDataInCookies(this.state.username, cookiesConstants.DEVICE_USERNAME);
        sessionManager.setDataInCookies(proxyResponse, cookiesConstants.DEVICE_RTSP);
        return deviceResponse;
    };

    timeOut = async (interval) => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                this.setState({imageLoaded: true});
                resolve();
            }, interval);
        });
    };

    getImageRecentFromCamera = async (deviceDetail, proxyResponse) => {
        let userDetail = this.props.user && this.props.user.cameraDetails ? this.props.user.cameraDetails.entityData ? this.props.user.cameraDetails.entityData : '' : '';
        let requestBody = {
            "rtsp": userDetail.rtsp && userDetail.rtsp.url ? userDetail.rtsp.url : proxyResponse,
            "deviceID": deviceDetail._id,
            "executingServer": userDetail.userDetails && userDetail.userDetails.executingServer ? userDetail.userDetails.executingServer : this.state.executingServer ? 'local' : 'cloud',
            "exchangeName": "recent-image-exchange",
            "exchangeType": "fanout",
            "queueName": "recent-image-queue",
            "queueType": "publisher_subscriber_queue",
            "frameInterval": 10,
            "frameRate": 10
        };
        let imageResponse = await Utils.parseResponse(MADService.getImageRecentFromCamera(requestBody));
        if (!imageResponse)
            return true;
        return imageResponse;
    };

    publishLocalCameraImageData = async (deviceDetail, proxyResponse) => {
        let userDetail = this.props.user && this.props.user.cameraDetails ? this.props.user.cameraDetails.entityData ? this.props.user.cameraDetails.entityData : '' : '';
        let entityDetail = sessionManager.getDataFromCookies(cookiesConstants.ENTITY_DETAILS);
        let imageResponse = await publishActionQueueData({
            userID: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : '',
            exchangeName: 'recent-image-exchange',
            exchangeType: 'fanout',
            queueName: sessionManager.getDataFromCookies(cookiesConstants.USER_ID) ? sessionManager.getDataFromCookies(cookiesConstants.USER_ID) : '',
            queueType: 'publisher_subscriber_queue',
            queueData: {
                time: 10,
                deviceID: deviceDetail._id,
                rtsp: userDetail.rtsp && userDetail.rtsp.url ? userDetail.rtsp.url : proxyResponse,
                frameRate: 10,
                executingServer: 'local'
            },
        }).catch(/*error => console.log(error)*/)
        if (!imageResponse)
            return true;
        return imageResponse;
    }

    getImageUrl = async () => {
        let url = '';
        let id = this.state.deviceID ? this.state.deviceID : sessionManager.getDataFromCookies(cookiesConstants.DEVICE_ID);
        if (this.state.executingServerDetails && Object.keys(this.state.executingServerDetails).length > 0)
            url = 'https://' + this.state.executingServerDetails.entityData.IP + ":" + this.state.executingServerDetails.entityData.port + '/public/cameraRecentImages/' + id + '.jpg';
        return url;
    };

    addMADSchedule = async (madDetails) => {
        if (!madDetails || !madDetails._id || !madDetails.schedules || madDetails.schedules.length <= 0)
            return;
        let reqObj = {
            "madID": madDetails._id,
            "schedules": madDetails.schedules,
            "isRecurring": 1
        };
        await Utils.parseResponse(MADService.addMADSchedule(reqObj));
    };

    updateMADConnectedDevice = async (rtsp) => {
        let requestBody = {
            "rtsp": rtsp,
            "deviceID": this.props.user.cameraDetails._id,
            "deviceName": this.state.deviceName,
            "executingServer": this.state.executingServer ? 'local' : 'cloud',
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(MADService.updateMADConnectedDevice(requestBody));
        if (error || !userDevicesResponse)
            return false;
        return userDevicesResponse;
    };

    updateCameraDetails = async (cameraID, userID, failureCount) => {
        let proxyResponse = await this.getRTSPProxy();
        if (!proxyResponse)
            return;
        let requestBody = {
            queryObj: {
                _id: cameraID,
                "entityData.userDetails.userID": userID
            },
            updationObj: {
                "entityData.executingServer": this.state.executingServer ? 'local' : 'cloud',
                "entityData.IP": this.state.IP,
                "entityData.deviceName": this.state.deviceName,
                "entityData.ffmpegFailureCount": failureCount ? failureCount : 0,
                "entityData.username": this.state.username,
                "entityData.password": this.state.password,
                "entityData.rtsp": {
                    "proxy": proxyResponse,
                    "url": proxyResponse
                },
                "entityData.camera-meta-data": {
                    "companyName": "",
                    "model": "",
                }
            }
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.updateCameraDetails(requestBody));
        if (error || !userDevicesResponse)
            return false;
        return Utils.apiSuccessToast(apiFailureConstants.CAMERA_DETAILS_UPDATED);
    };

    addCameraToMAD = async (madDetails, deviceDetails) => {
        if (!deviceDetails || !deviceDetails.entityData)
            return false;
        let connectedDevice = [];
        // if device does not exists in MAD then add device details in MAD otherwise change the status of device in MAD
        if (!madDetails.connectedDevice || madDetails.connectedDevice.length <= 0) {
            let devices = {
                "deviceName": deviceDetails.entityData.deviceName,
                "deviceID": deviceDetails._id,
                "rtsp": deviceDetails.entityData.rtsp.url,
                "executingServer": deviceDetails.entityData.executingServer ? deviceDetails.entityData.executingServer : "cloud"
            };
            connectedDevice = [devices];
        } else {
            for (let index = 0; index < madDetails.connectedDevice.length; index++) {
                let devices = {};
                if (madDetails.connectedDevice[index].deviceID !== deviceDetails._id && madDetails.connectedDevice[index] && madDetails.connectedDevice[index].length > 0 && !madDetails.connectedDevice[index].isDeleted) {
                    devices.deviceName = madDetails.connectedDevice[index].deviceName;
                    devices.deviceID = madDetails.connectedDevice[index].deviceID;
                    devices.rtsp = madDetails.connectedDevice[index].rtsp;
                    devices.executingServer = madDetails.connectedDevice[index].executingServer ? madDetails.connectedDevice[index].executingServer : "cloud";
                    connectedDevice.push(devices);
                }
            }
        }
        await this.updateMAD(madDetails, connectedDevice);
        try {
            await this.addMADSchedule(madDetails);
        } catch (err) {

        }
        return true;
    };

    updateMAD = async (mad, connectedDevice) => {
        let findObj = {
            "userID": mad.userID,
            "_id": mad._id,
            "appMeta.appID": mad.appMeta.appID
        };
        let updateObj = {
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "connectedDevice": connectedDevice
        };
        let [error, updateMadResponse] = await Utils.parseResponse(MADService.updateMAD(findObj, updateObj));
        return updateMadResponse;
    };

    async sendForSignIn() {
        this.authObject = new AuthService(process.env.REACT_APP_WEB_APP + "camera-sign-up");
        await this.authObject.signIn(process.env.REACT_APP_WEB_APP + "camera-sign-up");
    }

    getRTSPProxy = async () => {
        let rtspURL = "";
        let IP = this.state.IP.trim();
        let username = this.state.username.trim();
        if (IP.includes("rtsp://"))
            rtspURL = `rtsp://${username}:${this.state.password}@${IP.replace("rtsp://", "")}`;
        else
            rtspURL = `rtsp://${username}:${this.state.password}@${IP}`;
        this.setState({rtspUrl: rtspURL});
        let options = {
            'rtspUrl': rtspURL
        };
        return rtspURL;
        // console.log("rtspURL", rtspURL);
        // let [error, rtspProxyResponse] = await Utils.parseResponse(RTSPProxyService.getRTSPProxy(options));
        // if (error)
        //     Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
        // else if (rtspProxyResponse) {
        //     return rtspProxyResponse;
        // }
    };

    storeDeviceDetail = async (proxyResponse) => {
        if (!this.props.userDetails || !this.props.userDetails.sub || !this.props.userDetails.name) {
            await this.sendForSignIn();
            return;
        }
        let deviceDetails = {
            'entityMetaData': JSON.stringify({}),
            'entityType': 'connected_device',
            'entityData': JSON.stringify({
                "executingServer": this.state.executingServer ? 'local' : 'cloud',
                "deviceID": Utils.generateRandomNumber(9),
                "IP": this.state.IP,
                "deviceName": this.state.deviceName,
                "username": this.state.username,
                "password": this.state.password,
                "rtsp": {
                    "proxy": proxyResponse,
                    "url": this.state.rtspUrl
                },
                "camera-meta-data": {
                    "companyName": "",
                    "model": "",
                },
                "ffmpegFailureCount": 0,
                "userDetails": {
                    "userID": this.props.userDetails.sub,
                    "userName": this.props.userDetails.name,
                    "emailID": this.props.userDetails.email
                }
            })
        };
        let [error, deviceDetailsResponse] = await Utils.parseResponse(DeviceUserMapService.saveDeviceDetails(deviceDetails));
        if (error || !deviceDetailsResponse || !Object.keys(deviceDetailsResponse).length) {
            Utils.apiFailureToast(apiFailureConstants.GET_APP_DETAIL);
            return false;
        }
        this.setState({deviceDetailsResponse: deviceDetailsResponse});
        /*
        Whenever new camera is added, then add camera automatically to MAD and add schedules
         */
        // removed because It is not working
        // await this.addNewCameraToMad(deviceDetailsResponse);
        return deviceDetailsResponse;
    };

    handleChange = async (e) => {
        if (e.target.checked) {
            await this.setState({executingServer: true});
        } else {
            await this.setState({executingServer: false})
        }

    };

    render() {
        return (
            <div>
                <CameraSignUpComponent IP={this.state.IP}
                                       username={this.state.username}
                                       password={this.state.password}
                                       deviceName={this.state.deviceName}
                                       onChangeEvent={this.onChangeEvent}
                                       onCameraConnectClicked={this.onCameraConnectClicked}
                                       dropboxSuccess={this.dropboxSuccess}
                                       onCancelDropBox={this.onCancelDropBox}
                                       state={this.state}
                                       handleChange={this.handleChange}
                />
                <FooterComponent2/>
            </div>
        );
    }
}


const
    mapStateToProps = (state) => {
        return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
    };

export default connect(mapStateToProps, {dispatchAction})(CameraSignUp);
