import React ,{useEffect}from 'react';
import HeaderComponent from "../common/headerComponent";
import FooterComponent from "../common/footerComponent";
import FooterComponent2 from "../common/footerComponentSecond";


function PrivacyComponent(props) {
    useEffect(()=>window.scrollTo(0,0,),[])

    return (
        <>    
            <div className='home-container'>
            <div className='px-120 md-px-24 base-container xxl-px-120 xl-px-70'>
                <HeaderComponent loginRedirectEndPoint=""/>
            </div>
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
                <div className="mt-4 fs-16 l-h-1-4">
                    By accessing or using our services in any way, you acknowledge that you accept the policies
                    described in this privacy policy. You hereby consent that we will access, share and collect your
                    information in the following ways.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    If you do not agree with this policy, you may not use the services of Appsstore.ai. If you want to
                    use services on behalf of someone else or an entity, you represent that you are entitled by that
                    entity or individual to accept this privacy policy on such individual's or entity's behalf.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Your use of Appsstore.ai's services is at all times subject to the terms of use that incorporates
                    this privacy policy. If you have any questions, please feel free to contact us at
                    <a className="m-l-xs" href="mailto:support@appsstore.ai">hello@appsstore.ai.</a>
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    What does this privacy policy cover?
                </div>
                <div className="mt-4 fs-16 l-h-1-4">
                    The privacy policy includes how we treat personal data that we collect when users of our services
                    (you or third parties) access our services. "Personal data" means any information that is related to
                    a specific individual and covers information referred to as "personally identifiable information" or
                    "personal information" under applicable data privacy rules, regulations and laws. The privacy policy
                    does not include practices of companies we don't control or own or people we don't handle.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Origins of Personal Data
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    We gather personal data about you from:
                </div>
                <ul>
                    <li className="m-t-sm fs-16 l-h-1-4">You, when you create an account and use our services</li>
                    <div className="fs-16 m-t-sm">
                        <ul className="fs-16 m-t-sm">
                            <li className="m-t-sm">When you send personal data (images, text, videos, geolocation or
                                other metadata related to such personal data) to us
                            </li>
                            <li className="m-t-sm">When you interact with other users of services and share content</li>
                        </ul>
                    </div>
                    <li className="m-t-sm fs-16 l-h-1-4">Third parties, when they provide us with personal data about
                        you. Third parties that share your personal information with us include:
                    </li>
                    <div className="fs-16 m-t-sm">
                        <ul className="fs-16 m-t-sm">
                            <li className="m-t-sm">When third-party users use our services and send us personal data
                                about you, including videos, text, geolocation and images or other metadata related to
                                that personal data
                            </li>
                            <li className="m-t-sm">When third-party users add annotations enacting personal data about
                                you to the data they send us when they use our services
                            </li>
                            <li className="m-t-sm">If third-party users use certain apps and software we make available,
                                we may obtain and collect information transmitted from their computing device to provide
                                them the required services and that information may include personal data.
                            </li>
                        </ul>
                    </div>
                    <li className="m-t-sm fs-16 l-h-1-4">We may use analytics <b>service providers </b>to analyze how
                        you
                        engage and interact with services or third parties may help us provide you with customer
                        support. We may also collect your personal information from third parties that provide us with
                        sales leads.
                    </li>
                </ul>
                <div className="m-t-sm fs-25 font-weight-bold l-h-1-4">
                    Categories of Personal Data We Gather
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    The following section lists categories of personal data that we gather. For each category of
                    personal data, we have mentioned third parties with whom we share that personal data.
                </div>

                <div className="overflow-x-auto-m">
                    <table className="b-1-ccc m-t-15">
                        <tr className="b-1-ccc bg-f8f8f8">
                            <td className="b-1-ccc table-data">
                                <div className="font-weight-bold l-h-2-m fs-16">
                                    Category of Personal Data
                                </div>
                            </td>
                            <td className="b-1-ccc table-data">
                                <div className="font-weight-bold l-h-2-m fs-16">
                                    Examples of Collected Personal Data
                                </div>
                            </td>
                            <td className="b-1-ccc table-data">
                                <div className="font-weight-bold l-h-2-m fs-16">
                                    Source
                                </div>
                            </td>
                            <td className="b-1-ccc table-data">
                                <div className="font-weight-bold l-h-2-m fs-16">
                                    Third Party with whom we share data for business purposes
                                </div>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Biometric Data
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Images, videos and information related to videos and images, for example, patterns
                                    associated with videos or images for facial recognition purposes.
                                </li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>You</li>
                                <li>Third Parties</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                                <li>Third-party users sharing your personal data with us authorizes, authenticates or
                                    accesses
                                </li>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Device Information/IP
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Browser</li>
                                <li>IP address</li>
                            </td>
                            <td className="b-1-ccc table-data w-150">
                                <li>You</li>
                                <li>Third Parties</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Geolocation and other metadata
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Geolocation and other data that may constitute personal data</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>You</li>
                                <li>Third Parties</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                                <li>Third-party users sharing your personal data with us authorizes, authenticates or
                                    accesses
                                </li>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Identifiers/Contact Data
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Full name</li>
                                <li>Email</li>
                                <li>Company name</li>
                                <li>Phone Number</li>
                                <li>Location</li>
                                <li>Password</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>You</li>
                                <li>Third Parties</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                                <li>Third-party users sharing your personal data with us authorizes, authenticates or
                                    accesses
                                </li>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Payment Information
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Debit card number</li>
                                <li>Billing address</li>
                                <li>Credit card number</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>You</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Predictions, inferences and tags generated by us
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>We may gather new personal data from data we collect, including using automated
                                    means to
                                    generate information about your characteristics ( for example, making predictions
                                    about
                                    demographics biometric data)
                                </li>
                                <li>We may also add tags with personal data to describe the data we collect.
                                </li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>You</li>
                                <li>Third Parties</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                            </td>
                        </tr>
                        <tr className="b-1-ccc">
                            <td className="b-1-ccc table-data">
                                Social Network Data
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Credentials from other online services you use to access services, for example,
                                    email
                                    address, full name and profile image when you sign in to our service using Google
                                    Sign-in
                                </li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Generated by us</li>
                            </td>
                            <td className="b-1-ccc table-data">
                                <li>Service providers</li>
                                <li>Third-party users sharing your personal data with us authorizes, authenticates or
                                    accesses
                                </li>
                            </td>
                        </tr>

                    </table>
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Data Transfer
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    <li>
                        We use a secured network protocol to transfer any data between our servers and the cloud
                        using
                        the industry-standard https protocol.
                    </li>
                    <li>
                        Our website and apps have industry-grade security precautions in place to prevent the loss
                        and
                        alteration and misuse of information. We implement encryption and other security protocols
                        to
                        secure personal information stored by us.
                    </li>
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Data storage
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    <li>
                        All information is stored in a secured Amazon AWS s3 bucket.
                    </li>
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Data Deletion
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    When you delete data, we ensure to follow a deletion process to remove your data safely and
                    completely from our servers or retained only in anonymized form. We try to ensure that our services
                    protect your information from accidental or malicious deletion.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    If you ever decide to stop using <a href="https://www.appsstore.ai">AppsStore.ai</a>, you can just
                    ask us to delete your account.
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    Keep in mind that, while our systems are designed to carry out our deletion practices automatically,
                    we cannot assure that deletion will happen within a specific timeframe.There can be some legal
                    requirements to store your data and we may need to suspend those deletion practices if we receive a
                    valid legal process asking us to keep content or if we receive reports of abuse or other Terms of
                    Service violations. Finally, we may also retain certain information in backup for a limited period
                    of time or as required by law.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Data Access
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    We never access your personal data without your consent and never disclose it without your signed
                    consent.
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    However, we use your information for a wide range of purposes, including:
                </div>
                <table className="m-t-15">
                    <tr className="b-1-ccc bg-f8f8f8">
                        <td className="b-1-ccc font-weight-bold fs-16 p-l-sm">Reason for collecting data</td>
                        <td className="b-1-ccc font-weight-bold fs-16 p-l-sm">Description</td>
                    </tr>
                    <tr className="b-1-ccc">
                        <td className="b-1-ccc table-data">Customizing, providing and improving services</td>
                        <td className="b-1-ccc table-data">
                            <li>Providing you or applicable third-party user with services, products and requested
                                information and fulfilling related purposes (for example, using personal data provided
                                by you or third-party user to perform face recognition services.
                            </li>
                            <li>Training our models and algorithms to be accurate, smarter and faster. For example, if
                                we generally use biometrics data, as mentioned above, internally to train our models in
                                facial recognition or computer vision technology. Note that we do that with appropriate
                                authorization and do not distribute personal data to users who did not provide us with
                                such personal data.
                            </li>
                            <li>Creating and handling your account or other user profiles.</li>
                            <li>Enhancing services, including research, testing, product development and internal
                                analytics.
                            </li>
                            <li>Assisting with services.</li>
                            <li>Enhancing the quality of the product through development and research.</li>
                            <li>Billing</li>
                        </td>
                    </tr>
                    <tr className="b-1-ccc">
                        <td className="b-1-ccc table-data">Legal requirements</td>
                        <td className="b-1-ccc table-data">
                            <li>Meeting our legal obligations under applicable regulations, laws, court orders and other
                                legal processes such as detecting, preventing and investigating security incidents and
                                prohibited or illegal activities.
                            </li>
                            <li>Protecting against fraudulent, illegal or harmful actions and maintaining the safety,
                                integrity and security of services.
                            </li>
                            <li>Responding to claims that any other content or posting violates third-party rights.</li>
                            <li>Settling disputes.</li>
                            <li>Enforcing any agreements with you.</li>
                        </td>
                    </tr>
                    <tr className="b-1-ccc">
                        <td className="b-1-ccc table-data">Corresponding with you</td>
                        <td className="b-1-ccc table-data">
                            <li>Responding to your inquiries and meeting your requests.</li>
                            <li>Interacting with you about services, including updates, offers or service
                                announcements.
                            </li>
                        </td>
                    </tr>
                </table>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    What does the corporate using <a href="https://www.appsstore.ai">AppsStore.ai</a> need to do to
                    protect and comply with the data
                    protection?
                </div>
                <div className="m-t-15 fs-16 l-h-1-4">
                    <b className="m-r-xs">1.</b> A corporate body (entity) needs to obtain the consent of persons before collecting their
                    personal data. Or, an entity should inform about the collection of the data prior to its processing.
                    Sensitive data is only collected for a lawful purpose that is connected with and important to the
                    entity's function.

                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    <b className="m-r-xs">2.</b>Once the purpose is accomplished, the entity should no longer retain people's personal
                    data.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    <b className="m-r-xs">3.</b>When you request us to share your information with a third-party application or website, we
                    will offer you a fresh notice and get your consent regarding the sharing of information. In that
                    case, <a href="https://www.appsstore.ai"> Appsstore.ai</a> will not be in control of the information
                    shared with a third-party entity. Our
                    privacy policy will not apply to any such use and access.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    The customer shall read and consent to the third-party's privacy policy before requesting <a
                    href="https://www.appsstore.ai">
                    Appsstore.ai </a>to share the information.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    State Law of Privacy Rights
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-18 mt-lg-5">
                    California Resident Rights
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Under California Civil Code Sections 1798.83-1798.84, California residents are authorized to contact
                    us to prevent disclosure of personal data to third parties. Please reach us at <a className="m-l-xs"
                                                                                                      href="mailto:support@appsstore.ai">hello@appsstore.ai</a>.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-18 mt-lg-5">
                    European Union Data Subject Rights
                </div>
                <div className="m-t-sm fs-16 l-h-1-4 ">
                    If you are a resident of the European Union ("EU"), Lichtenstein, United Kingdom, Iceland or Norway,
                    you may have added rights under the EU General Data Protection Regulation (GDPR) with respect to
                    your personal data, as mentioned below:
                </div>
                <div className="m-t-sm fs-16 l-h-1-4 ">
                    We use the terms "personal data" and "processing" for this section as they are defined in GDPR.
                    "Personal data" usually refers to the information used to identify a person individually and
                    "processing" includes actions performed in connection with data, for example, collection, storage,
                    use and disclosure. Appsstore.ai will be the controller of your personal data processed in
                    connection with services.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4 ">
                    We carefully comply with the six principles set out in Article 5 of GDPR that in summary, are:
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-16 mt-lg-5">
                    Personal data shall be:
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    <li>gathered for explicit, legitimate and specified purposes and not processed in a way that is
                        incompatible with those purposes
                    </li>
                    <li>accurate and kept up to date where necessary</li>
                    <li>processed fairly, transparently and lawfully</li>
                    <li>relevant, adequate and restricted to what is necessary in connection to purposes for which they
                        are processed
                    </li>
                    <li>processed in a way that ensures the security of the personal data, including protection against
                        unlawful or unauthorized processing, destruction or damage, accidental loss with appropriate
                        organizational or appropriate measures
                    </li>
                    <li>kept in a form that permits identification of data subjects for no longer than is necessary for
                        purposes for which the data is processed.
                    </li>
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    You have specific rights with respect to your personal data. If you want more information about
                    these rights or submit a request, please contact us at <a className="m-l-xs"
                                                                              href="mailto:support@appsstore.ai">hello@appsstore.ai</a>. Please make sure that
                    in some circumstances, we may not be able to completely comply with your request if it is extremely
                    impractical or if not required by law, but even in such cases, we will respond to notify you of that
                    decision.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-16 m-t-15">
                    Access:
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    You can ask us for more information about the personal data we manage about you and request a copy
                    of that personal data.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-16 m-t-15">
                    Erasure:
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    You can also request to erase all or some of your personal data from our systems.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-16 m-t-15">
                    Rectification:
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    If you think that any personal data that we are holding about you is incomplete or incorrect, you
                    can request to supplement or correct that data.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-16 m-t-15">
                    Withdrawal of Consent:
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    Since we process your personal data based on your consent, you have the right to withdraw your
                    consent anytime.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-16 m-t-15 ">
                    Objection:
                </div>

                <div className="m-t-sm fs-16 l-h-1-4">
                    You can contact us to let us know that you object to the further disclosure or use of personal data
                    for specific purposes, for example, marketing purposes.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    How we use non-personal data
                </div>
                <div className="m-t-sm fs-16 l-h-1-4 display-flex">
                    We may use information that is not personal (including personal data that has been aggregated or
                    de-identified) to understand who uses Appsstore.ai and how can we provide a better experience. Using
                    this information, we customize our services to the interests of our users to enhance the quality,
                    interactivity and functionality and evaluate traffic within our services.
                </div>

                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Changes to this privacy policy
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    We constantly try to enhance our services, so we may need to modify this policy from time to time,
                    but we will notify you of changes by placing a notice on the website <a className="m-l-xs"
                                                                                            href="https://www.appsstore.ai">https://www.appsstore.ai/</a> ,
                    by sending you an email or some other
                    means.
                </div>

                <div className="m-t-sm fs-16 l-h-1-4 display-flex">
                    Please note that if you have not opted to receive our legal notice emails, legal notices will still
                    govern your use of services and you shall be responsible for understanding and reading them.
                </div>

                <div className="m-t-sm fs-16 l-h-1-4 display-flex">
                    If you use our services after changes in the privacy policy, it means you agree to all amendments
                    made in the policy.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4 ">
                    If you have any questions or comments about this privacy policy, please do not hesitate to reach us
                    at <a href='mailto:support@appsstore.ai'>hello@appsstore.ai</a>.
                </div>

            </div>
        </div>
        
        <div className="m-t-250">
                    {/* <FooterComponent/> */}
                    <FooterComponent2/>
                </div>
        </>
    );
}

export default PrivacyComponent;
