import React from 'react'
import {Column, Row} from "simple-flexbox";
import HeaderComponent from "../common/headerComponent";
import FooterComponent2 from "../common/footerComponentSecond";

function UserDeviceComponent(props) {
    return (
      <div className="home-container">
        <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
          <HeaderComponent />
        </div>
        <div className="text-align-center m-t-min-65-m ">
          <div className="m-t-150 user-device-ht">
            <span className="fs-m-17 user-device user-device-m user-device l-h-120">
              What kind of camera do you want to configure?
            </span>
            <div className="margin-auto w-600-desktop p-t-lg">
              <Row
                className="w-369-px-m w-600 margin-auto m-t-20 input-border height-100 p-xs m-l-xs-m m-r-xs-m bg-white cursor-pointer"
                onClick={() => {
                  props.redirect("PHONE_CAMERA");
                }}
              >
                <Column>
                  <img src="/images/mobileCam.svg" height="80px" />
                </Column>
                <Column className="mt-3 ">
                  <Row className="fc-dark-grey fs-20 fw-bold  m-b-0 h-15">
                    iPhone/Android Camera
                  </Row>
                  <Row className="fc-7c7c7c fs-16 m-t-sm  m-b-0 text-align-left w-210-m">
                    Use your mobile camera to capture people without masks
                  </Row>
                </Column>
                <Column vertical={"center"} className="m-l-auto">
                  <img
                    src="images/right-chevron.png"
                    className="w-10-px h-16"
                  />
                </Column>
              </Row>
              <Row
                className="w-369-px-m w-600 margin-auto m-t-30 input-border fs-20 height-100  p-xs m-l-xs-m m-r-xs-m  bg-white cursor-pointer"
                onClick={() => {
                  props.redirect("LAPTOP_CAMERA");
                }}
              >
                <Column>
                  <img src="/images/lapTop.svg" height="80px" />
                </Column>
                <Column className="mt-3 ">
                  <Row className="fc-dark-grey fs-20 fw-bold m-b-0 h-15">
                    Laptop Camera
                  </Row>
                  <Row className="fc-7c7c7c fs-16 m-t-sm  m-b-0 text-align-left w-210-m">
                    Use laptop camera to capture people without mask
                  </Row>
                </Column>
                <Column vertical={"center"} className="m-l-auto">
                  <img
                    src="images/right-chevron.png"
                    className="w-10-px h-16"
                  />
                </Column>
              </Row>
              <Row
                className="w-369-px-m w-600 margin-auto m-t-30 input-border height-100  p-xs m-l-xs-m m-r-xs-m  bg-white cursor-pointer"
                onClick={() => {
                  props.redirect("IP_CAMERA");
                }}
              >
                <Column>
                  <img src="/images/ipCam.svg" height="80px" />
                </Column>
                <Column className="mt-3 ">
                  <Row className="fc-dark-grey fs-20 fw-bold  m-b-0">
                    RTSP Camera
                  </Row>
                  <Row className="fc-7c7c7c fs-16 m-t-sm  m-b-0 text-align-left">
                    Use lP/ camera to capture people without mask
                  </Row>
                </Column>
                <Column vertical={"center"} className="m-l-auto">
                  <img
                    src="images/right-chevron.png"
                    className="w-10-px h-16"
                  />
                </Column>
              </Row>
            </div>
          </div>
        </div>
        <div className="m-t-300">
          {/* <FooterComponent /> */}
          <FooterComponent2 />;
        </div>
      </div>
    );
}

export default UserDeviceComponent;

