import React, { useState } from "react";
import Utils from "../../utility";

const ImageRecognition = ({ onPredict }) => {
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);

  const handlePredict = async () => {
    if (!image.name) return Utils.apiFailureToast("Image is required!");
    try {
      setLoading(true);
      await onPredict(image);
      setLoading(false);
      setImage({});
    } catch (error) {
      setLoading(false);
      Utils.apiFailureToast(
        "An error occured while face recognition. Please try again"
      );
    }
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    const name = file.name;
    const ext = name.split(".").pop().toLowerCase();
    const validExtensions = ["jpg", "png", "jpeg"];
    if (validExtensions.includes(ext)) setImage(file);
    else
      Utils.apiFailureToast(
        "File format is not supported, try .jpg, .png, .jpeg instead."
      );
  };

  return (
    <div>
      <div className="text-align-center m-t-60 font-lato font-bold fc-dark-slate-blue text-ft24-29">
        Upload Image for recognition
      </div>
      <div className="max-w-500 b-1-lightBlue-100 br-24 m-auto m-t-20 bg-lightBlue-10 min-h-300">
        <div className="m-t-120">
          <input
            type="file"
            className="display-none"
            accept="image/*"
            id="predict_image"
            onChange={onImageChange}
          />
          <label
            htmlFor="predict_image"
            className="b-1-lightBlue-100 max-w-200 br-24 m-auto h-60 display-flex align-item-center justify-center font-lato font-medium fc-dark-slate-blue gap-10 cursor-pointer"
          >
            {!!image?.name ? (
              image.name
            ) : (
              <>
                <img src="/images/upload-icon.svg" alt="" />
                Upload Image
              </>
            )}
          </label>
        </div>
        <div className="max-w-200 m-auto">
          <button
            disabled={loading}
            onClick={handlePredict}
            className="br-24 m-t-44 w-100-per h-42 bg-light-blue-20 br-1-blue cursor-pointer fc-white font-lato font-medium fs-16"
          >
            {loading ? (
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              "Run Modal"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageRecognition;
