import React ,{useEffect} from 'react';
import HeaderComponent from "../common/headerComponent";
import FooterComponent2 from "../common/footerComponentSecond";


function  TermsComponent(props){
    useEffect(()=>window.scrollTo(0,0,),[])

    return (
        <>
        <div className='home-container'>
            <div className='px-120 md-px-24 base-container xxl-px-120 xl-px-70'>
                <HeaderComponent loginRedirectEndPoint=""/>
            </div>
            <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
                <div className="font-weight-bold fs-25">
                    Terms of Service
                </div>
                <div className="mt-4 fs-16 l-h-1-4">
                    By signing up for an Appsstore.ai account or by using any Appsstore.ai services, you agree to be
                    bound by the following conditions and
                    terms (terms of service).
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Whenever used in these terms of service, "we," "us," and "<a href='/'>Appsstore.ai</a>" means the
                    applicable
                    <a href='/'>Appsstore.ai</a> contracting party.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    These terms and conditions /terms of services (TOS/Website Terms/"Terms") read with the
                    disclaimers, privacy policy, rules of Appsstore.ai, as may be posted on the website, with a
                    domain name <a href="https://www.appsstore.ai">www.appsstore.ai</a>, from time to time,
                    shall govern your access/use of the
                    website, its hyperlinks, sites and related content. These terms apply to all users of the
                    website.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Changes to the Website Terms
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    We may modify, update, amend or change terms of the website, from time to time, by
                    publishing an updated version on the website. You will be assumed to accept the updated
                    Terms of the website and agree to be bound by them to continue to use the website.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Definitions
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    The following terms will have specific meanings whenever used in the agreement:
                </div>
                <ul className="m-t-sm fs-16 m-t-sm">
                    <li className="fw-bold m-t-sm">Account</li>
                    It refers to the customer's account whereby the customer signs up for and uses services.
                    <li className="fw-bold m-t-sm"> Content</li>
                    Content means all graphics, text, visual interfaces, user interfaces, trademarks,
                    photographs, sounds, music, video, artwork, computer code and logos.
                    <li className="fw-bold m-t-sm">Subscription</li>
                    Subscription means the specific apps, features, pricing and attributed agreed upon by
                    customer and Appsstore.ai for the provision of the app marketplace or service outlines in a
                    different contract.
                    <li className="fw-bold m-t-sm">Free Trial Plan</li>
                    Free Trial Plan relates to a restricted account that includes restricted
                    functionality/feature, provided at zero cost for a limited period to take the demo of the
                    apps published on the Appsstore.ai.
                    <li className="fw-bold m-t-sm">Servers</li>
                    Servers mean the technological infrastructure controlled by Appsstore.ai upon which the app
                    marketplace works and the subscription requests are stored.
                </ul>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Trial
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Users may sign up for a trial plan for a period of "14 days." Once the trial period ends, the
                    account will have to upgrade to a paid plan. Appsstore.ai reserves the right to terminate,
                    modify or cancel a limited trial or free plan at any time and for any or no reason.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Billing
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai uses a prepaid credit system and customers will be billed automatically via PayPal,
                    credit card or invoice when credits are over or insufficient to use the subscription. The bill
                    shall include a suitable fee for subscription, together with any additional costs that are
                    incurred as a result of frequent setups and more apps usage. Appsstore.ai will have the sole
                    right to determine whether additional costs are billed to the customer and if it is so, then the
                    bill shall not be charged higher than 20% above cost.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Invoices shall be sent to the customer at the beginning of a billing cycle via email and must be
                    paid within 30 days of the receipt. A late fee shall be charged at the rate of 1% per month on
                    undisputed overdue amounts.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    If undisputed amount payments are not received within 30 days, Appsstore.ai has the right to
                    suspend access to the service. If payments on undisputed amounts are not made within 60 days,
                    Appsstore.ai reserves the right to delete the customer account and subscription to apps
                    permanently.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Term and Termination
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    The agreement comes into effect on the date the account is created and will continue to be in
                    effect until terminated. This agreement will imply to the provided services after or on the
                    effective date. If Appsstore.ai agrees to offer its services after the end of the term, this
                    agreement shall apply to such services. Without harming to any other rights, Appsstore.ai may
                    stop customer's subscription and the licenses contained herein
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    <p><b>a.</b> If customers fail to comply with terms and conditions</p>
                    <p><b>b.</b> For any or no reason at any time</p>
                    <p>After the termination of the subscription, the customer's right to access the service shall
                        automatically terminate. In case of the termination,</p>
                    <p><b>a.</b> Customer shall not be authorized to get any credit(s) and refund(s),</p>
                    <p><b>b.</b> Any remaining balance for service provided through the date of termination will be due
                        and
                        payable in full.</p>
                    <p><b>c.</b> Customer's subscription to apps on the Appsstore.ai will no longer be available.</p>
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Cancellation and Refund Policy
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Customers agree that Appsstore.ai may charge to your credit card or other payment mechanism chosen
                    by you and approved by Appsstore.ai. Appsstore.ai may change prices at any time, including updating
                    from a free service to a paid service and hanging of services that were free of charge earlier.
                    Appsstore.ai will provide you with prior notice and an opportunity to terminate your Appsstore.ai
                    account if Appsstore.ai changes the price of apps and services you are subscribed and will not
                    charge you unless you have been notified and agreed to pay such fees.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Customers can cancel their subscription at any time. If they cancel, they will not be charged for
                    additional terms of services and the existing service will continue until the end of the
                    subscription term. If you cancel the subscription, you will get a refund for any service you already
                    paid for.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Return Policy
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai does not have any return policy as it does not involve the shipment of any physical
                    goods or products.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Installation/Setup
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    To use applications published on the Appsstore.ai, the customer must add the URL of the RTSP
                    camera to use different applications.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Sensitive Data
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Customers must comply with all laws (including, but not restricted to, data security and privacy
                    laws) and warrant that they will not use the services of Appsstore.ai to violate such laws.
                    Customers agree to exclude any sensitive or personally identifiable information from the
                    Appsstore.ai.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Account and Security
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    The customer is responsible for protecting the confidentiality of login credentials issued by
                    Appsstore.ai and for any misuse or use of the subscription resulting from unauthorized access.
                    Customers should agree to notify Appsstore.ai in case of a security breach or unauthorized
                    access to the service or account.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai may log in to the customer's account to troubleshoot technical issues or make
                    improvements from time to time. Customer consents to such access.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    No viruses or other interference
                </div>
                <div className="fs-16 m-t-sm">
                    User must not enable another person to transmit any virus or other information via the website
                    or use the website in a way that:
                </div>
                <ul className="fs-16 m-t-sm">
                    <li className="m-t-sm">Inhibits any other user from using the website</li>
                    <li className="m-t-sm">Makes unauthorized modifications to the website</li>
                    <li className="m-t-sm">Harasses, threatens or offends any person</li>
                    <li className="m-t-sm">Contains indecent, pornographic, obscene or inflammatory material that could
                        raise criminal
                        or civil proceedings.
                    </li>
                </ul>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Accuracy/Exactness not guaranteed
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai has made significant efforts to represent details of the applications and color of
                    the website accurately. But, we do not guarantee/warrant the content of the website is
                    error-free. Technical specifications and settings of your system could impact the accuracy of
                    colors displayed on the website.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Intellectual Property
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Intellectual property rights in the website, whether it is the domain name, design, text,
                    applications, graphics, software, or underlying source code, every asset belongs to Appsstore.ai
                    and its affiliates.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Copyright
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    All the content published on the website, including text, graphics, button icons, logos, images,
                    video clips and audio, is the property of Appsstore.ai, its affiliates, or its content
                    suppliers. Intellectual property laws protect the content.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Intellectual Property Claims
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai, its content suppliers, or its affiliates, respect the intellectual property of
                    others. If you think that your intellectual property has been used in a way that raises concerns
                    of infringement, please write to us and we will try to answer your queries.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Links
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    The website may provide links to different applications, websites, services for your convenience
                    and Appsstore.ai does not have control over the linked applications or websites. Some of the
                    links can be associated with the websites, applications/services provided by our affiliates.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai does not recommend third-party websites, though the website might contain
                    advertisements. Appsstore.ai does not assume any responsibility for the content or accessibility
                    of third-party websites.
                </div>
                <div className="m-t-sm fs-16 l-h-1-4">
                    Appsstore.ai shall not be for infringement of intellectual property rights of anyone on
                    third-party websites. Your use of those linked mobile apps, sites and services are subject to
                    privacy policies and terms of use of each such website or application.
                </div>
                <div className="font-weight-bold m-t-30-m l-h-2-m fs-25 mt-lg-5">
                    Redressal of Grievance
                </div>
                <div>
                    <ul className="fs-16 m-t-sm">
                        <li className="m-t-sm">Sending a request through email or writing, signed with an electronic
                            signature,
                            identifying the content alleged to be in infringement of your rights
                        </li>
                        <li className="m-t-sm">
                            Providing your contact info, including address, email and contact number where you can
                            be reached when required
                        </li>
                        <li className="m-t-sm">Providing a declaration cum undertaking along with essential documents
                            stating you:
                            <ul>
                                <li className="m-t-sm">As the owner of the content to be disabled/affecting you
                                    prejudicially
                                </li>
                                <li className="m-t-sm">As an affected person, asserting that the information provided is
                                    correct,
                                    accurate and complete. No material fact has been hidden and stating that
                                    Appsstore.ai, its affiliates, directors, employees, including the Grievance
                                    officer, shall not be liable for any damage/loss/claim for relying on such
                                    requests.
                                </li>
                            </ul>
                        </li>
                        <li className="m-t-sm">You can send your complaints or requests to <a href='mailto'>hello@appsstore.ai</a>.</li>
                    </ul>
                </div>
            </div>
          
        </div>
          <div className="m-t-250">
          {/* <FooterComponent/> */}
          <FooterComponent2/>
      </div>
      </>
    );
}

export default TermsComponent;
