import React from "react";
import { Link } from "react-router-dom";
import { pathConstants } from "../../constants";

function Commitment() {
  return (
    <div className="flex-col-md base-container px-120 md-px-24 xxl-px-120 xl-px-70 display-flex gap-34 justify-center">
      <div>
        <img
          src="/images/commitment.png"
          className="w-100-per-m"
          alt="world-map"
        />
      </div>
      <div className="m-t-55">
        <div className="flex-center-md fc-dark-slate-blue text-ft32-38 font-weight-bold">
          Our Commitment to Our Customers
        </div>
        <div className="text-align-md w-unset flex-center-md w-497 mt-4 fs-16 l-h-150 fc-dark-grey">
          Appsstore.AI we are committed to providing our customers with the
          highest quality and the most accurate AI skills possible in order to
          help them automate their business processes and grow their businesses
          more efficiently.
        </div>
        <Link to={`${pathConstants.CAMERA_SIGN_UP}`}>
          <div className="flex-center-md">
            <button className="fs-18 br-1-blue fc-4c84ff lg-bg-white bg-white cursor-pointer m-t-55 br-25 p-t-16 p-b-16 p-x-32">
              Start your Trial Now
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Commitment;
