import {sessionManager} from '../managers/sessionManager'
import {cookiesConstants, actionsConst} from '../constants'

let userData = sessionManager.getDataFromCookies(cookiesConstants.USER_DETAIL);
if (userData)
    userData = JSON.parse(userData);
const deviceID = sessionManager.getDataFromCookies(cookiesConstants.DEVICE_ID);
let initialState = {
    userDetails: userData,
    isLoggedIn: !!userData,
    loginFailure: null,
    deviceID: deviceID,
    sessionToken: null,
    isLoading: false,
    ip: null,
    rtspUri: null,
    cameraAdd: "",
    madDetails: null,
    cameraDetails: {},
    connectedMachine: [],
    selectedApp: {},
    upcomingApps:[],
};
export default function user(state = initialState, action) {
    switch (action.type) {
        case actionsConst.HIDE_LOADER:
            return {
                ...state,
                loading: false
            };
        case actionsConst.SHOW_LOADER:
            return {
                ...state,
                loading: true
            };
        case actionsConst.GET_STARTED_IP:
            return {
                ...state,
                ip: action.data.ip
            };

        case actionsConst.RTSP_URI:
            return {
                ...state,
                rtspUri: action.data.rtspUri
            };

        case actionsConst.DEVICE_ID:
            return {
                ...state,
                deviceID: action.data.deviceID
            };
        case actionsConst.USER_AUTH_CALLBACK:
            return {
                ...state,
                userDetails: action.data.userDetails,
                isLoggedIn: true
            };

        case actionsConst.LOGOUT_USER:
            return {
                ...state,
                userDetails: null,
                isLoggedIn: false
            };

        case actionsConst.CAMERA_ADD:
            return {
                ...state,
                cameraAdd: action.data.firstCamera
            };
        case actionsConst.MAD_DETAILS:
            return {
                ...state,
                madDetails: action.data.madDetails
            };
        case actionsConst.CAMERA_EDIT:
            return {
                ...state,
                cameraDetails: action.data.cameraDetails
            };
        case actionsConst.USER_CONNECTED_MACHINE:
            return {
                ...state,
                connectedMachine: action.data.connectedMachine
            };
        case actionsConst.SELECTED_APP:
            return {
                ...state,
                selectedApp: action.data.selectedApp
            };
        case actionsConst.UPCOMING_APPS:
            return {...state,upcomingApps:action.data}

        default:
            return state;
    }
}
