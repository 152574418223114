import React from "react";
import Form from "./form";

const FormAndCCTV = () => {
  return (
    <>
      <div className="display-grid px-120 md-px-24 gap-24 m-t-100 justify-between grid-cols-2 lg-grid-cols-1 base-container grid-gap-align-div xxl-px-120 xl-px-70">
        <div className=" md-px-24 m-t-60">
          <div className="max-w-686 min-w-110">
            <div className="font-lato text-ft32-40 font-bold lg-text-center">
              What is AI Based theft Detection using a CCTV Camera
            </div>
            <div className="fs-18-27-400-lato fc-warm-grey m-t-20 lg-hidden ">
              Analyze live video footage from CCTV cameras to detect suspicious
              activity that could indicate theft. An AI system analyzes the
              footage in real time and alerts security personnel or authorities.
            </div>
          </div>
        </div>
        <div className="lg-grid lg-place-items-center lg-mt-16">
          <img
            className="md-w-full w-101-per max-h-420"
            src="/images/itemsStack.png"
            alt=""
          />
        </div>
        <div className="font-lato fc-warm-grey m-t-20 lg-block fs-16 display-none text-align-center ">
          Analyze live video footage from CCTV cameras to detect suspicious
          activity that could indicate theft. An AI system analyzes the footage
          in real time and alerts security personnel or authorities.
        </div>
      </div>

      <div className="m-t-120 display-grid px-120 gap-24 md-gap-0 md-px-0 grid-cols-2 md-mt-36 lg-grid-cols-1 h-460 lg-h-auto base-container xxl-px-120 xl-px-70">
        <div className="grid-place-items-center md-px-24">
          <div className="font-lato fs-22 font-bold text-align-center">
            Secure your Premises with AI, Now!
          </div>
          <video
            controls
            disablePictureInPicture
            muted
            controlslist="nodownload noplaybackrate nofullscreen"
            width="100%"
            className="br-30"
          >
            <source src="https://apps-store-ai.s3.amazonaws.com/app-directory/videos/theft+detection-video.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="max-w-686 lg-max-w-none md-mt-14 m-t-64">
          <Form />
        </div>
      </div>
    </>
  );
};

export default FormAndCCTV;
