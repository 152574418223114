import React, { Component } from "react";
import ReactGA from 'react-ga';

export default class BaseComponent extends Component {
    // && !window.location.host.startsWith("localhost")
    constructor(props) {
        // if (!window.location.host.startsWith("www") && !window.location.host.startsWith("localhost")){
        //     window.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
        // }
        super(props);
        ReactGA.initialize('UA-1212612-8');
        ReactGA.pageview(window.location.pathname + window.location.search);

        window._mfq = window._mfq || [];
        let mf = document.createElement("script");
        mf.type = "text/javascript";
        mf.defer = true;
        mf.src = "https://cdn.mouseflow.com/projects/fc8de72d-6f2f-4552-bc65-9137f4eb6af6.js";
        document.getElementsByTagName("head")[0].appendChild(mf);


        // window.$crisp=[];
        // window.CRISP_WEBSITE_ID="760e45f7-55e0-4cfb-99e4-5c12e7e75215";
        // let d=document.createElement("script");
        // d.src="https://client.crisp.chat/l.js";
        // d.async=1;
        // document.getElementsByTagName("head")[0].appendChild(d);
    }
}