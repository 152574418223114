export const httpConstants = {
    METHOD_TYPE: {
        POST: 'POST',
        PUT: 'PUT',
        GET: 'GET',
        DELETE: 'DELETE',
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: 'application/json',
        MULTIPART_FORM_DATA: 'multipart/form-data',
        APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
        IMAGE_PNG: 'image/png'
    },
    DEVICE_TYPE: {
        WEB: 'web'
    },
    API_END_POINT: {
        RTSP_PROXY: "rtsp-proxy",
        ADD_CONNECTED_DEVICE: "add-connected-devices",
        GET_ENTITY_LIST_BY_QUERY_OBJ: "get-entity-list-by-query-obj",
        ENTITIES: "entities",
        GET_RTSP: "stream-rtsp",
        GET_ENTITY_LIST: "get-entity-list",
        COMPANY_DETAILS: "/company",
        ADD_COMPANY_DETAILS: "/add-company",
        GET_LENDERS_LIST: "/add-relation",
        GET_RELATION: "/get-relation",
        GET_COMPANY_BASED_ON_ITS_TYPE: "/company",
        UPDATE_ENTITY_BY_QUERY_OBJ: "update-entity-by-query",
        PUBLISH_MEDIA_CLIENT_DATA: 'publish-media-client-data',

        //RECORD_SERVICE_END_POINT
        ADD_RECORD_DETAILS: "/add-record",
        RECORD_DETAILS: "/record/",
        SECTION_DETAILS: "/section/",
        QUESTION_DETAILS: "/question/",
        RECORD_LIST: "/record-list",
        SECTION_LIST: "/section-list/",
        QUESTION_LIST: "/question-list/",
        GET_MACHINE_STATUS: "get-local-machine-status",

        //USER_SERVICE_END_POINTS

        REGISTER_DEVICE: '/register-device',
        REGISTER_USER: '/register-user',
        LOGIN: '/login',
        LOGOUT: '/logout',
        GET_ROLE_LIST: '/get-role-list',
        GET_USER_DETAILS: '/get-user-details',
        GET_USER_LIST: '/get-users',
        GET_USER_LIST_BY_QUERY_OBJ: '/get-user-list-by-queryObj',
        RESET_PASSWORD: '/reset-password',

        //INVITE_SERVICE_END_POINTS

        INVITE_USER: '/invite-user',

        //APP_DIRECTORY_SERVICE
        GET_APP_DETAILS: "get-entity-detail/",

        //MAD_SERVICE
        ADD_MAD: "saveMAD",
        CREATE_ORDER: "create-order",
        GET_ORDER: "get-order",
        UPDATE_ORDER: "update-payments",
        GET_MAD: "getMAD/",
        GET_USER_MAD: "getUserMAD/",
        UPDATE_MAD: "updateMAD",
        UPDATE_MAD_FRAMES: "updateMADFrames",
        UPDATE_MAD_INTERNAL_USAGE: "updateMADUsageAndSchedule",
        DELETE_MAD_BY_ID: "deleteMADByID/",
        UPLOAD_VIDEO: "upload-file",
        CREATE_PAYMENT_LINK: "createPaymentLink",
        GET_DEVICE_SCHEDULE: "getDeviceSchedules",
        ADD_MAD_SCHEDULES: 'madSchedule',
        UPDATE_CONNECTED_DEVICE_DETAILS: 'updateConnectedDeviceDetails',
        REMOVE_DEVICE_SCHEDULE: 'removeDeviceSchedule',
        ADD_MAD_SCHEDULE: 'madSchedule',
        GET_IMAGE_FROM_CAMERA: 'publishData',
        DELETE_CAMERA: '/delete-entity/',
        GET_JWT_TOKEN: 'getJWTToken/',

        //developer-entity service
        ADD_ENITITY:"add-entity",
        ADD_CONTACT:"add-contact",

        // ATTENDANCE_SYSTEM_SERVICE
        GET_DATA: "get-data",
        PREDICT: "predict",
        TRAIN: "train",
    }
};

export const genericConstants = {
    MIME_TYPE: {
        IMAGE: "image",
        VIDEO: "video"
    }
};

export const actionsConst = {
    GET_STARTED_IP: "GET_STARTED_IP",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    RTSP_URI: 'RTSP_URI',
    DEVICE_ID: 'DEVICE_ID',
    USER_AUTH_CALLBACK: 'USER_AUTH_CALLBACK',
    LOGOUT_USER: 'LOGOUT_USER',
    CAMERA_ADD: 'CAMERA_ADD',
    MAD_DETAILS: 'MAD_DETAILS',
    CAMERA_EDIT: 'CAMERA_EDIT',
    USER_CONNECTED_MACHINE: 'USER_CONNECTED_MACHINE',
    SELECTED_APP: "SELECTED_APP",
    APP_ID: "APP_ID",
    UPCOMING_APPS:"upcomingApps"
};

export const apiSuccessConstants = {
    UPDATE_FRAME_RATE: "Update Frame settings successfully",
};

export const apiFailureConstants = {
    GET_APP_DETAIL: "Unable to fetch app details!",
    GET_USER_MAD: "Unable to get user selected apps!",
    GET_USER_DEVICES: "Unable to get user Devices!",
    UPDATE_FRAME_RATE: "Unable to update frame settings!",
    ADD_MAD: "Unable to add mad!",
    GET_UPCOMING_APPS: "Unable to fetch upcoming apps!",
    VIDEO_ADD: "No Video Added",
    CAMERA_ALREADY_EXISTS: "Camera already exists with same URL",
    CAMERA_DETAILS_UPDATED: "Camera details updated successfully",
    UNABLE_TO_FETCH_URL: "Unable to fetch url",
    PAYMENT_NOT_DONE: "Payment not done",
    IMAGE_GET_SUCCESS: 'Image get successfully',
    NO_ENTITY_DATA: "No entity data found",
    LOCAL_SERVER_FAILURE_MSG: "Local Server is not set up for user. Please set up local server.",
    DEVICE_NOT_ADDED: "device not added",
    CAN_NOT_CONNNECT_TO_CAMERA: "Cannot Connect to the Camera. Please check the settings",
    USER_APP_NOT_REGISTERED: "no app is purchased by user",
    NOT_LOGIN: "Please login to use device camera",
    MACHINE_DETAILS_UPDATED: "Machine details updated successfully",

};


export const pathConstants = {
    HOME: '/',
    CAMERA_SIGN_UP: '/camera-sign-up',
    MARKET_PLACE: '/market-place',
    APP_DETAILS: '/app-details/',
    APP_INPUT: '/app-input/',
    MY_APPS: '/my-apps/:appName',
    MY_CAMERAS: '/my-cameras',
    COST_CALCULATOR: '/cost-calculator/',
    ABOUT_US: '/about-us',
    DEVELOPER: '/developer',
    MEDIA_CLIENT: '/client-download',
    CONNECT_TO_LOCAL_SERVER: '/connect-to-local-server',
    PRIVACY: '/privacy',
    TERMS: "/terms",
    CONNECTED_MACHINE: '/connected-machine',
    DEVICE_CAMERA: '/device-camera',
    USER_DEVICES: '/user-device',
    DEVICE_CAMERA_SIGN_UP: '/device-camera-sign-up',
    THEFT_DETECTION :'/theft-detection',
    FACE_RECOGNITION :'/face-recognition',
};

export const appStoreSettingConstants = {
    FD: "FD",
    VIDEO: "VIDEO",
    FREE_TRIAL: "Sign Up Now for 30-Day Free Trial. Enjoy Access to the Presence System AI skills. Cancel Anytime During Your Trial. Start Today! No hidden fees. No automatic payment deduction on Credit Card"
};

export const cookiesConstants = {
    DEVICE_ID: 'deviceId',
    DEVICE_NAME: 'deviceName',
    DEVICE_USERNAME: 'deviceUserName',
    DEVICE_PASSWORD: 'devicePassword',
    DEVICE_RTSP: 'deviceRTSP',
    AUTH0_ACCESS_TOKEN: 'auth-access-token',
    AUTH0_ID_TOKEN: 'auth-id-token',
    USER_DETAIL: 'user-detail',
    EMAIL_ID: 'email-id',
    USER_ID: 'user-id',
    PASSWORD: 'password',
    SESSION_TOKEN: 'sessionToken',
    USER: 'user',
    MAD_ID: 'madID',
    USER_LOCAL_IP_IMAGE_URL: 'user local ip image url',
    ENTITY_DETAILS: 'entity detail',
    JWT_TOKEN: 'jwt-token'
};

export const tooltipConstant={
    APP_DETAILS:"Details about of your application",
    APP_NAME:"Name of your Application",
    PRICE:"The price needs to be in 10000/100000 hits price. per 10000 hits …. per 100000 hits The price of your App on which you will like to sell you App to potential clients",
    VERSION:"The latest version of you App",
    EMAIL:"Please give your customer service email",
    DEVELOPER:"Developer Name",
    COMPATIBILITY:"Please select the compatibility of camera required for functioning of your App. If it select multiple than select multiple.",
    CATEGORY:" Select the Category of your App",
    LANGUAGES:"Select the languages in which your application is available.",
    APP_ROUTES:"Route on which app will be accessible on Appsstore",
    WEBSITE_LINK:"The URL of your Company’s website. If you are an independent developer than please give the URL of you social profile.",
    ACTIVE_INPUT:"Input url of your app",
    ACTIVE_OUTPUT:"Report/Output url of your app",
    OVERVIEW:"Write an overview for your application. Maximum 150 words",
    DESCRIPTION:"Write a detailed description for your application explaining about its utility and and points to remember.(Max 500 words)",
    APP_IMAGE:"Provide a 200 by 300 pixel image representation of your Application",
    LOGO:"Provide a Logo for your application",
}

export const creditConstants = {
    CAPTURING_FREQUENCY: 'Smaller the frequency, higher the credit usages',
    TIME_PERIOD: "9 hours daily for 5 days of the week per month"
};

export const videoUploadMessage = {
    MESSAGE: "It will take a few minutes before the faces of the people not wearing mask is visible on the dashboard."
};

export const appQueryInformation = {
    MESSAGE: 'Face mask app looks for people who are not wearing a mask. Sometime it takes time to update the faces here. For any queries contact developer at '
};

export const localPort = {
    PORT: ':3200'
};

export const localStorageConstants = {
    ENTITY_DETAILS: 'Entity Detail'
};
export const socialDistancingAppID = "5eea1ba8657cea4170f6939f";

export const socialMediaConstant={
    LINKED_IN:"https://www.linkedin.com/company/appsstoreai",
    INSTAGRAM:"https://www.instagram.com/appsstore.ai/",
    TWITTER:"https://twitter.com/appsstoreai",
    YOUTUBE:"https://www.youtube.com/@appsstore_ai",
    FACEBOOK:"https://www.facebook.com/appsstoreai",
}

export const errorConstant={
    APP_DETAILS:"App Details is required",
    APP_NAME:"App name is required",
    PRICE:"Price is required",
    VERSION:"Version is required",
    EMAIL:"Email is required",
    DEVELOPER:"Developer is required",
    COMPATIBILITY:"Compatibility is required",
    CATEGORY:"Category is required",
    LANGUAGES:"Language is required",
    APP_ROUTES:"App name is required",
    WEBSITE_LINK:"Website Link is required",
    ACTIVEINPUT:"Input link is required",
    ACTIVEOUTPUT:"Output link is required",
    OVERVIEW:"Overview is required",
    DESCRIPTION:"Description is required",
    APP_IMAGE:"App image is required",
    LOGO:"Logo image is required",
    NAME:"Name is required",
    EMAIL:"Email is required",
    DESCRIPTION:"Description is required",
    EMAIL_VALID:"Email  is not valid"

}
export const howItsWorkConstant={
    
        STEPS: [
          {
            index: "01",
            text: "Visit AppsStore.ai and add your IP camera",
            image: "/images/work-one.svg"
          },
          {
            index: "02",
            text: "Choose from 100s of computer vision apps",
            image: "images/work-two.svg"
          },
          {
            index: "03",
            text: "Get instant result",
            image: "images/work-three.svg"
          }
        ]
}

export const faqsConstant={
    faqsData:[
        {
            index:1,
            question:"Which type of Camera can I connect to enable AI on them?",
            showFullText:false,
            answer:" Any type of camera which is connected to the internet can be connected to the AppsStore.ai dashboard. Once connected to the AppsStore you can select which AI App you would like to be used on your CCTV camera and start monitoring your camera feed using AI for various use cases like theft detection, Face Detection, Automatic attendance,  Weapon detection, Driver alert notification, Retail Analytics, Fire detection, etc."
        },
        {
            index:2,
            showFullText:false,
            question:"What can I do after connecting my CCTV camera?",
            answer:"Once connected to the Live stream of your camera, you have the option to select any App from the given AI Apps. These Apps will start analyzing the feed for their particular use case and send you an alert in case of the occurrence. For example, if you have selected the AI App for Theft Detection, our AI system will start analyzing the feed for any thief or shoplifters and send an alert to the registered person in case of any occurrence of the event." 
        },
        {
            index:3,
            showFullText:false,
            question:"Can AI Apps be used for commercial or industrial applications?",
            answer:" These AI apps are enterprises level applications which are designed to identify the objects, humans and scenarios in all type of conditions. All businesses looking to automate their tasks like inspections, Employee Safety Monitoring, Theft Monitoring etc should use these apps to increase productivity and efficiency. Our team of experts can help you to customize any of these applications as per your specific business needs."
        },
        {
            index:4,
            showFullText:false,
            question:"Can the AI identify specific individuals or objects?            ",
            answer:" Yes, AI Can identify specific objects and individuals very precisely. Our advanced AI Apps can identify specific scenarios or conditions as well as - Depth Detection, crop analysis, cattle identification, Fake Identification, X-ray Analysis, etc. Apart from hundreds of already trained models, we can train further models as per the specific need of the business.             "
        },
        {
            index:5,
            showFullText:false,
            question:"How does AI detect potential threats?",
            answer:"The AI Apps are advanced computer vision apps based on smart machine learning. All our AI Apps are trained on millions of data sets and are set to deliver nearly 99% of Accuracy. With this accuracy the AI Apps, when connected to a CCTV camera detect any object or human in accordance to their model and send the alert to the person responsible. "
        },
        {
            index:6,
            showFullText:false,
            question:"How many CCTVs can I connect to enable AI on them?",
            answer:" You are free to add as many cameras as you wish to add to the dashboard. You can select multiple AI Apps for multiple cameras. "
        },
        {
            index:7,
            showFullText:false,
            question:"Do I get a free trial of the services?",
            answer:"Yes, we have a free trial of every AI App. certain credits are given as per the app and you can try using the AI application for your business."
        },
        {
            index:8,
            showFullText:false,
            question:"Can I customize the types of alerts?            ",
            answer:"Yes, all the alerts and their frequency can be customized on the dashboard. Currently, appsstore supports email notifications, Mobile notifications will be live soon.  "
        },
        {
            index:9,
            showFullText:false,
            question:"Can I access the AI on multiple devices?",
            answer:"The appsstore dashboard to see the analytics from the camera feed and update the settings for the AI apps can be accessed from multiple devices as it is a login-based system."
        },
        {
            index:10,
            showFullText:false,
            question:"Can I view past footage or recordings?",
            answer:" No, the past recordings can not be viewed however we keep the image of the time when the occurrence is detected. These can be accessed using the dashboard."
        },
        {
            index:11,
            showFullText:false,
            question:"How are my data and privacy protected when using AI?",
            answer:"We have very strict guidelines for data protection and privacy. All you data is highly safe with us as we comply with data protection. To know more about the same, check our privacy policy."
        },
        {
            index:12,
            showFullText:false,
            question:"Can the AI be integrated with other security systems, such as alarms or Battery Management Systems or Operators?",
            answer:" The dashboard of Appsstore.ai does not support integration with other systems as a plug-and-play option, however in case of a specific business needs our AI experts can help you to create a customized solution."
        },
    ]
}

export const footerDataConstant={
   privacyTerms:[
    { name: "Privacy policy", to: "/privacy" },
    { name: "Terms of use", to: "/terms" },
  ],
    footerData: [
        {
          index: 1,
          firstData: [
            {
              name: "About",
              to: "/about-us",
              isBlank: false,
            },
            {
              name: "Developer",
              to: "/developer",
              isBlank: false,
            },
            {
              name: "Blogs",
              to: "https://blog.appsstore.ai/",
              isBlank: true,
            },
          ],
        },
        {
          index: 2,
          firstData: [
            {
              name: "PPE Detection",
               to: "/app-details/639719b0329ec061d4ae557b",
              isBlank: false,
            },
            {
              name: "Theft Detection in Retail",
                  to: "/app-details/63ad3a90411783469a0a73d4",
              isBlank: false,
            },
            {
              name: "Presence System",
                  to: "/app-details/5dcfcc1af5b5a9f53b6d1ecf",
              isBlank: false,
            },
            {
              name: "Automate No. plate recognition",
              to: "/app-details/6025207c36e51619a39a4388",
              isBlank: false,
            },
          ],
        },
        {
          index: 3,
          firstData: [
            {
              name: "Smart Meeting Reminder",
              to: "/app-details/5dcbf82aef7aed1728ce8aef",
              isBlank: false,
            },
            {
              name: "Retail Analytics",
              to: "/app-details/5df37e90d0db1606ac7ce029",
              isBlank: false,
            },
            {
              name: "Car Damage Detection for insurance",
              to: "/app-details/63a02ddaa29882d40c9190dc",
              isBlank: false,
            },
          ],
        },
      ],
}
export const aboutPageConstant={
   firstImages: [
    {to:"/app-details/5df37a6ed0db1606ac7ce011",img: "/images/live-id.png",},
    {to:"/app-details/5df37badd0db1606ac7ce019",img:"/images/eye-track.png"},
    {to:"/app-details/5dcfcc1af5b5a9f53b7d8edf",img:"/images/mask-alert.png"},
    {to:"/app-details/5eea1ba8657cea4170f6939f",img:"/images/social-distancing.png"},
    {to:"/app-details/5dcfcc1af5b5a9f53b6d1ecf",img:"/images/system.png"},
    {to:"/app-details/6025207c36e51619a39a4388",img:"/images/replace-plate.png"},
  ],
   secondImages: [
    {to:"/app-details/5df37577d0db1606ac7ce00a",img: "/images/retail-sense.png",},
    {to:"/app-details/5df37ff6d0db1606ac7ce02f",img:"/images/sport-eye.png"},
    {to:"/app-details/5df38100d0db1606ac7ce031",img:"/images/football.png"},
    {to:"/app-details/63ad3a90411783469a0a73d4",img: "/images/theaf.png",},
    {to:"/app-details/5df3830fd0db1606ac7ce037",img:"/images/track.png"},
    {to:"/app-details/5df37ec0d0db1606ac7ce02a",img:"/images/track-count.png"},
  ]
}