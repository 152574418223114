import React from "react";
import {connect} from "react-redux";
import BaseComponent from '../baseComponent'
import {dispatchAction} from "../../utility";
import {Row, Column} from 'simple-flexbox';

class FooterComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {

    }

    render() {
        let pages = (window.location.href.split("//")[1]).split('/')[1];
        let alignCenter = pages === "" && pages.charAt(0) === "#" && pages === "about-us" ? "" : "mr-auto ml-auto"
        let mlnone = pages !== "" && pages.charAt(0) !== "#" && pages !== "about-us" ? "m-l-0" : "m-l-60"
        return (
            <div className="footer m-t-600-m">
                <Row className="display-none-m justify-content-between align-items-center">
                    <div className='display-flex align-items-center'>
                        <span><a href="/terms">Terms</a></span>
                        <span><a href="/privacy" className="m-l-30">Privacy</a></span>
                    </div>


                    <Column className={alignCenter}>
                    <span className={mlnone}>©2022 AppsStore.ai | <a
                        href="mailto:support@appsstore.ai">hello@appsstore.ai</a></span>
                    </Column>

                    {pages !== "" && pages.charAt(0) !== "#" && pages !== "about-us" ? "" :
                        <Column>
                            <span>Unitech Cyber Park, Sector 39, Gurgaon, Haryana 122002</span>
                        </Column>}
                </Row>
                <div className="display-none-desktop">
                    <Row className="jc-space-between terms-privacy-footer">
                        <span className="fs-13 p-l-xs"><a href="/terms">Terms</a></span>
                        <span className="fs-13 p-r-xs"><a href="/privacy">Privacy</a></span>
                    </Row>
                    {pages !== "" && pages.charAt(0) !== "#" && pages !== "about-us" ? "" :
                        <Row className='justify-content-center m-t-xs'>
                            <span className="fs-13">Unitech Cyber Park, Sector 39, Gurgaon, Haryana 122002</span>
                        </Row>}
                </div>
                <Row className="justify-content-center display-none-desktop">
                   <span>©2022 AppsStore.ai | <a
                       href="mailto:support@appsstore.ai">hello@appsstore.ai</a></span>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(FooterComponent);
