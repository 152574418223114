import React from 'react';
import {Column, Row} from "simple-flexbox";
import Iframe from "react-iframe";
import {pathConstants, socialDistancingAppID} from "../../constants";
import Checkbox from '@material-ui/core/Checkbox';
import Switch from "react-switch";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import PulseLoader from "react-spinners/PulseLoader";
import {Dialog} from "@material-ui/core";
import Webcam from "react-webcam";
import CheeseburgerMenu from "cheeseburger-menu";
import {Handles, Rail, Slider, Tracks} from "react-compound-slider";
import {Handle, SliderRail,Track} from "../appInput/sliderComponent";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/core";


const override = css`
  margin-left: 15px;
`;


function renderCameraView(props) {
    return (
        <Row className='h-100-vh'>
            {props.state.menuOpen ?
                <Column className='display-none-m w-300 overflow-auto '>
                    <div className="display-flex jc-space-between align-item-center m-t-35 p-r-xs p-l-sm">
                        <span className="">CAMERAS</span>
                        <img src="/images/close-menu.png" height="30px" width="30px" onClick={() => {
                            props.toggleMenu()
                        }} className={"cursor-pointer"} alt="" />
                    </div>
                    <div>
                        {props.cameraList.map((cameraObject, index) => {
                            return renderAllCameras(props, cameraObject, index);
                        })}

                        <div className="cursor-pointer m-l-sm m-r-sm p-md m-t-sm app m-b-20"
                             onClick={() => props.addAnotherDevice(pathConstants.USER_DEVICES)}>
                            <img src='/images/plus.png' className="offset-5 w-30-px"
                                 alt='add-device'/>
                        </div>
                    </div>
                </Column> :
                <Column className=" m-t-35 display-none-m overflow-auto">
                    <Row className="align-item-center p-l-sm ">
                        CAMS<img src="/images/open-menu.png" height="30px" width="30px" onClick={() => {
                        props.toggleMenu()
                    }} className={"cursor-pointer"} alt=""/>
                    </Row>
                    <div>
                        {props.cameraList.map((cameraObject, index) => {
                            return onMenuClose(props, cameraObject, index);
                        })}

                        <div className="display-flex cursor-pointer p-sm m-t-xs m-l-sm w-62px h-63 app"
                             onClick={() => props.addAnotherDevice(pathConstants.USER_DEVICES)}>
                            <img src='/images/plus.png' className="margin-auto w-30-px h-30"
                                 alt='add-device'/>
                        </div>
                    </div>
                </Column>}
            <Column className="display-none-desktop display-none-m m-t-35 overflow-auto">
                <Row className="align-item-center">
                    <span className="margin-auto fs-14">CAMERAS</span>
                    {/* <img src="/images/open-menu.png" height="30px" width="30px" onClick={() => { props.toggleMenu() }} /> */}
                </Row>
                <div>
                    {props.cameraList.map((cameraObject, index) => {
                        return onMenuClose(props, cameraObject, index);
                    })}

                    <div className="display-flex cursor-pointer p-sm m-t-xs m-l-sm w-62px h-63 app"
                         onClick={() => props.addAnotherDevice(pathConstants.USER_DEVICES)}>
                        <img src='/images/plus.png' className="margin-auto w-30-px h-30"
                             alt='add-device'/>
                    </div>
                </div>
            </Column>
            <div className='b-vertical-line display-none-m'/>
            <Column className='flex-grow-1 w-50-per  m-t-20 m-t-0-m p-sm'>
                {/*{props.deviceMAD && props.deviceMAD.length > 0 ?*/}
                <Row className="display-none-desktop m-t-15 align-item-center">
                    <img src="/images/open-menu.png" height="35px" width="40px"
                         className="cursor-pointer display-none-desktop" onClick={props.handleMobileMenu} alt=""/>
                    <Row className="jc-space-between w-100-per-m">
                        <span className="fw-bold fs-16 m-l-20">Connected Apps</span>
                        <span className="fs-16 fc-dark-grey">To see the reports open <a
                            href="https://www.appsstore.ai/my-apps/:appName">Dashboard</a></span>
                    </Row>
                </Row>
                <Row className="jc-space-between display-none-m">
                    <span className="fw-bold fs-16 m-l-20">Connected Apps</span>
                    <span className="fs-16 fc-dark-grey">To see the reports open <a
                        href="https://www.appsstore.ai/my-apps/:appName">Dashboard</a></span>
                </Row>
                <Row className="p-md m-r-15 align-item-center m-t-sm display-none-m">
                    <div className="display-none-m">
                        <Checkbox
                            className="fc-brownish-grey"
                        /></div>
                    <label className="fs-16 text-align-center fc-dark-grey w-20">App Icon</label>
                    <label className="fs-16 fc-dark-grey w-40">App Name</label>
                    <label className="fs-16 text-align-center fc-dark-grey w-15 ml-auto">Status</label>
                </Row>
                {/*: ''}*/}
                <Row
                    className="display-none-desktop fs-16 fc-dark-slate-blue m-r-20 fw-bold m-t-15 jc-space-between align-item-center">{props.cameraName}{"\n"}
                    {editComponent(props)}
                </Row>
                {props.userMADDetails && props.userMADDetails.length > 0 ? props.userMADDetails.map((mad) => {
                    return renderMAD(props, mad);
                }) : ""}
                {/*props.state.ffmpegFailureCount >= 10 ? '' :*/}
                {/*<span className="text-align-center"><a className="fs-15 m-l-sm m-t-sm text-align-center"*/}
                {/*                                       href="/my-apps">Connect an app </a>to this Camera</span>}*/}
            </Column>

            <div className="h-fit-content-m w-100-per-m display-none-m">
                {props.state.executingServer === 'cloud' ?
                    <Column className="flex-grow-1 p-3">
                        <Column>
                            <Row className={"jc-space-between align-item-center"}>
                                <span className="fs-16 fc-dark-grey m-r-20 w-90">{props.cameraName}</span>
                                {editComponent(props)}
                            </Row>
                            {props.state.ffmpegFailureCount >= 10 ?
                                <div className="fc-red m-t-20">Unable to connect. Please edit the camera settings and
                                    credentials.</div> :
                                <div>{LiveFeeds(props)}</div>
                            }
                        </Column>
                    </Column> : props.state.executingServer === 'local' ?
                        <Column className="p-20">
                            <Row className="align-item-center">
                                <span className="fs-16 fc-dark-grey m-r-20 w-90">{props.cameraName}</span>
                                <Row className="align-items-center flex-lg-row-reverse">
                                    <Row className='b-1-ccc h-30 w-30-px justify-center'>
                                        <img src='/images/settings.svg' alt='settings'
                                             onClick={() => props.editCameraDetails()}
                                             className="cursor-pointer"/>
                                    </Row>
                                    <Row className='b-1-ccc h-30 w-30-px justify-center'>
                                        <img src='/images/delete.png' className="cursor-pointer margin-auto h-20-px"
                                             onClick={() => props.handleConfirmation()}
                                             alt='add-device'/>
                                    </Row>
                                </Row>
                            </Row>
                            {props.state.ffmpegFailureCount >= 10 ?
                                <div className="h-200 w-300 justify-center m-t-sm fc-red">Unable to connect. Please edit
                                    the
                                    camera
                                    settings and credentials.</div> :
                                <Row className="b-1-ccc h-200 w-300 justify-center m-t-30">
                                    {props.state.image ?
                                        <img alt='deviceRecentImage' src={props.state.image}
                                             className="w-100-per"/> : ''}
                                </Row>
                            }
                        </Column> : ''}
                <Row className="display-none-desktop">
                    {props.userMADDetails && props.userMADDetails.length > 0 ? props.userMADDetails.map((mad) => {
                        return renderMAD(props, mad);
                    }) : ""}
                </Row>
            </div>
            {props.state.executingServer === 'webCam' ?
                <div className="w-350 w-100-per-m display-none-m">{webCamComponent(props)}</div> : ''}
        </Row>
    );
}

function renderMAD(props, mad) {
    let checked = false;
    let internalUsage = (mad.internalUsage) ? mad.internalUsage : "";
    let appstoreSettingType = mad.appMeta.appStoreSettingType;
    let remainingCredits = internalUsage ? internalUsage[appstoreSettingType]?.remainingCredits : "-";
    let allocatedCredits = internalUsage ? internalUsage[appstoreSettingType]?.allocatedCredits : "-";
    let marginRight = props.state.executingServer === 'local' ? 'm-r-25' : "";
    let connectedDeviceToMad = mad.connectedDevice.find(device => device.deviceID === props.state.deviceID);
    if (connectedDeviceToMad && Object.keys(connectedDeviceToMad).length > 0) {
        checked = true;
    }
    return (
        <Row
            className={"fs-16 fc-whitish-grey align-item-center m-t-20-m w-100-per-m m-l-20 m-l-0-m m-b-20 p-t-xs p-b-xs b-1-grey cursor-pointer " + marginRight}>
            <Row className="display-none-m w-100-per align-item-center">
                <div className="">
                    <Checkbox
                        className="fc-brownish-grey display-none-m"
                    /></div>
                <div className="fs-16 justify-center display-flex w-20">
                    <img className="max-w-75" src={mad.appMeta.logo} alt=""/>
                </div>
                <Row
                    className="fs-16 w-65 align-item-center jc-space-between">
                    <span className="fs-16 w-fit-content">{mad.appMeta.appName} </span>
                    {mad.appMeta.appID === socialDistancingAppID && checked && mad.appMeta.appName === "Social Distancing" ?
                        <div className="w-50-per m-l-sm">
                            {thresholdSliderComponent(props, connectedDeviceToMad)}
                        </div> : ""}</Row>
                {/* <label className="fs-16 text-align-center w-20">{mad.appMeta.amount}</label> */}
                <div className="fs-16 display-flex align-item-center jc-space-between w-15 ml-auto">
                    <Switch checked={checked}
                            onChange={() => props.handleChange(checked, props.deviceID, mad)}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={'#4c84ff'}
                    />
                    <div onClick={() => {
                        props.navigateToApps()
                    }} className="h-30 w-30-px m-r-20">
                        <img src="images/right-chevron.png" height="20px" className="m-l-10 w-15-px" alt=""/>
                    </div>
                </div>
            </Row>
            <Row className="display-none-desktop align-item-center w-100-per m-l-xs m-r-sm p-sm h-150-px-m">
                <Column className="m-b-80-m-">
                    <img className="" src={mad.appMeta.logo} height="60px" alt="" />
                </Column>
                <Column className="m-l-20">
                    <Row>
                        <label className="fs-16 m-b-50-m-">{mad.appMeta.appName}</label>
                    </Row>
                    <Column
                        className="fs-16 w-65 align-item-center jc-space-between m-l-35">
                        {mad.appMeta.appID === socialDistancingAppID && checked && mad.appMeta.appName === "Social Distancing" ?
                            <div className="w-180 m-t-min-25-m">
                                {thresholdSliderComponent(props, connectedDeviceToMad)}
                            </div> : ""}</Column>

                    <Row className="m-t-xs m-t-20-m">
                        <Switch checked={checked}
                                onChange={() => props.handleChange(checked, props.deviceID, mad)}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor={'#4c84ff'}
                        />
                    </Row>

                </Column>
                <Column className="ml-auto w-fit-content">
                    <div onClick={() => {
                        props.navigateToApps()
                    }} className="h-30 w-30-px">
                        <img src="images/right-chevron.png" height="20px" className="m-l-10 w-15-px" alt=''/>
                    </div>
                </Column>
            </Row>

        </Row>

    )
}


function LiveFeeds(props) {
    return (
        <div>
            <Row>
                {props.state.loaded ?
                    <div className="position-abs m-l-40">
                        <img src={'/images/loader.svg'} alt=""/>
                    </div>
                    : ''}
            </Row>
            <Iframe url={props.videoStreamUrl} className="iframe-b-1 max-w-500 w-100 mt-2 iframe-b-1 margin-auto-m "
                    height="350"/>
        </div>
    )
}


function renderAllCameras(props, appObject, index) {
    let isActiveApp = props.deviceID === appObject._id ? 'selected-app' : 'app';
    return (
        <Row key={'ai-apps-' + index} className={"cursor-pointer p-md m-l-sm m-r-sm m-t-xs " + isActiveApp}
             onClick={() => props.onDeviceClicked(appObject)}>
            <Row className="w-250">
                <div className="p-sm display-flex align-item-center">
                    <img src='/images/my-cameras.png' alt='my-camera'/>
                </div>
                <div className="m-l-20 b-vertical-line"/>
                <div className="m-l-20">
                    <div className="fs-17">{appObject.entityData.deviceName}</div>
                    {props.userMADDetails.map((mad) => {
                        return appNames(props, mad, appObject._id);
                    })}
                </div>
            </Row>
        </Row>
    )
}

function appNames(props, mad, deviceID) {
    let checkStatus = mad.connectedDevice.find((devices) => {
        return devices.deviceID === deviceID
    });
    return (<div className="m-t-sm fs-13">
        {checkStatus ? mad.appMeta.appName : ""}
    </div>)
}

function myCamerasComponent(props) {
    // console.log("my cameras props-->", props);
    return (
    
        <div className="h-100-vh">
          <Column className="h-100-vh justify-content-between">
            {!props.cameraList || !props.cameraList.length ? (
              <PulseLoader
                loading={props.state.loaded}
                size={20}
                color={"#3979bb"}
              />
            ) : (
              renderCameraView(props)
            )}
          </Column>
          {confirmation(props)}
          {appMenuMobile(props)}
        </div>
        
    );
}


const onMenuClose = (props, appObject, index) => {
    let isActiveApp = props.deviceID === appObject._id ? 'selected-app' : 'app';
    return (
        <OverlayTrigger
            placement="right"
            delay={{show: 100, hide: 50}}
            overlay={deviceNameToolTip(appObject.entityData.deviceName, props)}>
            <Row key={'ai-apps-' + index} className={"cursor-pointer margin-10 p-sm w-62px h-63 " + isActiveApp}
                 onClick={() => props.onDeviceClicked(appObject)}>
                <img width="35px" height="35px" className="margin-auto" src='/images/my-cameras.png' alt='my-camera'/>
                {/* <div className="b-1-grey m-l-10" /> */}
                {/* <div className="m-l-20">
                    <div className="fs-17">{appObject.entityData.deviceName}</div>
                    {props.userMADDetails.map((mad) => {
                        return appNames(props, mad, appObject._id);
                    })}
                </div> */}
            </Row>
        </OverlayTrigger>
    )
}

function deviceNameToolTip(deviceName, props) {
    return (
        <Tooltip id="button-tooltip" {...props}>
            {deviceName}
        </Tooltip>
    );
}

const confirmation = (props) => {
    return (
        <>
        <Dialog open={props.state.confirmation}>
            <div className="h-130 w-250">
                <Row className="justify-center p-15 w-100 fs-16">
                    Do you want to delete ?
                </Row>
                <Row className="jc-space-between m-t-30">
                    <button className="h-40  b-1-ccc w-80-px m-l-15 br-4" onClick={props.handleConfirmation}>Cancel
                    </button>
                    <button className="h-40 w-80-px m-r-15 br-none bg-theme fc-white br-4"
                     onClick={props.deleteCamera}>{props.state.loading ? <MoonLoader color={'#ffffff'} loading={props.state.loading} size={30} css={override} /> : "OK"}
                    </button>
                </Row>
            </div>
        </Dialog>
         </>

    )
}


function webCamComponent(props) {
    const videoConstraints = {
        facingMode: "user"
    };
    return (
        <Column className="p-20">
            <Row className="align-item-center">
                <span className="fs-16 fc-dark-grey m-r-20 w-90">{props.cameraName}</span>
                <Row className="align-items-center flex-lg-row-reverse">
                    <Row className='b-1-ccc h-30 w-30-px justify-center'>
                        <img src='/images/settings.svg' alt='settings' onClick={() => props.editCameraDetails()}
                             className="cursor-pointer"/>
                    </Row>
                    <Row className='b-1-ccc h-30 w-30-px justify-center'>
                        <img src='/images/delete.png' className="cursor-pointer margin-auto h-20-px"
                             onClick={() => props.handleConfirmation()}
                             alt='add-device'/>
                    </Row>
                </Row>
            </Row>
            <Webcam
                audio={false}
                height={250}
                screenshotFormat="image/jpeg"
                screenshotQuality={0.95}
                videoConstraints={videoConstraints}
                className="w-100-per"
                width={600}
            />
        </Column>

    )
}


const appMenuMobile = (props) => {
    return (
        <CheeseburgerMenu className="display-none-desktop" isOpen={props.state.mobileMenuOpen}
                          closeCallback={props.handleMobileMenu}>
            <Row className='cursor-pointer m-b-sm align-item-center m-t-sm jc-space-between'>
                <img src="/images/appsStoreLogo.svg" alt="Appsstore.ai" height="23px" className="h-25px-m m-l-sm"/>
                <img src="/images/close-menu.png" height="35px" width="40px" onClick={() => {
                    props.handleMobileMenu()
                }} className="cursor-pointer display-none-desktop m-r-sm" alt=""/>
            </Row>
            {props.cameraList.map((cameraObject, index) => {
                return renderAllCameras(props, cameraObject, index);
            })}
        </CheeseburgerMenu>
    )
}


function thresholdSliderComponent(props, connectedDevices) {
    const thresholdDomain = [30, 300];
    let rate = [30];
    const sliderStyle = {
        position: 'relative',
        width: '80%',
        touchAction: 'none',
    };
    return (
        <Row>
            <Slider mode={1} step={1}
                    rootStyle={sliderStyle}
                    domain={thresholdDomain}
                    values={connectedDevices.threshold && connectedDevices.threshold > 0 ? [connectedDevices.threshold] : rate}
                    onChange={props.onSliderChange}>
                <div className="display-none-m">
                    <p>Distance</p>
                </div>
                <Rail>
                    {({getRailProps}) => (
                        <div className="m-t-sm w-100-m">
                            <SliderRail getRailProps={getRailProps}/>
                        </div>
                    )}
                </Rail>

                <Handles>
                    {({handles, getHandleProps}) => (
                        <div className="slider-handles ">
                            {handles.map(handle => (
                                <Handle key={handle.id} handle={handle}
                                        domain={thresholdDomain} getHandleProps={getHandleProps}/>
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks right={false}>
                    {({tracks, getTrackProps}) => (
                        <div className="slider-track">
                            {tracks.map(({id, source, target}) => (
                                <Track key={id} source={source} target={target}
                                       getTrackProps={getTrackProps}/>
                            ))}
                        </div>
                    )}
                </Tracks>

                <Row className="m-t-30 fs-12 jc-space-between">
                    Min<span className=""> Max</span>
                </Row>

            </Slider>

        </Row>

    );
}

const editComponent = (props) => {
    return (
        <Row className="align-items-center">
            <Row className='b-1-ccc h-30 w-30-px justify-center'>
                <img src='/images/delete.png' className="cursor-pointer margin-auto h-20-px"
                     onClick={props.handleConfirmation}
                     alt='add-device'/>
            </Row>
            <Row className='b-1-ccc h-30 w-30-px justify-center'>
                <img src='/images/settings.svg' alt='settings'
                     onClick={() => props.editCameraDetails()}
                     className="cursor-pointer"/>
            </Row>
        </Row>
    )
}

export default myCamerasComponent;
