import {httpService} from "./httpService";
import {httpConstants} from "../constants";

export default {
    getAppDetails, getUpcomingApps ,addDeveloperEntity,addContact
};

async function getAppDetails(appID) {
    let url = process.env.REACT_APP_APP_DIRECTORY_SERVICE + httpConstants.API_END_POINT.GET_APP_DETAILS + appID;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || !response.responseData.length > 0)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData[0]);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getUpcomingApps() {
    let url = process.env.REACT_APP_APP_DIRECTORY_SERVICE + httpConstants.API_END_POINT.GET_ENTITY_LIST;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addDeveloperEntity(requestData) {
    let url =  process.env.REACT_APP_APP_DIRECTORY_SERVICE + httpConstants.API_END_POINT.ADD_ENITITY;

    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addContact(requestData) {
    let url =  process.env.REACT_APP_METAVESAL_MICROSERVICE + httpConstants.API_END_POINT.ADD_CONTACT;

    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
