import {sessionManager} from "../managers/sessionManager";
import {cookiesConstants, httpConstants} from "../constants";

export default {
    uploadVideos, uploadImageToS3
};

async function uploadVideos(requestData) {
    let url = process.env.REACT_APP_UPLOAD_VIDEO_SERVICE;
    return fetch(url, {
        method: 'post',
        headers: {},
        body: requestData
    }).then(response => {
        return Promise.reject("Video Uploaded Successfully");
    }).catch(err => {
        return Promise.reject(err);
    })
}

async function uploadImageToS3(requestData) {
    let url = "https://www.appsstore.ai:3006/uploadFile";
    let jwtToken = sessionManager.getDataFromCookies(cookiesConstants.AUTH0_ACCESS_TOKEN);
    let header = new Headers();
    header.append("Authorization", "Bearer " + jwtToken);

    let requestOptions = {
        method: 'POST',
        body: requestData,
        headers: header,
        redirect: 'follow'
    };
    return new Promise(((resolve, reject) => {
        fetch(url, requestOptions).then((response) => {
            resolve({"message": "file uploaded successfully","response":response.json().then(data=>data).catch(err=>err)})
        }).catch(err =>
            reject({"message": "file not uploaded"}))
    }));

}