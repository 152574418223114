import React from "react";
import { PopupButton } from "react-calendly";

const BannerSection = () => {
  return (
    <div className="m-t-60 md-mt-28 lg-px-0 px-120 display-flex lg-flex-col-reverse base-container xxl-px-120 xl-px-70">
      <div className="m-t-80 md-mt-28 width-100-per">
        <div className="font-lato font-black md-text-ft24-30 text-ft56-70 max-w-510 lg-text-center lg-mx-auto">
          Intelligent AI-based
          <br />
          <span className="fc-4c84ff word-nowrap"> Anti-theft </span>
          system
        </div>
        <div>
          <h5 className="fs-18-22-500-lato max-w-510 font-color-light-grey-black m-t-20 lg-text-center">
            Detects theft and alerts automatically in real-time. The advanced
            facial recognition AI-technology integrates with existing CCTVs to
            prevent theft at your retail outlet or showroom.
          </h5>
        </div>
        <div className="m-t-64 display-flex lg-items-center lg-flex-col lg-gap-y-14">
          <div>
            <PopupButton
              className="blue-styled-btn w-240"
              url={process.env.REACT_APP_CALENDLY}
              rootElement={document.getElementById("root")}
              text="Talk to an AI Expert"
            />
          </div>
          {/* <div className="">
            <button className="fc-blue br-90 bg-white br-blue m-l-sm m-t-2 bg-center  btn-styled">
              Try Now
            </button>
          </div> */}
        </div>
      </div>
      <div className="lg-grid lg-place-items-center width-100-per">
        <img
          className="md-w-310 md-h-176"
          src="/images/theft-banner-image.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default BannerSection;
