import React from "react";

function WorldMapSection() {
  return (
    <div className="base-container m-t-80-imp">
      <div className="fc-dark-slate-blue text-ft32-38 font-weight-bold display-flex justify-center m-b-56">
        Our Offices
      </div>
      <div className="display-flex justify-center">
        <img
          src="/images/world-map.svg"
          className="w-100-per-m"
          alt="world-map"
        />
      </div>
      <div className="display-flex justify-center m-t-20">
      <img
          src="/images/dot-icon.svg"
          className=" w-4-px"
          alt="world-map"
        />
        <span className="text-ft24-32 fc-lightBlue30 fw-500 m-l-8 m-r-20">USA</span>
        <img
          src="/images/dot-icon.svg"
          className=" w-4-px"
          alt="world-map"
        />
        <span className="text-ft24-32 fc-lightBlue30 fw-500 m-l-8">India</span>
      </div>
    </div>
  );
}

export default WorldMapSection;
