import React from "react";
import { PopupButton } from "react-calendly";

const FreeConsultationBtn = () => {
  return (
    <div className="display-flex justify-center m-t-64">
      <PopupButton
        className="font-lato text-ft28-140 fc-dark-slate-blue bg-white border-darkBlue-50 h-96 font-semibold text-align-center w-362 br-24"
        url={process.env.REACT_APP_CALENDLY}
        rootElement={document.getElementById("root")}
        text="Talk to an AI Expert"
      />
    </div>
  );
};

export default FreeConsultationBtn;
