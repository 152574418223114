import React, { useState } from "react";
import { useCallback } from "react";
import { pathConstants } from "../../constants";
import Utils from "../../utility";

const SearchBar = ({  upComingApps }) => {
  const [displayedApps, setDisplayedApps] = useState(upComingApps.slice(0, 6));
  const [allDisplayed, setAllDisplayed] = useState(false);
  let moreIndex = 6;

  const updateField = useCallback(
    (event) => {
      let searchApps = upComingApps.filter((ele) =>
        ele.entityData.appName
          .toLowerCase()
          .includes(event.target.value.toLowerCase().trim())
      );
      setDisplayedApps(searchApps);
      if (searchApps.length <= 6 || searchApps.length === upComingApps.length)
        return setAllDisplayed(true);
      else return setAllDisplayed(false);
    },
    [upComingApps]
  );

  const navigate = useCallback((app) => {
    Utils.navigateToPath(pathConstants.APP_DETAILS + app._id);
  }, []);

  const handleMoreClick = useCallback((event) => {
    event.stopPropagation();
    setDisplayedApps((prev) => upComingApps.slice(0, prev.length + moreIndex));
    if (displayedApps.length + moreIndex >= upComingApps.length) {
      setAllDisplayed(true);
    }
  }, [displayedApps.length, moreIndex ,upComingApps]);

  return (
    <div className="position-absolute bg-white w-100-per top-49 left-0 z-index-100 p-25 z-index-102">
      <div className="fs-48 font-bold fc-4c84ff m-t-20 m-b-50">Apps</div>
      <div className="w-100-per border-1 border-black-10 rounded p-2 mt-3">
        <input
          className="w-100-per outline-none border-0"
          placeholder="Search Apps"
          onChange={(event) => updateField(event)}
        />
      </div>

      {displayedApps.map(({ entityData, ...app }, idx) => {
        return (
          <div
            className="flex m-t-15 gap-10"
            key={idx}
            onClick={() => navigate(app)}
          >
            <div className="">
              <img src={entityData.logo} alt="" />
            </div>
            <div className="">
              <div>{entityData.appName}</div>
              <div className="">{entityData.appType}</div>
              <div>{entityData.description}</div>
            </div>
          </div>
        );
      })}

      {!allDisplayed && (
        <div className="m-t-20 display-grid grid-place-items-center">
          <div
            onClick={(event) => handleMoreClick(event)}
            className="fc-4c84ff fs-24 font-bold"
          >
            More Apps...
          </div>
        </div>
      )}

      {displayedApps.length === 0 && (
        <div className="m-t-20 display-grid grid-place-items-center">
          No Apps Found...
        </div>
      )}
    </div>
  );
};

export default SearchBar;
