import React from "react";
import { Column, Row } from "simple-flexbox";
import Iframe from "react-iframe";
import Switch from "react-switch";
import { pathConstants } from "../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import PulseLoader from "react-spinners/PulseLoader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Webcam from "react-webcam";
import CheeseburgerMenu from "cheeseburger-menu";
import { Carousel } from "react-bootstrap";
import { useWideScreen } from "../../customHook/customHook";

function RenderAppInputView(props) {
  const isWideScreen = useWideScreen(1200);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: isWideScreen ? "environment" :"user",
  };
  let madOutputUrl = `${props.MADDetail.activeOutput}?madID=${props.MADDetail._id}&accessToken=${props.accessToken}`;
  return (
    // <Row className="w-100-per h-100-vh">
    <Row className="w-100-per">
      <Column>
        {props.state.menuOpen ? (
          <Column className="hide-left-menu w-300">
            <div className="hide-left-menu display-flex jc-space-between m-t-35 p-r-xs p-l-sm align-item-center">
              <span className="">ACTIVE APPS</span>
              <img
                src="/images/close-menu.png"
                height="30px"
                width="30px"
                onClick={() => {
                  props.toggleMenu();
                }}
                className={"cursor-pointer"}
                alt={"close-menu"}
              />
            </div>
            <div>
              {props.userMADDetails.map((appObject, index) => {
                return renderAllAppsDetails(props, appObject, index);
              })}
            </div>
          </Column>
        ) : (
          <Column className="hide-left-menu m-t-35">
            <Row className="align-item-center p-l-sm">
              APPS
              <img
                src="/images/open-menu.png"
                height="30px"
                width="30px"
                onClick={() => {
                  props.toggleMenu();
                }}
                className={"cursor-pointer"}
                alt={"open-menu"}
              />
            </Row>
            <div>
              {props.userMADDetails.map((appObject, index) => {
                return onMenuClose(props, appObject, index);
              })}
            </div>
          </Column>
        )}
        <Column className="display-none-desktop display-none-m m-t-35 overflow-auto">
          <Row className="align-item-center">
            <span className="margin-auto fs-14">APPS</span>
            {/* <img src="/images/open-menu.png" height="30px" width="30px" onClick={() => { props.toggleMenu() }} /> */}
          </Row>
          <div>
            {props.userMADDetails.map((appObject, index) => {
              return onMenuClose(props, appObject, index);
            })}
          </div>
        </Column>
      </Column>
      <div className="b-vertical-line display-none-m" />
      <Column className="flex-grow-1" id="iFrameParent">
        <div className="display-none-desktop jc-space-between display-flex-m m-t-15  m-b-50-m-">
          <img
            src="/images/open-menu.png"
            height="35px"
            width="40px"
            onClick={() => {
              props.handleMobileMenu();
            }}
            className="cursor-pointer display-none-desktop"
            alt={"open-menu"}
          />
          {uploadVideoComponent(props)}
        </div>

 {/* start-webcam and carousel for mobile  */}
        <div>
          <div className="display-none-desktop display-flex justify-content-center m-b-90-m">
            {props.state.isWebCam ? (
              <div className="m-l-10 m-t-20 h-200">
                {props.state.isWebCam && props.state.active ? (
                  <div>
                    <div></div>
                    <Webcam
                      style={{ marginLeft: "-3px" }}
                      className="w-300"
                      audio={false}
                      ref={props.setRef}
                      screenshotFormat="image/jpeg"
                      screenshotQuality={0.95}
                      videoConstraints={videoConstraints}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
         
          {(props.state.deviceFrames && props.state.deviceFrames.length > 0) ||
          (props.state.webCamImage && props.state.webCamImage.length > 0) ? (
            <div className="display-none-desktop display-flex justify-content-center m-b-90-m">
              {frameForMobile(props)}
            </div>
          ) : (
            ""
          )}
          {/* end-webcam and carousel for mobile  */}

          <Row className="h-95vh">
            <Column className="w-100-per">
              {/*{props.MADDetail && props.MADDetail.connectedDevice && props.MADDetail.connectedDevice.length &&*/}
              {/*props.MADDetail.schedules && props.MADDetail.schedules.length ||*/}
              {/*props.MADDetail.isVideoUploaded ?*/}
              <Iframe
                id="madInputFrame"
                className="iframe-b-0 m-l-sm h-100-per m-l-0-m position-relative-m"
                url={madOutputUrl}
                onLoad={props.onIFrameLoad}
              />

              {/* <div
                                className="fs-15 m-t-sm m-l-sm m-t-140-m m-b-min-250 m-r-sm display-none-desktop">{props.MADDetail.appMeta.appName === "Face Mask" ? appQueryInformation.MESSAGE : ""}<a
                                href="mailto:support@appsstore.ai"
                                className="m-l-xs">support@appsstore.ai</a>
                            </div> */}

              {/*:*/}
              {/*<div className="display-flex margin-auto">*/}
              {/*<span className="fs-15 m-l-xs">No masks are detected. <a*/}
              {/*    href="/camera-sign-up" className="fc-theme fs-15"> Add a camera</a> or <label*/}
              {/*    className="fs-15 cursor-pointer margin-auto m-b-0 fc-theme"*/}
              {/*    htmlFor="multiplefileupload">Upload*/}
              {/*            a Video.</label>*/}
              {/*        <input type='file' id="multiplefileupload"*/}
              {/*               style={{position: "absolute", display: "none"}}*/}
              {/*               onChange={(event) => {*/}
              {/*                   props.uploadVideo(event)*/}
              {/*               }}*/}
              {/*        /></span>*/}
              {/*    </div>}*/}
            </Column>
            <Column className="p-t-xs m-t-sm m-r-15 w-270 position-hidden-m">
              {uploadVideoComponent(props)}
              <div className="m-t-20 justify-content-center">
                {props.state.deviceFrames &&
                props.state.deviceFrames.length > 0 ? (
                  <div>
                    {props.state.deviceFrames.map((frame) => {
                      return <div>{deviceFrames(props, frame)}</div>;
                    })}
                  </div>
                ) : (
                  ""
                )}
                <Column>
                <div className="desktop-visibility-hidden">
                  {props.state.isWebCam ? (
                    <div className="m-l-10 m-t-20 h-200">
                      {props.state.isWebCam && props.state.active ? (
                        <div>
                          <div></div>
                          <Webcam
                            style={{ marginLeft: "-3px" }}
                            className="w-300"
                            audio={false}
                            ref={props.setRef}
                            screenshotFormat="image/jpeg"
                            screenshotQuality={0.95}
                            videoConstraints={videoConstraints}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  </div>
                </Column>
                {webCamComponent(props)}
              </div>
              {/* </Column> */}
              <PulseLoader
                id="loading"
                className="pulse-loader"
                loading={props.state.loaded}
                size={20}
                color={"#3979bb"}
              />
            </Column>
          </Row>
          {/* <div className="fs-15 m-t-sm m-l-sm display-none-m">{props.MADDetail.appMeta.appName === "Face Mask" ?  appQueryInformation.MESSAGE : ""}<a
                        href="mailto:support@appsstore.ai"
                        className="m-l-xs">support@appsstore.ai</a>
                    </div> */}
        </div>
      </Column>
      {/* <Column>
                {props.state.isWebCam ?
                    <div className="m-l-10 m-t-20 h-200">
                        {props.state.isWebCam && props.state.active ?
                            <div>
                                <div></div>
                                <Webcam
                                    style={{marginLeft: "-3px"}}
                                    className="position-abs"
                                    audio={false}
                                    ref={props.setRef}
                                    screenshotFormat="image/jpeg"
                                    screenshotQuality={0.95}
                                    videoConstraints={videoConstraints}
                                />
                            </div> : ""}
                    </div> : ''}
            </Column> */}
    </Row>
  );
}

function AddCamera(props) {
  return (
    <Dropdown className="b-1-ccc p-0-m br-4 box-shadow-none h-35">
      <Dropdown.Toggle
        split
        id="dropdown-split-basic"
        className="br-3 display-flex align-item-center br-none box-shadow-none b-none dropdown-btn bg-white fc-black"
      >
        <div className="display-flex align-item-center">
          <img
            src="/images/add-camera.png"
            height="20px"
            className="margin-auto"
            alt={"add-camera"}
          />
          {props.MADDetail &&
          props.MADDetail.connectedDevice &&
          props.MADDetail.connectedDevice.length === 0 ? (
            <span className="fc-black-hover">No camera</span>
          ) : (
            ""
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="width-250 fs-14 p-sm drop-menu">
        {props.userAllDevices.map((devices, index) => {
          return PopoverDevices(props, devices, index);
        })}
        <div
          className="cursor-pointer p-sm m-t-sm app"
          onClick={() => props.addAnotherDevice(pathConstants.USER_DEVICES)}
        >
          <img
            src="/images/plus.png"
            className="offset-5 w-30-px"
            alt="add-device"
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function PopoverDevices(props, devices, index) {
  let madDevices = props.MADDetail.connectedDevice.find((device) => {
    return device.deviceID === devices._id;
  });
  let checked =
    madDevices && madDevices.deviceName && madDevices.deviceName.length > 0;
  return devices.entityData.ffmpegFailureCount >= 10 ? (
    ""
  ) : (
    <Row className="m-t-15 align-item-center">
      <img src="/images/my-cameras.png" alt="my-camera" />
      <div className="m-l-20 w-100-px word-break m-r-40">
        {devices.entityData.deviceName}
      </div>
      {AddSwitchToggle(props, checked, devices)}
    </Row>
  );
}

function AddSwitchToggle(props, checked, deviceDetails) {
  return (
    <Switch
      checked={checked}
      onChange={() =>
        props.handleChange(checked, deviceDetails, props.MADDetail)
      }
      checkedIcon={false}
      uncheckedIcon={false}
      onColor={"#4c84ff"}
    />
  );
}

function renderAllAppsDetails(props, appObject, index) {
  let isActiveApp =
    props.MADDetail._id === appObject._id ? "selected-app" : "app";
  return (
    <Row
      key={"ai-apps-" + index}
      className={"cursor-pointer p-md m-l-sm m-r-sm m-t-xs " + isActiveApp}
      onClick={() => props.onAppsClicked(appObject)}
    >
      <Row className="w-320">
        <div className="display-flex align-item-center">
          <img
            width="45px"
            className="bg-white"
            height="45px"
            src={appObject.appMeta.logo}
            alt="meeting"
          />
        </div>
        <div className="m-l-20 b-vertical-line" />
        <Column className="m-l-20">
          <div className="fs-16">{appObject.appMeta.appName}</div>
          {appObject.connectedDevice && appObject.connectedDevice.length ? (
            appObject.connectedDevice.length <= 2 ? (
              appObject.connectedDevice.map((devices) => {
                return (
                  <Row className="m-t-15 align-item-center">
                    <img
                      height="15px"
                      width="15px"
                      src="/images/my-cameras.png"
                      alt="meeting"
                    />
                    <div className="m-l-10 fs-13">{devices.deviceName}</div>
                  </Row>
                );
              })
            ) : (
              <Column>
                <Row className="m-t-15 align-item-center">
                  <img
                    height="15px"
                    width="15px"
                    src="/images/my-cameras.png"
                    alt="meeting"
                  />
                  <div className="m-l-10 fs-13">
                    {appObject.connectedDevice[0].deviceName}
                  </div>
                </Row>
                <Row className="m-t-15 align-item-center">
                  <img
                    height="15px"
                    width="15px"
                    src="/images/my-cameras.png"
                    alt="meeting"
                  />
                  <div className="m-l-10 fs-13">
                    +{appObject.connectedDevice.length - 1}
                  </div>
                </Row>
              </Column>
            )
          ) : (
            ""
          )}
        </Column>
      </Row>
    </Row>
  );
}

function uploadVideoComponent(props) {
  return (
    <Row className="flex-row m-t-30 m-t-0-m justify-content-end m-r-sm-m">
      <div>
        {props.MADDetail &&
        props.MADDetail.connectedDevice &&
        props.MADDetail.connectedDevice.length &&
        props.state.active ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 50 }}
            overlay={greenToolTip}
          >
            <div className="m-r-xs cursor-pointer align-item-center display-flex b-1-ccc w-40-px br-4 h-35">
              <img
                src="/images/cameraActive.png"
                height="10px"
                className="margin-auto"
                alt=""
              />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 50 }}
            overlay={redToolTip}
          >
            <div className="m-r-xs cursor-pointer align-item-center display-flex b-1-ccc w-40-px br-4 h-35">
              <img
                src="/images/inactive.png"
                height="15px"
                className="margin-auto"
                alt=""
              />
            </div>
          </OverlayTrigger>
        )}
      </div>
      <div className="m-r-xs cursor-pointer ">
        {props.MADDetail &&
        props.MADDetail.connectedDevice &&
        props.MADDetail.connectedDevice.length > 0 ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 50 }}
            overlay={settingsToolTip}
          >
            <div
              className="align-item-center display-flex br-4 h-35"
              onClick={props.navigateToAppInput}
            >
              <img src="/images/settings.png" alt="" className="h-35" />
              {/* <span>App Settings</span> */}
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 50 }}
            overlay={noCameraToolTip}
          >
            <div className="align-item-center display-flex br-4 h-35">
              <img src="/images/settings.png" className="h-35" alt="" />
            </div>
          </OverlayTrigger>
        )}
      </div>
      <div className="cursor-pointer">
        <OverlayTrigger
          placement="top"
          delay={{ show: 100, hide: 50 }}
          overlay={addCameraToolTip}
        >
          {AddCamera(props)}
        </OverlayTrigger>
      </div>
      {/*<div className="cursor-pointer">*/}
      {/*    <div className="display-flex b-1-ccc br-4 h-35 p-xs">*/}
      {/*        <label className="fs-15 cursor-pointer margin-auto m-b-0"*/}
      {/*               for="multiplefileupload">Upload*/}
      {/*            Video </label>*/}
      {/*        <input type='file' id="multiplefileupload"*/}
      {/*               style={{position: "absolute", display: "none"}}*/}
      {/*               onChange={(event) => {*/}
      {/*                   props.uploadVideo(event)*/}
      {/*               }}*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*</div>*/}
    </Row>
  );
}

const settingsToolTip = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Settings
    </Tooltip>
  );
};

const greenToolTip = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {/* Face Mask app is working and looking for masks as per your schedule. */}
      This app is working
    </Tooltip>
  );
};

const redToolTip = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      You do not have a schedule at this time. You do not have a camera
      connected to the AppsStore
    </Tooltip>
  );
};

const noCameraToolTip = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Please Add a Camera or Upload a Video
    </Tooltip>
  );
};

const addCameraToolTip = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Add Camera
    </Tooltip>
  );
};

function myAppComponent(props) {
  // console.log("myAppComponent props--> ", props);
  return (
    <div className="min-h-100vh">
      <Row className="h-100-per justify-content-between w-100-per">
        {!props.userMADDetails || !props.userMADDetails.length ? (
          <div className="text-center h-100-vh w-100-per">
            <img src={"/images/loader.svg"} alt="" />
          </div>
        ) : (
          RenderAppInputView(props)
        )}
      </Row>
      <div className="display-none-desktop">{appMenuMobile(props)}</div>
    </div>
  );
}

function onMenuClose(props, appObject, index) {
  let isActiveApp =
    props.MADDetail._id === appObject._id ? "selected-app" : "app";
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 50 }}
      overlay={appNameToolTip(appObject.appMeta.appName, props)}
    >
      <Row
        key={"ai-apps-" + index}
        className={"cursor-pointer p-sm margin-10 " + isActiveApp}
        onClick={() => props.onAppsClicked(appObject)}
      >
        <Row>
          <div className="display-flex align-item-center">
            <img
              width="42px"
              height="42px"
              className="bg-white"
              src={appObject.appMeta.logo}
              alt="meeting"
            />
          </div>
        </Row>
      </Row>
    </OverlayTrigger>
  );
}

function appNameToolTip(appName, props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {appName}
    </Tooltip>
  );
}

function webCamComponent(props) {
  return (
    <div className="w-350 desktop-display-hide">
      {props.state.webCamImage ? (
        <div>
          <div className={"fw-bold m-b-sm"}>
            Recent Captures
            <br />
          </div>
          <div>
            {props.state.webCamDevice
              ? props.state.webCamDevice.deviceName
              : ""}
          </div>
        </div>
      ) : (
        ""
      )}
      <img
        src={props.state.webCamImage ? props.state.webCamImage : ""}
        height={"190px"}
        alt=""
      />
    </div>
  );
}

const deviceFrames = (props, frame) => {
  return (
    <div className="w-250 p-t-xs ml-auto">
      <Row>{frame.deviceName}</Row>
      <img src={frame.image} className="w-100-per" />
    </div>
  );
};

const appMenuMobile = (props) => {
  return (
    <CheeseburgerMenu
      className="display-none-desktop"
      isOpen={props.state.mobileMenuOpen}
      closeCallback={props.handleMobileMenu}
    >
      <Row className="cursor-pointer m-b-sm align-item-center m-t-sm jc-space-between">
        <img
          src="/images/appsStoreLogo.svg"
          alt="Appsstore.ai"
          height="23px"
          className="h-25px-m m-l-sm"
        />
        <img
          src="/images/close-menu.png"
          height="35px"
          width="40px"
          onClick={() => {
            props.handleMobileMenu();
          }}
          className="cursor-pointer display-none-desktop m-r-sm"
        />
      </Row>
      {props.userMADDetails && props.userMADDetails.length > 0
        ? props.userMADDetails.map((appObject, index) => {
            return renderAllAppsDetails(props, appObject, index);
          })
        : ""}
    </CheeseburgerMenu>
  );
};

const frameForMobile = (props) => {
  return (
    <Carousel
      id={"frame"}
      className="w-100 b-1-grey"
      pauseOnHover={true}
      slide={true}
      wrap={true}
    >
      {props.state.deviceFrames && props.state.deviceFrames.length > 0
        ? props.state.deviceFrames.map((frame, index) => {
            return (
              <Carousel.Item key={"carousel-item" + index}>
                <div>
                  <img
                    className="w-100-per margin-auto"
                    src={frame.image}
                    alt={"Media-" + index}
                  />
                </div>
              </Carousel.Item>
            );
          })
        : ""}
      {props.state.isWebCam ? (
        <Carousel.Item key={"carousel-item"}>
          {props.state.webCamImage ? (
            <div>
              <img
                className="w-100-per margin-auto"
                src={props.state.webCamImage ? props.state.webCamImage : ""}
                alt={"Media"}
              />
            </div>
          ) : (
            ""
          )}
        </Carousel.Item>
      ) : (
        ""
      )}
    </Carousel>
  );
};

export default myAppComponent;
