import React from "react";
import { render } from "react-dom";
import Routes from "./routes";
import store from "./store.js";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/custom.css";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes component={Routes} />
      </Provider>
    </BrowserRouter>
  );
}

 render(<App/>, document.getElementById('root'));
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App/>, rootElement);
// } else {
//   render(<App/>, rootElement);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
