import { httpService } from "../utility/httpService";
import { httpConstants } from "../constants";

const {
  METHOD_TYPE: { POST, GET },
  API_END_POINT: { GET_DATA, PREDICT, TRAIN },
} = httpConstants;

export default class AttendanceSystemService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_ATTENDANCE_SYSTEM_SERVICE;
    this.localUrl = "http://localhost:3001/";
  }
  getData() {
    return new Promise((resolve, reject) => {
      httpService(GET, null, null, this.baseUrl + GET_DATA)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res.responseData);
        })
        .catch(reject);
    });
  }

  train(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(this.baseUrl + TRAIN, {
          method: POST,
          body,
        }).then((r) => r.json());

        if (!response.success) {
          const error =
            response.responseCode === 500 ? response : response?.message;
          return reject(error);
        }
        return resolve(response.responseData);
      } catch (err) {
        reject(err);
      }
    });
  }

  predict(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(this.baseUrl + PREDICT, {
          method: POST,
          body,
        }).then((r) => r.json());

        if (!response.success) {
          const error =
            response.responseCode === 500 ? response : response?.message;
          return reject(error);
        }
        return resolve(response.responseData);
      } catch (err) {
        reject(err);
      }
    });
  }
}
