import React from 'react';
import {Column, Row} from "simple-flexbox";
import {
    Button, Typography, Select,
    MenuItem
} from "@material-ui/core";
import Iframe from 'react-iframe'
import {Tabs, Tab} from "react-bootstrap";
import {Slider, Rail, Handles, Tracks, Ticks} from "react-compound-slider";
import {SliderRail, Handle, Track, Tick} from './sliderComponent';
import {creditConstants} from '../../constants';
import PulseLoader from "react-spinners/PulseLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/core";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const sliderStyle = {
    position: 'relative',
    width: '100%',
    touchAction: 'none',
};

const selectStyle = {
    padding: '0.25rem',
    border: 'solid 1px #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '100%',
    height: '36px'
};

const frameRateDomain = [1, 600];
const frameIntervalDomain = [10, 300];

function renderAppInputView(props) {
    let madInputUrl = `${props.MADDetail.activeInput}?madID=${props.madID}&accessToken=${props.accessToken}`;
    let rtsp = `${props.videoStreamUrl}${props.deviceID}?width=325&height=210`;
    let logo = `${props.MADDetail.appMeta.logo}`;
    let appSetting = props.MADDetail.appMeta.appStoreSettingType ? props.MADDetail.appMeta.appStoreSettingType : "";
    let remainingCredits = props.MADDetail.internalUsage[appSetting] ? props.MADDetail.internalUsage[appSetting].remainingCredits : "";
    let connectedDevices = (props.MADDetail.connectedDevice && props.MADDetail.connectedDevice.length) ? props.MADDetail.connectedDevice.length : "per";
    const {frameRate, frameInterval} = props;
    return (
      
        <Row className="w-100-per-m p-l-25 mt-20-t">
          <Column className="m-r-20 w-30 align-items-center display-none-m">
            {/*<Column>*/}
            {/*    <label className="fs-16 fc-dark-grey">Camera Feed</label>*/}
            {/*    <Iframe url={rtsp} width="328px" height="216px" className="mt-3 iframe-b-1"/>*/}
            {/*</Column>*/}
            {/*<img className='h-200' src="/images/vertical-connection-image.png"/>*/}
            <Column className="w-100 display-none-m">
              <Typography className="p-l-md fs-16 fc-dark-grey fw-bold">
                Selected App
              </Typography>
              <Column className="b-1-grey" alignItems="center">
                <Column className="p-60" alignItems="center">
                  <img className="w-100-px" src={logo} />
                  <Typography className="fs-16 fw-bold fc-dark-grey mt-2">
                    {props.MADDetail.appMeta.appName}
                  </Typography>
                </Column>
              </Column>
            </Column>
          </Column>
          <Column className="w-75 flex-grow-1 w-100-per-m m-t-20-m">
            <Row
              className="display-none-desktop  m-l-135 br-4 h-35 fc-blue cursor-pointer text-align-left br-none m-b-20"
              onClick={() => props.navigateToDashboard()}
            >
              <span className="fs-15 fw-bold m-l-20">Back to Dashboard</span>
            </Row>
            <Tabs
              id="madInputSetting"
              defaultActiveKey="frame"
              className="madInputSetting m-l-15"
            >
              <Tab
                eventKey="settings"
                className="fs-16 fw-bold fc-dark-grey h-100"
                title="Settings"
              >
                <PulseLoader
                  id="loading"
                  className="pulse-loader"
                  loading={props.state.loaded}
                  size={20}
                  color={"#3979bb"}
                />
                <Iframe
                  id="madInputFrame"
                  className="iframe-b-0 mt-3 h-100-vh"
                  url={madInputUrl}
                  height="100vh"
                  width="100%"
                  onLoad={() => props.onIFrameLoad(props.madID, props._this)}
                />
              </Tab>

              <Tab
                eventKey="schedules"
                className="fs-16 fc-dark-grey overflow-x-auto-m- w-360-m"
                title="Schedules"
              >
                <div className="m-t-30 jc-space-between display-flex align-item-center">
                  <div className="">
                    <div className="p-l-xs-m fs-16 fc-black">
                      Set Camera Tracking Schedule
                    </div>
                  </div>
                  <Row>
                    <span>
                      {props.state.loading && (
                        <MoonLoader
                          color={"#3979bb"}
                          loading={props.state.loading}
                          size={30}
                        />
                      )}
                    </span>
                  </Row>
                  <Row
                    className="color-007bff jc-flex-end align-item-center m-l-15 height-40 outline-none cursor-pointer br-4 fs-15 p-r-xs-m"
                    onClick={props.addScheduleFields}
                  >
                    + Add Schedules
                  </Row>
                </div>
                <hr className="m-t-sm p-l-xs-m p-r-xs-m" />
                <div id="registerPage" className="m-t-20">
                  {props.schedules.map((schedule, index) => {
                    return (
                      <div className="mb-1 display-flex w-100 align-item-center jc-center-m">
                        <div className="">
                          <div className="fs-14 fc-black m-t-sm">Day</div>
                          <div className="w-140 w-95px-m m-t-xs">
                            <Select
                              className="w-140 color-dark-grey h-40 w-95px-m"
                              style={selectStyle}
                              value={props.schedules[index].day}
                              onChange={(event) => {
                                props.changeShiftTime(
                                  event.target.value,
                                  "day",
                                  index
                                );
                              }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                            >
                              <MenuItem value="0">Sunday</MenuItem>
                              <MenuItem value="1">Monday</MenuItem>
                              <MenuItem value="2">Tuesday</MenuItem>
                              <MenuItem value="3">Wednesday</MenuItem>
                              <MenuItem value="4">Thursday</MenuItem>
                              <MenuItem value="5">Friday</MenuItem>
                              <MenuItem value="6">Saturday</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="m-l-sm">
                          <div className="fs-14 fc-black m-t-sm">
                            Start Time
                          </div>
                          <DatePicker
                            onChange={(date) => {
                              props.changeShiftTime(
                                new Date(date).getTime(),
                                "startTime",
                                index
                              );
                            }}
                            selected={schedule.startTime}
                            showTimeSelect
                            dateFormat="h:mm aa"
                            className="br-3 w-140 w-95px-m color-dark-grey m-t-xs h-40 b-1-ccc outline-none bg-white p-l-15 fs-15 "
                          />
                        </div>
                        <div className="m-l-sm">
                          <div className="fs-14 fc-black m-t-sm">End Time</div>
                          <DatePicker
                            onChange={(date) => {
                              props.changeShiftTime(
                                new Date(date).getTime(),
                                "endTime",
                                index
                              );
                            }}
                            selected={schedule.endTime}
                            showTimeSelect
                            dateFormat="h:mm aa"
                            className="br-3 w-140 w-95px-m m-t-xs color-dark-grey h-40 b-1-ccc outline-none bg-white p-l-15 fs-15"
                          />
                        </div>
                        <div className="m-l-sm">
                          <div className="fs-15 fc-black m-t-sm invisible">
                            Shift
                          </div>
                          <div className="p-t-5 text-right  display-flex align-item-center m-l-sm">
                            <img
                              className="width-20 cursor-pointer"
                              height="20px"
                              onClick={(event) =>
                                props.removeScheduleFields(index)
                              }
                              src="/images/cross_red.png"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <Row className="m-t-20">
                    <Button
                      className="bg-blue fc-white outline-none text-transform-none  width-80 py-2 fs-15 cursor-pointer"
                      onClick={props.saveSchedules}
                    >
                      Save
                    </Button>
                  </Row>
                </div>
              </Tab>

              <Tab
                eventKey="frame"
                className="fs-16 fc-dark-grey"
                title="Usage and Billing"
              >
                <Column className="mt-3 p-4">
                  {/* <div> */}
                  <Row className="flex-wrap-m">
                    <Column
                      className="b-1-input br-4 text-center p-l-15 p-r-15 margin-auto-m h-110px-m"
                      vertical={"center"}
                    >
                      <Row className="fs-15 fc-warm-grey">
                        <span className="margin-auto">Available credits</span>
                      </Row>
                      <Row className="fs-40 m-t-20">
                        <span className="margin-auto">{remainingCredits}</span>
                      </Row>
                      {/*<Row className="fs-15 m-t-20">*/}
                      {/*    <Button className="fs-14 bg-theme fc-white margin-auto"*/}
                      {/*            onClick={props.addCreditsButtonClick}>*/}
                      {/*        Add Credits*/}
                      {/*    </Button>*/}
                      {/*</Row>*/}
                    </Column>
                    <div className="m-l-35 m-r-40 b-vertical-line display-none-m " />
                    <Column className="w-100 justify-content-between p-t-sm p-b-sm m-t-20-m">
                      <Row className="jc-space-between">
                        <Column>
                          <Row className="fs-15">Image capturing frequency</Row>
                          <Row className="fs-12 fc-warm-grey">
                            ({creditConstants.CAPTURING_FREQUENCY})
                          </Row>
                          {/* <span className="fs-18">{props.frameRate}</span> */}
                        </Column>
                        <Row className="h-35 w-25-per-m flex-wrap-m">
                          {props.state.loading && (
                            <MoonLoader
                              color={"#3979bb"}
                              loading={props.state.loading}
                              size={30}
                            />
                          )}
                        </Row>

                        <Row className="h-35 w-25-per-m flex-wrap-m">
                          <select
                            value={props.frameRate}
                            className="outline-none fs-17 m-r-20 w-80-px h-35px-m w-100-px-m"
                            onChange={(event) =>
                              props.onChangeFrameRate(event.target.value)
                            }
                          >
                            {props.MADDetail.appMeta.appName ===
                              "Label Printing App" && (
                              <option value="0.5 sec">0.5 sec</option>
                            )}
                            {props.MADDetail.appMeta.appName ===
                              "Label Printing App" && (
                              <option value="1 sec">1 sec</option>
                            )}
                            <option value="1 sec">1 sec</option>
                            <option value="2 sec">2 sec</option>
                            <option value="5 sec">5 sec</option>
                            <option value="10 sec">10 sec</option>
                            <option value="15 sec">15 sec</option>
                            <option value="30 sec">30 sec</option>
                            <option value="1 min">1 min</option>
                            <option value="2 min">2 min</option>
                            <option value="5 min">5 min</option>
                            <option value="10 min">10 min</option>
                            <option value="30 min">30 min</option>
                            <option value="1 hr">1 hour</option>
                          </select>
                          <div
                            className="fs-18 m-t-sm-m br-3 bg-theme cursor-pointer h-35px-m text-align-center fc-white outline-none w-100-px text-capitalize display-flex align-items-center"
                            onClick={props.onUpdateMADFrames}
                          >
                            <span className="margin-auto">Save</span>
                          </div>
                        </Row>
                      </Row>
                      <Row className="m-t-30 w-100-per">
                        <Column className="w-100-per m-t-sm-m">
                          <Row className="align-item-center jc-space-between">
                            <span className="fs-15 w-75-per-m">
                              Estimated credit usage for {connectedDevices}{" "}
                              {connectedDevices > 1 ? (
                                <span>cameras</span>
                              ) : (
                                <span>camera</span>
                              )}{" "}
                              in a month based on your current schedules.
                            </span>
                            <span className="fs-20 m-r-xs">
                              ~{props.estimatedCredits}
                            </span>
                          </Row>
                          <Row className="fc-warm-grey fs-12 w-75-per-m">
                            Assuming 3 people are seen in a photo taken every{" "}
                            {props.frameRate}
                            {/*<span>({creditConstants.TIME_PERIOD})</span>*/}
                          </Row>
                        </Column>
                      </Row>
                    </Column>
                  </Row>
                  <Column className=" m-t-40">
                    <Row className="justify-content-between m-t-20 w-100 ">
                      <Column className="">Purchase Credits</Column>
                      <Column className="fc-theme cursor-pointer fs-13">
                        Learn why I need credits?
                      </Column>
                    </Row>

                    <Row className="justify-content-between w-100 b-1-input p-t-lg p-b-lg p-l-25 p-r-md m-t-sm br-4">
                      <Column
                        className="fs-20 font-weight-bold"
                        vertical={"center"}
                      >
                        {props.estimatedCredits} Credits
                      </Column>
                      <Column
                        className="fs-13 font-weight-bold color-555 p-r-4"
                        vertical={"center"}
                      >
                        Estimated 1 month usage
                      </Column>
                      <Column className="" vertical={"center"}>
                        <Button
                          className="fs-18 bg-theme fc-white outline-none w-110-px text-capitalize p-t-3 p-b-2"
                          onClick={() =>
                            props.createPayment(
                              (
                                props.estimatedCredits /
                                props.state.creditPerDollar
                              ).toFixed(2),
                              1
                            )
                          }
                        >
                          $
                          {(
                            props.estimatedCredits / props.state.creditPerDollar
                          ).toFixed(2) >= 20
                            ? (
                                props.estimatedCredits /
                                props.state.creditPerDollar
                              ).toFixed(2)
                            : "20.00"}
                        </Button>
                      </Column>
                    </Row>

                    <Row className="justify-content-between w-100 b-1-input p-t-lg p-b-lg p-l-25 p-r-md m-t-20 br-4">
                      <Column
                        className="fs-20 font-weight-bold"
                        vertical={"center"}
                      >
                        {props.estimatedCredits * 3} Credits
                      </Column>
                      <Column
                        className="fs-13 font-weight-bold color-555 p-r-4"
                        vertical={"center"}
                      >
                        Estimated 3 month usage
                      </Column>
                      <Column className="" vertical={"center"}>
                        <Button
                          className="fs-18 bg-theme fc-white outline-none w-110-px text-capitalize p-t-3 p-b-2"
                          onClick={() =>
                            props.createPayment(
                              (
                                (props.estimatedCredits * 3) /
                                props.state.creditPerDollar
                              ).toFixed(2),
                              3
                            )
                          }
                        >
                          $
                          {(
                            (props.estimatedCredits * 3) /
                            props.state.creditPerDollar
                          ).toFixed(2) >= 20
                            ? (
                                (props.estimatedCredits * 3) /
                                props.state.creditPerDollar
                              ).toFixed(2)
                            : "20.00"}
                        </Button>
                      </Column>
                    </Row>

                    <Row className="justify-content-between w-100 b-1-input p-t-lg p-b-lg p-l-25 p-r-md m-t-20 br-4">
                      <Column
                        className="fs-20 font-weight-bold"
                        vertical={"center"}
                      >
                        {props.estimatedCredits * 6} Credits
                      </Column>
                      <Column
                        className="fs-13 font-weight-bold color-555 p-r-4"
                        vertical={"center"}
                      >
                        Estimated 6 month usage
                      </Column>
                      <Column className="" vertical={"center"}>
                        <Button
                          className="fs-18 bg-theme fc-white outline-none w-110-px text-capitalize p-t-3 p-b-2"
                          onClick={() =>
                            props.createPayment(
                              (
                                (props.estimatedCredits * 6) /
                                props.state.creditPerDollar
                              ).toFixed(2),
                              6
                            )
                          }
                        >
                          $
                          {(
                            (props.estimatedCredits * 6) /
                            props.state.creditPerDollar
                          ).toFixed(2) >= 20
                            ? (
                                (props.estimatedCredits * 6) /
                                props.state.creditPerDollar
                              ).toFixed(2)
                            : "20.00"}
                        </Button>
                      </Column>
                    </Row>
                  </Column>
                  {/* </div> */}
                  <div>
                    {props.appSetting && props.appSetting.length ? (
                      <div className="display-none">
                        <Row className="mt-3">
                          <Typography className="fs-16 fc-dark-grey">
                            Per Video length?
                          </Typography>
                        </Row>
                        <Row className="mt-3 mb-5">
                          <Slider
                            mode={1}
                            step={10}
                            domain={frameIntervalDomain}
                            rootStyle={sliderStyle}
                            onChange={props.onChangeFrameInterval}
                            values={frameInterval}
                          >
                            <Rail>
                              {({ getRailProps }) => (
                                <SliderRail getRailProps={getRailProps} />
                              )}
                            </Rail>
                            <Handles>
                              {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                  {handles.map((handle) => (
                                    <Handle
                                      key={handle.id}
                                      handle={handle}
                                      domain={frameRateDomain}
                                      getHandleProps={getHandleProps}
                                    />
                                  ))}
                                </div>
                              )}
                            </Handles>
                            <Tracks right={false}>
                              {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                  {tracks.map(({ id, source, target }) => (
                                    <Track
                                      key={id}
                                      source={source}
                                      target={target}
                                      getTrackProps={getTrackProps}
                                    />
                                  ))}
                                </div>
                              )}
                            </Tracks>
                            <Ticks count={6}>
                              {({ ticks }) => (
                                <div className="slider-ticks">
                                  {ticks.map((tick) => (
                                    <Tick
                                      key={tick.id}
                                      tick={tick}
                                      count={ticks.length}
                                    />
                                  ))}
                                </div>
                              )}
                            </Ticks>
                          </Slider>
                        </Row>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  <Row className="m-b-60 m-t-40"></Row>
                </Column>
              </Tab>
            </Tabs>
          </Column>
          <Column
            className="position-relative display-none-m m-t-30-m display-flex-tab m-l-135- b-1-ccc br-4 h-35 fc-blue cursor-pointer width-160 align-item-center br-none"
            onClick={() => props.navigateToDashboard()}
          >
            <span className="fs-15 fw-bold margin-auto display-none-m">
              Open Dashboard
            </span>
          </Column>
        </Row>
        
      
    );
}

function appListComponent(props) {
    // console.log(props);
    return (
        <div>
            <Column className="p-3-desktop justify-content-between h-100-vh">
                {(!props.MADDetail || !Object.keys(props.MADDetail).length) ?
                    <PulseLoader loading={props.state.loaded} size={20}
                                 color={"#3979bb"}/> : renderAppInputView(props)}
                                 
            </Column>
        </div>
    );
}

export default appListComponent;
