import React from 'react'
import {Row} from "simple-flexbox";
import HeaderComponent from '../common/headerComponent'
import {Button} from "@material-ui/core";
import Webcam from "react-webcam";
import FooterComponent2 from '../common/footerComponentSecond';

function UserDeviceComponent(props) {
    const videoConstraints = {
        width: 480,
        height: 480,
        facingMode: "user"
    };
    return (
      <div className="home-container">
        <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
          <HeaderComponent loginRedirectEndPoint="" />
        </div>
        <Row className="text-align-center justify-content-center p-15-m">
          <div className="mt-auto w-100-per-m p-l-xs-m p-r-xs-m">
            <span className="fs-30 fs-26-m fw-bold w-450 l-h-3-m">
              Configure Your{" "}
              {props.deviceType === "phone" ? "iPhone/Android " : "Laptop "}{" "}
              Camera
            </span>
            <div className="margin-auto w-480 w-100-per-m p-l-xs-m p-r-xs-m p-b-25px">
              <Webcam
                audio={false}
                height={480}
                screenshotFormat="image/jpeg"
                width={480}
                screenshotQuality={0.95}
                videoConstraints={videoConstraints}
                className="m-t-20 input-border bg-f9f9f9-m w-100-per-m cursor-pointer h-250-m"
              />
              <div className="m-t-20 fc-dark-grey fs-16 fw-bold">
                Give name to your camera
              </div>
              <input
                id="deviceName"
                type="text"
                onChange={props.onChangeEvent}
                value={props.deviceName}
                className="w-100 fs-16 p-2 mt-1 outline-none h-45 b-1-input"
                placeholder="Office.Reception"
              />
              <Button
                className={
                  "w-100 m-t-20 m-b-40 fc-white h-45 text-capitalize fs-18 fw-500 cursor-pointer bg-theme"
                }
                onClick={props.onCameraConnectClicked}
              >
                Connect
              </Button>
            </div>
          </div>
        </Row>
        <FooterComponent2 />
      </div>
    );
}

export default UserDeviceComponent;

