import React from "react";

const FacesComponent = ({ faces }) => {
  return (
    <div className="m-b-200">
      <div className="text-ft40-56 font-lato font-bold text-align-center m-t-44 fc-dark-slate-blue m-b-15">
        Face
      </div>
      <div className="px-120">
        {Object.keys(faces).map((name, idx) => (
          <div key={idx} className="m-t-40">
            <div className="font-lato font-bold fs-28">{name}</div>
            <div className="flex gap-24 justify-between flex-wrap m-t-16">
              {faces[name].map((detail, idx) => (
                <div key={idx}>
                  <img
                    className="w-153 h-153 br-16"
                    src={detail?.imageUrl}
                    alt=""
                  />
                  <div className="text-align-center m-auto font-lato font-regular text-darkGray-100 fs-16 trucate w-80-px">
                    {detail?.imageUrl?.substr(
                      detail?.imageUrl?.lastIndexOf("/") + 1
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacesComponent;
