import React from "react";
import BaseComponent from "../../baseComponent";
import TheftDetectionComponent from "./theftDetectionComponent";
import TopBar from "./topBar";
import BannerSection from "./bannerSection";
import PreventTheft from "./preventTheft";
import FreeConsultationBtn from "./freeConsultationBtn";
import WhyAppsstore from "./whyAppsstore";
import FormAndCCTV from "./formAndCCTV";
import BigBrands from "./bigBrands";
import Footer from "../../common/footerComponentSecond";

class Theft extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="home-container">
        <TopBar />
        <BannerSection />
        <PreventTheft />
        <FreeConsultationBtn />
        <WhyAppsstore />
        <FormAndCCTV />
        <BigBrands />
        <TheftDetectionComponent />
        <Footer />
      </div>
    );
  }
}

export default Theft;
