import React from "react";

function AboutUsComponent(props) {
  const { changeState } = props;
  return (
    <div className="home-container">
      <div className="base-container px-120 md-px-24 xxl-px-120 xl-px-70">
        <div className="mt-3 flex-wrap-reverse-m  margin-auto display-flex">
          <div className="pl-2 pr-3 m-t-20-m">
            <div className="font-weight-bold fs-30 mt-lg-5">Developers</div>
            <div className="font-weight-bold fs-18-22-500 mt-lg-5">
              Let’s build future-proof computer vision apps together!
            </div>
            <div className="mt-4 fs-18 l-h-1-4">
              We are a team of highly enthusiast Data Scientists whose mission
              is to bring AI closer to everyone. Skills on the &nbsp;
              <a href="https://www.appsstore.ai">AppsStore.ai</a>&nbsp; enable
              consumers to access AI, attach it to the existing cameras and
              increase productivity immediately. Our aim is to build a
              marketplace featuring a wide range of AI apps that allow
              individuals, enterprises and businesses to use AI applications on
              their local devices, computers and cloud servers.
            </div>
            <div className="mt-3 fs-18 l-h-1-4">
              Our aim is to build a marketplace featuring a wide range of AI
              apps that allow individuals, enterprises and businesses to use AI
              applications on their local devices, computers and cloud servers.
            </div>
            <div className="mt-4">
              <a href="mailto:support@appsstore.ai">hello@appsstore.ai</a>
            </div>
            <div className="mt-4">
              <button
                onClick={() => changeState("type", "developer-form")}
                className="fs-18 bg-theme w-150 border-0 text-white h-40 rounded cursor-pointer"
              >
                List Your App
              </button>
            </div>
          </div>
          <div className="mt-lg-5 display-flex-m jc-center-m margin-auto-m">
            <img src="/images/appsStore.png" className="w-100-per-m" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsComponent;
