import React, { useEffect } from "react";

import HeaderComponent from "../common/headerComponent";
import FooterComponent2 from "../common/footerComponentSecond";
import NewAboutUsComponent from "./newAboutUsComponent";

function Aboutus() {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="home-container">
      <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
        <HeaderComponent loginRedirectEndPoint="" />
      </div>
      <NewAboutUsComponent />
      <FooterComponent2 />
    </div>
  );
}

export default Aboutus;
