import React from "react";
import BaseComponent from "../baseComponent";
import HomePage from "./homePage";
import Utils, { dispatchAction } from "../../utility";
import { AppDirectoryService, MADService } from "../../services";
import { connect } from "react-redux";
import {
  actionsConst,
  apiFailureConstants,
  cookiesConstants,
  pathConstants,
} from "../../constants";
import AuthService from "../../services/auth0Service";
import { sessionManager } from "../../managers/sessionManager";
import HeaderComponent from "../common/headerComponent";
import FooterComponent2 from "../common/footerComponentSecond";


class Home extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      IP: "",
      upcomingApps: [],
      appID: "",
      uiState:{
        inputFieldActive:false,
      },
      activeAppPurchased: [],
      blankField: "bg-theme",
    };
  }

  async componentDidMount() {
    // this.authObject = new AuthService("");
    // await this.authObject.saveJWTToken();
    window.scrollTo(0,0)
    let [error, upcomingAppResponse] = await Utils.parseResponse(
      AppDirectoryService.getUpcomingApps()
    );
    if (error) Utils.apiFailureToast(apiFailureConstants.GET_UPCOMING_APPS);
    else if (upcomingAppResponse) {
      this.setState({
        upcomingApps: upcomingAppResponse.responseData.entityList,
      });
      this.props.dispatchAction("upcomingApps", upcomingAppResponse.responseData.entityList);
      let upcomingApps = upcomingAppResponse.responseData.entityList;
      let activeAppsPurchased = [];
      for (let index = 0; index < upcomingApps.length; index++) {
        if (upcomingApps[index].isActive) {
          let activeAppPurchased = await this.getOrder(upcomingApps[index]._id);
          activeAppsPurchased.push({
            appID: upcomingApps[index]._id,
            isAppPurchased: activeAppPurchased.isAppPurchased
              ? activeAppPurchased.isAppPurchased
              : false,
            isFreeTrialActive: activeAppPurchased.isFreeTrialActive
              ? activeAppPurchased.isFreeTrialActive
              : false,
            isFreeTrialExpired: activeAppPurchased.isFreeTrialExpired
              ? activeAppPurchased.isFreeTrialExpired
              : false,
          });
        }
      }
      this.setState({ activeAppPurchased: activeAppsPurchased });
    }
  }

  onChangeEvent = (event) => {
    if(event.target.value.length!==0) this.setState({blankField:"bg-theme"})  
    this.setState({ [event.target.id]: event.target.value });
  };

  validateIP = () => {
    return Utils.isValidIP(this.state.IP) || !Utils.isEmpty(this.state.IP);
  };

  onStarted=async ()=>{
    if (!this.props.isLoggedIn) {
      await this.sendForSignIn();
      return;
    }
    Utils.navigateToPath(pathConstants.CAMERA_SIGN_UP);

  }

  onGetStartedClicked = async (event) => {
    event.preventDefault();
    this.setState({uiState: {
      inputFieldActive: true
    }})
    if(this.state.uiState.inputFieldActive){
      if (this.state.IP.trim().length === 0) {
        this.setState({ blankField: "bg-red" });
        return;
      }
        this.setState({ blankField: "bg-theme" });
        this.props.dispatchAction(actionsConst.GET_STARTED_IP, {
          ip: this.state.IP,
        });
        if (!this.props.isLoggedIn) {
          localStorage.setItem("IP", this.state.IP);
          await this.sendForSignIn();
          return;
        }
        Utils.navigateToPath(pathConstants.CAMERA_SIGN_UP);
    } 

    // if (this.validateIP(this.state.IP)) {
   

    // } else
    //     Utils.basicAlert("Invalid RTSP camera IP");
  };

  getOrder = async (appID) => {
    let isAppPurchased, isFreeTrialActive, isFreeTrialExpired;
    if (!sessionManager.getDataFromCookies(cookiesConstants.USER_ID)) {
      return false;
    }
    let requestBody = {
      "purchasedBy.userID": sessionManager.getDataFromCookies(
        cookiesConstants.USER_ID
      ),
      "appDetails.appId": appID,
    };

    let [error, getOrderResponse] = await Utils.parseResponse(
      MADService.getOrder(requestBody)
    );
    if (
      !error &&
      getOrderResponse &&
      getOrderResponse.isPurchased &&
      getOrderResponse.expiryTimeMillis > Date.now()
    )
      isAppPurchased = true;
    if (
      !error &&
      getOrderResponse &&
      getOrderResponse.freeTrialInfo &&
      getOrderResponse.freeTrialInfo.isActive &&
      getOrderResponse.freeTrialInfo.freeTill > Date.now()
    ) {
      isFreeTrialActive = true;
      isFreeTrialExpired = false;
    }
    if (
      !error &&
      getOrderResponse &&
      getOrderResponse.freeTrialInfo &&
      getOrderResponse.freeTrialInfo.isActive &&
      getOrderResponse.freeTrialInfo.freeTill <= Date.now()
    ) {
      isFreeTrialExpired = true;
      isFreeTrialActive = false;
    }
    return {
      isAppPurchased,
      isFreeTrialActive,
      isFreeTrialExpired,
      getOrderResponse,
    };
  };

  async sendForSignIn() {
    this.authObject = new AuthService(
      process.env.REACT_APP_WEB_APP + "camera-sign-up"
    );
    await this.authObject.signIn(
      process.env.REACT_APP_WEB_APP + "camera-sign-up"
    );
  }

  onUpcomingAppsClicked = (id, value, app) => {
    if (value) Utils.navigateToPath(pathConstants.MY_APPS);
    else {
      // Utils.navigateToPath(pathConstants.APP_DETAILS + app.entityData._id);
      Utils.navigateToPath(pathConstants.APP_DETAILS + app._id);
    }
  };

  render() {
    return (
      // <HomeComponent
      //     onChangeEvent={this.onChangeEvent}
      //     onGetStartedClicked={this.onGetStartedClicked}
      //     appID={this.state.appID}
      //     IP={this.state.IP}
      //     blankField={this.state.blankField}
      //     upcomingApps={this.state.upcomingApps}
      //     onUpcomingAppsClicked={this.onUpcomingAppsClicked}
      //     activeAppPurchased={this.state.activeAppPurchased}
      // />
      <div className="home-container">
        <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
          <HeaderComponent loginRedirectEndPoint="" />
        </div>
        <HomePage
          onChangeEvent={this.onChangeEvent}
          onGetStartedClicked={this.onGetStartedClicked}
          appID={this.state.appID}
          IP={this.state.IP}
          uiState={this.state.uiState}
          blankField={this.state.blankField}
          upcomingApps={this.state.upcomingApps}
          onUpcomingAppsClicked={this.onUpcomingAppsClicked}
          activeAppPurchased={this.state.activeAppPurchased}
          onStarted={this.onStarted}
        />
        <FooterComponent2 />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userDetails: state.user.userDetails,
    isLoggedIn: state.user.isLoggedIn,
  };
};

export default connect(mapStateToProps, { dispatchAction })(Home);
