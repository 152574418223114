import React from "react";
import { Column, Row } from "simple-flexbox";
import Form from "./form";
import TestimonialSection from "./testimonialSection";

const howAiHelps = [
  {
    desc: "Real-time notifications for quick response",
    image: "/images/notification.png",
  },
  {
    desc: "Accurate theft detection",
    image: "/images/cameraFocuseImage.png",
  },
  {
    desc: "Continuous surveillance",
    image: "/images/surviellenceImage.png",
  },
  {
    desc: "Accurate Analysis of footage",
    image: "/images/inspectionImage.png",
  },
];

const TheftDetectionComponent = () => {
  return (
    <div>
      <div className="font-lato text-ft32-40 font-bold text-align-center md-mt-36 m-t-120">
        Make camera AI enabled & stop thefts
      </div>
      <div className="display-grid px-120 gap-24 md-gap-0 md-px-0 grid-cols-2 lg-grid-cols-1 h-460 lg-h-auto base-container xxl-px-120 xl-px-70">
        <div className="grid-place-items-center md-px-24">
          <img
            className="md-w-310 width-100-per md-mt-14 max-h-360"
            src="/images/stop-theft-image.png"
            alt=""
          />
        </div>
        <div className="max-w-686 lg-max-w-none md-mt-14 m-t-64">
          <Form />
        </div>
      </div>
      {/* *********************************************************************************************************** */}
      {/* <Row>
        <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
          <div className=" m-t-60  md-mt-14  m-l-auto m-r-auto ">
            <div className="padd-top">
              <video controls width="100%" className=" br-30 m-min56-per-top">
                <source src="/images/theftDetection.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </Row> */}
      <Row className="display-flex flex-direction-column m-t-100 home-container ">
        <p className="font-lato font-bold text-ft32-40 text-align-center">
          How AI helps
        </p>
        <div className="display-flex justify-center  m-t-60 md-mt-0 flex-direction-row px-120 md-px-24 md-grid md-grid-cols-2 md-place-items-center md-gap-24 grid-gap-align">
          {howAiHelps.map(({ desc, image }, idx) => (
            <div
              key={idx}
              className="width-180 h-150 grid-place-items-center md-w-140 md-h-88 "
            >
              <div>
                <img src={image} alt="" className="md-h-40 md-w-40" />
              </div>
              <p className="text-align-center font-lato text-ft18-22 md-text-ft14-17">
                {desc}
              </p>
            </div>
          ))}
        </div>
      </Row>
      {/* *********************************************************************************************************** */}

      {/* <Row className="display-flex  m-t-60 flex-direction-column lg-hidden">
        <Row className="justify-center">
          <p className="fs-24-29-400-lato  text-align-center">
            let’s us help you to secure your business fill this form
            <br /> to get a call back
          </p>
        </Row>
        <div className="">
          <div className="max-w-686 mx-auto md-max-w-none">
            <Form />
          </div>
        </div>
      </Row> */}
      {/* *********************************************************************************************************** */}
      <TestimonialSection />
      {/* *********************************************************************************************************** */}
      {/* <div className="display-flex m-t-60 flex-direction-column md-bg-lightBlue-10 ">
        <div className="m-t-30 font-lato text-align-center  md-px-24 text-ft24-29 md-text-ft18-22 md-pt-32 m-b-18">
          Ready to make your showroom theft free? <br /> Fill in your details
          and our AI expert will be in touch with you.
        </div>
        <div className="">
          <div className="max-w-686 mx-auto md-max-w-none">
            <Form />
          </div>
        </div>
      </div> */}
      {/* *********************************************************************************************************** */}
      <div className="px-120 m-t-60 md-px-24 m-t-100 xxl-px-120 xl-px-70 base-container">
        <div className="bg-theme-blue gap-24 h-200 br-30 display-flex md-flex-col md-h-360 md-grid md-place-items-center md-border-2-blue md-bg-white">
          <div className="m-t-40 m-l-60 md-ml-0 m-l-120">
            <img
              className="md-filter-blue-10"
              src="/images/theifImage.svg"
              alt=""
            />
          </div>
          <Column className="">
            <div className="m-t-60 md-mt-0 md-ml-0 m-r-8-per ">
              <p className="font-lato font-semibold text-ft1vw-29  fc-white md-text-ft14-17 md-text-center md-text-black">
                Many businesses have already secured themselves using
                AppsStore’s Theft Detection on their CCTV cameras.
              </p>
            </div>
            <button className="fc-white br-90 bg-black btn-outline-none m-t-2 bg-center m-l-auto md-mr-auto md-mt-14 fs-22 br-1-b4d font-medium m-r-150 h-54 w-200 font-lato">
              Try Now
            </button>
          </Column>
        </div>
      </div>
    </div>
  );
};

export default TheftDetectionComponent;
