import React from "react";
import BaseComponent from '../baseComponent'
import {connect} from "react-redux";
import Utils, {dispatchAction} from "../../utility";
import DeviceCameraSignUpComponent from "./deviceCameraSignUpComponent";
import {apiFailureConstants, cookiesConstants, pathConstants} from "../../constants";
import {sessionManager} from "../../managers/sessionManager";
import {DeviceUserMapService, MADService} from "../../services";
import utility from "../../utility";

class DeviceCameraSignUp extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            deviceName: "",
            deviceType: "",
            webCamDetails: {}
        }
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        let deviceType = localStorage.getItem("deviceType");
        if (!deviceType || deviceType.length <= 0)
            this.setState({deviceType: "laptop"});
        else
            this.setState({deviceType: deviceType})
    }

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    onCameraConnectClicked = async () => {
        if (!this.state.deviceName)
            return false;
        let userID = sessionManager.getDataFromCookies(cookiesConstants.USER_ID);
        // let userID = "auth0|5eb98232e8c4f20c13c47f70";
        if (!userID) {
            Utils.apiFailureToast(apiFailureConstants.NOT_LOGIN);
            return Utils.navigateToPath(pathConstants.HOME);
        }
        let madDetail = await this.getUserMad(userID);
        await this.userAllDevice(userID);
        await this.isDeviceWebCamRegistered();
        if (!this.state.webCamDetails || Object.keys(this.state.webCamDetails).length <= 0) {
            await this.storeDeviceDetail();
            if (madDetail && Object.keys(madDetail).length > 0) {
                await this.updateMAD(userID);
                await this.addMadSchedule(this.state.madDetails);
            }
            utility.apiSuccessToast("WebCam added successfully")
        } else {
            utility.apiFailureToast("WebCam already added");
            if (madDetail && Object.keys(madDetail).length > 0) {
                return Utils.navigateToPath(pathConstants.MY_APPS);
            }
            Utils.apiFailureToast("Please purchase an app")
            return Utils.navigateToPath(pathConstants.HOME);
        }
        localStorage.clear();
        localStorage.setItem("webCam", this.state.deviceName);
        if (madDetail && Object.keys(madDetail).length > 0) {
            return Utils.navigateToPath(pathConstants.MY_APPS);
        }
        Utils.apiFailureToast("Please purchase an app")
        return Utils.navigateToPath(pathConstants.HOME);
    };

    getUserMad = async (userID) => {
        let userMADResponse = await MADService.getUserMAD(userID).catch(err => {
            return {};
        });
        if (!userMADResponse) {
            return {};
        }
        await this.setState({madDetails: userMADResponse[0]});
        return userMADResponse[0];
    };

    userAllDevice = async (userID) => {
        let query = {
            queryObj: JSON.stringify({
                'entityData.userDetails.userID': userID
            }),
            limit: 0, skip: 0
        };
        let [error, userDevicesResponse] = await Utils.parseResponse(DeviceUserMapService.getDevicesByQueryObj(query));
        if (!error)
            this.setState({userAllDevices: userDevicesResponse});
    };

    isDeviceWebCamRegistered = async () => {
        let deviceWebCamDetails = this.state.userAllDevices.find((device) => {
            return (device.entityData.executingServer === "webCam");
        });
        if (deviceWebCamDetails && Object.keys(deviceWebCamDetails).length >= 1)
            await this.setState({webCamDetails: deviceWebCamDetails});
    };

    storeDeviceDetail = async () => {
        let deviceDetails = {
            'entityMetaData': JSON.stringify({}),
            'entityType': 'connected_device',
            'entityData': JSON.stringify({
                "executingServer": 'webCam',
                "deviceID": Utils.generateRandomNumber(9),
                "IP": "",
                "deviceName": this.state.deviceName ? this.state.deviceName : "Device Web Cam",
                "username": "",
                "password": "",
                "rtsp": {
                    "proxy": "",
                    "url": ""
                },
                "camera-meta-data": {
                    "companyName": "",
                    "model": "",
                },
                "ffmpegFailureCount": 0,
                "userDetails": {
                    "userID": this.props.userDetails.sub,
                    "userName": this.props.userDetails.name,
                    "emailID": this.props.userDetails.email
                }
            })
        };
        let [error, deviceDetailsResponse] = await Utils.parseResponse(DeviceUserMapService.saveDeviceDetails(deviceDetails));
        if (error || !deviceDetailsResponse || !Object.keys(deviceDetailsResponse).length) {
            Utils.apiFailureToast(apiFailureConstants.DEVICE_NOT_ADDED);
            return Utils.navigateToPath(pathConstants.HOME);
        }
        await this.setState({webCamDetails: deviceDetailsResponse});
        return deviceDetailsResponse;
    };

    updateMAD = async (userID) => {
        if (!userID || !this.state.madDetails || !this.state.madDetails._id || !this.state.madDetails.appMeta.appID)
            return false;
        let connectedDevice = [];
        let devices = {
            "deviceName": this.state.webCamDetails.entityData.deviceName,
            "deviceID": this.state.webCamDetails._id,
            "rtsp": this.state.webCamDetails.entityData.rtsp.url,
            "executingServer": this.state.webCamDetails.entityData.executingServer ? this.state.webCamDetails.entityData.executingServer : "webCam"
        };
        connectedDevice = [...this.state.madDetails.connectedDevice, devices];
        let findObj = {
            "userID": userID,
            "_id": this.state.madDetails._id,
            "appMeta.appID": this.state.madDetails.appMeta.appID
        };
        let updateObj = {
            "addedOn": Date.now(),
            "modifiedOn": Date.now(),
            "connectedDevice": connectedDevice
        };
        let [error, updateMadResponse] = await Utils.parseResponse(MADService.updateMAD(findObj, updateObj));
    };

    addMadSchedule = async (madDetails) => {
        if (!madDetails || !madDetails._id || !madDetails.schedules || madDetails.schedules.length <= 0)
            return;
        let reqObj = {
            "madID": madDetails._id,
            "schedules": madDetails.schedules,
            "isRecurring": 1
        };
        await Utils.parseResponse(MADService.addMADSchedule(reqObj));
    };

    render() {
        return (
            <div>
                <DeviceCameraSignUpComponent
                    onCameraConnectClicked={this.onCameraConnectClicked}
                    deviceName={this.state.deviceName}
                    onChangeEvent={this.onChangeEvent}
                    deviceType={this.state.deviceType}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {user: state.user, userDetails: state.user.userDetails, isLoggedIn: state.user.isLoggedIn}
};

export default connect(mapStateToProps, {dispatchAction})(DeviceCameraSignUp);
