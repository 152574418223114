import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const SuggestApps = ({ upcomingApps, onUpcomingAppsClicked }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1500, min: 1000 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 1000, min: 0 },
      items: 2,
    },
  };
  return (
    <div className="px-120 md-px-24 base-container xxl-px-120 xl-px-70">
    <section className="m-t-64">
      <div className="fs-36-43-600 md-fs-21-140per-700 fc-dark-slate-blue m-b-30">Suggested Apps</div>
      <Carousel
        responsive={responsive}
        renderArrowsWhenDisabled={true}
        rewind={true}
        infinite={true}
      >
        {upcomingApps.map((appObject, index) => {
          return (
            !appObject.isActive && (
              <div
              key={index}
              className="display-grid grid-place-items-center gap-16 shadow-blue-1 h-451 br-16 md-br-7 px-16 md-px-5 p-t-12 md-p-t-4 p-b-28 md-p-b-9 max-w-331 height-400 md-h-237"
            >
              <img
                src={appObject.entityData.appImage}
                alt=""
                className="br-25 w-307  suggested-apps-image "
              />
              <div className="fs-24-29-600 md-fs-10-11-600 fc-dark-slate-blue t-a-c">
                {appObject.entityData.appName}
              </div>
              <div className="fs-16-19-400 md-fs-7-8-400 t-a-c fc-7d7d7d flex-1 max-w-247">
                {appObject.entityData.description}
              </div>
              <button
                onClick={() =>
                  onUpcomingAppsClicked(appObject._id, false, appObject)
                }
                className="blue-button-1 w-219 md-w-86 md-h-15 md-fs-7-8-600 md-w-86 h-38 border-0 br-25 fc-white m-t-8"
              >
                Try Now
              </button>
            </div>
            )
          );
        })}
      </Carousel>
    </section>
    </div>
  );
};

export default SuggestApps;
