import {httpService} from "./httpService";
import {httpConstants} from "../constants";

export default {
    addMAD,
    getMAD,
    getUserMAD,
    updateMadFrames,
    createOrder,
    updateOrder,
    getOrder,
    updateMAD,
    updateMADInternalUsage,
    disableApp,
    updatePayment,
    createPaymentLink,
    getMADSchedules,
    addMadSchedules,
    removeDeviceSchedule,
    updateMADConnectedDevice,
    getImageRecentFromCamera,
    addMADSchedule,
    getJWTToken
};

async function addMAD(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.ADD_MAD;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function updateMADInternalUsage(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.UPDATE_MAD_INTERNAL_USAGE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function createOrder(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.CREATE_ORDER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getOrder(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.GET_ORDER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateOrder(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.UPDATE_ORDER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getMAD(madID) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.GET_MAD + madID;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getUserMAD(userID) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.GET_USER_MAD + userID;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || !response.responseData.length > 0)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function updateMadFrames(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.UPDATE_MAD_FRAMES;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateMAD(findObj, updateObj) {
    let requestData = {findObj, updateObj};
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.UPDATE_MAD;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || !response.responseData.length > 0)
                    return Promise.reject(response);
                return Promise.resolve(response);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function disableApp(madID) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.DELETE_MAD_BY_ID + madID;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updatePayment(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.UPDATE_ORDER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function createPaymentLink(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.CREATE_PAYMENT_LINK;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getMADSchedules(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.GET_DEVICE_SCHEDULE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function addMadSchedules(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.ADD_MAD_SCHEDULES;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function updateMADConnectedDevice(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.UPDATE_CONNECTED_DEVICE_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getImageRecentFromCamera(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.GET_IMAGE_FROM_CAMERA;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function removeDeviceSchedule(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.REMOVE_DEVICE_SCHEDULE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function addMADSchedule(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.ADD_MAD_SCHEDULE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function sendNotification(requestData) {
    let url = process.env.REACT_APP_MAD_SERVICE + httpConstants.API_END_POINT.ADD_MAD_SCHEDULE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getJWTToken() {
    let url = process.env.REACT_APP_MAD_SERVICE_URL + httpConstants.API_END_POINT.GET_JWT_TOKEN;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, "", url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}