import React, { useState } from "react";
import { Column, Row } from "simple-flexbox";
import DropDown from "./dropDown";
import ImageDropZone from "../common/imageDropZone";
import { useForm } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { tooltipConstant } from "../../constants";
import { UploadVideoService, AppDirectoryService } from "../../services";
import { errorConstant } from "../../constants";
import Utils from "../../utility";

const DeveloperForm = ({ state, changeState }) => {
  const [category, setCategory] = useState("Public");
  const [languages, setLanguages] = useState("English");
  const [compatibility, setCompatibility] = useState("RTSP");
  const [logoFile, setLogoFile] = useState([]);
  const [appImageFile, setAppImageFile] = useState([]);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const generateFormData = async (file) => {
    let formData = new FormData();
    formData.append("uploadedFile", file, file.name);
    formData.append("isSignedURL", "true");
    formData.append("path", "unprocessedQueue/" + file.name);
    let url = await UploadVideoService.uploadImageToS3(formData);
    let response = await url.response;
    return response.responseData[0];
  };

  const handleFormSubmit = async (event) => {
    if (logoFile.length === 0 || appImageFile.length === 0)
      return Utils.apiFailureToast("logo and app image is required");
    try {
      const [logo, app] = await Promise.all([
        generateFormData(logoFile),
        generateFormData(appImageFile),
      ]);
      const obj = getValues();
      obj.logo = logo.unSignedUrl;
      obj.appImage = app.unSignedUrl;
      obj.languages = languages;
      obj.category = category;
      obj.compatibility = compatibility;
      obj.appRoute = obj.appName;

      const reqObj = {
        entityData: obj,
        entityMetaData: {},
        entityType: "APP_DIRECTORY",
      };

      const res = await AppDirectoryService.addDeveloperEntity(reqObj);
      if (res) return changeState("type", "success-msg");
    } catch (err) {
      console.log(err);
    }
  };

  const ToolTipText = ({ placement = "top", text }) => {
    return (
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={<Tooltip id={`tooltip-${placement}`}>{text}</Tooltip>}
      >
        <AiOutlineInfoCircle className="ml-2 cursor-pointer" />
      </OverlayTrigger>
    );
  };

  return (
    <>
      <div className={`bg-white`}>
        <div className="">
          <div className="position-absolute cross-icon home-container">
            <img
              src="/images/close.svg"
              className="cursor-pointer"
              alt=""
              onClick={() => changeState("type", "developer")}
            />
          </div>
          <div className="developer-form-container w-75 mt-5 home-container">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Column className="m-t-64  max-w-683 margin-auto  align-item-center justify-center">
                <Row className={`w-100-per m-t-32`}>
                  <Column className="form-column w-330">
                    <div className="form-label m-b-8">
                      App Name <ToolTipText text={tooltipConstant.APP_NAME} />
                      {errors.appName && (
                        <label className="font-color-red ml-2">
                          {errors.appName.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("appName", {
                        required: errorConstant.APP_NAME,
                      })}
                    />
                  </Column>

                  <Column className="form-column w-330 m-l-23">
                    <div className="form-label m-b-8">
                      Price <ToolTipText text={tooltipConstant.PRICE} />
                      {errors.amount && (
                        <label className="font-color-red ml-2">
                          {errors.amount.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("amount", {
                        required: errorConstant.PRICE,
                        validate: (value) => {
                          // Validate that the input contains only numbers
                          const isNumeric = /^\d+(\.\d+)?$/.test(value);
                          if (!isNumeric) {
                            return "Price must be number";
                          }
                          return true;
                        },
                      })}
                    />
                  </Column>
                </Row>

                <Row className={`w-100-per m-t-32`}>
                  <Column className="form-column w-330">
                    <div className="form-label m-b-8">
                      Version <ToolTipText text={tooltipConstant.VERSION} />
                      {errors.Version && (
                        <label className="font-color-red ml-2">
                          {errors.Version.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("Version", {
                        required: errorConstant.VERSION,
                      })}
                    />
                  </Column>

                  <Column className="form-column w-330 m-l-23">
                    <div className="form-label m-b-8">
                      Support Email
                      <ToolTipText text={tooltipConstant.EMAIL} />
                      {errors["support Email"] && (
                        <label className="font-color-red ml-2">
                          {errors["support Email"].message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("support Email", {
                        required: errorConstant.EMAIL,
                      })}
                    />
                  </Column>
                </Row>
                <Row className={`w-100-per m-t-32`}>
                  <Column className="form-column w-330">
                    <div className="form-label m-b-8">
                      Developer <ToolTipText text={tooltipConstant.DEVELOPER} />
                      {errors.developer && (
                        <label className="font-color-red ml-2">
                          {errors.developer.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("developer", {
                        required: errorConstant.DEVELOPER,
                      })}
                    />
                  </Column>

                  <Column className="form-column w-330 m-l-23">
                    <div className="form-label m-b-8">
                      Compatibility
                      <ToolTipText text={tooltipConstant.COMPATIBILITY} />
                    </div>
                    <DropDown
                      setValue={setCompatibility}
                      value={compatibility}
                      dropDownData={["RTSP", "IP", "Internet"]}
                    />
                  </Column>
                </Row>
                <Row className={`w-100-per m-t-32`}>
                  <Column className="form-column w-330">
                    <div className="form-label m-b-8">
                      Category <ToolTipText text={tooltipConstant.CATEGORY} />
                    </div>
                    <DropDown
                      setValue={setCategory}
                      value={category}
                      dropDownData={["Private", "Public"]}
                    />
                  </Column>

                  <Column className="form-column w-330 m-l-23">
                    <div className="form-label m-b-8">
                      Languages <ToolTipText text={tooltipConstant.LANGUAGES} />
                    </div>
                    <DropDown
                      setValue={setLanguages}
                      value={languages}
                      dropDownData={[
                        "English",
                        "French",
                        "German",
                        "Japanese",
                        "Chinese",
                      ]}
                    />
                  </Column>
                </Row>

                <Row className={`w-100-per m-t-32`}>
                  <Column className="form-column w-330">
                    <div className="form-label m-b-8">
                      Active Input
                      <ToolTipText text={tooltipConstant.ACTIVE_INPUT} />
                      {errors.activeInput && (
                        <label className="font-color-red ml-2">
                          {errors.activeInput.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("activeInput", {
                        required: errorConstant.ACTIVEINPUT,
                      })}
                    />
                  </Column>

                  <Column className="form-column w-330 m-l-23">
                    <div className="form-label m-b-8">
                      Active Output
                      <ToolTipText text={tooltipConstant.ACTIVE_OUTPUT} />
                      {errors.activeOutput && (
                        <label className="font-color-red ml-2">
                          {errors.activeOutput.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("activeOutput", {
                        required: errorConstant.ACTIVEOUTPUT,
                      })}
                    />
                  </Column>
                </Row>

                <Row className={`w-100-per m-t-32`}>
                  {/* <Column className="form-column w-330">
                    <div className="form-label m-b-8">
                      App Route
                      <ToolTipText text={tooltipConstant.APP_ROUTES} />
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("appRoute")}
                      required
                    />
                  </Column> */}

                  <Column className="w-100-per">
                    <div className="form-label m-b-8">
                      Website Link
                      <ToolTipText text={tooltipConstant.WEBSITE_LINK} />
                      {errors.websiteLink && (
                        <label className="font-color-red ml-2">
                          {errors.websiteLink.message}
                        </label>
                      )}
                    </div>
                    <input
                      className="form-input h-48 pl-2"
                      type="text"
                      {...register("websiteLink", {
                        required: errorConstant.WEBSITE_LINK,
                      })}
                    />
                  </Column>
                </Row>

                <Column className="m-t-32 w-100-per">
                  <div className="form-label m-b-8 ">
                    App Details{" "}
                    <ToolTipText text={tooltipConstant.APP_DETAILS} />
                    {errors.appDetails && (
                      <label className="font-color-red ml-2">
                        {errors.appDetails.message}
                      </label>
                    )}
                  </div>
                  <textarea
                    className="form-input h-48 resize-none pl-2 pt-2 min-h-100"
                    type="text"
                    maxLength={150}
                    {...register("appDetails", {
                      required: errorConstant.APP_DETAILS,
                    })}
                  />
                </Column>

                <Column className="m-t-32 w-100-per">
                  <div className="form-label m-b-8 ">
                    Overview <ToolTipText text={tooltipConstant.OVERVIEW} />
                    {errors.overview && (
                      <label className="font-color-red ml-2">
                        {errors.overview.message}
                      </label>
                    )}
                  </div>
                  <textarea
                    className="form-input h-48 resize-none pl-2 pt-2 min-h-100"
                    type="text"
                    maxLength={150}
                    {...register("overview", {
                      required: errorConstant.OVERVIEW,
                    })}
                  />
                </Column>

                <Column className="m-t-32 w-100-per">
                  <div className="form-label m-b-8 ">
                    Description
                    <ToolTipText text={tooltipConstant.DESCRIPTION} />
                    {errors.description && (
                      <label className="font-color-red ml-2">
                        {errors.description.message}
                      </label>
                    )}
                  </div>
                  <textarea
                    className="form-input h-48 resize-none pl-2 pt-2 min-h-100"
                    type="text"
                    maxLength={500}
                    {...register("description", {
                      required: errorConstant.DESCRIPTION,
                    })}
                  />
                </Column>

                <Row className="m-t-32 w-100-per m-b-15">
                  <Column className="width-184">
                    <div className="form-label m-b-8">
                      App Image <ToolTipText text={tooltipConstant.APP_IMAGE} />
                    </div>
                    <ImageDropZone
                      setSelectFile={setAppImageFile}
                      file={appImageFile}
                      text="Upload Image"
                    />
                  </Column>
                  <Column className="m-l-24 width-184">
                    <div className="form-label m-b-8">
                      Logo <ToolTipText text={tooltipConstant.LOGO} />
                    </div>
                    <ImageDropZone
                      setSelectFile={setLogoFile}
                      file={logoFile}
                      text="Upload"
                    />
                  </Column>
                </Row>

                <button
                  className={`
                   ${
                     Object.keys(errors).length === 0
                       ? "bg-theme cursor-pointer"
                       : "bg-red cursor-default "
                   } h-53 w-277 br-25 m-b-84 fc-white border-0`}
                >
                  Submit
                </button>
              </Column>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeveloperForm;
